<div class="presence" [ngClass]="{'presence-large': size == 'large'}">
  <img *ngIf="showBirthday" src="/assets/images/global/birthday-circle.png" class="birthday-circle" title="Send a birthday wish!" (click)="openBirthdayWishModal(templateBirthdayWish)">
  <img src="/assets/images/teams-presence/{{userPresence}}.png" title="{{userActivity}}">
</div>

<ng-template #templateBirthdayWish>
  <app-birthday-wish-modal *ngIf="birthdayData"
  [recentPeopleBirthdays]="[birthdayData]"
  (onCloseModalEmmiter)="modalRef?.hide()"
  ></app-birthday-wish-modal>
</ng-template>
