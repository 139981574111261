<div class="modal-body" [@.disabled]="!isMobile" @headerModal *ngIf="isModalOpen">
  <div class="modal-body-padding">
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeHeaderModalEmitter()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
    <div class="modal-body__search">
      <app-search-header></app-search-header>
    </div>
    <div *ngIf="sideItemsCategories && sideItemsCategories.length > 0" class="modal-body__sections">
      <ng-container *ngFor="let category of sideItemsCategories; let i = index">
        <div class="modal-body__divider"></div>
        <div class="modal-body__section">
          <div class="modal-body__section__image">
            <div class="modal-body__section__image__shadow">
              <img class="modal-body__section__image__icon" onerror="this.onerror=null;this.src='/assets/images/global/spinner.gif';" [src]="category.image">
            </div>
          </div>
          <div class="modal-body__section__title" title="{{category.title}}">
            {{category.title}}
          </div>
          <div
          (click)="onDropdownClick(i)"
          class="modal-body__section__dropdown-button"
          [@dropdownArrow]="{ value: dropdownStates[i]}">
          </div>
          <div class="modal-body__section__links"
          [@dropdown]="{ value: dropdownStates[i]}"
          [ngClass]="{'closed': !areDropdownsEnabled}">
            <div class="link" *ngFor="let item of category.items">
              <ng-container *ngIf="!hasChildren(item)">
                <a *ngIf="item.isExternal && !item.isPdf" href="{{item.link}}" target="_blank" (click)="closeHeaderModalEmitter()">{{item.title}}</a>
                <a *ngIf="!item.isExternal && !item.isPdf" routerLink="{{item.link}}" (click)="closeHeaderModalEmitter()">{{item.title}}</a>
                <a (click)="onPdfOpen(item.link); closeHeaderModalEmitter()" *ngIf="item.isPdf">{{item.title}}</a>
              </ng-container>
              <ng-container *ngIf="hasChildren(item)">
                <div *ngIf="item" class="link__parent">
                  <a *ngIf="item.isExternal && !item.isPdf" href="{{item.link}}" target="_blank" (click)="closeHeaderModalEmitter()">{{item.title}}</a>
                  <a *ngIf="!item.isExternal && !item.isPdf" routerLink="{{item.link}}" (click)="closeHeaderModalEmitter()">{{item.title}}</a>
                  <a (click)="onPdfOpen(item.link); closeHeaderModalEmitter()" *ngIf="item.isPdf">{{item.title}}</a>
                  <div
                    class="link__dropdown-button"
                    (click)="onStateDropdownClick(item.id)"
                    [@dropdownArrow]="{ value: getSideItemDropdownState(item.id)}">
                  </div>
                </div>
              </ng-container>

              <div class="link__children" *ngIf="hasChildren(item)" [@dropdown]="{ value: getSideItemDropdownState(item.id)}">
                <ng-container *ngFor="let childItem of item.children">
                  <a *ngIf="childItem.isExternal && !childItem.isPdf" href="{{childItem.link}}" target="_blank" class="link__item" (click)="closeHeaderModalEmitter()">{{childItem.title}}</a>
                  <a *ngIf="!childItem.isExternal && !childItem.isPdf" routerLink="{{childItem.link}}" class="link__item" (click)="closeHeaderModalEmitter()">{{childItem.title}}</a>
                  <a (click)="onPdfOpen(childItem.link); closeHeaderModalEmitter()" *ngIf="childItem.isPdf" class="link__item" >{{childItem.title}}</a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mobile-menu-content__close" (click)="closeHeaderModalEmitter()">
    <button type="button" class="btn-close close pull-right" aria-label="Close" >
      <div class="close-image">
        <img src="/assets/images/global/icon-system-cross-medium.png" alt="Close">
      </div>
    </button>
  </div>
</div>
