import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-alert-modal',
  templateUrl: './confirm-alert-modal.component.html',
  styleUrls: ['./confirm-alert-modal.component.scss']
})
export class ConfirmAlertModalComponent implements OnInit {

  modalRef?: BsModalRef;

  @Input() config = {
    title: 'The event was already added to your calendar!',
    subTitle: 'Do you want to add it again?',
    cancelText: 'No',
    confirmText: 'Yes'
  }

  @Output() isConfirmedEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  saveChanges() {
    this.isConfirmedEmitter.emit(true);

  }

  discardChanges() {
    this.isConfirmedEmitter.emit(false);
  }
}
