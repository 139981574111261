import { Component, Input, OnInit } from '@angular/core';
import { ICibusData } from 'src/app/Shared/Models/sidebar-dashboard.model';
import { IUserPermitions } from 'src/app/Shared/Models/user.model';

@Component({
  selector: 'app-dashboard-cibus',
  templateUrl: './dashboard-cibus.component.html',
  styleUrls: ['./dashboard-cibus.component.scss']
})
export class DashboardCibusComponent implements OnInit {

  @Input() cibusData: ICibusData
  @Input() userPermitions: IUserPermitions
  constructor() { }

  ngOnInit(): void {
  }

}
