<div class="banners">
  <ng-container *ngFor="let banner of banners; let i = index">

    <a *ngIf="banner.link" class="banner-wrapper" href="{{banner.link}}"
      target="{{banner.link.includes(windowHref) == true ? '_self' : '_blank'}}">
      <div class="banner" [ngStyle]="{
            background: 'url(' + banner.image + ')',
            backgroundRepeat: 'no-repeat'
          }">
      </div>
      <div class="image-secondary" *ngIf="banner.imageSecondary" [ngStyle]="{
        background: 'url(' + banner.imageSecondary + ')',
        backgroundRepeat: 'no-repeat'
      }"></div>
    </a>

    <a *ngIf="!banner.link" class="banner-wrapper">
      <div class="banner" [ngStyle]="{
          background: 'url(' + banner.image + ')',
          backgroundRepeat: 'no-repeat'
        }">
      </div>
      <div class="image-secondary" *ngIf="banner.imageSecondary" [ngStyle]="{
        background: 'url(' + banner.imageSecondary + ')',
        backgroundRepeat: 'no-repeat'
      }"></div>
    </a>

    <!-- <img src="{{banner.imageSecondary}}" alt=""> -->
    <!-- <div class="image-secondary" *ngIf="banner.imageSecondary" [ngStyle]="{
    background: 'url(' + banner.imageSecondary + ')',
    backgroundRepeat: 'no-repeat'
  }"></div> -->

  </ng-container>
</div>
