import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AnnouncementsGraphFacade } from './Facade/GraphApiFacade/announcements-graph.facade';

const DEFAULT_TITLE = 'Playtika';

@Injectable({ providedIn: 'root' })
export class TitleService {
  title$ = new BehaviorSubject<string>(DEFAULT_TITLE);

  private titleRoute$: Observable<string | undefined> =
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.getPageTitle(this.activatedRoute))
    );

  private titleState$ = merge(this.title$, this.titleRoute$).pipe(
    filter((title) => title !== undefined),
    tap((title) => {
      this.titleService.setTitle(`${title}`);
    })
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private modalService: BsModalService
  ) {
    this.titleState$.subscribe();
  }

  private getPageTitle(
    activatedRoute: ActivatedRoute | null
  ): string | undefined {

    if(document.getElementsByClassName("lightbox-shown").length>0){
      window.dispatchEvent(new KeyboardEvent('keydown', {
        'key': 'Escape'
      }));
    }
    setTimeout(() => {
      this.modalService.hide();
    }, 1000);
    while (activatedRoute) {
      if (activatedRoute.firstChild) {
        activatedRoute = activatedRoute.firstChild;
      } else if (
        activatedRoute.snapshot.data &&
        activatedRoute.snapshot.data['title']
      ) {
        return activatedRoute.snapshot.data['title'] as string;
      } else {
        return undefined;
      }
    }
    return undefined;
  }
}
