import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import { ArticlesApi } from 'src/app/Core/Services/GraphApiServices/articles.graph-api';
import {
  IArticle,
  ICategory,
  IArticleSource,
} from 'src/app/Shared/Models/article.model';
import { FileService } from 'src/app/Shared/Services/file.service';
import { MemberOfService } from 'src/app/Shared/Services/member-of.service';
import { PlaytikaSiteAdminService } from 'src/app/Shared/Services/playtika-site-admin.service';
import { PreloaderService } from 'src/app/Shared/Services/preloader.service';
import { SessionStorageService } from 'src/app/Shared/Services/session-storage.service';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { UtitlityService } from 'src/app/Shared/Services/utilitiy.service';
import { environment } from 'src/environments/environment';
import { ArticlesApiFacade } from '../ApiFacade/articles.facade';
import { UserGraphApiFacade } from './user-graph.facade';
import { IDepartment } from 'src/app/Shared/Models/departmens.interface';
import { result } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ArticlesFacade {
  currentCategoriesFilter: any = [];
  InMemoryCacheLimit = 900000;
  userEmail: string;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  _articleSource: IArticleSource = {
    articlesState: [],
    articles: [],
    articlesHome: [],
    articlesFiltred: [],
    articlesHomeFiltred: [],
    relatedArticles: [],
    isNewToOldSort: true,
    isArticleFilter: false,
    isArticleHomeFilter: false,
    timeCached: new Date().getTime(),
    favoriteItems: [],
    favoriteItemsAll: [],
    mostViewedArticles: [],
  };
  articleSource$: BehaviorSubject<IArticleSource> =
    new BehaviorSubject<IArticleSource>(this._articleSource);

  _categoriesItems: ICategory[] = [
    {
      id: 0,
      name: 'All topics',
      color: '#43484E',
      bg: '#E3E7EC',
      countOfItems: 0,
    },
  ];

  isMainSiteAdmin: boolean;

  categoriesItems$: BehaviorSubject<ICategory[]> = new BehaviorSubject<
    ICategory[]
  >(this._categoriesItems);

  constructor(
    private apiService: ArticlesApi,
    private storage: SessionStorageService,
    private fileService: FileService,
    private preloaderService: PreloaderService,
    protected userProfileService: UserProfileService,
    private memberOfService: MemberOfService,
    private playtikaSiteAdminService: PlaytikaSiteAdminService,
    private utilityService: UtitlityService,
    private userGraphFacade: UserGraphApiFacade,
    private articlesApiFacade: ArticlesApiFacade
  ) {
    let sort = storage.getData('isArticlesNewToOldSort');
    if (sort == null) {
      this.storage.saveData(
        this._articleSource.isNewToOldSort,
        'isArticlesNewToOldSort',
        true
      );
    } else {
      this._articleSource.isNewToOldSort = sort;
      this.articleSource$.next(this._articleSource);
    }

    this.playtikaSiteAdminService.playtikaSiteAdminSource$.subscribe(
      (source) => {
        if (source.isMainSiteAdmin != undefined) {
          this.isMainSiteAdmin = source.isMainSiteAdmin;
          this.getArticles();
          this.getArticleCategories();
        }
      }
    );

    userProfileService.userProfileSource$.subscribe((user) => {
      this.userEmail = user.userPrincipalName;
    });

    this.articleSource$.subscribe(
      (articles) => (this._articleSource.articlesState = articles.articlesState)
    );
  }

  getArticles() {
    this.isLoading$.next(true);
    this.preloaderService.showPreloader();
    return this.apiService
      .getArticles(1)
      .pipe(
        map((result: any) => {
          let sort = this.storage.getData('isArticlesNewToOldSort');
          if (sort != null && sort == false) {
            result.value.sort((a: any, b: any) =>
              a.fields.StartDate.localeCompare(b.fields.StartDate)
            );
          }

          result.value.forEach((element) => {
            let assigned =
              element.fields.AssignedTo != undefined ||
              element.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(
                    element.fields.AssignedTo,
                    element.fields.UnAssignedTo
                  )
                : true;

            if (assigned) {
              let tempArticle: IArticle = this.mapArticleItem(
                element,
                result.nextPageIndex
              );

              let double = this._articleSource.articlesState.find(
                (a) => a.id == tempArticle.id
              );
              if (double == undefined) {
                this._articleSource.articlesState.push(tempArticle);
              }
            }
          });

          this.checkIfHasUserArticles(result.nextPageIndex);
        })
      )
      .subscribe(() => {
        this._articleSource.articlesHome = [
          ...new Set(this._articleSource.articlesState),
        ];
        this._articleSource.articles = [
          ...new Set(this._articleSource.articlesState),
        ];
        this.articleSource$.next(this._articleSource);
      });
  }

  public hideArticlesFavorites() {
    this._articleSource.favoriteItems = [];
    this.currentCategoriesFilter.forEach((element) => {
      this._articleSource.isArticleFilter = true;
      this.filterArticlesByCategory(element.id);
    });
    this.articleSource$.next(this._articleSource);
  }

  public hideArticles() {
    this._articleSource.articles = [];
    this.articleSource$.next(this._articleSource);
  }

  public resetArticlesFilter() {
    this._articleSource.articlesFiltred = [];
    this._articleSource.isArticleFilter = false;
    this.articleSource$.next(this._articleSource);
  }

  public getArticlesFavorites(idlist?) {
    this.isLoading$.next(true);
    this.preloaderService.showPreloader();

    return this.articlesApiFacade
      .getArticlesFavorites()
      .pipe(
        map((result: any) => {
          let sort = this.storage.getData('isArticlesNewToOldSort');
          if (sort != null && sort == false) {
            result.value.sort((a: any, b: any) =>
              a.fields.StartDate.localeCompare(b.fields.StartDate)
            );
          } else {
            result.value.sort((a: any, b: any) =>
              b.fields.StartDate.localeCompare(a.fields.StartDate)
            );
          }
          result.value.forEach((element, index) => {
            let assigned =
              element.fields.AssignedTo != undefined ||
              element.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(
                    element.fields.AssignedTo,
                    element.fields.UnAssignedTo
                  )
                : true;

            if (assigned) {
              let tempArticle: IArticle = this.mapArticleItem(
                element,
                result.nextPageIndex
              );
              if (idlist) {
                if (
                  tempArticle.category?.id &&
                  idlist.toString().includes(tempArticle.category?.id)
                ) {
                  this._articleSource.favoriteItems = [];
                  let double = this._articleSource.favoriteItems.find(
                    (a) => a.id.toString() == tempArticle.id.toString()
                  );
                  let double1 = this._articleSource.favoriteItemsAll.find(
                    (a) => a.id == tempArticle.id
                  );
                  if (double == undefined) {
                    if (index <= 11) {
                      this._articleSource.favoriteItems =
                        this._articleSource.favoriteItemsAll;
                    }
                    if (double1 == undefined) {
                      this._articleSource.favoriteItemsAll.push(tempArticle);
                    }
                  }
                }
              } else {
                let double = this._articleSource.favoriteItems.find(
                  (a) => a.id.toString() == tempArticle.id.toString()
                );
                let double1 = this._articleSource.favoriteItemsAll.find(
                  (a) => a.id == tempArticle.id
                );
                if (double == undefined) {
                  if (index <= 11) {
                    this._articleSource.favoriteItems.push(tempArticle);
                  }
                  if (double1 == undefined) {
                    this._articleSource.favoriteItemsAll.push(tempArticle);
                  }
                }
              }
            }
          });
          this.checkMoreFavoriteArticles();
        })
      )
      .subscribe(() => {
        this.articleSource$.next(this._articleSource);
      });
  }

  checkMoreFavoriteArticles(count?, idlist?) {
    let topCount =
      count != undefined ? count : this._articleSource.favoriteItems.length;
    if (
      this._articleSource.favoriteItems.length < 12 &&
      this._articleSource.favoriteItems.length <
        this._articleSource.favoriteItemsAll.length
    ) {
      this.getMoreFavoriteArticles(topCount, true, idlist);
    } else {
      this.preloaderService.hidePreloader();
      this.isLoading$.next(false);
    }
  }

  getMoreFavoriteArticles(count, cheching?, idlist?) {
    if (idlist) {
      for (
        let index = 0;
        index < this._articleSource.favoriteItemsAll.length;
        index++
      ) {
        if (
          this._articleSource.favoriteItemsAll[index].category &&
          !this._articleSource.favoriteItemsAll[index].category?.id
            ?.toString()
            .includes(idlist)
        ) {
          this._articleSource.favoriteItemsAll.splice(index, 1);
        }
      }
    }
    let moreArticles = [
      ...this._articleSource.favoriteItemsAll.slice(count, count + 12),
    ];
    this._articleSource.favoriteItems.push(...moreArticles);
    this.articleSource$.next(this._articleSource);

    if (cheching == true) {
      this.checkMoreFavoriteArticles(count + 12, idlist);
    }
  }

  getMoreArticles(nextPageIndex: any, from?) {
    let moreArticles: IArticle[] = [];
    let moreNewArticles: IArticle[] = [];
    return this.apiService
      .getArticles(nextPageIndex)
      .pipe(
        map((result: any) => {
          for (let i = 0; i < result.value.length; i++) {
            const element = result.value[i];
            let assigned =
              element.fields.AssignedTo != undefined ||
              element.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(
                    element.fields.AssignedTo,
                    element.fields.UnAssignedTo
                  )
                : true;
            if (assigned) {
              let double = moreArticles.find((a) => a.id == result.value[i].id);
              let doubleIn =
                this._articleSource.articlesHome.find(
                  (a) => a.id == result.value[i].id
                ) != undefined &&
                this._articleSource.articles.find(
                  (a) => a.id == result.value[i].id
                ) != undefined;
              let state = this._articleSource.articlesState.find(
                (a) => a.id == result.value[i].id
              );

              if (double == undefined && state == undefined) {
                let tempArticle: IArticle = this.mapArticleItem(
                  result.value[i],
                  result.nextPageIndex
                );
                moreNewArticles.push(tempArticle);
              } else if (state) {
                if (doubleIn == false) {
                  moreArticles.push(state);
                }
              }
            }
          }

          this.checkIfHasUserArticles(result.nextPageIndex);

          this._articleSource.articlesState.push(...new Set(moreNewArticles));

          if (from == 'home') {
            this._articleSource.articlesHome.push(
              ...moreArticles,
              ...moreNewArticles
            );
            this._articleSource.articlesHome[
              this._articleSource.articlesHome.length - 1
            ].nextPageIndex = result.nextPageIndex;
          }

          if (from == 'articles') {
            this._articleSource.articles.push(
              ...moreArticles,
              ...moreNewArticles
            );
            this._articleSource.articles[
              this._articleSource.articles.length - 1
            ].nextPageIndex = result.nextPageIndex;
          }

          if (from == undefined && moreNewArticles.length > 0) {
            this._articleSource.articlesHome.push(
              ...moreArticles,
              ...moreNewArticles
            );
            this._articleSource.articlesHome[
              this._articleSource.articlesHome.length - 1
            ].nextPageIndex = result.nextPageIndex;

            this._articleSource.articles.push(
              ...moreArticles,
              ...moreNewArticles
            );
            this._articleSource.articles[
              this._articleSource.articles.length - 1
            ].nextPageIndex = result.nextPageIndex;
          }
        })
      )
      .subscribe((a) => {
        this.articleSource$.next(this._articleSource);
      });
  }

  getFilterArticlesByCategory(categoryId: any) {
    let currentFilter: IArticle[] = [];
    let currentNewFilter: IArticle[] = [];
    this.isLoading$.next(true);
    this.preloaderService.showPreloader();
    return this.apiService
      .filterArticlesByCategory(categoryId)
      .pipe(
        map((result: any) => {
          for (let i = 0; i < result.value.length; i++) {
            const element = result.value[i];
            let assigned =
              element.fields.AssignedTo != undefined ||
              element.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(
                    element.fields.AssignedTo,
                    element.fields.UnAssignedTo
                  )
                : true;

            if (assigned) {
              let double = currentFilter.find(
                (a) => a.id == result.value[i].id
              );
              let state = this._articleSource.articlesState.find(
                (a) => a.id == result.value[i].id
              );
              let lastArticle =
                this._articleSource.articlesState[
                  this._articleSource.articlesState.length - 1
                ];

              if (double == undefined && state == undefined) {
                let tempArticle: IArticle = this.mapArticleItem(
                  result.value[i]
                );
                tempArticle.nextPageIndex = lastArticle.nextPageIndex;
                currentNewFilter.push(tempArticle);
              } else if (state) {
                currentFilter.push(state);
              }
            }
          }

          this._articleSource.articlesFiltred.push(
            ...currentFilter,
            ...currentNewFilter
          );
          let sort = this.storage.getData('isArticlesNewToOldSort');
          this._articleSource.articlesFiltred.sort((a: any, b: any) =>
            a.startDate.localeCompare(b.startDate)
          );
          this._articleSource.isNewToOldSort = sort;
          if (sort != null && sort == true) {
            this._articleSource.articlesFiltred.reverse();
          }

          this._articleSource.articlesState.push(...new Set(currentNewFilter));
        })
      )
      .subscribe(() => {
        const key = 'id';
        let uniqueArticles: any = [
          ...new Map(
            this._articleSource.articlesFiltred.map((item) => [item[key], item])
          ).values(),
        ];
        this._articleSource.articlesFiltred = [...uniqueArticles];
        this.articleSource$.next(this._articleSource);
        this.preloaderService.hidePreloader();
        this.isLoading$.next(false);
      });
  }
  getFilterArticlesByCategories(ids) {
    let currentFilter: IArticle[] = [];
    let currentNewFilter: IArticle[] = [];
    this.isLoading$.next(true);
    this.preloaderService.showPreloader();
    const idsParam = ids.join(',');
    return this.apiService
      .filterArticlesByCategories(idsParam)
      .pipe(
        map((result: any) => {
          for (let i = 0; i < result.value.length; i++) {
            const element = result.value[i];
            let assigned =
              element.fields.AssignedTo != undefined ||
              element.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(
                    element.fields.AssignedTo,
                    element.fields.UnAssignedTo
                  )
                : true;

            if (assigned) {
              let double = currentFilter.find(
                (a) => a.id == result.value[i].id
              );
              let state = this._articleSource.articlesState.find(
                (a) => a.id == result.value[i].id
              );
              let lastArticle =
                this._articleSource.articlesState[
                  this._articleSource.articlesState.length - 1
                ];

              if (double == undefined && state == undefined) {
                let tempArticle: IArticle = this.mapArticleItem(
                  result.value[i]
                );
                tempArticle.nextPageIndex = lastArticle.nextPageIndex;
                currentNewFilter.push(tempArticle);
              } else if (state) {
                currentFilter.push(state);
              }
            }
          }

          this._articleSource.articlesFiltred.push(
            ...currentFilter,
            ...currentNewFilter
          );
          let sort = this.storage.getData('isArticlesNewToOldSort');
          this._articleSource.articlesFiltred.sort((a: any, b: any) =>
            a.startDate.localeCompare(b.startDate)
          );
          this._articleSource.isNewToOldSort = sort;
          if (sort != null && sort == true) {
            this._articleSource.articlesFiltred.reverse();
          }

          this._articleSource.articlesState.push(...new Set(currentNewFilter));
        })
      )
      .subscribe(() => {
        const key = 'id';
        let uniqueArticles: any = [
          ...new Map(
            this._articleSource.articlesFiltred.map((item) => [item[key], item])
          ).values(),
        ];
        this._articleSource.articlesFiltred = [...uniqueArticles];
        this.articleSource$.next(this._articleSource);
        this.preloaderService.hidePreloader();
        this.isLoading$.next(false);
      });
  }
  removeArticleFavorite(articleId) {
    let artToRemove = this._articleSource.favoriteItems.find(
      (a) => a.id == articleId
    );
    let artToRemoveFromAll = this._articleSource.favoriteItemsAll.find(
      (a) => a.id == articleId
    );

    if (artToRemove != undefined) {
      this._articleSource.favoriteItems.splice(
        this._articleSource.favoriteItems.indexOf(artToRemove),
        1
      );
    }

    if (artToRemoveFromAll != undefined) {
      this._articleSource.favoriteItemsAll.splice(
        this._articleSource.favoriteItemsAll.indexOf(artToRemoveFromAll),
        1
      );
    }
  }

  getFilterHomeArticlesByCategory(categoryId: any) {
    let currentFilter: IArticle[] = [];
    let currentNewFilter: IArticle[] = [];
    this.isLoading$.next(true);
    this.preloaderService.showPreloader();
    return this.apiService
      .filterArticlesByCategory(categoryId)
      .pipe(
        map((result: any) => {
          for (let i = 0; i < result.value.length; i++) {
            const element = result.value[i];
            let assigned =
              element.fields.AssignedTo != undefined ||
              element.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(
                    element.fields.AssignedTo,
                    element.fields.UnAssignedTo
                  )
                : true;

            if (assigned) {
              let double = currentFilter.find(
                (a) => a.id == result.value[i].id
              );
              let state = this._articleSource.articlesState.find(
                (a) => a.id == result.value[i].id
              );

              if (double == undefined && state == undefined) {
                let tempArticle: IArticle = this.mapArticleItem(
                  result.value[i]
                );
                currentNewFilter.push(tempArticle);
              } else if (state) {
                currentFilter.push(state);
              }
            }
          }

          this._articleSource.articlesHomeFiltred.push(
            ...currentFilter,
            ...currentNewFilter
          );

          this._articleSource.articlesHomeFiltred.sort((a: any, b: any) =>
            b.startDate.localeCompare(a.startDate)
          );

          this._articleSource.articlesHomeFiltred.sort((a: any, b: any) =>
            a.category.name.localeCompare(b.category.name)
          );

          this._articleSource.articlesState.push(...new Set(currentNewFilter));
        })
      )
      .subscribe(() => {
        const key = 'id';
        let uniqueArticles: any = [
          ...new Map(
            this._articleSource.articlesHomeFiltred.map((item) => [
              item[key],
              item,
            ])
          ).values(),
        ];
        this._articleSource.articlesHomeFiltred = [...uniqueArticles];
        this.articleSource$.next(this._articleSource);
        this.isLoading$.next(false);
        this.preloaderService.hidePreloader();
      });
  }

  getRelatedArticles(categoryId: any, articleId): Observable<IArticle[]> {
    this._articleSource.relatedArticles = [];

    return this.apiService
      .getArticlesByCategory(categoryId)
      .pipe(map((result) => this.parseRelatedArticles(result, articleId)));
  }

  getMostViewedArticles(count: number, articleId): Observable<IArticle[]> {
    this._articleSource.mostViewedArticles = [];

    return this.apiService.getMostViewedArticles(count).pipe(
      map((result: any) => {
        let newMostViewedArticles: IArticle[] = [];
        for (let i = 0; i < result.value.length; i++) {
          const element = result.value[i];
          let assigned =
            element.fields.AssignedTo != undefined ||
            element.fields.UnAssignedTo != undefined
              ? this.memberOfService.isMember(
                  element.fields.AssignedTo,
                  element.fields.UnAssignedTo
                )
              : true;

          if (assigned) {
            let double = this._articleSource.mostViewedArticles.find(
              (a) => a.id == result.value[i].id
            );
            let state = this._articleSource.articlesState.find(
              (a) => a.id == result.value[i].id
            );

            if (
              double == undefined &&
              state == undefined &&
              result.value[i].id != articleId
            ) {
              let tempArticle: IArticle = this.mapArticleItem(result.value[i]);
              newMostViewedArticles.push(tempArticle);
            } else if (state && result.value[i].id != articleId) {
              this._articleSource.mostViewedArticles.push(state);
            }
          }
        }

        this._articleSource.articlesState.push(
          ...new Set(newMostViewedArticles)
        );
        this._articleSource.mostViewedArticles.push(...newMostViewedArticles);
        this._articleSource.mostViewedArticles =
          this._articleSource.mostViewedArticles.filter((obj, index) => {
            return (
              index ===
              this._articleSource.mostViewedArticles.findIndex(
                (o) => obj.id === o.id
              )
            );
          });
        this.articleSource$.next(this._articleSource);

        return this._articleSource.mostViewedArticles;
      })
    );
  }

  getArticlesByKeywords(
    keywords: string[],
    currentId?: number,
    categoryId?: number
  ) {
    return this.apiService.getArticlesByKeywords(keywords).pipe(
      map((response: any) => {
        let retItems: IArticle[] = [];
        response.value.forEach((rawArticle) => {
          let isUnassigned =
            rawArticle.fields.UnAssignedTo != undefined
              ? this.memberOfService.isNotMember(rawArticle.fields.UnAssignedTo)
              : false;

          if (!isUnassigned && !(currentId && rawArticle.id == currentId)) {
            var article = this.mapArticleItem(rawArticle);
            if (
              !(
                categoryId &&
                article.category &&
                categoryId == article.category.id
              )
            )
              retItems.push(article);
          }
        });

        this.articlesApiFacade.getArticleDetails(
          retItems.map((i) => Number(i.id))
        );

        return retItems.map((a) => {
          return { value: a, type: 'Article' };
        });
      })
    );
  }

  getArticleById(id: string) {
    return this.apiService.getArticleById(id).pipe(
      map((result: any) => {
        let assigned =
          result.value[0].fields.AssignedTo != undefined ||
          result.value[0].fields.UnAssignedTo != undefined
            ? this.memberOfService.isMember(
                result.value[0].fields.AssignedTo,
                result.value[0].fields.UnAssignedTo
              )
            : true;

        let tempArticle: any;
        if (assigned) {
          tempArticle = this.mapArticleItem(result.value[0]);
          let isArticle = this._articleSource.articlesState.find(
            (a) => a.id == result.value[0].id
          );

          if (!isArticle) {
            this._articleSource.articlesState.push(tempArticle);
            this.articleSource$.next(this._articleSource);
          } else {
            tempArticle = isArticle;
          }
        }

        return tempArticle;
      }),
      catchError((err) => {
        return of({});
      })
    );
  }

  getArticleCategories() {
    let categories: ICategory[] = [];

    var defaultCategory = {
      id: 0,
      name: 'All topics',
      color: '#43484E',
      bg: '#E3E7EC',
      countOfItems: 0,
    };

    this.apiService.getArticleCategories().subscribe((result: any) => {
      let categoriesItems: any = [];
      categoriesItems.push(
        ...[
          ...result.value.filter((categor) => {
            return (
              categor.fields.CountOfItems > 0 ||
              categor.fields.AnnouncementsCount > 0
            );
          }),
        ]
      );
      categoriesItems.forEach((element, index) => {
        let category: ICategory;

        category = {
          id: element.id,
          name: element.fields.Title,
          color: element.fields.TextColor,
          bg: element.fields.BackgroundColor,
          countOfItems: element.fields.CountOfItems,
        };

        let exist = categories.find((c) => c.id == category.id);

        if (exist == undefined) {
          categories.push(category);
        }

        if (index == categoriesItems.length - 1) {
          this._categoriesItems.push(
            ...categories.sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      });

      this._categoriesItems.unshift(defaultCategory);
      const key = 'id';
      let uniqueCategories: any = [
        ...new Map(
          this._categoriesItems.map((item) => [item[key], item])
        ).values(),
      ];

      this.categoriesItems$.next(uniqueCategories);
    });
  }

  updateArticleData() {
    if (
      (new Date().getTime() - this._articleSource.timeCached >
        this.InMemoryCacheLimit &&
        this.isMainSiteAdmin != undefined) ||
      this._articleSource.articlesState.length == 0
    ) {
      this._articleSource = {
        articlesState: [],
        articles: [],
        articlesHome: [],
        articlesFiltred: [],
        articlesHomeFiltred: [],
        relatedArticles: [],
        isNewToOldSort: true,
        isArticleFilter: false,
        isArticleHomeFilter: false,
        timeCached: new Date().getTime(),
        favoriteItems: [],
        favoriteItemsAll: [],
        mostViewedArticles: [],
      };
      this.articleSource$.next(this._articleSource);
      this.getArticles();
    } else if (this._articleSource.articles.length == 0) {
      this._articleSource.articles = [
        ...new Set(this._articleSource.articlesHome),
      ];
      this._articleSource.favoriteItems = [];
      this._articleSource.favoriteItemsAll = [];
      this.articleSource$.next(this._articleSource);
    }
  }

  removeHomeArticlesFromFilter(categoryId: any) {
    this._articleSource.articlesHomeFiltred = [
      ...this._articleSource.articlesHomeFiltred.filter((article) => {
        return article.category?.id != categoryId;
      }),
    ];

    this.articleSource$.next(this._articleSource);
    this.isLoading$.next(false);
  }

  removeArticlesFromFilter(categoryId: any) {
    this._articleSource.articlesFiltred = [
      ...this._articleSource.articlesFiltred.filter((article) => {
        return article.category?.id != categoryId;
      }),
    ];

    this.articleSource$.next(this._articleSource);
    this.isLoading$.next(false);
  }

  filterArticlesByCategory(categoryId: any) {
    if (this._articleSource.favoriteItems.length > 0) {
      if (categoryId == 0) {
        this._articleSource.articlesFiltred = [];
        this._articleSource.articles = [];
        this._articleSource.isArticleFilter = false;
        this.articleSource$.next(this._articleSource);
      } else {
        let filterdItems = [
          ...new Set(
            this._articleSource.favoriteItems.filter((gallery) => {
              return gallery.category?.id == categoryId;
            })
          ),
        ];
        this._articleSource.isArticleFilter = true;
        this._articleSource.articlesFiltred.push(...filterdItems);
        this._articleSource.articlesFiltred = [
          ...new Map(
            this._articleSource.articlesFiltred.map((item) => [
              item['id'],
              item,
            ])
          ).values(),
        ];

        this.articleSource$.next(this._articleSource);
      }
    } else {
      if (categoryId != 0) {
        this._articleSource.isArticleFilter = true;
        this.articleSource$.next(this._articleSource);
        this.getFilterArticlesByCategory(categoryId);
      } else {
        this._articleSource.articlesFiltred = [];
        this._articleSource.isArticleFilter = false;
        this.articleSource$.next(this._articleSource);
        this.getArticles();
      }
    }
  }

  filterArticlesByCategories(ids: any) {
    if (this._articleSource.favoriteItems.length > 0) {
      if (!ids || (Array.isArray(ids) && ids.length == 1 && ids[0] === '0')) {
        this._articleSource.articlesFiltred = [];
        this._articleSource.articles = [];
        this._articleSource.isArticleFilter = false;
        this.articleSource$.next(this._articleSource);
      } else {
        let filterdItems = [
          ...new Set(
            this._articleSource.favoriteItems.filter((gallery) => {
              return ids.includes(gallery.category?.id);
            })
          ),
        ];
        this._articleSource.isArticleFilter = true;
        this._articleSource.articlesFiltred.push(...filterdItems);
        this._articleSource.articlesFiltred = [
          ...new Map(
            this._articleSource.articlesFiltred.map((item) => [
              item['id'],
              item,
            ])
          ).values(),
        ];

        this.articleSource$.next(this._articleSource);
      }
    } else {
      if (Array.isArray(ids) && !ids.includes('0')) {
        this._articleSource.isArticleFilter = true;
        this.articleSource$.next(this._articleSource);
        this.getFilterArticlesByCategories(ids);
      } else {
        this._articleSource.articlesFiltred = [];
        this._articleSource.isArticleFilter = false;
        this.articleSource$.next(this._articleSource);
        this.getArticles();
      }
    }
  }

  filterHomeArticlesByCategory(categoryId: any) {
    if (categoryId != 0) {
      this._articleSource.isArticleHomeFilter = true;
      this.articleSource$.next(this._articleSource);
      this.getFilterHomeArticlesByCategory(categoryId);
    } else {
      this._articleSource.articlesHomeFiltred = [];
      this._articleSource.isArticleHomeFilter = false;
      this.updateArticleData();
      this.articleSource$.next(this._articleSource);
    }
  }

  private checkIfHasUserArticles(nextPageIndex: number) {
    if (nextPageIndex != null) {
      setTimeout(() => {
        if (this._articleSource.articlesState.length < 12) {
          this.getMoreArticles(nextPageIndex);
        } else {
          this.preloaderService.hidePreloader();
          this.isLoading$.next(false);
        }
      }, 0);
    } else {
      this.preloaderService.hidePreloader();
      this.isLoading$.next(false);
    }
  }

  private parseRelatedArticles(result, articleId) {
    let newRelatedArticles: IArticle[] = [];
    let source: Array<Observable<any>> = [];
    for (let i = 0; i < result.value.length; i++) {
      const element = result.value[i];
      let assigned =
        element.fields.AssignedTo != undefined ||
        element.fields.UnAssignedTo != undefined
          ? this.memberOfService.isMember(
              element.fields.AssignedTo,
              element.fields.UnAssignedTo
            )
          : true;

      if (assigned) {
        let double = this._articleSource.relatedArticles.find(
          (a) => a.id == result.value[i].id
        );
        let state = this._articleSource.articlesState.find(
          (a) => a.id == result.value[i].id
        );

        if (
          double == undefined &&
          state == undefined &&
          result.value[i].id != articleId
        ) {
          let tempArticle: IArticle = this.mapArticleItem(result.value[i]);
          newRelatedArticles.push(tempArticle);
        } else if (state && result.value[i].id != articleId) {
          this._articleSource.relatedArticles.push(state);
        }
      }
    }

    this._articleSource.articlesState.push(...new Set(newRelatedArticles));
    this._articleSource.relatedArticles.push(...newRelatedArticles);
    this._articleSource.relatedArticles =
      this._articleSource.relatedArticles.filter((obj, index) => {
        return (
          index ===
          this._articleSource.relatedArticles.findIndex((o) => obj.id === o.id)
        );
      });
    this.articleSource$.next(this._articleSource);

    return this._articleSource.relatedArticles;
  }

  private mapArticleItem(article, nextPageIndex?: any) {
    let department =
      article.fields.PlaytikaDepartment_x003A_Title != undefined
        ? this.setArticleDepartment(article)
        : undefined;

    let category =
      article.fields.PlaytikaCategories_x003A_Title != undefined
        ? this.setArticleCategory(article)
        : undefined;

    let image =
      article.fields.Image != undefined ? article.fields.Image.Url : undefined;

    let keywords =
      article.fields.TaxKeyword != undefined
        ? article.fields.TaxKeyword
        : undefined;

    if (article.fields.ArticleVideoUrl && image == undefined) {
      image = '/assets/images/global/video-default-bg.png';
    }

    let tempArticle: IArticle = {
      title:
        article.fields.Title != undefined ? article.fields.Title : undefined,
      image: image,
      keywords: keywords,
      video: article.fields.ArticleVideoUrl,
      pdfUrl:
        article.fields.PdfURL != undefined
          ? article.fields.PdfURL.Url
          : undefined,
      body: article.fields.ArticleBody,
      startDate: article.fields.StartDate,
      descr: article.fields.PlaytikaDescription,
      id: article.id,
      nextPageIndex: nextPageIndex,
      community: article.fields.PlaytikaCommunity_x003A_Title,
      isSticky: article.fields.IsSticky,
      department: department,
      category: category,
      editor: article.fields.Editor1,
      isHebrewTitle: this.utilityService.isHebrew(
        article.fields.Title != undefined ? article.fields.Title : ''
      ),
      isHebrewDescription: this.utilityService.isHebrew(
        article.fields.PlaytikaDescription != undefined
          ? article.fields.PlaytikaDescription
          : ''
      ),
      isHebrewBody: this.utilityService.isHebrew(
        article.fields.ArticleBody != undefined
          ? article.fields.ArticleBody
          : ''
      ),
    };

    this.setArticleMediaUrl(tempArticle);
    this.setArticleEditor(tempArticle);

    return tempArticle;
  }

  private setArticleDepartment(article) {
    let department: IDepartment = {
      id: article.fields.PlaytikaDepartment_x003A_ID,
      title: article.fields.PlaytikaDepartment_x003A_Title,
      image: article.fields.PlaytikaDepartment_x003A_ImageUr,
    };

    this.setDepartmentImageUrl(department);

    return department;
  }

  private setArticleCategory(article) {
    let category: ICategory = {
      id: article.fields.PlaytikaCategories_x003A_ID,
      name: article.fields.PlaytikaCategories_x003A_Title,
      color: article.fields.PlaytikaCategories_x003A_TextCol,
      bg: article.fields.PlaytikaCategories_x003A_Backgro,
      countOfItems: 0,
    };

    return category;
  }

  private setArticleEditor(tempArticle: IArticle) {
    if (tempArticle.editor && !tempArticle.department?.title) {
      this.userGraphFacade
        .getPersonByEmail(String(tempArticle.editor))
        .subscribe((user) => {
          tempArticle.editor = user;
        });
    }
  }

  private setDepartmentImageUrl(tempDepartment: IDepartment) {
    if (
      tempDepartment.image &&
      tempDepartment.image.includes(environment.SharePoint.SiteURL)
    ) {
      let libraryName = tempDepartment.image.split('sites/')[1].split('/')[1];
      let pathArray = tempDepartment.image.split('sites/')[1].split('/');
      let filePath = '';

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService
              .getFileByPathInB64(
                environment.SharePoint.ContentSiteId,
                libraryName,
                filePath
              )
              .subscribe((result) => {
                if (result != '') {
                  tempDepartment.image = result;
                }
              });
          }
        }
      });
    }
  }

  private setArticleMediaUrl(tempArticle: IArticle) {
    if (
      tempArticle.image &&
      tempArticle.image.includes(environment.SharePoint.SiteURL)
    ) {
      let libraryName = tempArticle.image.split('sites/')[1].split('/')[1];
      let pathArray = tempArticle.image.split('sites/')[1].split('/');
      let filePath = '';

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService
              .getFileByPathInB64(
                environment.SharePoint.ContentSiteId,
                libraryName,
                filePath
              )
              .subscribe((result) => {
                if (result != '') {
                  tempArticle.image = result;
                }
              });
          }
        }
      });
    }

    if (
      tempArticle.pdfUrl &&
      tempArticle.pdfUrl!.includes(environment.SharePoint.SiteURL)
    ) {
      let libraryName = tempArticle.pdfUrl!.split('sites/')[1].split('/')[1];
      let pathArray = tempArticle.pdfUrl!.split('sites/')[1].split('/');
      let filePath = '';

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService
              .getByPathFile(
                environment.SharePoint.ContentSiteId,
                libraryName,
                filePath
              )
              .subscribe((result) => {
                if (result != '') {
                  tempArticle.pdfUrl! = result;
                }
              });
          }
        }
      });
    }

    if (
      tempArticle.video &&
      tempArticle.video!.includes(environment.SharePoint.SiteURL)
    ) {
      let libraryName = tempArticle.video!.split('sites/')[1].split('/')[1];
      let pathArray = tempArticle.video!.split('sites/')[1].split('/');
      let filePath = '';

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService
              .getByPathFile(
                environment.SharePoint.ContentSiteId,
                libraryName,
                filePath
              )
              .subscribe((result) => {
                if (result != '') {
                  tempArticle.video! = result;
                }
              });
          }
        }
      });
    }
  }
  splitArray(a, n, balanced) {
    if (n < 2) return [a];

    var len = a.length,
      out: any[] = [],
      i = 0,
      size;

    if (len % n === 0) {
      size = Math.floor(len / n);
      while (i < len) {
        out.push(a.slice(i, (i += size)));
      }
    } else if (balanced) {
      while (i < len) {
        size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, (i += size)));
      }
    } else {
      n--;
      size = Math.floor(len / n);
      if (len % size === 0) size--;
      while (i < size * n) {
        out.push(a.slice(i, (i += size)));
      }
      out.push(a.slice(size * n));
    }

    return out;
  }
}
