import { IUserProfileData } from "./user.model";

export interface GaEvent{
    eventType:EnGaEventType;
    eventName:string;
    eventDetails:any;
    //userProfile?:IUserProfileData;
}

export enum EnGaEventType{
    Click,
    Pageload
}