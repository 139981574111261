import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import {
  IAiBanner,
  IAiBannerResponse,
  IAiHomeDataResponse,
  IAiLinksResponse,
  IAiMedia,
  IAiMediaResponse,
  IAiRating,
  IAiSearchAnnouncementResponse,
  IAiSideMenuResponse,
  IAiToolResponse,
  IAiToolsRequestParams,
  IAiToolsResponse,
} from 'src/app/Shared/Models/ai-tools.interface';
import { FileService } from 'src/app/Shared/Services/file.service';
import { UtitlityService } from 'src/app/Shared/Services/utilitiy.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AiToolsApiService {

  constructor(
    private http: HttpClient,
    private fileService: FileService,
    private utilityService: UtitlityService
  ) {}

  getAiHomeData() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';

    return this.http.get<IAiHomeDataResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiHomeData}`
    );
  }

  getAiTool(toolId) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';

    return this.http.get<IAiToolResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiTool}/${toolId}?filterdate=${filterDate}`
    );
  }

  getAiMediaData(toolId, pageSize, pageIndex) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    var body = {
      toolId: toolId,
      filterdate: filterDate,
      paginationRequest: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
    };

    return this.http
      .post<IAiMediaResponse>(
        `${environment.ApiPath}api/${environment.API.AiTools.GetAiToolsMedia}`,
        body
      )
      .pipe(
        tap((data) => {
          data.media.items.map((me) => this.setMediaConfigs(me));
        })
      );
  }

  getAiMediaDataById(mediaId: number) {
    return this.http
      .get<IAiMedia>(
        `${environment.ApiPath}api/${environment.API.AiTools.GetAiToolsMedia}/${mediaId}`
      )
      .pipe(
        tap((item) => {
          console.log(item)
          this.setMediaConfigs(item);
        }),
        catchError((err) => {
          return of(undefined);
        })
      );
  }

  getAiLinksData(toolId, pageSize, pageIndex) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    var body = {
      toolId: toolId,
      filterdate: filterDate,
      paginationRequest: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
    };

    return this.http.post<IAiLinksResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiToolsLinks}`,
      body
    );
  }
  
  getFeaturedAiTools(filterParams: IAiToolsRequestParams, pageIndex, pageSize) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
    datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    filterParams.filterdate = filterDate;

    var body = {
      paginationRequest: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
      aiToolsFilterRequest: filterParams,
    };

    return this.http.post<IAiToolsResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetFeaturedTools}`,
      body
    );
  }

  getAiTools(filterParams: IAiToolsRequestParams, pageIndex, pageSize) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    filterParams.filterdate = filterDate;

    var body = {
      paginationRequest: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
      aiToolsFilterRequest: filterParams,
    };

    return this.http.post<IAiToolsResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetTools}`,
      body
    );
  }

  getSimilarTools(toolId: any) {
    return this.http.get<IAiToolsResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetTools}/similar/${toolId}`
    );
  }

  getAiBanner(toolId?, pageType?: string, isMainBanner?: boolean) {
    var query = `?pageType=${pageType}&isMainBanner=${isMainBanner}${toolId ? '&toolId=' + toolId : ''}`;
    query = query.replace('?&', '&');

    return this.http.get<IAiBannerResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiToolsBanner}${query}`
    );
  }

  getAiFeaturedArticle(pageType) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get<IAiMedia>(`${environment.ApiPath}api/${environment.API.AiTools.GetFeaturedAiArticle}?filterDate=${filterDate}&pageType=${pageType}`);
  }
  getAiHomePageArticles() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get<IAiMediaResponse>(`${environment.ApiPath}api/${environment.API.AiTools.GetAIHomePageArticles}?filterDate=${filterDate}`).pipe(
      tap((data) => {
        data.media.items.map((me) => this.setMediaConfigs(me));
      })
    );
  }
  getAiNewsArticles(filterParams: IAiToolsRequestParams, pageIndex, pageSize): Observable<IAiMediaResponse> {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    var body = {
      paginationRequest: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
      aiToolsFilterRequest: filterParams,
      pageType: 'AI-News',
    };
    return this.http
      .post<IAiMediaResponse>(
        `${environment.ApiPath}api/${environment.API.AiTools.GetAiNewsArticles}`,
        body
      )
      .pipe(
        tap((data) => {
          data.media.items.map((me) => this.setMediaConfigs(me));
        })
      );
  }

  getAiNewsArticlesCount(filterParams: IAiToolsRequestParams): Observable<number> {

    return this.http
      .post<number>(
        `${environment.ApiPath}api/${environment.API.AiTools.GetAiNewsArticlesCount}`,
        filterParams
      );
  }

  getSearchAnnouncement() {
    return this.http.get<IAiSearchAnnouncementResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiSearchAnnouncement}`
    );
  }

  getSideMenu() {
    return this.http.get<IAiSideMenuResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiSideMenu}`
    );
  }
  saveUserRating(rating: number, toolId: number) {
    const body = {
      rating,
      toolId,
    };
    return this.http.post<IAiToolResponse>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiTool}/${toolId}/rating`,
      body
    );
  }
  getUrl(link) {
    if (
      link &&
      link.includes(environment.SharePoint.SiteURL) &&
      link.includes('sites')
    ) {
      let siteName = link.split('sites/')[1].split('/')[0];
      let libraryName = link.split('sites/')[1].split('/')[1];
      let pathArray = link.split('sites/')[1].split('/');
      let filePath = '';
      let newFilePath = '';
      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;
        }
      });
      newFilePath = filePath.replace(/\//g, '&#47');

      return `file-reader/${siteName}/${libraryName}/${newFilePath}`;
    }
    return link;
  }

  private setMediaConfigs(tempMedia: IAiMedia) {
    tempMedia.isHebrewTitle = this.utilityService.isHebrew(
      tempMedia.title != undefined ? tempMedia.title : ''
    );
    tempMedia.isHebrewDescription = this.utilityService.isHebrew(
      tempMedia.description != undefined ? tempMedia.description : ''
    );
    tempMedia.isHebrewBody = this.utilityService.isHebrew(
      tempMedia.body != undefined ? tempMedia.body : ''
    );

    if (
      tempMedia.video &&
      (tempMedia.image == undefined || tempMedia.image == null)
    ) {
      tempMedia.image = '/assets/images/global/video-default-bg.png';
    }

    if (
      tempMedia.image &&
      tempMedia.image.includes(environment.SharePoint.SiteURL)
    ) {
      let libraryName = tempMedia.image.split('sites/')[1].split('/')[1];
      let pathArray = tempMedia.image.split('sites/')[1].split('/');
      let filePath = '';

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService
              .getFileByPathInB64(
                environment.SharePoint.ContentSiteId,
                libraryName,
                filePath
              )
              .subscribe((result) => {
                if (result != '') {
                  tempMedia.image = result;
                }
              });
          }
        }
      });
    }

    if (
      tempMedia.video &&
      tempMedia.video!.includes(environment.SharePoint.SiteURL)
    ) {
      let libraryName = tempMedia.video!.split('sites/')[1].split('/')[1];
      let pathArray = tempMedia.video!.split('sites/')[1].split('/');
      let filePath = '';

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService
              .getByPathFile(
                environment.SharePoint.ContentSiteId,
                libraryName,
                filePath
              )
              .subscribe((result) => {
                if (result != '') {
                  tempMedia.video! = result;
                }
              });
          }
        }
      });
    }
  }
}
