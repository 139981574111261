import { Injectable } from "@angular/core";
import { type } from "os";
import { BehaviorSubject, forkJoin, from, Observable, of } from "rxjs";
import { catchError, delay, map, switchMap } from "rxjs/operators";
import { SideItemsGraphApiService } from "src/app/Core/Services/GraphApiServices/sideItems.graph-api";
import { ISideItem, ISideItemCategory } from "src/app/Shared/Models/sideItem.interface";
import { FileService } from "src/app/Shared/Services/file.service";
import { MemberOfService } from "src/app/Shared/Services/member-of.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
  })

  export class SideItemsGraphFacade {
    
    allSideItems:ISideItem[]= []
    _sideCategoriesItems: ISideItemCategory[] = [];
    sideCategoriesItems$: BehaviorSubject<ISideItemCategory[]> = new BehaviorSubject<ISideItemCategory[]>(
      this._sideCategoriesItems
    );

    constructor(private sideItemsGraphApiService:SideItemsGraphApiService, private fileService:FileService, private memberOfService:MemberOfService){
        this.getSideItems()
        this.getAllSideItems().subscribe(data => {this.allSideItems = data})
    }


    getSideItems(){

        forkJoin([
          this.sideItemsGraphApiService.getSideItemsCategories(),
          this.sideItemsGraphApiService.getSideItems()
        ]).pipe(
          delay(1000),
          map(([categories, items]) => {

              let sideItemCategories:any = [];

              categories.value.forEach(item => {
                let assigned =
                item.fields.AssignedTo != undefined || item.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(item.fields.AssignedTo, item.fields.UnAssignedTo)
                : true;

                if(assigned){
                  sideItemCategories.push(this.mapSideCategoryItem(item))
                }
              });
              let categoriesWithItems = this.getChildremItems(sideItemCategories,items,"category")

              categoriesWithItems.forEach(element => {
                if(element.items.length > 0){
                  this.getChildremItems(element.items,items,"items")
                }

                element.items = element.items.filter(el  => el.parentId == undefined)
              });

              this._sideCategoriesItems = sideItemCategories.filter(el  => el.items.length > 0)
              
              this.sideCategoriesItems$.next(this._sideCategoriesItems);

          })
      ).subscribe()

    }

    getAllSideItems(){
      return this.allSideItems.length > 0
        ? of(this.allSideItems.filter(i => i.link != undefined))
        : this.sideItemsGraphApiService.getSideItems().pipe(
          map((data:any) => {
              let allSideItems:ISideItem[]=[];
              data.value.forEach(item => {

                let assigned =
                item.fields.AssignedTo != undefined || item.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(item.fields.AssignedTo, item.fields.UnAssignedTo)
                : true;

                let mappedItem = this.mapSideItem(item)
                if(mappedItem.link != undefined){
                  mappedItem.isAllwed = assigned
                  allSideItems.push(mappedItem)
                }
                
              });
              return allSideItems;
          }))
    }

    getAllSideItemCategories(){
      return this.sideItemsGraphApiService.getSideItemsCategories()
    }

    private getChildremItems(parents:any, result:any, type:any){

      let key = type == "category" ? "SideItemCategory_x003A_ID" : "ParentSideItem_x003A_ID"

      parents.forEach((element:any) => {

        let childItems = result.value.filter(child => {
            return +child.fields[key] === element.id
          })

          childItems.forEach(item => {
            let assigned =
            item.fields.AssignedTo != undefined || item.fields.UnAssignedTo != undefined
            ? this.memberOfService.isMember(item.fields.AssignedTo, item.fields.UnAssignedTo)
            : true;

            if(assigned){
              let mappedItem = this.mapSideItem(item)

              if(type == "category"){
                element.items.push(mappedItem)
              }else{
                element.children.push(mappedItem)
              }

              this.allSideItems.push(mappedItem)
            }
          });

          if(type == "category"){
            element.items.sort((a, b) => a.order - b.order);
          }else{
            element.children.sort((a, b) => a.order - b.order);
          }
        })

        return parents
    }

    private mapSideItem(item){

        let tempItem:ISideItem = {
                id:+item.id,
                categoryId: +item.fields.SideItemCategory_x003A_ID,
                parentId: item.fields.ParentSideItem_x003A_ID,
                title:item.fields.Title,
                order:item.fields.Order1,
                link:item.fields.Link,
                isExternal:item.fields.IsExternalLink,
                isPdf:item.fields.IsPDF,
                children:[]
            };

            // if(tempItem.isPdf){
            //   this.setPdfUeUrl(tempItem)
            // }

        return tempItem;
    }

    private mapSideCategoryItem(category){

      let tempCategory:ISideItemCategory = {
              id: +category.id,
              title:category.fields.Title,
              image:category.fields.ImageUrl,
              order:+category.fields.Order1,
              items:[]
          };

      this.setImageUrl(tempCategory)
      return tempCategory;
    }

    private setImageUrl(sideCategory:ISideItemCategory){

        if(sideCategory.image && sideCategory.image.includes(environment.SharePoint.SiteURL)){

          let libraryName =  sideCategory.image.split('sites/')[1].split('/')[1];
          let pathArray = sideCategory.image.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if(index > 1){
              filePath+='/'+element;

              if(index == pathArray.length-1){
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
                  if(result != ""){
                    sideCategory.image = result;
                  }
                })
              }
            }
          });
        }
    }

    private setPdfUeUrl(sideItem:ISideItem){

      if(sideItem.link && sideItem.link.includes(environment.SharePoint.SiteURL)){

        let libraryName =  sideItem.link.split('sites/')[1].split('/')[1];
        let pathArray = sideItem.link.split('sites/')[1].split('/');
        let filePath = "";

        pathArray.forEach((element, index) => {
          if(index > 1){
            filePath+='/'+element;

            if(index == pathArray.length-1){
              filePath = filePath.split('?')[0];
              this.fileService.getByPathFile(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
                if(result != ""){
                  sideItem.link = result;
                }
              })
            }
          }
        });
      }
  }
  }
