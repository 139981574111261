import { Injectable } from '@angular/core';
import { CacheItem, CacheSchema, CacheService, CacheStore } from '@microsoft/mgt';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserGraphApiService } from 'src/app/Core/Services/GraphApiServices/user.graph-api.service';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';

const cacheSchema: CacheSchema = {
  name: 'users',
  stores: {
    users: '',
    photos: '',
    presence: ''
  },
  version: 1
};

interface CacheUser extends CacheItem {
  user?: string;
}

interface CachePhoto extends CacheItem {
  photo?: string;
}

interface CachePresence extends CacheItem {
  presence?: string;
}



@Injectable({
  providedIn: 'root'
})

export class GraphToolkitCachingService {

  cacheUser: CacheStore<CacheUser>
  cachePhoto: CacheStore<CachePhoto>
  cachePresence: CacheStore<CachePresence>
  
  constructor(private userGraphApi: UserGraphApiService, private userApiFacade:UserApiFacade) {
    CacheService.config.users.isEnabled = true;
    CacheService.config.users.invalidationPeriod = 259200000;
    CacheService.config.photos.isEnabled = true;
    CacheService.config.presence.isEnabled = true; 
    
   }

   getPersonByEmail(userEmail:string):Observable<any>{
      this.cacheUser = CacheService.getCache<CacheUser>(cacheSchema, 'users');
      return from(this.cacheUser.getValue(userEmail)).pipe(
        map((res:any) => {
          let ret_User:any;
          if (res && this.getUserInvalidationTime() > Date.now() - res.timeCached){
            ret_User = JSON.parse(res.user);
          }
          return ret_User;
        }),
        switchMap(res => {
          let ret_result: any;
          if(res){
            ret_result = of(res);
          }else{
            ret_result = this.getUser(userEmail);
          }
          return ret_result
        })
      )
   }

   getUserPhoto(userEmail:string):Observable<any>{
    
    this.cachePhoto = CacheService.getCache<CachePhoto>(cacheSchema, 'photos');
    return from(this.cachePhoto.getValue(userEmail)).pipe(
      map((res:any) => {
       
        let ret_photo:any;
        if (res && this.getPhotoInvalidationTime() > Date.now() - res.timeCached){
          ret_photo =res.photo;
        }
        return ret_photo;
      }),
      switchMap(res => {
       
        let ret_result: any;
        if(res || res == ''){
          ret_result = of(res);
        }else{
          ret_result = this.getPhoto(userEmail);
        }
        return ret_result
      })
    )
 }

 getUserPresence(userId:string):Observable<any>{
  this.cachePresence = CacheService.getCache<CachePresence>(cacheSchema, 'presence');
  return from(this.cachePresence.getValue(userId)).pipe(
    map((res:any) => {
      let ret_presence:any;
      if (res && this.getPresenceInvalidationTime() > Date.now() - res.timeCached){
        ret_presence = JSON.parse(res.presence);
      }
      return ret_presence;
    }),
    switchMap(res => {
      let ret_result: any;
      if(res){
        ret_result = of(res);
      }else{
        ret_result = this.getPresence(userId);
      }
     
      return ret_result
    })
  )
}

 getUser(userEmail:string){
  return this.userApiFacade.getUser(userEmail).pipe(
    tap((res:any) => {
     if (this.usersCacheEnabled()) {
       this.cacheUser.putValue(res.userPrincipalName, { user: JSON.stringify(res) });
     }
    })
  )
}

 getPhoto(userEmail:string){
  return this.userGraphApi.getUserPhoto(userEmail).pipe(
    catchError(err => {return of('')}),
    switchMap( photo => this.createPhotoFromBlob(photo)),
    tap((res:any) => {
     if (this.photosCacheEnabled()) {
       this.cachePhoto.putValue(userEmail, { photo: res });
     }
    })
  )
}

public getPresence(userId: string){
  return this.userGraphApi.getUserPresence(userId).pipe(
    catchError(e => {
      let presence:any = {activity: "PresenceUnknown",
      availability: "presenceunknown",
      id: userId
     }; 
    return of(presence)}),
    tap((res:any) => {
      if (this.presenceCacheEnabled()) {
        this.cachePresence.putValue(userId, { presence: JSON.stringify(res) });
      }
     })
  )
}

private createPhotoFromBlob(image: Blob | string){

  return this.blobToArrayBuffer(image).pipe(
    map(arrayBuffer => {
      let photo = 'data:image/jpeg;base64,'+ this.arraybufferToBase64(arrayBuffer);
      if(image == '')
      {
        photo = '';
      }
      return photo;
    })
  );
}

private arraybufferToBase64(arrayBuffer:any){
  return btoa(
    new Uint8Array(arrayBuffer)
      .reduce((data, byte) => data + String.fromCharCode(byte), '')
  );
}

private blobToArrayBuffer(blob:any){
  var arrayBuffer =  from(new Response(blob).arrayBuffer());
  return arrayBuffer;
}



  // retrieves invalidation time from cache config
  getUserInvalidationTime = (): number =>
  CacheService.config.users.invalidationPeriod || CacheService.config.defaultInvalidationPeriod;
  getPhotoInvalidationTime = (): number =>
  CacheService.config.photos.invalidationPeriod || CacheService.config.defaultInvalidationPeriod;
  getPresenceInvalidationTime = (): number =>
  CacheService.config.presence.invalidationPeriod || CacheService.config.defaultInvalidationPeriod;

  // checks for if cache is enabled
  usersCacheEnabled = (): boolean => CacheService.config.users.isEnabled && CacheService.config.isEnabled;
  photosCacheEnabled = (): boolean => CacheService.config.photos.isEnabled && CacheService.config.isEnabled;
  presenceCacheEnabled = (): boolean => CacheService.config.presence.isEnabled && CacheService.config.isEnabled;
}
