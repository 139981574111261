import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IVodItem } from "src/app/Shared/Models/vod-item.interface";
import { FileService } from "src/app/Shared/Services/file.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
  })
  export class VodsApiService {
    constructor(private http: HttpClient, private fileService:FileService) {}

    getVods(nextPageIndex: number){
    if (nextPageIndex == undefined) {
            nextPageIndex = 1;
        }
        const datepipe: DatePipe = new DatePipe('en-US');
        const filterDate = datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
        return this.http.get(
        `${environment.ApiPath}api/${environment.API.Vods.GetVods}/?localtime=${filterDate}&nextPageIndex=${nextPageIndex}`
        );
    }

    getVodById(vodId: number){

        const datepipe: DatePipe = new DatePipe('en-US');
        const filterDate = datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
        return this.http.get(
        `${environment.ApiPath}api/${environment.API.Vods.GetVods}/${vodId}?localtime=${filterDate}`
        );
    }

    getVodsByCategory(categoryId: number){

        const datepipe: DatePipe = new DatePipe('en-US');
        const filterDate = datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
        return this.http.get(
        `${environment.ApiPath}api/${environment.API.Vods.GetVodsByCategory}/${categoryId}?localtime=${filterDate}`
        );
    }

    getVodsByKeywords(keywords: string[]){

        const datepipe: DatePipe = new DatePipe('en-US');
        const filterDate = datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
        return this.http.get(
        `${environment.ApiPath}api/${environment.API.Vods.GetVodsByKeywords}?localtime=${filterDate}&keywords=${keywords.join(',')}`
        );
    }

    getVodsHomePage():Observable<IVodItem[]>{
        const datepipe: DatePipe = new DatePipe('en-US');
        const filterDate = datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
        return this.http.get<IVodItem[]>(
        `${environment.ApiPath}api/${environment.API.Vods.GetVodsHomePage}?localtime=${filterDate}`
        ).pipe(
            map((data:any) => {
            var retData = data.value
            retData.map(p => p.type = "vod")
         return retData;
         }),
         catchError((err) => {
            let emptyResult: IVodItem[] = [];
            return of(emptyResult);
          })
         );
    }

  }