import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { PreloaderService } from 'src/app/Shared/Services/preloader.service';

@Injectable()
export class ApiRequestHandlerInterceptor implements HttpInterceptor {

  constructor(private preloaderService: PreloaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      filter((event) => event instanceof HttpResponse),
      tap(response => {
        setTimeout(() => {
          this.forceHidePreloader()
        }, 3000);
      }),
      catchError(err => {
        setTimeout(() => {
          this.forceHidePreloader();
        }, 3000);
        return throwError(() => new Error(err))
      })
    );
  }

  forceHidePreloader(){
    var preloader = document.querySelectorAll('.preloader')
    if(preloader){
     preloader.forEach((item:any) => {
      item.style.display= "none";
     })
    }

    this.preloaderService.hidePreloader();
  }
}
