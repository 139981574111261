export class PlaytikaSiteAdmin{
    isMainSiteAdmin?:boolean;
    isWikiSiteAdmin?:boolean;
}

export class WikiNavMenu{
    id?:string;
    siteId?:string;
    title?:string;
    url?:string;
    order?:number;
    siteTemplate?:string;
}


export class WikiNavigation{
    id:number;
    title:string;
    parentId:number;
    link:string;
    order:number;
    display?:boolean;
    children: WikiNavigation[];
}

export class WikiContent{
    id:number;
    link?:string;
    linkTitle?:string;
    richContent?:string;
    order:number;
    contentType?:string;
    isPdf?:boolean;
    isVideo?:boolean;
    isLink?:boolean;
}

export class ContentType{
    id:string;
    name:string;
}

export class WikiState{
    wikiNavigationItems:WikiNavigation[];
    wikiContentItems:WikiContent[];
    wikiNavigationSelected:WikiNavigation;
    wikiContentSelected:WikiContent;
    cancelEditContent:boolean;
    siteId:string;
    siteDescription:string;
}

export class WikiNavMenuState{
  isWikiNavMenuActive:boolean = false;
  isWikiNavMenuStarted:boolean = false;
  siteId?:string = '0000-0000-0000-000000000000';
}

export interface IWikiSiteList {
  listId: string;
  listType: string;
  siteId: string;
  siteName: string;
}
