import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { MainMenuGraphApiService } from "src/app/Core/Services/GraphApiServices/mainMenu.graph-api";
import { MainMenu } from "src/app/Shared/Models/mainMenu.model";
import { FileService } from "src/app/Shared/Services/file.service";
import { MemberOfService } from "src/app/Shared/Services/member-of.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
  })
  export class MainMenuGraphFacade {

    mainMenuItems: MainMenu[] = [];
    _mainMenu: MainMenu[] = [];
    mainMenu$:  BehaviorSubject<MainMenu[]> = new BehaviorSubject<MainMenu[]>(
      this._mainMenu
    );

    constructor(private mainMenuGraphApiService:MainMenuGraphApiService, private memberOfService:MemberOfService,private fileService:FileService){
            this.getMainMenu();
            this.getMainMenuItems().subscribe(items => {this.mainMenuItems = items;});
    }


    getMainMenu() {
        return this.mainMenuGraphApiService.getMainMenu().pipe(
          map((result: any) => {
            result.value.forEach((item, index) => {

                let assigned = item.fields.AssignedTo != undefined || item.fields.UnAssignedTo != undefined
                  ? this.memberOfService.isMember(item.fields.AssignedTo, item.fields.UnAssignedTo)
                  : true;

                if((assigned || item.fields.Link == '/home') && item.fields.MenuIcon){
                  let tempItem: MainMenu = {
                    id:+item.id,
                    title:item.fields.Title,
                    order:item.fields.Order1,
                    link:item.fields.Link,
                    isExternal:item.fields.IsExternalLink == undefined ? false : item.fields.IsExternalLink,
                    icon:item.fields.MenuIcon.Url != undefined ?  item.fields.MenuIcon.Url : undefined,
                    iconActive:item.fields.MenuIconActive.Url,
                    iconWhite: item.fields.MenuIconWhite != undefined ?  item.fields.MenuIconWhite.Url : undefined,
                    showOnMobile: item.fields.ShowOnMobile
                  };

                  this.setIconUrl(tempItem)

                  this._mainMenu.push(tempItem);
                }

                if(index == result.value.length -1){
                  this.mainMenu$.next(this._mainMenu)
                }
            });

          })
        ).subscribe();
      }

      getMainMenuItems() {
        return of(this.mainMenuItems).pipe(
          switchMap(items => {
            if(items != null && items != undefined && items.length>0){
              return of(items)
            }

            return this.mainMenuGraphApiService.getMainMenu().pipe(
              map((result: any) => {
                result.value.forEach((item) => {

                    let assigned = item.fields.AssignedTo != undefined || item.fields.UnAssignedTo != undefined
                      ? this.memberOfService.isMember(item.fields.AssignedTo, item.fields.UnAssignedTo)
                      : true;
                    if(item.fields.MenuIcon){
                      let tempItem: MainMenu = {
                        isAllwed:assigned,
                        id:+item.id,
                        title:item.fields.Title,
                        order:item.fields.Order1,
                        link:item.fields.Link,
                        isExternal:item.fields.IsExternalLink == undefined ? false : item.fields.IsExternalLink,
                        icon:item.fields.MenuIcon.Url != undefined ?  item.fields.MenuIcon.Url : undefined,
                        iconActive:item.fields.MenuIconActive.Url,
                        iconWhite: item.fields.MenuIconWhite != undefined ?  item.fields.MenuIconWhite.Url : undefined,
                        showOnMobile: item.fields.ShowOnMobile
                      };

                      this.mainMenuItems.push(tempItem);
                    }


                });
                return this.mainMenuItems
              })
            );
          }),
        )

      }

      private setIconUrl(menuItem:MainMenu){

        if(menuItem.icon && menuItem.icon.includes(environment.SharePoint.SiteURL)){

          let libraryName =  menuItem.icon.split('sites/')[1].split('/')[1];
          let pathArray = menuItem.icon.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if(index > 1){
              filePath+='/'+element;

              if(index == pathArray.length-1){
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
                  if(result != ""){
                    menuItem.icon = result;
                  }
                })
              }
            }
          });
        }

        if(menuItem.icon && menuItem.icon.includes(environment.SharePoint.SiteURL)){

          let libraryName =  menuItem.icon.split('sites/')[1].split('/')[1];
          let pathArray = menuItem.icon.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if(index > 1){
              filePath+='/'+element;

              if(index == pathArray.length-1){
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
                  if(result != ""){
                    menuItem.icon = result;
                  }
                })
              }
            }
          });
        }

        if(menuItem.iconWhite && menuItem.iconWhite.includes(environment.SharePoint.SiteURL)){

            let libraryName =  menuItem.iconWhite.split('sites/')[1].split('/')[1];
            let pathArray = menuItem.iconWhite.split('sites/')[1].split('/');
            let filePath = "";

            pathArray.forEach((element, index) => {
              if(index > 1){
                filePath+='/'+element;

                if(index == pathArray.length-1){
                  filePath = filePath.split('?')[0];
                  this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
                    if(result != ""){
                        menuItem.iconWhite = result;
                    }
                  })
                }
              }
            });
          }
    }

}
