import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AiArticlesCommentaryService {

  obj ={
    currentCommentIdReply:0,
    currentCommentIdEdit:0,
    currentCommentLoadMore:0,
    currentTextBoxFocused:0
  }
  private commentaryState = new BehaviorSubject<any>(this.obj);
  public commentarySource$ = this.commentaryState.asObservable();

  constructor(){}

  setCurrentCommentaryReplyId(value:number){
      this.obj.currentCommentIdReply = value;
      this.obj.currentCommentIdEdit = -1;
      this.commentaryState.next(this.obj);
  }

  setCurrentCommentaryEditId(value:number){
      this.obj.currentCommentIdEdit = value;
      this.obj.currentCommentIdReply = -1;
      this.commentaryState.next(this.obj);
  }

  setCurrentCommentaryLoadMore(value:number){
    this.obj.currentCommentLoadMore = value;
    this.commentaryState.next(this.obj);
  }

  setCurrentBoxFocused(value:number){
    this.obj.currentTextBoxFocused = value;
    this.commentaryState.next(this.obj);
  }
}
