import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SPListGraphApiService } from './spList-graphApi.service';

@Injectable({
  providedIn: 'root',
})
export class FileService extends SPListGraphApiService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getByIdFile(fileId, liberId): Observable<any> {
    return this.getFileById(fileId, liberId).pipe(
      map((result: any) => {
        let downloadUrl = result['@microsoft.graph.downloadUrl'];
        return downloadUrl;
      }),
      catchError((err) => {
        return of('');
      })
    );
  }

  public getFileInB64(fileId: string, liberId: string): Observable<any> {
    return this.getFileThumbnails(liberId, fileId).pipe(
      map((result: any) => {
        let downloadUrl =
          result.value.length > 0 ? result.value[0].large.url : '';
        return downloadUrl;
      }),
      catchError((err) => {
        return of('');
      })
    );
  }

  public getFileInB64ByDriveId(itemId: string, libraryId): Observable<any> {
    return this.getFileThumbnails(libraryId, itemId).pipe(
      map((result: any) => {
        let downloadUrl =
          result.value.length > 0 ? result.value[0].large.url : '';
        return downloadUrl;
      }),
      catchError((err) => {
        return of('');
      })
    );
  }

  public getByPathFile(
    siteId: string,
    libraryName: string,
    filePath: string
  ): Observable<any> {
    return this.getFileByPath(siteId, libraryName, filePath).pipe(
      map((result: any) => {
        let downloadUrl = result['@microsoft.graph.downloadUrl'];
        return downloadUrl;
      }),
      catchError((err) => {
        return of('');
      })
    );
  }

  public getBySiteNamePathFile(params: any): Observable<any> {
    return this.getSpSiteByName(params.siteName, params.subSiteName)
      .pipe(
        switchMap((result: any) => {
          let siteId = result.id.split(',')[1] + ',' + result.id.split(',')[2];
          return this.getFileByPath(siteId, params.libraryName, params.path);
        })
      )
      .pipe(
        map((result: any) => {
          let downloadUrl = result['@microsoft.graph.downloadUrl'];
          return downloadUrl;
        }),
        catchError((err) => {
          return of('');
        })
      );
  }

  public getByPathFileWithProperties(
    siteId: string,
    libraryName: string,
    filePath: string
  ): Observable<any> {
    return this.getFileByPath(siteId, libraryName, filePath).pipe(
      map((result: any) => {
        let obj = {
          id: result.cTag.split('{')[1].split('}')[0].toLowerCase(),
          downloadUrl: result['@microsoft.graph.downloadUrl'],
          webUrl: result.webUrl,
          thumbnail:
            result.thumbnails != undefined
              ? result.thumbnails[0].large.url
              : '',
        };

        return obj;
      }),
      catchError((err) => {
        return of('');
      })
    );
  }

  public getFileByPathInB64(
    siteId: string,
    libraryName: string,
    filePath: string
  ): Observable<any> {
    return this.getFileByPath(siteId, libraryName, filePath).pipe(
      map((result: any) => {
        let downloadUrl =
          result.thumbnails != undefined ? result.thumbnails[0].large.url : '';
        return downloadUrl;
      }),
      catchError((err) => {
        return of('');
      })
    );
  }

  public getFileByPathInB64WithProperties(
    siteId: string,
    libraryName: string,
    filePath: string
  ): Observable<any> {
    return this.getFileByPath(siteId, libraryName, filePath).pipe(
      map((result: any) => {
        let obj = {
          id: result.cTag.split('{')[1].split('}')[0].toLowerCase(),
          url:
            result.thumbnails != undefined
              ? result.thumbnails[0].large.url
              : '',
          webUrl: result.webUrl,
        };
        return obj;
      }),
      catchError((err) => {
        return of('');
      })
    );
  }

  private createIconFromBlob(image: Blob) {
    return this.blobToArrayBuffer(image).pipe(
      map((arrayBuffer) => {
        let photo =
          'data:image/jpeg;base64,' + this.arraybufferToBase64(arrayBuffer);
        return photo;
      })
    );
  }

  private arraybufferToBase64(arrayBuffer: any) {
    return btoa(
      new Uint8Array(arrayBuffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    );
  }

  private blobToArrayBuffer(blob: any) {
    var arrayBuffer = from(new Response(blob).arrayBuffer());
    return arrayBuffer;
  }
}
