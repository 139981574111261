import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IAddBirthdayWish, IEmployeeBirthdaysData, IUserApp, IUserInfo, IUserProfileData } from 'src/app/Shared/Models/user.model';
import { UtitlityService } from 'src/app/Shared/Services/utilitiy.service';
import { ApiService } from '../../Core/Services/ApiServices/api.service';


@Injectable({
  providedIn: 'root'
})
export class UserApiFacade {

  countUserBirthdayWishes$:  Subject<number> = new Subject<number>();

  userProfileDataSource$: Subject<IUserProfileData> = new Subject<IUserProfileData>();

  constructor(private userApiDBSrv: ApiService,  private utitlityService:UtitlityService) {
    this.getUserProfile()
  }


  getUser(userEmail: string){
    return this.userApiDBSrv.getUser(userEmail)
    .pipe(
      map(data => {
        let user:IUserInfo={}
        if(data == null){
          return user
        }
        return data;
      }),
      catchError(e => {let user:IUserInfo={}; return of(user)})
    );
  }

  addBirthdayWish(birthdayWish: IAddBirthdayWish){
    return this.userApiDBSrv.addBirthdayWish(birthdayWish)
    .pipe(
      catchError(e => {return of(false)})
    );
  }

  public getUserBirthday():Observable<IEmployeeBirthdaysData> {
    let datepipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(),'MM-dd');
    let userBirthday = JSON.parse(String(localStorage.getItem("userBirthday")))
    
    if(userBirthday != null && userBirthday.cachedAt == todayDate){
      return of(userBirthday.birthday)
    }
    
    return this.userApiDBSrv.getUserBirthday().pipe(
      tap(birthday => {
        let obj = {
          birthday:birthday,
          cachedAt:todayDate
        }
        localStorage.setItem("userBirthday", JSON.stringify(obj));
      })
    );
  }

  public getUserBirthdayWishes(birthday:string) {
    return this.userApiDBSrv.getUserBirthdayWishes(birthday).pipe(
      catchError(err => {return of([])})
    )
  }

  public getCountUserBirthdayWishes(birthday:string) {
    return this.userApiDBSrv.getCountUserBirthdayWishes(birthday).pipe(
      catchError(err => {return of(0)})
    ).subscribe(count => {
      if(count != null){
        this.countUserBirthdayWishes$.next(count)
      }
    })
  }

  public setReadBirthdayWishes(wishIds:number[]) {
    return this.userApiDBSrv.setReadBirthdayWishes(wishIds).pipe(
      catchError(e => {return of(false)})
    ).subscribe(result => {
      if(result == true){
        this.countUserBirthdayWishes$.next(0)
      }
    })
  }


  getUserApps() {
    return this.userApiDBSrv.getUserApps().pipe(
      map(result => {
        result.forEach(element => {
         element.isHebrew = this.utitlityService.isHebrew(element.name)
        });
        return result
      })
    );
  }

  getUserProfile(userEmail?: string){
    return this.userApiDBSrv.getUserProfile(userEmail)
    .pipe(
      map(userProfile => {
        if(userProfile.country != '' || null || undefined){
          userProfile.countryCode = this.getCountryCode(String(userProfile.country));
        }
        this.userProfileDataSource$.next(userProfile)
        return userProfile
      } ),
      catchError(e => {let dbUser:IUserProfileData={}; return of(dbUser)})
    );
  }

  GetUserTasksSummary(){
    return this.userApiDBSrv.GetUserTasksSummary();
  }

  getCountryCode(countryName: string):string{
    const country = this.utitlityService.getCountryByName(countryName);
    return country!.code.toLowerCase();
  }
}

