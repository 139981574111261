<div class="dashboard-events">
    <div class="dashboard-events__title">
        <img src="/assets/images/global/logo-icon.png" alt="Logo">
        <p class="dashboard-events__title__text">Company events</p>
    </div>
    <a *ngFor="let dashboardEvent of dashboardEvents" class="dashboard-event" (click)="openModal($event, moreEventsModal, dashboardEvent)">
        <div class="dashboard-event__date">
            <p class="dashboard-event__date__day">{{dashboardEvent.start | date: 'd'}}</p>
            <p class="dashboard-event__date__month">{{dashboardEvent.start | date: 'MMMM'}}</p>
        </div>
        <div class="dashboard-event__details">
            <div class="dashboard-event__details__name" title="{{dashboardEvent.title}}">{{dashboardEvent.title}}</div>
            <div *ngIf="dashboardEvent.description" class="dashboard-event__details__description" title="{{dashboardEvent.description}}">{{dashboardEvent.description}}</div>
            <div *ngIf="dashboardEvent.location" class="dashboard-event__details__location" title="{{dashboardEvent.location}}"><i class="marker-icon"></i>{{dashboardEvent.location}}</div>
        </div>
    </a>
</div>

<ng-template #moreEventsModal>
    <app-more-events-modal
    (onCloseModalEmmiter)="closeModal()"
    [dayEvents]="getSelectedEvent()"
    [day]="selectedEvent.start"
    [onDashboard]="true"
    (onRefreshCalendarDataEmmiter)="refreshCalendar()"
    ></app-more-events-modal>
</ng-template>