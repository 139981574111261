import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ai-tools-comments-mobile',
  templateUrl: './ai-tools-comments-mobile.component.html',
  styleUrls: ['./ai-tools-comments-mobile.component.scss']
})
export class AiToolsCommentsMobileComponent implements OnInit {

  @Input() toolId
  @Output() closeModalEmitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closeModal() {
    this.closeModalEmitter.emit()
  }
}
