import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';
import { environment } from 'src/environments/environment';
import { IUserInfo } from '../Models/user.model';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class MemberOfService {

  user:any;
  memberList:any[] = []

  constructor(
    private http:HttpClient,
    private userGraphApiFacade:UserGraphApiFacade,
    protected userProfileService:UserProfileService){
    this.setMembers();
    userProfileService.userProfileSource$.subscribe(user => {
      this.user = user;
    })
  }

  isMember(assignedToList:any[],unassignedToList?:any[], from?){
    let assignedTo:any[] = []
    this.memberList  =  JSON.parse(String(localStorage.getItem('memberOf')));
    
    if(assignedToList && assignedToList.length > 0){

      if(!this.memberList){
        this.memberList = [];
        this.setMembers();
      }
      const key = 'LookupValue';

      if(from == 'search'){
        assignedTo = assignedToList
      }else{
        assignedTo = [...new Map(assignedToList.map(item =>[item[key], item.LookupValue])).values()];
      }
      
    }
   
    if(unassignedToList && unassignedToList.length > 0){
      if(this.isNotMember(unassignedToList, from) == true){
        return !this.isNotMember(unassignedToList, from);
      }
    }

    return assignedToList && assignedToList.length > 0
    ? (assignedTo.some(r => this.memberList && this.memberList.indexOf(r) >= 0)
    || assignedTo.find(m => m == 'All Playtika Users') != undefined
    || assignedTo.find(m => m == 'All Users') != undefined)
    : true;
  }

  isNotMember(unassignedToList:any[], from?){
    let unassignedTo:any[] = []
    this.memberList  =  JSON.parse(String(localStorage.getItem('memberOf')));
    
    if(unassignedToList && unassignedToList.length > 0){

      if(!this.memberList){
        this.memberList = [];
        this.setMembers();
      }
      const key = 'LookupValue';

      if(from == 'search'){
        unassignedTo = unassignedToList
      }else{
        unassignedTo = [...new Map(unassignedToList.map(item =>[item[key], item.LookupValue])).values()];
      }
      
    }

    return (unassignedTo.some(r => this.memberList && this.memberList.indexOf(r) >= 0)
    || unassignedTo.find(m => m == 'All Playtika Users') != undefined
    || unassignedTo.find(m => m == 'All Users') != undefined)
  }

  setMembers(){
    this.memberList = JSON.parse(String(localStorage.getItem('memberOf')));  
    of(this.user).pipe(
      switchMap(user => {
        if(user && user.userPrincipalName != undefined){
          return of(user)
        }
        return this.userGraphApiFacade.getUserProfile()
      }),
      tap(user => {this.user = user;}),
      switchMap(() => this.getUserMemberOf())
    ).subscribe((result:any) => {
      const key = 'displayName';
      let memberList = [...new Map(result.value && result.value.map(item =>[item[key], item.displayName])).values()];
    
      if(this.user && this.user.displayName){
        memberList.push(this.user.displayName)
      }

      localStorage.setItem('memberOf',JSON.stringify(memberList));
      this.memberList = result.value;
    })
  }

  getUserMemberOf(){
    return this.http.get(environment.GraphAPIURL +`me/transitiveMemberOf?$select=displayName&$top=999`).pipe(
      catchError(error => {return of([])})
    );
  }
}
