<div class="calendar-placeholder" title="{{placeholderText}}">
  <ng-lottie [ngClass]="hideClass" class="preloader-image" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
  <div class="calendar-placeholder__text">
    <p class="calendar-placeholder__text__first-line" #placeholderFirstLine>{{ firstLine }}</p>
    <p *ngIf="secondLine.length > 1" class="calendar-placeholder__text__second-line" #placeholderSecondLine>{{ secondLine }}</p>

  </div>
  <div class="calendar-placeholder__image">
    <img *ngIf="!placeholderImage" src="/assets/images/dashboard/placeholder-image.png">
    <img *ngIf="placeholderImage" src="{{placeholderImage}}">
  </div>
</div>
