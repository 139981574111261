import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ISideItem, ISideItemCategory } from 'src/app/Shared/Models/sideItem.interface';
import { FileReaderService } from 'src/app/Shared/Services/file-reader.service';
import { IBanner } from '../../Models/banner.interface';

@Component({
  selector: 'app-home-right-menu',
  templateUrl: './home-right-menu.component.html',
  styleUrls: ['./home-right-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({  display:"block" })),
      state('false', style({  display:"none" })),
      transition('true <=> false', animate('100ms')),
    ]),
    trigger('dropdownArrow', [
      state('closed', style({
        transform: 'rotate(0deg)',
      })),
      state('open', style({
        transform: 'rotate(180deg)',
      })),
      transition('open <=> closed', animate('300ms')),
    ]),
    trigger('dropdown', [
      state('closed', style({
        height: '0px',
      })),
      state('open', style({
        height: '*',
      })),
      transition('open <=> closed', animate('300ms')),
    ])
  ]
})
export class HomeRightMenuComponent implements OnInit {
  isActiveNav:boolean = false;
  @Input() sideBanners:IBanner[];
  @Input() sideItemsCategories: ISideItemCategory[];
  currentNavItem:ISideItemCategory;
  sideItemsDropdownStates: {id: number, value: string}[] = []

  @ViewChild('stickyMenu') menuElement: ElementRef;
  sticky: boolean = false;
  elementPosition: any;

  constructor(private fileReaderService: FileReaderService) { }

  ngOnInit(): void {
    
  }
  
  onToggleWikiNavMenu(currentItem: ISideItemCategory){

    if(this.isActiveNav && this.currentNavItem.id==currentItem.id){
      this.isActiveNav=false;
    }else{
      this.isActiveNav=true;
    }
    this.currentNavItem=currentItem;

    currentItem.items.forEach(item => {
      var hasStateAlready = this.sideItemsDropdownStates.find(state => state.id == item.id) != undefined
      if (this.hasChildren(item) && !hasStateAlready) {
        this.sideItemsDropdownStates.push({id: item.id, value: 'closed'})
      }
    })
  }

  onPdfOpen(link){
    this.fileReaderService.openFile(link)
  }

  onStateDropdownClick(id) {
    var i = this.sideItemsDropdownStates.findIndex(state => state.id == id)
    this.sideItemsDropdownStates[i].value = this.sideItemsDropdownStates[i].value != 'open' ? 'open' : 'closed'
  }

  getSideItemDropdownState(id) {
    return this.sideItemsDropdownStates.find(state => state.id == id)?.value
  }

  hasChildren(sideItem: ISideItem) {
    return sideItem.children.length > 0
  }

}
