import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserInfo } from '../../../Shared/Models/user.model';
import { environment } from '../../../../environments/environment';
import { SPListGraphApiService } from 'src/app/Shared/Services/spList-graphApi.service';
import { IWellbeingEventItem } from 'src/app/Shared/Models/wellbeing.interface';
import { addDays } from 'date-fns';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserGraphApiService extends SPListGraphApiService {
  private readonly GraphAPIURL = environment.GraphAPIURL;
  private readonly fields =
    '$select=id,displayName,jobTitle,userPrincipalName,givenName,officeLocation,mobilePhone,department,accountEnabled';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getUserProfile() {
    const fields =
      '?$select=id,displayName,jobTitle,userPrincipalName,officeLocation,givenName,mobilePhone,skills,department,extension_218efcb9f4dc475cacd2e04d3ca582ba_dateOfBirth';
    return this.http.get<any>(
      this.GraphAPIURL + environment.API.User.Profile + fields
    );
  }

  getUserBirthDayBanner() {
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.SpecialBanners.GetBirthdayBanner}`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }

  getUserMemberOf() {
    return this.http.get<IUserInfo>(
      this.GraphAPIURL + `me/transitiveMemberOf?$select=displayName&$top=999`
    );
  }

  getUserMemberOfCount() {
    return this.http.get<IUserInfo>(
      this.GraphAPIURL + `me/memberOf?$count=true&$select=displayName&$top=1`,
      { headers: { ConsistencyLevel: 'eventual' } }
    );
  }

  getPersonByEmail(userEmail: string) {
    const fields =
      '$select=id,displayName,jobTitle,givenName,userPrincipalName,officeLocation,mobilePhone,skills,department,extension_218efcb9f4dc475cacd2e04d3ca582ba_dateOfBirth,accountEnabled';
    return this.http.get<IUserInfo>(
      this.GraphAPIURL + `${environment.API.User.Users}/${userEmail}?${fields}`
    );
  }

  getPhoto(userEmail: string) {
    let headers = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(userEmail, headers);
  }

  getUserPhoto(userEmail: string) {
    let headers = {
      headers: new HttpHeaders({ 'Content-Type': 'image/jpg' }),
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(
      this.GraphAPIURL +
        `${environment.API.User.Users}/${userEmail}/${environment.API.User.Photo}`,
      headers
    );
  }

  getUserManagers(userEmail: string) {
    return this.http.get<any>(
      this.GraphAPIURL +
        `${environment.API.User.Users}/${userEmail}/?$expand=manager($levels=max;${this.fields})&${this.fields}&$count=true`,
      { headers: { ConsistencyLevel: 'eventual' } }
    );
  }

  getUserManager(userEmail: string) {
    return this.http.get<any>(
      this.GraphAPIURL +
        `${environment.API.User.Users}/${userEmail}/?$expand=manager($levels=1;${this.fields})&$select=displayName&$count=true`,
      { headers: { ConsistencyLevel: 'eventual' } }
    );
  }

  getUserDirectReports(userEmail: string) {
    return this.http.get<any>(
      this.GraphAPIURL +
        `${environment.API.User.Users}/${userEmail}/${environment.API.User.DirectReports}?${this.fields}`
    );
  }

  getMostRecentPeople(userEmail: string, top: number) {
    const fields =
      '$select=id,displayName,jobTitle,userPrincipalName,officeLocation,phones,department';
    const filter = `$filter=(personType/subclass eq 'OrganizationUser' and jobTitle ne null and displayName ne 'Playtika Employee')`;
    return this.http.get<any>(
      this.GraphAPIURL +
        `${environment.API.User.Users}/${userEmail}/${environment.API.User.People}?${filter}&top=${top}&${fields}`
    );
  }

  createUserCalendarEvent(event: IWellbeingEventItem, isOneDay?: boolean) {
    if (!event.daysCount || event.daysCount == 0) {
      event.daysCount = 1;
    }
    if (event.allDay == null) {
      event.allDay = false;
    }
    if ((event?.daysCount ?? 0) > 0) {
      event.allDay = true;
    }
    const datepipe: DatePipe = new DatePipe('en-US');
    var eventStart = new Date(event.eventDateTime).toISOString();
    // var eventStart = `${datepipe.transform(event.eventDateTime, 'yyyy-MM-ddTHH:mm:ss.SSS') + 'Z'}`;
    var eventEnd = this.addMinutes(eventStart, 30);
    if (event.endDate && (isOneDay == undefined || isOneDay == false)) {
      eventEnd = new Date(event.endDate).toISOString();
    }
    // var recurrenceDateFormated =  datepipe.transform(event.eventDateTime, 'yyyy-MM-dd');
    if (event.allDay == true) {
      var currentStartDate = new Date(event.eventDateTime);
      currentStartDate.setHours(0, 0, 0, 0);
      var currentEndDate = addDays(currentStartDate, 1);
      eventStart = `${
        datepipe.transform(currentStartDate, 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z'
      }`;
      eventEnd = `${
        datepipe.transform(currentEndDate, 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z'
      }`;
      if (event.daysCount > 1) {
        currentEndDate = new Date(event.eventEndDate);
        currentEndDate.setHours(0, 0, 0, 0);
        eventEnd = `${
          datepipe.transform(
            addDays(new Date(currentEndDate), 1),
            'yyyy-MM-ddTHH:mm:ss.ms'
          ) + 'Z'
        }`;
      }
    }
    var body = {
      subject: event.eventName,
      body: {
        contentType: 'HTML',
        content: event.description,
      },
      start: {
        dateTime: eventStart,
        timeZone: 'UTC',
      },
      end: {
        dateTime: eventEnd,
        timeZone: 'UTC',
      },
      location: {
        displayName:
          event.location != undefined && event.location != null
            ? event.location
            : '',
      },
      // "recurrence": {
      //   "pattern": {
      //     "type": "daily",
      //     "interval": event.daysCount
      //     },
      //     "range": {
      //     "type": "numbered",
      //     "startDate": recurrenceDateFormated,
      //     "numberOfOccurrences":1
      //     }
      // },
      isAllDay: event.allDay,
    };
    return this.http.post<any>(this.GraphAPIURL + 'me/calendar/events', body);
  }

  updateUserCalendarEvent(
    eventId,
    event: IWellbeingEventItem,
    isOneDay?: boolean
  ) {
    if (!event.daysCount || event.daysCount == 0) {
      event.daysCount = 1;
    }
    if (event.allDay == null) {
      event.allDay = false;
    }
    if ((event?.daysCount ?? 0) > 0) {
      event.allDay = true;
    }
    const datepipe: DatePipe = new DatePipe('en-US');
    var eventStart = new Date(event.eventDateTime).toISOString();
    // var eventStart = `${datepipe.transform(event.eventDateTime, 'yyyy-MM-ddTHH:mm:ss.SSS') + 'Z'}`;
    var eventEnd = this.addMinutes(event.eventDateTime, 30);
    if (event.endDate && (isOneDay == undefined || isOneDay == false)) {
      eventEnd = new Date(event.endDate).toISOString();
    }
    // var recurrenceDateFormated =  datepipe.transform(event.startDate, 'yyyy-MM-dd');
    if (event.allDay == true) {
      var currentStartDate = new Date(event.eventDateTime);
      currentStartDate.setHours(0, 0, 0, 0);
      var currentEndDate = addDays(currentStartDate, 1);
      eventStart = `${
        datepipe.transform(currentStartDate, 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z'
      }`;
      eventEnd = `${
        datepipe.transform(currentEndDate, 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z'
      }`;
      if (event.daysCount > 1) {
        currentEndDate = new Date(event.eventEndDate);
        currentEndDate.setHours(0, 0, 0, 0);
        eventEnd = `${
          datepipe.transform(
            addDays(new Date(currentEndDate), 1),
            'yyyy-MM-ddTHH:mm:ss.ms'
          ) + 'Z'
        }`;
      }
    }
    var body = {
      subject: event.eventName,
      body: {
        contentType: 'HTML',
        content: event.description,
      },
      start: {
        dateTime: eventStart,
        timeZone: 'UTC',
      },
      end: {
        dateTime: eventEnd,
        timeZone: 'UTC',
      },
      location: {
        displayName:
          event.location != undefined && event.location != null
            ? event.location
            : '',
      },
      //  "recurrence": {
      //    "pattern": {
      //      "type": "daily",
      //      "interval": event.daysCount
      //      },
      //      "range": {
      //      "type": "numbered",
      //      "startDate": recurrenceDateFormated,
      //      "numberOfOccurrences":1
      //      }
      //  },
      isAllDay: event.allDay,
    };
    return this.http.patch<any>(
      this.GraphAPIURL + 'me/calendar/events/' + eventId,
      body
    );
  }

  filterActivePeople(emails) {
    const filter = `$filter=(userPrincipalName in (${emails}) and accountEnabled eq true)`;
    const fields =
      '$select=id,displayName,jobTitle,userPrincipalName,officeLocation,mobilePhone,department';
    return this.http.get<any>(
      this.GraphAPIURL + `${environment.API.User.Users}?${filter}&${fields}`,
      { headers: { ConsistencyLevel: 'eventual' } }
    );
  }

  loadMoreMostRecentPeople(requestString: string) {
    return this.http.get<any>(requestString);
  }

  searchPeople(query: string, top?: number) {
    let result;
    const filter = `$filter=(accountEnabled eq true and jobTitle ne null and displayName ne 'Playtika Employee')`; // and endsWith(userPrincipalName,'@playtika.com')
    if (top) {
      result = this.http
        .get<any>(
          this.GraphAPIURL +
            `${environment.API.User.Users}/?$search="displayName:${query}"&${filter}&$count=true&$top=${top}&${this.fields}`,
          { headers: { ConsistencyLevel: 'eventual' } }
        )
        .pipe(catchError((err) => throwError(err)));
    } else {
      result = this.http
        .get<any>(
          this.GraphAPIURL +
            `${environment.API.User.Users}/?$search="displayName:${query}"&${filter}&$count=true&${this.fields}`,
          { headers: { ConsistencyLevel: 'eventual' } }
        )
        .pipe(catchError((err) => throwError(err)));
    }
    return result;
  }

  getUsersPresence(ids: string[]) {
    return this.http.post(
      this.GraphAPIURL +
        `${environment.API.User.Communications}/getPresencesByUserId`,
      { ids: ids }
    );
  }

  getUserPresence(userId: string) {
    return this.http.get<any>(
      this.GraphAPIURL + `${environment.API.User.Users}/${userId}/presence`
    );
  }

  getBatchRequest(requests) {
    let body = {
      requests: requests,
    };
    return this.http.post(`${environment.GraphAPIURL}$batch`, body);
  }

  constructBatchRequest(emails) {
    let requests: any = [];

    const fields =
      '$select=id,displayName,jobTitle,userPrincipalName,officeLocation,mobilePhone,department';
    emails.forEach((element, index) => {
      const filter = `$filter=(userPrincipalName in (${element.join(
        ','
      )}) and accountEnabled eq true)`;
      let obj = {
        id: index + 1,
        method: 'GET',
        url: `/users/?${filter}&${fields}`,
      };
      requests.push(obj);
    });

    return requests;
  }

  addMinutes(time, minsToAdd) {
    var millisec = minsToAdd * 60000;
    var dateTimeInMillisec = new Date(time).getTime() + millisec;
    return new Date(dateTimeInMillisec).toISOString();
  }
}
