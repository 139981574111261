<a class="cibus" href="https://consumers.pluxee.co.il/restaurants/delivery?sortBy=recommended&city=0&minimumDeliveryPrice=150&kosher=2&favorites=false">
    <img class="cibus-logo" src="/assets/images/dashboard/cibus.png">
    <div class="cibus-content">

        <div [ngStyle]="{'height':(100-cibusData.leftPercentage)+'%'}" class="separator">
            <div class="face2"></div>
        </div>
        <div class="face1"></div>

    </div>
    <div class="cibus-diff">
        <span style="color: #ff8850;">{{cibusData.leftQuantity == undefined ? 0 : 1000 - cibusData.leftQuantity}}</span>/1000
    </div>
    <div class="cibus-percentage">
       {{cibusData.leftPercentage == undefined ? 0 : 100 - cibusData.leftPercentage }}% used
    </div>
</a>
