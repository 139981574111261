import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SPListGraphApiService } from 'src/app/Shared/Services/spList-graphApi.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchGraphApiService extends SPListGraphApiService {
  pipe(arg0: MonoTypeOperatorFunction<unknown>) {
    throw new Error('Method not implemented.');
  }

  private readonly GraphAPIURL = environment.GraphAPIURL;

  constructor(protected http: HttpClient) {
    super(http);
  }

  searchInSharepoint(searchText: string) {
    let params = {
      requests: [
        {
          entityTypes: ['listItem'],
          query: {
            queryString:
              'listId:' +
              environment.SharePoint.ArticlesListId +
              ' ' +
              searchText,
          },
          fields: [
            'Id',
            'name',
            'title',
            'listId',
            'imageOWSURLH',
            'createdDateTime',
            'ArticleBody',
            'PlaytikaDescription',
            'sharepointIds',
            'AssignedTo',
            'UnAssignedToOWSUSER',
            'StartDateOWSDATE',
          ],
        },
      ],
    };
    return this.http
      .post(`${environment.GraphAPIURL}search/query`, params)
      .pipe(catchError((err) => throwError(err)));
  }

  searchInSharepointList(searchText: string, listId: string) {
    let params = {
      requests: [
        {
          entityTypes: ['listItem'],
          query: { queryString: 'listId:' + listId + ' ' + searchText },
          fields: [
            'Id',
            'name',
            'title',
            'sharepointIds',
            'UnAssignedToOWSUSER',
          ],
        },
      ],
    };
    return this.http
      .post(`${environment.GraphAPIURL}search/query`, params)
      .pipe(catchError((err) => throwError(err)));
  }

  searchFileInSharepointDocumentLibrary(searchText: string) {
    let params = {
      requests: [
        {
          entityTypes: ['driveItem'],
          query: {
            queryString:
              'listId:' +
              environment.SharePoint.DocumentLibraryId +
              ' ' +
              searchText +
              ' (size>0)',
          },
          fields: ['Id', 'name', 'size', 'parentReference'],
        },
      ],
    };
    return this.http
      .post(`${environment.GraphAPIURL}search/query`, params)
      .pipe(catchError((err) => throwError(err)));
  }

  searchFolderInSharepointDocumentLibrary(searchText: string) {
    let params = {
      requests: [
        {
          entityTypes: ['driveItem'],
          query: {
            queryString:
              'listId:' +
              environment.SharePoint.DocumentLibraryId +
              ' ' +
              searchText +
              ' (size=0)',
          },
          fields: ['Id', 'name', 'size', 'parentReference', 'sharepointIds'],
        },
      ],
    };
    return this.http
      .post(`${environment.GraphAPIURL}search/query`, params)
      .pipe(catchError((err) => throwError(err)));
  }

  searchVodVideosInSharepointDocumentLibrary(searchText: string) {
    let params = {
      requests: [
        {
          entityTypes: ['driveItem', 'listItem'],
          query: {
            queryString:
              'listId:' +
              environment.SharePoint.PlaytikaVODLibraryId +
              ' ' +
              searchText +
              ' (size>0)',
          },
          fields: [
            'Id',
            'name',
            'size',
            'parentReference',
            'sharepointIds',
            'UnAssignedToOWSUSER',
            'PlaytikaTitleOWSTEXT',
            'PlaytikaDescriptionOWSTEXT',
          ],
        },
      ],
    };
    return this.http
      .post(`${environment.GraphAPIURL}search/query`, params)
      .pipe(catchError((err) => throwError(err)));
  }

  searchGalleryInSharepointDocumentLibrary(searchText: string) {
    let params = {
      requests: [
        {
          entityTypes: ['driveItem', 'listItem'],
          query: {
            queryString:
              'listId:' +
              environment.SharePoint.PlaytikaGalleryId +
              ' ' +
              searchText +
              ' (size=0)',
          },
          fields: [
            'Id',
            'name',
            'size',
            'AssignedTo',
            'UnAssignedToOWSUSER',
            'parentReference',
            'sharepointIds',
            'PlaytikaTitleOWSTEXT',
            'PlaytikaDescriptionOWSTEXT',
          ],
        },
      ],
    };
    return this.http
      .post(`${environment.GraphAPIURL}search/query`, params)
      .pipe(catchError((err) => throwError(err)));
  }

  searchInOneDrive(query: string) {
    return this.http
      .get(
        `${environment.GraphAPIURL}/me/drive/root/search(q='${query}')?select=name,id,webUrl,size,file,folder,package`
      )
      .pipe(catchError((err) => throwError(err)));
  }

  getGalleryImage(galleryId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.PlaytikaGallery
      }/drive/list/items/${galleryId}?$expand=driveItem,fields`
    );
  }

  getVodImage(vodId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.PlaytikaVOD
      }/drive/list/items/${vodId}?$expand=driveItem($expand=thumbnails),fields`
    );
  }
}
