import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PreloaderService  {
    isLoading$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    showPreloader(){
        var preloader = document.querySelectorAll('.preloader')
        if(preloader){
         preloader.forEach((item:any) => {
            item.style.display= "flex";
         })
        }

        this.isLoading$.next(true)
        return true
    }

    hidePreloader(){
        this.isLoading$.next(false)
        return false
    }
}