import { Component, Input, OnInit } from '@angular/core';
import { IPerson, IUserInfo } from 'src/app/Shared/Models/user.model';

@Component({
  selector: 'app-dashboard-user-info',
  templateUrl: './dashboard-user-info.component.html',
  styleUrls: ['./dashboard-user-info.component.scss']
})
export class DashboardUserInfoComponent implements OnInit {

  @Input() title: string
  @Input() userInfo: IPerson

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'75px',
      'height':'75px'
    },
    size: 'middle'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
