import { DatePipe } from "@angular/common"
import { Injectable } from "@angular/core"
import { CalendarEvent } from "angular-calendar"
import { forkJoin, Observable, of} from "rxjs"
import { map} from "rxjs/operators"
import { OutlookCalendarGraphApiService } from "src/app/Core/Services/GraphApiServices/outlookCalendar.graph-api"
import { IEvent } from "src/app/Shared/Models/calendar.interface"
import { IOutlookCalendar } from "src/app/Shared/Models/outlookCalendar.inteface"
import { IUserInfo } from "src/app/Shared/Models/user.model"
import { SessionStorageService } from "src/app/Shared/Services/session-storage.service"
import { UtitlityService } from "src/app/Shared/Services/utilitiy.service"
import { OutlookCalendarColors } from "src/app/Shared/Utilities/outlookEventsColos"


    @Injectable({
        providedIn:"root"
    })
    export class OutlookCalendarGraphFacade {

        constructor(private outlookCalendarGraphApiService:OutlookCalendarGraphApiService,private utilityService:UtitlityService,
            private store:SessionStorageService){
        }

        getOutLookCalendar(viewDate){
            return this.outlookCalendarGraphApiService.getOutLookCalendarWithColors(viewDate).pipe(
                map((result:any) => {
                    let ret_list:IEvent[] = [];
                    result.responses.sort((a,b) => a.id - b.id)

                    result.responses[1].body.value.forEach((element, index) => {
                        if(element.type != 'seriesMaster'){
                            var tempcalendar:any;

                            if(element.seriesMasterId){
                              let parent = result.responses[1].body.value.find(c => c.id == element.seriesMasterId);

                              if(parent){
                                tempcalendar = this.mapOutLookCalendarEvent(element,index, result.responses[0].body.value, parent)
                              }
                            }else{
                              tempcalendar = this.mapOutLookCalendarEvent(element,index, result.responses[0].body.value)
                            }
                        }

                        if(tempcalendar){
                            tempcalendar.start = new Date(tempcalendar.start+"Z")
                            tempcalendar.end = new Date(tempcalendar.end+"Z")

                            if(tempcalendar.end){
                                tempcalendar.daysCount = this.dateDiffInDays(tempcalendar.start,tempcalendar.end) > 0
                                ? this.dateDiffInDays(tempcalendar.start,tempcalendar.end)+1
                                : 0
                            }else{
                                tempcalendar.daysCount = 0
                            }


                            ret_list.push(tempcalendar)

                        }

                    });

                    return ret_list.sort((a:any,b:any) => a.start - b.start);
                })
            )
        }

        getCalendarEvents():Observable<IOutlookCalendar[]>{
            let outlookCalendar;

            const datepipe: DatePipe = new DatePipe('en-US');
            const today = datepipe.transform(Date.now(),'yyyy-MM-ddTHH:mm:ss.ms')+'Z';

            outlookCalendar =  forkJoin([
                this.outlookCalendarGraphApiService.getMasterCategories(),
                this.outlookCalendarGraphApiService.getCalendarEvents()
            ]).pipe(
                map((result:any) => {
                    let ret_list:IOutlookCalendar[] = [];
                    result[1].value.forEach((element) => {
                        let atends:any[] = [];

                        if(element.attendees){
                            for (let index = 0; index < element.attendees.slice(0,5).length; index++) {
                                const element1 = element.attendees[index];

                                let double = atends.find(a => a == element1.emailAddress.address)
                                if(double == undefined){
                                    let user:IUserInfo = {
                                        displayName: element1.emailAddress.name,
                                        userPrincipalName:element1.emailAddress.address
                                    }
                                    atends.push(user)
                                }
                            }

                            let double = result[1].value.find(el =>  el.changeKey === element.changeKey && el.id != element.id);
                            let doubles = [double, element].sort((a:any, b:any) => a.createdDateTime.localeCompare(b.createdDateTime)).reverse()

                            if(double){
                                element = doubles[0]
                            }

                            var tempcalendar:IOutlookCalendar | undefined;
                            if(element.type != 'seriesMaster'){
                            tempcalendar = this.mapCalendarEvent(element, result[0].value)
                            }else{
                                let startDate = datepipe.transform(element.recurrence.range.startDate,'yyyy-MM-ddT00:00:00.00')+'Z';
                                let endDate = datepipe.transform(element.recurrence.range.endDate,'yyyy-MM-ddT00:00:00.00')+'Z';
                                if(element.recurrence.range.type == 'noEnd' || endDate == "0001-01-01T00:00:00.00Z"){
                                    const newEndData = new Date(today).getTime()+86400000;
                                    endDate =  datepipe.transform(newEndData,'yyyy-MM-ddT00:00:00.00')+'Z';
                                }

                                tempcalendar = this.mapCalendarEvent(element, result[0].value)
                                tempcalendar.isAllDay = this.isAllDay(tempcalendar)
                                tempcalendar = this.setStartAndEndDate(tempcalendar, today, startDate, endDate)
                            }

                            if(tempcalendar){
                                tempcalendar.start = new Date(tempcalendar.start+"Z")
                                tempcalendar.end = new Date(tempcalendar.end+"Z")

                                let duplicated = ret_list.find(el => tempcalendar!.changeKey && el.changeKey === tempcalendar!.changeKey);

                                if(!duplicated){
                                    ret_list.push(tempcalendar)
                                }

                            }
                        }

                    });
                    ret_list.sort((a:any, b:any) => a.start.toISOString().localeCompare(b.start.toISOString()));
                    this.store.saveData(ret_list,'outlookCalendar');
                    return ret_list;
                })
            )

            return outlookCalendar;
        }

        mapCalendarEvent(element:any, colors, parent?){

          let atends:any[] = [];
          if(element.attendees){
            for (let index = 0; index < element.attendees.slice(0,5).length; index++) {
              const element1 = element.attendees[index];

              let double = atends.find(a => a == element1.emailAddress.address)
              if(double == undefined){
                  let user:IUserInfo = {
                      displayName: element1.emailAddress.name,
                      userPrincipalName:element1.emailAddress.address
                  }
                  atends.push(user)
              }
            }
          }

          let colorObject =  colors.find(c => element.categories && c.displayName == element.categories[0]);
          let color;
          if(colorObject == undefined){
              color = OutlookCalendarColors.find(c => c.name == 'preset7')?.color;
          }else{
              color = OutlookCalendarColors.find(c => c.name == colorObject.color)?.color;
          }

          let tempcalendar: IOutlookCalendar = {
              color: color,
              subject: parent == undefined ? element.subject : parent.subject,
              attendees:atends,
              start: element.start.dateTime,
              end: element.end.dateTime,
              url:element.onlineMeeting != undefined
              ? element.onlineMeeting.joinUrl
              : element.webLink,
              changeKey:element.changeKey,
              isAllDay: element.isAllDay
          };
          return tempcalendar;
        }

        mapOutLookCalendarEvent(element:any, id, colors, parent?){

            let colorObject =  colors.find(c => element.categories && c.displayName == element.categories[0]);
            let color;
            if(colorObject == undefined){
                color = OutlookCalendarColors.find(c => c.name == 'preset7')?.color;
            }else{
                color = OutlookCalendarColors.find(c => c.name == colorObject.color)?.color;
            }

            var isAllDay = element.end != undefined && element.end != null
            && (parent == undefined ? element.isAllDay : parent.isAllDay) == false
            && element.start.dateTime.split('T')[0] != element.end.dateTime.split('T')[0] ? true : (parent == undefined ? element.isAllDay : parent.isAllDay);
            var icon = `<img src="https://stplaytika360.blob.core.windows.net/360images/CalendarIcons/noun-person.png"> `

            if(element.isAllDay == true){
              if(element.end.dateTime
                && String(element.start.dateTime).split('T')[0]
                == String(element.end.dateTime).split('T')[0]){
                  element.end.dateTime = `${element.end.dateTime.split('T')[0]}T01:30:00.0000000`
              }
            }

            let tempcalendar: IEvent = {
                id:id,
                start: element.start.dateTime,
                end: element.end.dateTime,
                title: parent == undefined ? icon+element.subject : icon+parent.subject,
                organizer:parent == undefined ? element.organizer.emailAddress.address : parent.organizer.emailAddress.address,
                color:{
                    primary: color,
                    secondary: color+"0D",
                },
                icon:"https://stplaytika360.blob.core.windows.net/360images/CalendarIcons/noun-person.png",
                meta: [
                    {
                        "color":color,
                        "title": parent == undefined ? element.subject : parent.subject,
                        "startDateTime": element.start.dateTime+"Z",
                        "endDateTime": element.end != null && element.end != undefined && isAllDay == false ? element.end.dateTime+"Z" : undefined
                    }
                ],
                isCompany:false,
                allDay: isAllDay,
            }

            if(tempcalendar.end && String(tempcalendar.start).split('T')[0] != String(tempcalendar.end).split('T')[0]){

                if((String(tempcalendar.start).split('T')[1] == String(tempcalendar.end).split('T')[1])
                || String(tempcalendar.end).split('T')[1].includes('00:00:00')){
                    tempcalendar.end = this.subDays(tempcalendar.end, 1)
                }
            }


            if(tempcalendar.allDay == false){
                this.allDay(tempcalendar)
            }

            return tempcalendar;
        }

        setStartAndEndDate(tempcalendar, today, startDate, endDate){
            if(endDate >= today && startDate <= today){
                tempcalendar.start = `${today.split('T')[0]}T${tempcalendar.start.split('T')[1]}`
                tempcalendar.end = `${today.split('T')[0]}T${tempcalendar.end.split('T')[1]}`
            }else{
                tempcalendar = undefined
            }

            return tempcalendar
        }

        dateDiffInDays(a, b) {
            const _MS_PER_DAY = 1000 * 60 * 60 * 24;
            // Discard the time and time-zone information.
            const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
            const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        }

        isAllDay(tempcalendar):boolean{
            return (tempcalendar.start && tempcalendar.start.includes(tempcalendar.end))
                && (tempcalendar.end && tempcalendar.end.includes(tempcalendar.start))
        }

        allDay(tempcalendar){
            tempcalendar.allDay = tempcalendar.start && tempcalendar.end
            && tempcalendar.start == tempcalendar.end
        }

        private subDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() - days);
            return result;
        }

    }
