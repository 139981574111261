<div class="alert-modal">
    <div class="header">
        {{message}}
      <button
        (click)="onCloseModalEmmiter.emit(true)"
        class="close-icon"
        aria-label="Close"
        type="button"
      >
        <img
          src="/assets/images/global/close-modal-apps-page.png"
          alt="close-modal"
        />
      </button>
    </div>
    <div class="content">

      <div class="action-bottons">
        <button (click)="onDeleteButtonEmmiter.emit(true)" class="action-bottons__button action-bottons__button-delete">
          Yes
        </button>
        <button (click)="onCloseModalEmmiter.emit(true)" class="action-bottons__button action-bottons__button-cancel">
          Cancel
        </button>
      </div>

    </div>
  </div>
