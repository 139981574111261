import { IColor } from "../Models/utilities.interface";

export const Colors:IColor[] =[
    {
        background: '#f2d5cd',
        color: '#6c2a12'
    },
    {
        background: '#ebd3e1',
        color: '#7b4967'
    },
    {
        background: '#c7d4e7',
        color: '#4c6389'
    },
    {
        background: '#cddfd5',
        color: '#406450'
    },
    {
        background: '#cfdae0',
        color: '#64787e'
    },
    {
        background: '#e2deed',
        color: '#6e6686'
    },
    {
        background: '#f8eacd',
        color: '#a6884f'
    },
    {
        background: '#d0eadd',
        color: '#478063'
    },
    {
        background: '#e6eddd',
        color: '#667551'
    },
    {
        background: '#fcead5',
        color: '#9f7548'
    },
    {
        background: '#e8ddd9',
        color: '#7d675b'
    },
    {
        background: '#e7ced1',
        color: '#693037'
    },
    {
        background: '#f3d7d7',
        color: '#965b62'
    },
    {
        background: '#dbe1d7',
        color: '#7d8672'
    },
    {
        background: '#e8d0de',
        color: '#6e3758'
    },
    {
        background: '#f5d4df',
        color: '#9d576f'
    },
]
