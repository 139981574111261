import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IUserPermitions } from 'src/app/Shared/Models/user.model';
import { IWellbeingPopularEvent } from 'src/app/Shared/Models/wellbeing.interface';
import { FileReaderService } from 'src/app/Shared/Services/file-reader.service';
import SwiperCore, { Pagination, Navigation } from "swiper";
import { DashboardService } from '../../Services/DashboardService/DashboardService';

SwiperCore.use([Pagination,Navigation]);

@Component({
  selector: 'app-dashboard-activities',
  templateUrl: './dashboard-activities.component.html',
  styleUrls: ['./dashboard-activities.component.scss']
})
export class DashboardActivitiesComponent implements OnInit {

  @Input() popularActivities: IWellbeingPopularEvent[];
  @Input() monthlyActivitiesPDF:string;
  @Input() userPermitions:IUserPermitions;

  constructor(private fileReaderService:FileReaderService,private dashboardService: DashboardService) { }

  ngOnInit(): void {
  }

  onPdfOpen(){
    this.fileReaderService.openFile(this.monthlyActivitiesPDF);
    this.dashboardService.close();

  }
  isLongTitle(title){
    var result = title.split(" ");
    if(result[0] && result[0].length >=8){
      return true;
    }
    return false;
  }
}
