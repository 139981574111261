import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { MenuSidePopupGraphApiService } from "src/app/Core/Services/GraphApiServices/menu-side-popup.graph-api";
import { IMenuSidePopupBanner } from "src/app/Shared/Models/side-menu-popup.interface";
import { FileService } from "src/app/Shared/Services/file.service";
import { MemberOfService } from "src/app/Shared/Services/member-of.service";
import { SessionStorageService } from "src/app/Shared/Services/session-storage.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
  })
  export class MenuSidePopupGraphFacade {

    sideMenuPopupBanners:IMenuSidePopupBanner[] = []
    sideMenuPopupBanners$ = new BehaviorSubject<IMenuSidePopupBanner[]>(this.sideMenuPopupBanners);

    constructor(private menuSidePopupGraphApiService:MenuSidePopupGraphApiService,
      private store:SessionStorageService,
      private memberOfService:MemberOfService,
      private fileService:FileService){}

    getSideMenuPopupBanners() {

      let sideMenuPopupBanners;

        sideMenuPopupBanners = this.menuSidePopupGraphApiService.getMenuSidePopupBanners().pipe(
          map((result: any) => {
              result.value.forEach(element => {
                let assigned = element.fields.AssignedTo != undefined || element.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(element.fields.AssignedTo, element.fields.UnAssignedTo)
                : true;

                if(assigned){
                  let banner: IMenuSidePopupBanner ={
                    image: element.fields.Image != undefined ? element.fields.Image.Url: undefined,
                    location:element.fields.BannerLocation.split(' ')[0].toLowerCase(),
                    link:element.fields.URL != undefined ? element.fields.URL.Url : undefined,
                        isExternal:element.fields.IsExternalLink != undefined ? element.fields.IsExternalLink : true,
                    isPdf:  element.fields.IsPDF == undefined ? false :  element.fields.IsPDF
                  }
                  this.setImageUrl(banner)
                  this.sideMenuPopupBanners.push(banner)
                }

              });
          })
        )

      sideMenuPopupBanners.subscribe(() => {
        this.sideMenuPopupBanners$.next(this.sideMenuPopupBanners)
      });
    }

      private setImageUrl(banner:IMenuSidePopupBanner){

        if(banner.image && banner.image.includes(environment.SharePoint.SiteURL)){

          let libraryName =  banner.image.split('sites/')[1].split('/')[1];
          let pathArray = banner.image.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if(index > 1){
              filePath+='/'+element;

              if(index == pathArray.length-1){
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
                  if(result != ""){
                    banner.image = result;
                  }
                })
              }
            }
          });
        }
        // if(banner.link && banner.link.includes(environment.SharePoint.SiteURL) && banner.isPdf == true){
        //   let libraryName =  banner.link.split('sites/')[1].split('/')[1];
        //   let pathArray = banner.link.split('sites/')[1].split('/');
        //   let filePath = "";

        //   pathArray.forEach((element, index) => {
        //     if(index > 1){
        //       filePath+='/'+element;

        //       if(index == pathArray.length-1){
        //         filePath = filePath.split('?')[0];
        //         this.fileService.getByPathFile(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
        //           if(result != ""){
        //             banner.link = result;
        //           }
        //         })
        //       }
        //     }
        //   });
        // }
    }

}
