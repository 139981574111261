import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GalleryGraphApiService {
  fields =
    'PlaytikaTitle,UnAssignedTo,BlobPath,PlaytikaDescription,GalleryMainImage,StartDate,PlaytikaCategories_x003A_Title,Editor1,PlaytikaCommunity_x003A_Title,PlaytikaDepartment_x003A_Title,PlaytikaDepartment_x003A_ImageUrl,PlaytikaCategories_x003A_TextColor,PlaytikaCategories_x003A_BackgroundColor,PlaytikaDepartment_x003A_ID,PlaytikaCategories_x003A_ID,AssignedTo';

  constructor(protected http: HttpClient) {}

  getGalleries() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterStartDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    const filterEndDate = datepipe.transform(Date.now(), 'yyyy-MM-dd');
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.PlaytikaGallery
      }/drive/list/items?$expand=driveItem,fields($select=${
        this.fields
      })&$filter=(fields/ContentType eq 'Folder' and ((fields/StartDate  le '${filterStartDate}' and fields/_EndDate  ge '${filterEndDate}') or (fields/StartDate  le '${filterStartDate}' and fields/_EndDate eq null)))&$orderby=fields/StartDate desc&top=12`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }
  getGalleryById(id) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.PlaytikaGallery
      }/drive/list/items/${id}?$expand=driveItem,fields($select=${
        this.fields
      })`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }
  getGalleryImages(galleryId: string, top) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.PlaytikaGallery
      }/drive/items/${galleryId}/children?$expand=thumbnails&$top=${top}`
    );
  }

  filterGalleryByCategory(categoryId) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterStartDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    const filterEndDate = datepipe.transform(Date.now(), 'yyyy-MM-dd');
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.PlaytikaGallery
      }/drive/list/items?$expand=driveItem,fields($select=${
        this.fields
      })&$filter=(((fields/StartDate  le '${filterStartDate}' and fields/_EndDate  ge '${filterEndDate}') or (fields/StartDate  le '${filterStartDate}' and fields/_EndDate eq null)) and fields/PlaytikaCategoriesLookupId eq '${categoryId}' and fields/ContentType eq 'Folder')&$orderby=fields/StartDate desc&top=20`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }
  filterGalleryByCategories(ids) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterStartDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    const filterEndDate = datepipe.transform(Date.now(), 'yyyy-MM-dd');

    let idsFilter = '';
    for (let index = 0; index < ids.length; index++) {
      idsFilter += "fields/PlaytikaCategoriesLookupId eq '" + ids[index] + "'";
      if (index < ids.length - 1) {
        idsFilter += ' or ';
      }
    }

    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.PlaytikaGallery
      }/drive/list/items?$expand=driveItem,fields($select=${
        this.fields
      })&$filter=(((fields/StartDate  le '${filterStartDate}' and fields/_EndDate  ge '${filterEndDate}') or (fields/StartDate  le '${filterStartDate}' and fields/_EndDate eq null)) and (${idsFilter}) and fields/ContentType eq 'Folder')&$orderby=fields/StartDate desc&top=20`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }

  getMore(requestString: any) {
    return this.http.get(requestString, {
      headers: { Prefer: 'allowthrottleablequeries' },
    });
  }

  getGalleryCategories() {
    const fields = '$select=Title,TextColor,BackgroundColor,GalleryCount';
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.Categories
      }/items?$expand=fields(${fields})`
    );
  }

  getGalleriesList(requests) {
    let body = {
      requests: requests,
    };
    return this.http.post(`${environment.GraphAPIURL}$batch`, body);
  }

  constructUrl(ids) {
    let requests: any = [];
    ids.forEach((element) => {
      let obj = {
        id: element,
        method: 'GET',
        url: `/sites/${
          environment.SharePoint.SiteURL +
          ',' +
          environment.SharePoint.ContentSiteId
        }/lists/${
          environment.SharePoint.PlaytikaGallery
        }/drive/list/items/${element}?$expand=driveItem,fields($select=${
          this.fields
        })`,
      };

      requests.push(obj);
    });

    return requests;
  }
  getGalleryFiles(folderId:string, liberId:string){

    return this.http.get(
        `${environment.GraphAPIURL}/drives/${liberId}/items/${folderId}/children`
    );
  }
  downloadFile(downloadUrl:string){
    let headers = {
        responseType: 'blob' as 'json',
    }
    return this.http.get<Blob>(`${downloadUrl}`, headers);
  }
  async downloadFileAsync(downloadUrl:string){
    let headers = {
        responseType: 'blob' as 'json',
    }
    const res = await this.http.get<Blob>(`${downloadUrl}`, headers).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  }
}
