import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OutLookEventApiService } from 'src/app/Core/Services/ApiServices/outLookEvents.api';
import { WellbeingApiService } from 'src/app/Core/Services/ApiServices/wellbeing.api';
import {
  IWellbeingCategory,
  IWellbeingCategoryItem,
  IWellbeingEventItem,
  IWellbeingMainBanner,
  IWellbeingPopularEvent,
  IWellbeingPopularItem,
} from 'src/app/Shared/Models/wellbeing.interface';
import { PlaytikaSiteAdminService } from 'src/app/Shared/Services/playtika-site-admin.service';
import { PreloaderService } from 'src/app/Shared/Services/preloader.service';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { UserGraphApiFacade } from '../GraphApiFacade/user-graph.facade';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { GalleryGraphApiService } from 'src/app/Core/Services/GraphApiServices/gallery.graph-api';
import { FileService } from 'src/app/Shared/Services/file.service';

@Injectable({
  providedIn: 'root',
})
export class WellbeingFacade {
  currentEvent: any;
  popularActivity: IWellbeingPopularItem = {
    pageIndex: 0,
    pageSize: 0,
    totalItems: 0,
    items: [],
  };
  categoryWithEvents: IWellbeingCategoryItem = {
    pageIndex: 0,
    pageSize: 0,
    totalItems: 0,
    items: [],
    category: {
      id: 0,
      title: '',
      subTitle: '',
      description: '',
      icon: '',
      iconAnimated: '',
      backgroundColor: '',
      order: 0,
    },
  };
  userLocation?: string;
  adminSelectedLocation?: string = 'Select';
  isMainSiteAdmin: boolean = false;

  eventIds: number[] = [];

  wellbeingCategoryies: IWellbeingCategory[] = [];
  wellbeingCategoryiesSourse$: BehaviorSubject<IWellbeingCategory[]> =
    new BehaviorSubject<IWellbeingCategory[]>(this.wellbeingCategoryies);

  categoriesWithEvents: IWellbeingCategoryItem[] = [];

  categoriesWithEventsSourse$: BehaviorSubject<IWellbeingCategoryItem[]> =
    new BehaviorSubject<IWellbeingCategoryItem[]>(this.categoriesWithEvents);

  categoryWithEventsSourse$: BehaviorSubject<IWellbeingCategoryItem> =
    new BehaviorSubject<IWellbeingCategoryItem>(this.categoryWithEvents);
  popularActivitySourse$: BehaviorSubject<IWellbeingPopularItem> =
    new BehaviorSubject<IWellbeingPopularItem>(this.popularActivity);
  mainBannerSource$: BehaviorSubject<IWellbeingMainBanner[]> =
    new BehaviorSubject<IWellbeingMainBanner[]>([]);
  likedUserList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  _wellbeingEventDetailsSource: any[] = [];
  public wellbeingEventDetailsSource$ = new BehaviorSubject<any>(
    this._wellbeingEventDetailsSource
  );
  public isMainSiteAdmin$ = new BehaviorSubject<boolean>(this.isMainSiteAdmin);

  addEventToCalendarStatus: any;
  currentdbEventId: any;

  constructor(
    private wellbeingApiService: WellbeingApiService,
    private outLookEventApiService: OutLookEventApiService,
    private preloaderService: PreloaderService,
    private userGraphFacade: UserGraphApiFacade,
    private playtikaSiteAdminService: PlaytikaSiteAdminService,
    private userProfileService: UserProfileService,
    private galleryGraphApiService: GalleryGraphApiService,
    private fileService: FileService
  ) {
    this.playtikaSiteAdminService.playtikaSiteAdminSource$.subscribe(
      (source) => {
        if (source.isMainSiteAdmin) {
          this.isMainSiteAdmin = source.isMainSiteAdmin;
          this.isMainSiteAdmin$.next(this.isMainSiteAdmin);
        }
      }
    );

    this.userProfileService.userProfileSource$.subscribe((user) => {
      if (user && user.userPrincipalName) {
        this.userLocation = user?.officeLocation;
      }
    });
  }

  setAdminLocationSelection(selectedLocation) {
    this.adminSelectedLocation = selectedLocation;
    this.getWellbeingCategories();
  }

  getLocation() {
    if (this.isMainSiteAdmin && this.adminSelectedLocation !== 'Select') {
      return this.adminSelectedLocation;
    }
    return this.userLocation;
  }

  getWellbeingSiteLocations() {
    return this.wellbeingApiService.getWellbeingSiteLocations();
  }

  getWellbeingMainBanner() {
    this.getUserLocation()
      .pipe(
        switchMap((location) =>
          this.wellbeingApiService.getWellbeingMainBanner(location)
        )
      )
      .pipe(
        catchError((err) => {
          return of([]);
        })
      )
      .subscribe((source) => {
        this.mainBannerSource$.next(source);
      });
  }

  getWellbeingCategories() {
    return this.getUserLocation()
      .pipe(
        switchMap((location) =>
          this.wellbeingApiService.getWellbeingCategories(location)
        )
      )
      .pipe(
        catchError((err) => {
          let categories: IWellbeingCategory[] = [];
          return of(categories);
        }),
        tap((data) => {
          this.wellbeingCategoryies.push(
            ...data.sort((a, b) => a.order - b.order)
          );
          this.wellbeingCategoryiesSourse$.next(this.wellbeingCategoryies);
        })
      )
      .subscribe();
  }

  getWellbeingPopularActivities(PageSize, PageIndex) {
    if (PageIndex == 1) {
      this.popularActivity.items = [];
    }

    this.getUserLocation()
      .pipe(
        switchMap((location) =>
          this.wellbeingApiService.getWellbeingPopularActivities(
            PageIndex,
            PageSize,
            location
          )
        )
      )
      .pipe(
        catchError((err) => {
          return of(this.popularActivity);
        })
      )
      .subscribe((source) => {
        this.popularActivity.items.push(...source.items);
        this.popularActivity.pageIndex = source.pageIndex;
        this.popularActivity.pageSize = source.pageSize;
        this.popularActivity.totalItems = source.totalItems;
        this.popularActivitySourse$.next(this.popularActivity);
      });
  }

  getWellbeingCategoryWithEventsById(PageSize, PageIndex, categoryId) {
    this.preloaderService.showPreloader();

    if (PageIndex == 1) {
      this.categoryWithEvents.items = [];
      this.categoryWithEvents.category = {
        id: 0,
        title: '',
        subTitle: '',
        description: '',
        icon: '',
        iconAnimated: '',
        backgroundColor: '',
        order: 0,
      };
    }

    this.getUserLocation()
      .pipe(
        switchMap((location) =>
          this.wellbeingApiService.getWellbeingEventsByCategory(
            PageIndex,
            PageSize,
            categoryId,
            location
          )
        )
      )
      .pipe(
        catchError((err) => {
          this.preloaderService.hidePreloader();
          return of(this.categoryWithEvents);
        })
      )
      .subscribe((source: any) => {
        let eventIds: any[] = [
          ...new Map(
            source.items.map((item) => [item['id'], Number(item.id)])
          ).values(),
        ];
        if (eventIds.length > 0) {
          this.getWellbeingEventDetails(eventIds);
        }
        this.categoryWithEvents.items.push(...source.items);
        this.categoryWithEvents.pageIndex = source.pageIndex;
        this.categoryWithEvents.pageSize = source.pageSize;
        this.categoryWithEvents.totalItems = source.totalItems;
        this.categoryWithEvents.category = source.category;

        this.categoryWithEventsSourse$.next(this.categoryWithEvents);
        this.preloaderService.hidePreloader();
      });
  }

  getWellbeingCategoryEventsById(PageSize, PageIndex, categoryId) {
    this.getUserLocation()
      .pipe(
        switchMap((location) =>
          this.wellbeingApiService.getWellbeingEventsByCategory(
            PageIndex,
            PageSize,
            categoryId,
            location
          )
        )
      )
      .pipe(
        catchError((err) => {
          return of({});
        })
      )
      .subscribe((result: any) => {
        if (result && result.items) {
          let eventIds: any[] = [
            ...new Map(
              result.items.map((item) => [item['id'], Number(item.id)])
            ).values(),
          ];
          if (eventIds.length > 0) {
            this.getWellbeingEventDetails(eventIds);
          }

          let currentItem = this.categoriesWithEvents.find(
            (c) => c.category?.id == categoryId
          );

          if (currentItem) {
            currentItem.items.push(...result.items);
            currentItem.pageIndex = result.pageIndex;
            currentItem.pageSize = result.pageSize;
            currentItem.totalItems = result.totalItems;
          } else {
            this.categoriesWithEvents.push(result);
          }

          this.categoriesWithEventsSourse$.next(this.categoriesWithEvents);
        }
      });
  }

  getWellbeingEventsByKeywords(keywords: string[]) {
    return this.getUserLocation().pipe(
      switchMap((location) =>
        this.wellbeingApiService.getWellbeingEventsByKeywords(
          keywords,
          location
        )
      ),
      tap((response) =>
        this.getWellbeingEventDetails(response.map((r) => Number(r.id)))
      ),
      map((response) =>
        response.map((e) => {
          return { value: e, type: 'WellbeingEvent' };
        })
      )
    );
  }

  getWellbeingEvent(eventId: number) {
    return this.getUserLocation()
      .pipe(
        switchMap((location) =>
          this.wellbeingApiService.getWellbeingEvent(eventId, location)
        )
      )
      .pipe(
        tap((data) => {
          let eventIds: any[] = [];
          eventIds.push(data.id);
          if (eventIds.length > 0) {
            this.getWellbeingEventDetails(eventIds);
          }
        })
      );
  }

  getWellbeingEventDetails(eventIds: number[]) {
    let new_array_articleIds = this.splitArray(
      eventIds,
      (eventIds.length / 10).toFixed(),
      true
    );
    let source: Array<Observable<any>> = [];

    new_array_articleIds.forEach((element) => {
      source.push(
        this.wellbeingApiService.getwellbeingEventDetails(
          element,
          this.getLocation()
        )
      );
    });

    forkJoin(source)
      .pipe(
        tap((result) => {
          var mergedResult = [].concat.apply([], result);

          if (result != null) {
            this._wellbeingEventDetailsSource.push(...mergedResult);
            this.wellbeingEventDetailsSource$.next(
              this._wellbeingEventDetailsSource
            );
          }
        })
      )
      .subscribe();
  }

  createUserCalendarEvent(event: IWellbeingEventItem) {
    this.addEventToCalendarStatus = undefined;
    this.currentdbEventId = undefined;
    return this.outLookEventApiService
      .getOutLookEvent(event.id, String(this.getLocation()))
      .pipe(
        switchMap((result) => {
          if (result == null) {
            return this.createCalendarEvent(event);
          } else if (
            (result != null && result.eventDateTime != event.eventDateTime) ||
            result.subject != event.eventName ||
            result.eventLocation != event.location ||
            result.eventBody != event.description
          ) {
            return this.updateCalendarEvent(event, result).pipe(
              catchError((err) => {
                return this.createCalendarEvent(event);
              })
            );
          } else if (result == false) {
            this.addEventToCalendarStatus = {
              error: true,
            };
            return of(this.addEventToCalendarStatus);
          }

          this.addEventToCalendarStatus = {
            exists: true,
          };

          this.currentdbEventId = result.id;
          return of(this.addEventToCalendarStatus);
        }),
        map(() => {
          return this.addEventToCalendarStatus;
        })
      );
  }

  createCalendarEvent(event, isDuplicated?) {
    return this.userGraphFacade
      .createUserCalendarEvent(event, true)
      .pipe(
        switchMap((value) => {
          if (value == false) {
            this.addEventToCalendarStatus = {
              error: true,
            };
            return of(false);
          }

          this.addEventToCalendarStatus = {
            created: true,
          };

          this.currentEvent = value;

          if (isDuplicated == true) {
            return this.outLookEventApiService.updateOutLookEvent(
              this.currentdbEventId,
              value.id,
              event
            );
          }

          return this.outLookEventApiService.createOutLookEvent(
            event,
            value.id,
            String(this.getLocation())
          );
        })
      )
      .pipe(
        map(() => {
          return this.addEventToCalendarStatus;
        })
      );
  }

  updateCalendarEvent(spEvent, dbEvent) {
    return this.userGraphFacade
      .updateUserCalendarEvent(dbEvent.outlookEventId, spEvent, true)
      .pipe(
        switchMap((value) => {
          if (value == false) {
            this.addEventToCalendarStatus = {
              error: true,
            };
            return of(false);
          }

          this.addEventToCalendarStatus = {
            created: true,
          };

          return this.outLookEventApiService.updateOutLookEvent(
            dbEvent.id,
            dbEvent.outlookEventId,
            spEvent
          );
        })
      );
  }

  addWellbeingEventLike(eventId: number) {
    return this.wellbeingApiService.addWellbeingEventLike(
      eventId,
      String(this.getLocation())
    );
  }

  removeWellbeingEventLike(eventId: number) {
    return this.wellbeingApiService.removeWellbeingEventLike(
      eventId,
      String(this.getLocation())
    );
  }

  public getUserLocation() {
    return of(this.getLocation()).pipe(
      switchMap((location) => {
        if (location != undefined && location != '') {
          return of(location);
        }
        return this.userGraphFacade.getUserProfile().pipe(
          map((user) => {
            let location: string = '';
            if (user && user.userPrincipalName) {
              location = String(user?.officeLocation);
            }
            return location;
          }),
          catchError(() => {
            return of('null');
          })
        );
      })
    );
  }

  updateDataSource() {
    this.wellbeingCategoryies = [];
    this.categoriesWithEvents = [];
    this._wellbeingEventDetailsSource = [];

    this.popularActivity = {
      pageIndex: 0,
      pageSize: 0,
      totalItems: 0,
      items: [],
    };
    this.categoryWithEvents = {
      pageIndex: 0,
      pageSize: 0,
      totalItems: 0,
      items: [],
      category: {
        id: 0,
        title: '',
        subTitle: '',
        description: '',
        icon: '',
        iconAnimated: '',
        backgroundColor: '',
        order: 0,
      },
    };

    this.wellbeingCategoryiesSourse$.next(this.wellbeingCategoryies);
    this.categoriesWithEventsSourse$.next(this.categoriesWithEvents);
    this.categoryWithEventsSourse$.next(this.categoryWithEvents);
    this.popularActivitySourse$.next(this.popularActivity);
    this.mainBannerSource$.next([]);
    this.wellbeingEventDetailsSource$.next(this._wellbeingEventDetailsSource);
  }

  splitArray(a, n, balanced) {
    if (n < 2) return [a];

    var len = a.length,
      out: any[] = [],
      i = 0,
      size;

    if (len % n === 0) {
      size = Math.floor(len / n);
      while (i < len) {
        out.push(a.slice(i, (i += size)));
      }
    } else if (balanced) {
      while (i < len) {
        size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, (i += size)));
      }
    } else {
      n--;
      size = Math.floor(len / n);
      if (len % size === 0) size--;
      while (i < size * n) {
        out.push(a.slice(i, (i += size)));
      }
      out.push(a.slice(size * n));
    }

    return out;
  }
  getWellbeingLikedUserList(eventId: number) {
    return this.getUserLocation().pipe(
      switchMap((location) =>
        this.wellbeingApiService.getWellbeingLikedUserList(eventId, location)
      )
    );
  }
  downloadGalleryFiles(filesArray, event) {
    let source: Array<Observable<any>> = [];

    filesArray.forEach((element) => {
      source.push(this.getFileDownloadLink(element.fileUrl));
    });

    forkJoin(source)
      .pipe()
      .subscribe((result) => {
        this.createZip(result, filesArray, event);
      });
  }

  async createZip(
    downloadLinks: any[],
    files: any[],
    event: IWellbeingEventItem
  ) {
    const zip = new JSZip();
    const name = event.eventName + '.zip';
    let previousFileName = '';
    for (let counter = 0; counter < files.length; counter++) {
      const fileData: any = await this.downloadFileAsync(
        downloadLinks[counter]
      );
      const b: any = new Blob([fileData], { type: '.pdf' });
      let filePathArray = files[counter].fileUrl.split('sites/')[1].split('/');
      let fileNameFull = filePathArray[filePathArray.length - 1];
      let fileName = fileNameFull.split('?')[0];
      if (previousFileName == fileName) {
        fileName =
          fileName.split('.')[0] + counter + '.' + fileName.split('.')[1];
      }
      previousFileName = fileName;

      zip.file(fileName, b);
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });
  }
  async downloadFileAsync(downloadUrl: string) {
    return await this.galleryGraphApiService.downloadFileAsync(downloadUrl);
  }
  getFileDownloadLink(file) {
    let siteName = file.split('sites/')[1].split('/')[0];
    let libraryName = file.split('sites/')[1].split('/')[1];
    let pathArray = file.split('sites/')[1].split('/');
    let filePath = '';
    let newFilePath = '';
    pathArray.forEach((element, index) => {
      if (index > 1) {
        filePath += '/' + element;
      }
    });

    newFilePath = filePath;
    let newParams = {
      siteName: siteName,
      libraryName: libraryName,
      path: newFilePath,
    };
    this.preloaderService.showPreloader();
    return this.fileService.getBySiteNamePathFile(newParams);
  }
}
