import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  cacheLimit = 1200000;
  constructor() { }

  public saveData(data: any, nameData: string, noCacheTime?): void {
    let obj = {
        data:data,
        timeCached: noCacheTime == undefined ? new Date().getTime() : 0
    }
    window.sessionStorage.removeItem(nameData);
    window.sessionStorage.setItem(nameData, JSON.stringify(obj));
  }

  public removeData(nameData){
    window.sessionStorage.removeItem(nameData);
  }

  public getData(nameData: string): any {
    const data = window.sessionStorage.getItem(nameData);
    if (data) {
      let ret_data = JSON.parse(data);
      let diff = new Date().getTime() - ret_data.timeCached

      if(ret_data.timeCached > 0){
        if(diff <= this.cacheLimit){
          return ret_data.data;
        }
      }else{
        return ret_data.data;
      }
    }

    return;
  }
}
