import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SPListGraphApiService } from "src/app/Shared/Services/spList-graphApi.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export class MainMenuGraphApiService extends SPListGraphApiService{
    constructor(protected http: HttpClient){
      super(http, environment.SharePoint.MainMenu)
    }

    getMainMenu():Observable<any>{
      return this.http.get(
        `${environment.ApiPath}api/${environment.API.MainMenu.GetMainMenu}`
      );
    }
}