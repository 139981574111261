import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';
import { IBirthdayWish } from 'src/app/Shared/Models/user.model';
import { UtitlityService } from 'src/app/Shared/Services/utilitiy.service';

@Component({
  selector: 'app-birthday-wishes-modal',
  templateUrl: './birthday-wishes-modal.component.html',
  styleUrls: ['./birthday-wishes-modal.component.scss']
})
export class BirthdayWishesModalComponent implements OnInit, OnDestroy {

  @Output() onCloseModalEmmiter = new EventEmitter<boolean>();
  @Input() birthdayWishes:IBirthdayWish[];

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'55px',
      'height':'55px',
      'border-radius': '50%',
      'padding': '2px'
    },
    size: 'smaller'
  };

  constructor(private userApiFacade: UserApiFacade, private utitlityService:UtitlityService) { }
  ngOnInit(): void {
  }

  isHebrew(text){
      return this.utitlityService.isHebrew(text)
  }

  ngOnDestroy(): void {
 
      let wishIds:number[] = [];
  
      this.birthdayWishes.forEach(element => {
        if(element.wasRead == false){
          wishIds.push(element.id)
        }
      });
  
      if(wishIds.length > 0){
        this.userApiFacade.setReadBirthdayWishes(wishIds)
      }
  
  }

}
