import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { StartPreloaderService } from '../../Services/StartPreloader/start-preloader.service';

@Component({
  selector: 'app-start-preloader',
  templateUrl: './start-preloader.component.html',
  styleUrls: ['./start-preloader.component.scss']
})
export class StartPreloaderComponent implements OnInit {

  options: AnimationOptions =  {
    path: '/assets/images/global/cubeAni.json'
  };

  isLoading:boolean = true;
  subscription:Subscription

  constructor(private startPreloaderService: StartPreloaderService ) {
    this.subscription = this.startPreloaderService.isLoading$.subscribe(isLoading => this.isLoading = isLoading);
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }
}
