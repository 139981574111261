import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IEvent } from 'src/app/Shared/Models/calendar.interface';
import { MoreEventsModalService } from '../../Services/more-events-modal.service';

@Component({
  selector: 'app-more-events-modal',
  templateUrl: './more-events-modal.component.html',
  styleUrls: ['./more-events-modal.component.scss']
})
export class MoreEventsModalComponent implements OnInit, OnDestroy, OnChanges {

  @Output() onCloseModalEmmiter = new EventEmitter<boolean>();
  @Output() onRefreshCalendarDataEmmiter = new EventEmitter<boolean>();
  @Output() onEventEditPress = new EventEmitter<any>();

  @Input() dayEvents:IEvent[];
  @Input() day:any;
  @Input() onDashboard: boolean = false;
  @Input() isEditMode: boolean = false;
  @Input() isPersonalCalendarEvent: boolean = false;

  constructor(private eventModalService: MoreEventsModalService) { }

  ngOnInit(): void { }

  ngOnChanges(): void {
    this.eventModalService.updateModalPosition();
  }

  ngOnDestroy(): void {
    this.dayEvents = []
  }
  refreshCalendarDataEmmiter(){
    this.onRefreshCalendarDataEmmiter.emit();
  }

  editEvent(pwaData: any) {
    this.onEventEditPress?.emit(pwaData);
  }
}
