<div class="birthday-wishes-modal">
    <div class="header">
        Your birthday wishes
      <button
        (click)="onCloseModalEmmiter.emit(true)"
        class="close-icon"
        aria-label="Close"
        type="button"
      >
      <img src="/assets/images/global/close-modal-apps-page.png"
        alt="close-modal"/>
      </button>
    </div>
    <div class="content">
          <div>
            <img src="/assets/images/global/wish-playtika-banner.png" alt="">
          </div>
          <div *ngFor="let w of birthdayWishes" class="wish">
              <div class="wish__user">
                  <div class="wish__user-photo">
                      <app-user-photo
                      [userEmail]="w.wishingUser"
                      [userPhotoSettings]="userPhotoSettings"
                      (userDisplayName)="w.displayName = $event"
                      ></app-user-photo>
                  </div>
                  <div class="wish__user__details">
                      <div class="wish__user__details-name">{{w.displayName}}</div>
                      <div [ngClass]="{'hebrew-font': isHebrew(w.message) == true }" class="wish__user__details-message"  [innerHTML]="w.message">
                      </div>
                  </div>
                  <div *ngIf="w.wishingIcon" class="wish__user-icon">
                    <img [src]="w.wishingIcon" alt="">
                </div>
              </div>
          </div>
    </div>
  </div>
