import { IOutlookCalendarColor } from "../Models/outlookCalendar.inteface";

export const OutlookCalendarColors: IOutlookCalendarColor[] = [
    {
        name: "preset0",
        color: "#E74856"
    },
    {
        name: "preset1",
        color: "#FF8C00"
    },
    {
        name: "preset2",
        color: "#AB620D"
    },
    {
        name: "preset3",
        color: "#FFF100"
    },
    {
        name: "preset4",
        color: "#47D041"
    },
    {
        name: "preset5",
        color: "#33BAB1"
    },
    {
        name: "preset6",
        color: "#73AA24"
    },
    {
        name: "preset7",
        color: "#00BCF2"
    },
    {
        name: "preset8",
        color: "#8764B8"
    },
    {
        name: "preset9",
        color: "#6a2140"
    },
    {
        name: "preset10",
        color: "#B9C0CB"
    },
    {
        name: "preset11",
        color: "#4C596E"
    },
    {
        name: "preset12",
        color: "#5D5A58"
    },
    {
        name: "preset13",
        color: "#666666"
    },
    {
        name: "preset14",
        color: "#000000"
    },
    {
        name: "preset15",
        color: "#750B1C"
    },
    {
        name: "preset16",
        color: "#CA5010"
    },
    {
        name: "preset17",
        color: "#AB620D"
    },
    {
        name: "preset18",
        color: "#C19C00"
    },
    {
        name: "preset19",
        color: "#004B1C"
    },
    {
        name: "preset20",
        color: "#004B50"
    },
    {
        name: "preset21",
        color: "#0B6A0B"
    },
    {
        name: "preset22",
        color: "#002050"
    },
    {
        name: "preset23",
        color: "#32145A"
    },
    {
        name: "preset24",
        color: "#6a2140"
    }
]