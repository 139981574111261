import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';
import { IUserInfo } from '../../Models/user.model';
import { UtitlityService } from '../../Services/utilitiy.service';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss']
})
export class UserPhotoComponent implements OnInit {

  @Input() userEmail:any;
  @Input() userDisplayNameIn?:any;
  @Input() userPhotoSettings: any;
  @Input() showPresence: boolean;

  @Output() photoLoaded = new EventEmitter<boolean>(false);
  @Output() userDisplayName = new EventEmitter<string>();

  user:IUserInfo;
  colors:any;
  userPhoto:any;
  firstLetter;
  secondLetter;

  constructor(private graphApiFacade: UserGraphApiFacade, private utilityService:UtitlityService) { }

  ngOnInit(): void {
    this.graphApiFacade.getPersonByEmail(this.userEmail).subscribe(result => {
      if(result && result.userPrincipalName){
        this.photoLoaded.emit(true);
        this.user = result;
        this.setFirstAndSecondLetter(this.user.displayName)
        this.userDisplayName.emit(this.user.displayName)
        this.userPhoto = result.photo;
        this.colors = this.utilityService.getColor();
      }
      if(result && !result.userPrincipalName && this.userDisplayNameIn){
        this.user ={
          displayName:this.userDisplayNameIn,
        }
        this.setFirstAndSecondLetter(this.user.displayName)
      }

    })
  }

  setFirstAndSecondLetter(displayName){
    if(displayName.trim() != "" && displayName.length > 0){
      this.firstLetter = displayName[0];
      let lastName =  displayName!.split(' ')[displayName!.split(' ').length -1];
      this.secondLetter = lastName && lastName.length > 0 ? lastName[0] : '';
    }
  }

}
