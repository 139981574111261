import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of, Subject } from "rxjs";
import {  catchError, map } from "rxjs/operators";
import { AnnouncementsGraphApiService } from "src/app/Core/Services/GraphApiServices/announcements.graph-api";
import { IAnnouncement, IAnnouncementId,  IAnnouncementSource, ICommentsMention, INotification } from "src/app/Shared/Models/announcements.interface";
import { ICategory } from "src/app/Shared/Models/article.model";
import { FileService } from "src/app/Shared/Services/file.service";
import { MemberOfService } from "src/app/Shared/Services/member-of.service";
import { PreloaderService } from "src/app/Shared/Services/preloader.service";
import { environment } from "src/environments/environment";
import { UserGraphApiFacade } from "./user-graph.facade";
import { IDepartment } from "src/app/Shared/Models/departmens.interface";



@Injectable({
    providedIn: 'root',
  })
  export class AnnouncementsGraphFacade {

    _announcementsSource: IAnnouncementSource = {
      announcements:[],
      homeAnnouncements:[],
      homeAnnouncementsFiltered:[],
      isHomeAnnouncementFilter:false
    }

    announcementsSource$: Subject<IAnnouncementSource> = new Subject<IAnnouncementSource>();
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    newannouncementsIdsSource:IAnnouncementId[] = []
    newannouncementsIdsSource$ = new BehaviorSubject<IAnnouncementId[]>(this.newannouncementsIdsSource);

    commentsMentionSource:ICommentsMention[] = []
    commentsMentionSource$ = new Subject<ICommentsMention[]>();

    mentionNotificationSource: INotification[] = []
    mentionNotificationSource$ = new Subject<INotification[]>()

    newannouncementsCountSource:number=0;
    newannouncementsCountSource$ = new BehaviorSubject<number>(this.newannouncementsCountSource);

    constructor(private announcementsGraphApiService:AnnouncementsGraphApiService,
      private preloaderService: PreloaderService,
      private fileService:FileService,
      private memberOfService:MemberOfService,
        private userGraphFacade:UserGraphApiFacade){}

      getAnnouncements() {
        this.isLoading$.next(true);
        const datepipe: DatePipe = new DatePipe('en-US');
        const filterDate = datepipe.transform(Date.now(),'yyyy-MM-ddTHH:mm:ss.ms')+'Z';

        let allAnnouncements:any = [];
        this._announcementsSource.announcements = [];
        this._announcementsSource.homeAnnouncements = [];

        return this.announcementsGraphApiService.getAnnouncements(1).pipe(
          map((result: any) => {

            allAnnouncements.push(...[...result.value.filter(announcement => { return announcement.fields.StartDate.split('T')[0] == filterDate?.split('T')[0];})])
            allAnnouncements.push(...[...result.value.filter(announcement => { return announcement.fields.StartDate.split('T')[0] != filterDate?.split('T')[0];})])

            for (let i = 0; i < allAnnouncements.length; i++) {
              const element = allAnnouncements[i];

              let assigned =  element.fields.AssignedTo != undefined || element.fields.UnAssignedTo != undefined
              ? this.memberOfService.isMember(element.fields.AssignedTo, element.fields.UnAssignedTo)
              : true;

              if(assigned){
                let tempAnnouncement: IAnnouncement = this.mapAnnouncementItem(allAnnouncements[i], result.nextPageIndex)

                let double = this._announcementsSource.announcements.find(a=> a.id == tempAnnouncement.id);

                if(double==undefined && tempAnnouncement.description != undefined && tempAnnouncement.description != ''){
                  this._announcementsSource.announcements.push(tempAnnouncement);
                }
              }
            }



          })
        ).subscribe(() => {
          let homeAnnouncements:any = []
          homeAnnouncements.push(...[...this._announcementsSource.announcements.filter(announcement => { return announcement.isSticky == true; })])
          this._announcementsSource.homeAnnouncements = [...homeAnnouncements.slice(0,2)]
          if(homeAnnouncements.length == 0){
            this._announcementsSource.homeAnnouncements = [...this._announcementsSource.announcements.slice(0,2)]
          }else if(homeAnnouncements.length == 1 && this._announcementsSource.announcements.length > 1){
            let announcement = this._announcementsSource.announcements.find(a => a.isSticky == false);
            if(announcement != undefined){
              this._announcementsSource.homeAnnouncements.push(announcement)
            }
          }

          this.announcementsSource$.next(this._announcementsSource);

          setTimeout(() => {
            this.isLoading$.next(false);
          }, 0);
        });
      }

      getAnnouncementsByCategory(categoryId) {

        return this.announcementsGraphApiService.getAnnouncementsByCategory(categoryId, 1).pipe(
          map((result: any) => {

            for (let i = 0; i < result.value.length; i++) {
              const element = result.value[i];

              let assigned =  element.fields.AssignedTo != undefined || element.fields.UnAssignedTo != undefined
              ? this.memberOfService.isMember(element.fields.AssignedTo, element.fields.UnAssignedTo)
              : true;

              if(assigned){
                let tempAnnouncement: IAnnouncement = this.mapAnnouncementItem(result.value[i], result.nextPageIndex)

                let double = this._announcementsSource.announcements.find(a=> a.id == tempAnnouncement.id);

                if(double==undefined && tempAnnouncement.description != undefined && tempAnnouncement.description != ''){
                  this._announcementsSource.announcements.push(tempAnnouncement);
                }
              }

            }

            this._announcementsSource.announcements.sort((a:any, b:any) => a.startDate.localeCompare(b.startDate));
            this._announcementsSource.announcements.reverse();

            this.announcementsSource$.next(this._announcementsSource);

          })
        ).subscribe();
      }

      getHomeAnnouncementsByCategory(categoryId) {

        return this.announcementsGraphApiService.getAnnouncementsByCategory(categoryId, 1).pipe(
          map((result: any) => {

            for (let i = 0; i < result.value.length; i++) {
              const element = result.value[i];

              let assigned = element.fields.AssignedTo != undefined || element.fields.UnAssignedTo != undefined
              ? this.memberOfService.isMember(element.fields.AssignedTo, element.fields.UnAssignedTo)
              : true;

              if(assigned){
                let tempAnnouncement: IAnnouncement = this.mapAnnouncementItem(result.value[i], result.nextPageIndex)

                let double = this._announcementsSource.homeAnnouncementsFiltered.find(a=> a.id == tempAnnouncement.id);

                if(double==undefined){
                  this._announcementsSource.homeAnnouncementsFiltered.push(tempAnnouncement);
                }
              }
            }

            this._announcementsSource.homeAnnouncementsFiltered.sort((a:any, b:any) => b.startDate.localeCompare(a.startDate));

            this.announcementsSource$.next(this._announcementsSource);

          })
        ).subscribe();
      }

      filterAnnouncementsByCategory(categoryId: any){
        if(categoryId == 0){
          this._announcementsSource.homeAnnouncementsFiltered = [];
          this._announcementsSource.isHomeAnnouncementFilter = false;
          this.announcementsSource$.next(this._announcementsSource);
        }else{
          this.getHomeAnnouncementsByCategory(categoryId);
          this._announcementsSource.isHomeAnnouncementFilter = true;
        }
      }

      removeAnnouncementsFromFilter(categoryId: any){
        this._announcementsSource.homeAnnouncementsFiltered = [...this._announcementsSource.homeAnnouncementsFiltered.filter(announcement => {
          return announcement.category?.id != categoryId;
        })];

        this.announcementsSource$.next(this._announcementsSource);
      }

      private mapAnnouncementItem(announcement, nextPageIndex?: any){
        const datepipe: DatePipe = new DatePipe('en-US');
        const filterDate = datepipe.transform(Date.now(),'yyyy-MM-ddTHH:mm:ss.ms')+'Z';
        const timeZoneOffset = Math.abs((new Date(filterDate).getTimezoneOffset()) * 60 * 1000);

        let department = announcement.fields.PlaytikaDepartment_x003A_Title != undefined
              ? this.setAnnouncementDepartment(announcement)
              : undefined

              let category = announcement.fields.PlaytikaCategories_x003A_Title != undefined
              ? this.setAnnouncementCategory(announcement)
              : undefined

        let tempAnnouncement: IAnnouncement = {
          startDate: new Date(new Date(announcement.fields.StartDate).getTime()-timeZoneOffset).toISOString(),
          description: announcement.fields.PlaytikaDescription,
          id: announcement.id,
          nextLink: nextPageIndex,
          community:announcement.fields.PlaytikaCommunity_x003A_Title,
          isSticky:announcement.fields.IsSticky,
          department:department,
          category:category,
          editor:announcement.fields.Editor1
        };

        this.setAnnouncementEditor(tempAnnouncement)

        return tempAnnouncement;
      }

      private setAnnouncementEditor(tempAnnouncement:IAnnouncement){
        if(tempAnnouncement.editor && !tempAnnouncement.department?.title){
          this.userGraphFacade.getPersonByEmail(String(tempAnnouncement.editor)).subscribe(user => {
            tempAnnouncement.editor = user;
          })
        }
      }

      private setAnnouncementDepartment(announcement){
        let department:IDepartment = {
          id:announcement.fields.PlaytikaDepartment_x003A_ID,
          title:announcement.fields.PlaytikaDepartment_x003A_Title,
          image: announcement.fields.PlaytikaDepartment_x003A_ImageUr
        }

        this.setDepartmentImageUrl(department)

        return department;
      }

      private setAnnouncementCategory(announcement){
        let category:ICategory = {
          id:announcement.fields.PlaytikaCategories_x003A_ID,
          name:announcement.fields.PlaytikaCategories_x003A_Title,
          color:announcement.fields.PlaytikaCategories_x003A_TextCol,
          bg:announcement.fields.PlaytikaCategories_x003A_Backgro,
          countOfItems:0
        }

        return category;
      }

      private setDepartmentImageUrl(tempDepartment:IDepartment){

        if(tempDepartment.image && tempDepartment.image.includes(environment.SharePoint.SiteURL)){

          let libraryName =  tempDepartment.image.split('sites/')[1].split('/')[1];
          let pathArray = tempDepartment.image.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if(index > 1){
              filePath+='/'+element;

              if(index == pathArray.length-1){
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
                  if(result != ""){
                    tempDepartment.image = result;
                  }
                })
              }
            }
          });
        }
      }

      SetNotificationsReaded() {
         this.announcementsGraphApiService.SetNotificationsReaded().subscribe(result => {
          this.newannouncementsIdsSource = [];
          this.newannouncementsIdsSource.push();
          this.newannouncementsIdsSource$.next(this.newannouncementsIdsSource);
          this.mentionNotificationSource = [];
          this.mentionNotificationSource.push();
          this.mentionNotificationSource$.next(this.mentionNotificationSource);
          this.newannouncementsCountSource=0;
          this.newannouncementsCountSource$.next(this.newannouncementsCountSource);
        });
      }

      setMentionArticlesNotificationsRead() {
        this.announcementsGraphApiService.setMentionArticlesNotificationsRead().subscribe(result => {
          if(result == true){
            this.newannouncementsCountSource=0;
            this.newannouncementsCountSource$.next(this.newannouncementsCountSource);
          }
        });
      }

      setMentionVodsNotificationsRead() {
        this.announcementsGraphApiService.setMentionVodsNotificationsRead().subscribe(result => {
          if(result == true){
            this.newannouncementsCountSource=0;
            this.newannouncementsCountSource$.next(this.newannouncementsCountSource);
          }
        });
      }

      // setMentionNotificationsRead() {
      //   this.announcementsGraphApiService.setMentionNotificationsRead().subscribe(result => {
      //     if(result == true){
      //       this.newannouncementsCountSource=0;
      //       this.newannouncementsCountSource$.next(this.newannouncementsCountSource);
      //     }
      //   });
      // }

      getNewNotifications(){
        this.commentsMentionSource=[];
        this.isLoading$.next(true);
        return this.announcementsGraphApiService.GetNewUserNotifications().subscribe(result => {
          this.newannouncementsIdsSource.push(...result)
          this.newannouncementsIdsSource$.next(this.newannouncementsIdsSource);
          setTimeout(() => {
            this.isLoading$.next(false);
          }, 1500);

        });
      }

      getMentionedArticlesNotifications(){
        this.announcementsGraphApiService.getMentionedArticlesNotifications().pipe(
          catchError(err => {
            let altData:ICommentsMention[]=[]
            return of(altData)
          })
        ).subscribe(result => {
          if(result != null){
            this.commentsMentionSource.push(...result)
            this.commentsMentionSource.sort((a:any, b:any) => a.createdDate.localeCompare(b.createdDate));
            this.commentsMentionSource$.next(this.commentsMentionSource.reverse())
          }
        })
      }

      getMentionedVodsNotifications(){
        this.announcementsGraphApiService.getMentionedVodsNotifications().pipe(
          catchError(err => {
            let altData:ICommentsMention[]=[]
            return of(altData)
          })
        ).subscribe(result => {
          if(result != null){
            this.commentsMentionSource.push(...result)
            this.commentsMentionSource.sort((a:any, b:any) => a.createdDate.localeCompare(b.createdDate));
            this.commentsMentionSource$.next(this.commentsMentionSource.reverse())
          }
        })
      }

      getNotifications() {
        this.announcementsGraphApiService.getNotifications().pipe(
          catchError(err => {
            let altData:INotification[]=[]
            return of(altData)
          })
        ).subscribe(result => {
          if(result != null){
            this.mentionNotificationSource.push(...result)
            this.mentionNotificationSource.sort((a:any, b:any) => a.createDate.localeCompare(b.createDate));
            this.mentionNotificationSource$.next(this.mentionNotificationSource.reverse())
          }
        })
      }

      getUserCountNotificationsToSee(){
        return this.announcementsGraphApiService.GetNewUserNotificationsCount().subscribe(result => {
          this.newannouncementsCountSource=result;
          this.newannouncementsCountSource$.next(this.newannouncementsCountSource);
        });
      }
  }
