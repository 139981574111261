<div class="profile-dashboard-calendar-container" #myDayCarousel>
  <div *ngIf="!areControlsDisabled" class="profile-dashboard-calendar-controls">
      <div class="navigation-button prev-event" (click)="swipePrev()" [ngClass]="{'disabled': isPrevDisabled}"></div>
      <div class="navigation-button next-event" (click)="swipeNext()" [ngClass]="{'disabled': isNextDisabled}"></div>
  </div>
  <swiper
    class="profile-dashboard-calendar"
    [navigation]="false"
    [slidesPerView]="'auto'"
    [spaceBetween]="14"
    [pagination]="false"
  >
    <ng-template *ngFor="let calendarEvent of calendarEvents" swiperSlide>
      <div [ngClass]="{'text-dir-rtl': calendarEvent.isHebrew == true }" class="calendar-event">
        <div class="color-line" [style.background-color]="calendarEvent.color"></div>
        <a href="{{calendarEvent.url}}" target="_blank">
          <div title="{{calendarEvent.subject}}" class="title" [ngClass]="{'hebrew-font': calendarEvent.isHebrew == true }">{{ calendarEvent.subject }}</div>
          <div *ngIf="!calendarEvent.isAllDay" class="time-range">{{ calendarEvent.start | date: 'shortTime' }} - {{ calendarEvent.end | date: 'shortTime' }}</div>
          <div *ngIf="calendarEvent.isAllDay" class="time-range">All day</div>
        </a>
        <ul class="users" *ngIf="calendarEvent.attendees">
          <li  title="{{user.displayName}}" *ngFor="let user of calendarEvent.attendees.slice(0,4)">
            <app-user-photo
            [userPhotoSettings]="userPhotoSettings"
            [userEmail]="user.userPrincipalName"
            [userDisplayNameIn]="user.displayName"
          ></app-user-photo>
          </li>

          <li class="more-users" *ngIf="calendarEvent.attendees.length > 4">
          +{{calendarEvent.attendees.length-4}}
          </li>
        </ul>
      </div>
    </ng-template>
  </swiper>
</div>
