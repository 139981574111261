import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class TextWidthService {

    canvas: HTMLCanvasElement;
    constructor(){
        this.canvas = document.createElement("canvas");
    }

    getTextWidth(text, element: HTMLElement) {
        var context = this.canvas.getContext("2d")!;
        context.font = this.getCanvasFont(element);
        const metrics = context.measureText(text);
        return metrics.width;
      }

    getCssStyle(element: HTMLElement, prop: string) {
        return window.getComputedStyle(element, null).getPropertyValue(prop);
    }
    
    getCanvasFont(el = document.body) {
      var fontWeight = this.getCssStyle(el, 'font-weight') || 'normal';
      var fontSize = this.getCssStyle(el, 'font-size') || '13px';
      var fontFamily = this.getCssStyle(el, 'font-family') || 'CodecPro';
      
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    }
  }
  
  