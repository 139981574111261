import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/Core/Services/ApiServices/api.service';
import { ITask, ITaskApp } from 'src/app/Shared/Models/task.model';

@Injectable({
    providedIn: 'root'
})
export class TasksFacade {

    newTasksCountSource:number=0;
    newTasksCountSource$ = new BehaviorSubject<number>(this.newTasksCountSource);
    constructor(private TasksApi: ApiService) {

    }

    getUserTasks():Observable<ITask[]>{
        return this.TasksApi.getUserTasks();
    }

    getTasksApps():Observable<ITaskApp[]>{
        return this.TasksApi.getTasksApps();
    }

    setUserTasksToSee(tasksToSee:any){
        return this.TasksApi.setUserTasksToSee(tasksToSee);
    }

    getUserCountTasksToSee(){
         return this.TasksApi.getUserCountTasksToSee().subscribe(result => {
            this.newTasksCountSource=result;
            this.newTasksCountSource$.next(this.newTasksCountSource);
        });
    }



}
