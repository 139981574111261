import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './Core/core.module';
import { MSALGuardConfigFactory } from './Core/Guards/MSAL/MSALGuardConfigFactory';
import { MSALInterceptorConfigFactory } from './Core/Interceptors/MSAL/MSALInterceptorConfigFactory';
import { MSALInstanceFactory } from './Core/MSALInstance/MSALInstanceFactory';

import { ApiInterceptor } from './Core/Interceptors/api.interceptor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from './Shared/shared.module';
import { FilterService } from 'primeng/api';
import { MonitoringService } from './Core/Services/Monitoring/MonitoringService';
import { TitleService } from './title.service';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { ApiRequestHandlerInterceptor } from './Core/Interceptors/api-request-handler.interceptor';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MsalModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    CrystalLightboxModule,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestHandlerInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    FilterService,
    MonitoringService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(public titleService: TitleService) {}
}
