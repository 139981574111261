import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';
import { IEmployeeBirthdaysData } from '../../Models/user.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-presence',
  templateUrl: './user-presence.component.html',
  styleUrls: ['./user-presence.component.scss']
})
export class UserPresenceComponent implements OnInit {

  @Input() size:string;
  @Input() user:any;
  showBirthday: boolean;
  birthdayData: IEmployeeBirthdaysData;

  userPresence:string = 'presenceunknown';
  userActivity:string = 'PresenceUnknown';
  datepipe: DatePipe = new DatePipe('en-US');
  today = this.datepipe.transform(Date.now(),'MM-dd');
  modalRef?: BsModalRef;

  constructor(
    private graphApi: UserGraphApiFacade, 
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {

    if(this.user.id){
      this.graphApi.getUserPresence(this.user.id).subscribe(presence => {
        this.userPresence = presence.availability.toLowerCase();
        this.userActivity = presence.activity;
      })
    }

    if(this.user && this.user.birthday  && this.today ==  this.datepipe.transform(this.user.birthday,'MM-dd')){
        this.showBirthday = true;

        this.birthdayData = {
          userEmail: this.user.userPrincipalName,
          displayName: this.user.displayName,
          dateOfBirth: this.user.birthday
        }
    }
  }

  openBirthdayWishModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
}
