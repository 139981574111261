import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { ApiService } from "src/app/Core/Services/ApiServices/api.service";
import { IVodComment, IVodItem } from "src/app/Shared/Models/vod-item.interface";
import { map, tap } from "rxjs/operators";
import { PreloaderService } from "src/app/Shared/Services/preloader.service";
import { VodsApiService } from "src/app/Core/Services/ApiServices/vods.api";
import { MemberOfService } from "src/app/Shared/Services/member-of.service";

@Injectable({
    providedIn: 'root'
})
export class VodsApiFacade {

    _currentVodComments: any[] = [];
    public currentVodCommentsSource$ = new BehaviorSubject<any[]>(this._currentVodComments);

    _vodDetailsSource: any[] = [];
    public vodDetailsSource$ = new BehaviorSubject<any>(this._vodDetailsSource);

    constructor(private vodsApi: ApiService,
        private memberOfService: MemberOfService,
        private newVodsApi: VodsApiService,
        private preloaderService: PreloaderService
        ) {

    }

    getHomevods(){
        return this.newVodsApi.getVodsHomePage().pipe(
            map(data => {
                let retItems:IVodItem[] = []
                data.forEach(element => {
                    let assignedList:any =element.assignedTo != null ? element.assignedTo.map(v => {v.LookupValue = v.lookupValue; return v}) : []
                    let unassignedList:any = element.unAssignedTo != null ? element.unAssignedTo.map(v => {v.LookupValue = v.lookupValue; return v}) : []
                    let assigned = element.assignedTo != null || element.unAssignedTo != null
                    ? this.memberOfService.isMember(assignedList, unassignedList)
                    : true;

                    if (assigned == true){
                        element.duration = this.formateDideoDuration(element.duration)
                        retItems.push(element)
                    }
                });
              return retItems;
            })
        )
    }

    getVodsByKeywords(keywords: string[], currentId?: number, categoryId?: number) {
        return this.newVodsApi.getVodsByKeywords(keywords).pipe(
            map((response: any) => {
                let retItems:IVodItem[] = []
                response.value.forEach(element => {
                    let unassignedList:any = element.unAssignedTo != null ? element.unAssignedTo.map(v => {v.LookupValue = v.lookupValue; return v}) : []
                    let isUnassigned = element.unAssignedTo != null
                    ? this.memberOfService.isNotMember(unassignedList)
                    : false;

                    if (!isUnassigned && (currentId == undefined || (currentId != undefined && element.id != currentId))){
                        element.duration = this.formateDideoDuration(element.duration)
                        if (!(categoryId && element.category && categoryId == element.category.id)) retItems.push(element);
                    }
                });
        
                this.getVodDetails(retItems.map(i => Number(i.id)));
                return retItems.map(v => {return {value: v, type: "VOD"}});
            })
        );
    }

    getVodDetails(vodIds:number[]){
      //this.preloaderService.showPreloader();
        let new_array_vodIds = this.splitArray(vodIds, (vodIds.length/10).toFixed(), true)
        let source:Array<Observable<any>> = [];

        new_array_vodIds.forEach(element => {
          source.push(this.vodsApi.getVodDetails(element));
        });

        forkJoin(source).pipe(
          tap(result => {
            var mergedResult = [].concat.apply([], result);

            if(result != null){
              this._vodDetailsSource.push(...mergedResult);
              this.vodDetailsSource$.next(this._vodDetailsSource);

              let filterResult = this._vodDetailsSource.map(a => {
                  let obj = {
                      vodId:a.vodId,
                      comments:a.topComments
                  }

                  return obj
               });
               this._currentVodComments.push(...filterResult)
               this.currentVodCommentsSource$.next(this._currentVodComments);
            }
          })
        ).subscribe(()=> {
          //this.preloaderService.hidePreloader()
        });
      }

    addVodLike(vodId:number, vodEditor:string){
        return this.vodsApi.addVodLike(vodId, vodEditor);
    }

    removeVodLike(vodId:number){
        return this.vodsApi.removeVodLike(vodId);
    }

    addVodCommentLike(commentId:number, vodId:number, commentEditor:any){
      return this.vodsApi.addVodCommentLike(commentId, vodId, commentEditor);
    }

    removeVodCommentLike(commentId:number){
      return this.vodsApi.removeVodCommentLike(commentId);
    }

    getVodComments(vodId:number){
        this.preloaderService.showPreloader()
        let comments = this._currentVodComments.find(c => c.vodId == vodId)

        this.vodsApi.getVodComments(vodId).subscribe(result => {
            result.forEach(element => {
                let double = comments.comments.find(c => c.id == element.id)

                if(double == undefined){
                    comments.comments.push(element)
                }
            });

            comments.loadedAll = true;
            comments.limitSlice = comments.comments.length;
            this.preloaderService.hidePreloader()
            this.currentVodCommentsSource$.next(this._currentVodComments);
        })


    }

    getVodChildComments(commentId:number, vodId){
        let comments = this._currentVodComments.find(c => c.vodId == vodId)

        if(comments.comments.find(c => c.topParentId == commentId) == undefined){
            this.preloaderService.showPreloader()
            this.vodsApi.getVodChildComments(commentId).subscribe(result => {

                if(comments){
                    result.forEach(element => {
                        let double = comments.comments.find(c => c.id == element.id)

                        if(double == undefined){
                            comments.comments.push(element)
                        }
                    });

                    this.preloaderService.hidePreloader()
                    this.currentVodCommentsSource$.next(this._currentVodComments);
                }
            })
        }

    }

    addVodComment(commentData){
        this.vodsApi.addVodComment(commentData).subscribe(result => {

            let comments = this._currentVodComments.find(c => c.vodId == commentData.vodId)
            result.createdDate = result.createdDate.split('+')[0]
            result.likesCount = 0
            if(comments){
                comments.comments.unshift(result);
                let comme = comments.comments.find(c => c.id == commentData.topParentId)
                if(comme){
                    comme.childCommetsCount++
                }
            }else{
                let obj = {
                    vodId:commentData.vodId,
                    comments:[result]
                }
                this._currentVodComments.push(obj)
            }

            let details = this._vodDetailsSource.find(s => s.vodId == commentData.vodId)
            details.vodCommentsCount++
            this.currentVodCommentsSource$.next(this._currentVodComments);
            this.vodDetailsSource$.next(this._vodDetailsSource)
        })
    }

    editVodComment(commentData){
        this.vodsApi.editVodComment(commentData).subscribe(result => {
            if(result && result.id != 0){
                let comments = this._currentVodComments.find(c => c.vodId == commentData.vodId)
                var editedComment = comments.comments.find(c => c.id == commentData.commentId);

                if(editedComment != undefined){
                    editedComment.comment = result.comment;
                    this.currentVodCommentsSource$.next(this._currentVodComments);
                }
            }
        })
    }

    deleteComment(commentData){
        if(this.isChildComments(commentData.id, commentData.vodId)){
            let comment ={
                "commentId": commentData.id,
                "comment": "This comment has been removed",
                "vodId":commentData.vodId
              }
            this.deleteVodCommentWithChildren(comment)
        }else{
            this.deleteVodComment(commentData)
        }
    }

    private deleteVodCommentWithChildren(commentData){
        this.vodsApi.deleteVodCommentWithChildren(commentData).subscribe(result => {
            if(result && result.id != 0){
                let comments = this._currentVodComments.find(c => c.vodId == commentData.vodId)
                var editedComment = comments.comments.find(c => c.id == commentData.commentId);

                if(editedComment != undefined){
                    editedComment.comment = result.comment;
                    editedComment.deleted = result.deleted;
                    this.currentVodCommentsSource$.next(this._currentVodComments);
                }

            }
        })
    }

    private deleteVodComment(comment:IVodComment){
        this.vodsApi.deleteVodComment(comment.id).subscribe(result => {
            if(result == true){
                let comments = this._currentVodComments.find(c => c.vodId == comment.vodId)
                var deletedComment = comments.comments.find(c => c.id == comment.id);
                if(deletedComment != undefined){
                    comments.comments.splice(comments.comments.indexOf(deletedComment), 1)
                    this.currentVodCommentsSource$.next(this._currentVodComments);

                    let comme = comments.comments.find(c => c.id == comment.topParentId)
                    if(comme){
                        comme.childCommetsCount--
                    }
                }

                let details = this._vodDetailsSource.find(s => s.vodId == comment.vodId)
                details.vodCommentsCount--
                this.vodDetailsSource$.next(this._vodDetailsSource)
            }
        })
    }

    private isChildComments(id, vodId){
        let comments = this._currentVodComments.find(c => c.vodId == vodId)
        return comments.comments.find(c => c.parentId == id) != undefined;
    }

    private getStructuredComments(commentsArray) {

        let commentsIterator = 0;
        let commentsLength = commentsArray.length;

        while (commentsIterator < commentsLength) {
            if (commentsArray[commentsIterator].parentId != 0 && commentsArray[commentsIterator].parentId != null) {
                for (var j = 0; j < commentsArray.length; j++) {
                    if (commentsArray[j].id == commentsArray[commentsIterator].parentId) {
                        if (typeof commentsArray[j].childComments === 'undefined') {
                            commentsArray[j].childComments = [];
                        }
                        commentsArray[j].childComments.push(commentsArray[commentsIterator]);
                        break;
                    }
                }
                commentsArray.splice(commentsIterator, 1);
                commentsIterator--;
                commentsLength--;
            }
            commentsIterator++;
        }

        return commentsArray;
    }

    splitArray(a, n, balanced) {

      if (n < 2)
          return [a];

      var len = a.length,
              out:any[] = [],
              i = 0,
              size;

      if (len % n === 0) {
          size = Math.floor(len / n);
          while (i < len) {
              out.push(a.slice(i, i += size));
          }
      }

      else if (balanced) {
          while (i < len) {
              size = Math.ceil((len - i) / n--);
              out.push(a.slice(i, i += size));
          }
      }

      else {

          n--;
          size = Math.floor(len / n);
          if (len % size === 0)
              size--;
          while (i < size * n) {
              out.push(a.slice(i, i += size));
          }
          out.push(a.slice(size * n));

      }

      return out;
    }
  addVodFavorite(vodId:number){
    return this.vodsApi.addVodFavorite(vodId);
  }

  removeVodFavorite(vodId:number){
      return this.vodsApi.removeVodFavorite(vodId);
  }
  getVodsFavorites(){
    return this.vodsApi.getVodsFavorites();
  }

  private formateDideoDuration(duration) {
    const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, duration)),
      parts = [d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds()];

    return parts.map((s) => String(s).padStart(2, '0')).join(':');
  }
  getVodLikedUserList(eventId: number) {
    return this.vodsApi.getVodLikedUserList(eventId, null)
  }
}
