import { DatePipe } from "@angular/common"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { map } from "rxjs/operators"
import { SPListGraphApiService } from "src/app/Shared/Services/spList-graphApi.service"
import { environment } from "src/environments/environment"
import { ApiService } from "../ApiServices/api.service"

@Injectable({
    providedIn:"root"
})
export class AnnouncementsGraphApiService extends SPListGraphApiService{
    constructor(protected http: HttpClient,private apiService: ApiService){
        super(http)
    }

    getAnnouncements(nextPageIndex: number){
        const datepipe: DatePipe = new DatePipe('en-US')
        const filterDate = datepipe.transform(Date.now(),'yyyy-MM-ddTHH:mm:ss.ms')+'Z'
        return this.http.get(`${environment.ApiPath}api/${environment.API.Announcements.GetAnnouncements}/?localtime=${filterDate}&nextPageIndex=${nextPageIndex}`);
    }
    getAnnouncementsByCategory(categoryId: string, nextPageIndex: number){
        const datepipe: DatePipe = new DatePipe('en-US')
        const filterDate = datepipe.transform(Date.now(),'yyyy-MM-ddTHH:mm:ss.ms')+'Z'
        return this.http.get(`${environment.ApiPath}api/${environment.API.Announcements.GetAnnouncementsByCategory}/${categoryId}?localtime=${filterDate}&nextPageIndex=${nextPageIndex}`);    
    }
      SetNotificationsReaded() {
      return this.apiService.SetNotificationsReaded();
    }
    GetNewUserNotifications() {
      return this.apiService.GetNewUserNotifications();
    }
    GetNewUserNotificationsCount() {
      return this.apiService.GetNewUserNotificationsCount();
    }

    getMentionedArticlesNotifications() {
      return this.apiService.getMentionedArticlesNotifications();
    }

    getMentionedVodsNotifications() {
      return this.apiService.getMentionedVodsNotifications();
    }

    getNotifications() {
      return this.apiService.getNotifications().pipe(map((data:any)=>{
        return data.map(d => {d.createDate = d.createDate+"Z"; return d;})
      }));
    }

    // setMentionNotificationsRead() {
    //   return this.apiService.setMentionNotificationsRead();
    // }

    setMentionArticlesNotificationsRead() {
      return this.apiService.setMentionArticlesNotificationsRead();
    }

    setMentionVodsNotificationsRead() {
      return this.apiService.setMentionVodsNotificationsRead();
    }

}
