import { Injectable } from '@angular/core';
import { Countries } from '../Utilities/country_list_code';
import { Colors } from '../Utilities/user_avatar_colors';
import { SessionStorageService } from './session-storage.service';

const RTL = [
  'א',
  'ב',
  'ג',
  'ד',
  'ה',
  'ו',
  'ז',
  'ח',
  'ט',
  'י',
  'כ',
  'ל',
  'מ',
  'נ',
  'ס',
  'ע',
  'פ',
  'צ',
  'ק',
  'ר',
  'ש',
  'ת',
];
@Injectable({
  providedIn: 'root',
})
export class UtitlityService {
  count = 0;

  constructor(private storage: SessionStorageService) {}

  getCountryByName(countryName: string) {
    return Countries.find(
      (c) => c.name.toLowerCase() == countryName.toLowerCase()
    );
  }

  getColor() {
    let color = Colors[Math.floor(Math.random() * Colors.length)];
    return color;
  }

  isHebrew(text: string) {
    var isRTL = false;
    if (text != undefined && text != null) {
      for (var i = 0; i < text.length && !isRTL; i++) {
        isRTL = RTL.indexOf(text[i]) > -1;
      }
    }

    return isRTL;
  }

  toDecimal(number: any, fractionDigits: number = 1) {
    if (!number || isNaN(number)) {
      return 0;
    }
    if (Number.isInteger(number)) {
      return Math.floor(number);
    }

    return number.toFixed(fractionDigits);
  }
}
