import { HostListener, Injectable, TemplateRef } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, iif } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MoreEventsModalService {

  element:HTMLAnchorElement;
  modalRef: BsModalRef;
  modalSelector: string = "more-events-modal-dialog";
  configModal = {
    backdrop: false,
    ignoreBackdropClick: true,
    class: this.modalSelector,
    animated:false
  };
  modalElement: HTMLElement;
  selectedEventRect: DOMRect;

  constructor(private modalService: BsModalService) { }

  openModal(template: TemplateRef<any>, selectedEventElement: HTMLElement, isWeekView: boolean = false) {
    if('ontouchstart' in window || navigator.maxTouchPoints){
      //not open on mobile
    }else{
      this.modalRef = this.modalService.show(template, this.configModal);
      
      this.selectedEventRect = selectedEventElement.getBoundingClientRect();
      this.modalElement = document.querySelector<HTMLElement>(`.${this.modalSelector}`)!;
      this.modalElement.style.opacity = '0';
      

      setTimeout(() => {
        this.updateModalPosition(isWeekView);
        this.modalElement.style.opacity = '1';
      }, 10)
    }
  }

  
  @HostListener('window:resize', [])
  @HostListener('window:scroll', [])
  updateModalPosition(isWeekView: boolean = false) {
    if (this.selectedEventRect && this.modalElement) {
      let modalHeight = this.modalElement.clientHeight;
      let modalWidth = this.modalElement.clientWidth;

      let topPosition = isWeekView ? this.selectedEventRect.top + this.selectedEventRect.height : this.selectedEventRect.top;
      let leftPosition = isWeekView ? this.selectedEventRect.left : this.selectedEventRect.left + this.selectedEventRect.width;

      if (topPosition + modalHeight < window.innerHeight) {
        this.modalElement.style.top = topPosition + "px";
      } else {
        this.modalElement.style.top = Number(window.innerHeight - modalHeight) + "px";
      }

      if (leftPosition + modalWidth < window.innerWidth) {
        this.modalElement.style.left = leftPosition + "px";
      } else {
        this.modalElement.style.left = Number(window.innerWidth - modalWidth) + "px";
      }      
    }
  }

  closeModal(){
    this.modalElement.style.opacity = '0';
    this.modalService.hide();
  }
}
