import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IOutlookCalendar } from 'src/app/Shared/Models/outlookCalendar.inteface';
import { IMenuSidePopupBanner } from 'src/app/Shared/Models/side-menu-popup.interface';
import {
  IBirthdayWish,
  IPerson,
  IUserPermitions,
  IUserProfileData,
} from 'src/app/Shared/Models/user.model';
import { DashboardService } from '../../Services/DashboardService/DashboardService';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FileReaderService } from 'src/app/Shared/Services/file-reader.service';
import { DashboardsApiFacade } from 'src/app/Facade/ApiFacade/dashboard.facade';
import {
  ICibusData,
  IDashboardHeader,
  IDashboardShortcut,
} from 'src/app/Shared/Models/sidebar-dashboard.model';
import { Observable, Subscription } from 'rxjs';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';
import { IWellbeingPopularEvent } from 'src/app/Shared/Models/wellbeing.interface';
import { IEvent } from 'src/app/Shared/Models/calendar.interface';
import { concatMap, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/Shared/Services/local-storage.service';

@Component({
  selector: 'app-global-profile-dashboard',
  templateUrl: './sidebar-dashboard.component.html',
  styleUrls: ['./sidebar-dashboard.component.scss'],
})
export class SidebarDashboardComponent implements OnInit {
  modalRef?: BsModalRef;

  @Input() calendarEvents: IOutlookCalendar[];
  @Input() sidebarDashboardWidth: number;

  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() onDashboardModeSwitched: EventEmitter<boolean> = new EventEmitter();
  @Output() onRefreshCalendarDataEmmiter = new EventEmitter<boolean>();

  @ViewChild('toggleSidebar') toggleButton: ElementRef;
  userPhotoSettings = {
    userPhotoStyles: {
      width: '59px',
      height: '59px',
      border: '3px solid #fff',
      'border-radius': '50%',
    },
    size: 'smaller',
  };

  collaboratorsAmount = 12;

  isDarkMode: boolean = false;

  dashboardSourceSubscription: Subscription;
  userProfileDataSubscription: Subscription;
  currentBudgetSourceSubscription: Subscription;

  isContentLoaded: boolean = false;
  shortcuts: IDashboardShortcut[];
  header: IDashboardHeader;
  dashboardEvents: IEvent[];
  popularActivities: IWellbeingPopularEvent[];
  userProfileData: IUserProfileData;
  cibusData: ICibusData;
  monthlyActivitiesPDF: string;
  userPermitions: IUserPermitions;

  calendarPlaceholder: any;

  dashboardOpenSubscription: Subscription;
  dashboardOpeningSubscription: Subscription;
  isDashboardOpening: boolean = false;
  isDashboardOpened: boolean = false;

  constructor(
    private dashboardApiFacade: DashboardsApiFacade,
    private dashboardService: DashboardService,
    private modalService: BsModalService,
    private fileReaderService: FileReaderService,
    private userFacade: UserApiFacade,
    private userProfileService: UserProfileService,
    private storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.dashboardSourceSubscription =
      this.dashboardApiFacade.dashboardSource$.subscribe((source) => {
        this.shortcuts = source.shortcuts;
        this.header = source.header;
        this.popularActivities = source.popularActivities;
        this.dashboardEvents = source.dashboardEvents;
        this.monthlyActivitiesPDF = source.monthlyActivitiesPDF;
        this.userPermitions = source.userPermitions;
        this.isContentLoaded = true;
      });

    this.userProfileDataSubscription = this.userFacade
      .getUserProfile()
      .subscribe((user) => {
        this.userProfileData = user;
      });

    this.currentBudgetSourceSubscription =
      this.dashboardApiFacade.currentBudgetSource$.subscribe((result) => {
        if (result != null) {
          this.cibusData = {
            leftQuantity: Math.round(result),
            leftPercentage: Math.round((result / 1000) * 100),
          };
        }
      });

    this.dashboardApiFacade.getDashboardInitData().subscribe();

    var isDarkMode = this.storage.getData('isDarkModeEnabled');
    if (isDarkMode) {
      this.isDarkMode = isDarkMode;

      this.onDashboardModeSwitched.emit(isDarkMode);
    }

    this.dashboardOpenSubscription = this.dashboardService.isOpened$.subscribe(
      (isOpen) => {
        this.isDashboardOpened = isOpen;
      }
    );

    this.dashboardOpeningSubscription =
      this.dashboardService.isOpening$.subscribe((isOpening) => {
        this.isDashboardOpening = isOpening;
      });
  }

  ngOnDestroy(): void {
    this.dashboardSourceSubscription.unsubscribe();
    this.userProfileDataSubscription.unsubscribe();
    this.currentBudgetSourceSubscription.unsubscribe();
    this.dashboardOpenSubscription.unsubscribe();
    this.dashboardOpeningSubscription.unsubscribe();
  }

  onCloseClicked() {
    this.close.emit();
  }

  onPdfOpen(link) {
    this.close.emit();
    this.fileReaderService.openFile(link);
  }

  toggleDashboardMode(): void {
    this.isDarkMode = !this.isDarkMode;
    this.storage.saveData(this.isDarkMode, 'isDarkModeEnabled', true);

    this.onDashboardModeSwitched.emit(this.isDarkMode);
  }

  refreshCalendar() {
    this.onRefreshCalendarDataEmmiter.emit();
  }
}
