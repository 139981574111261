import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn: 'root'})
export class AppsService {

    public isEditMode$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isEditComplete$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public isAppsStateSaved$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    isDiscardChanges:boolean = false;
    public nextRoute$:BehaviorSubject<string> = new BehaviorSubject<string>("");;

    constructor(){
    }
}
