import { Injectable } from '@angular/core';
import { IAddApp } from 'src/app/Shared/Models/add-app-item.interface';
import { IOrder } from 'src/app/Shared/Models/user.model';
import { ApiService } from '../../Core/Services/ApiServices/api.service';


@Injectable({
  providedIn: 'root'
})
export class AppsApiFacade {

  constructor(private userApiDBSrv: ApiService) { }


  addApps(apps:IAddApp[]) {
    return this.userApiDBSrv.addApps(apps);
  }

  toggleFavoriteApps(names:string[]) {
    return this.userApiDBSrv.toggleFavoriteApps(names)
  }

  removeApps(names:string[]){
    return this.userApiDBSrv.removeApps(names)
  }

  setOrder(apps:IOrder[]){
    return this.userApiDBSrv.setOrder(apps)
  }

  updateApp(app:IAddApp){
    return this.userApiDBSrv.updateApp(app)
  }

}

