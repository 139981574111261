import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SPListGraphApiService } from 'src/app/Shared/Services/spList-graphApi.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArticlesApi extends SPListGraphApiService {
  fields =
    'Title,IsSticky,PdfURL,PlaytikaDescription,ArticleBody,Image,ArticleVideoUrl,StartDate,Editor1,PlaytikaCommunity_x003A_Title,PlaytikaDepartment_x003A_Title,PlaytikaDepartment_x003A_ImageUr,PlaytikaCategories_x003A_Title,PlaytikaCategories_x003A_TextCol,PlaytikaCategories_x003A_Backgro,PlaytikaDepartment_x003A_ID,PlaytikaCategories_x003A_ID,AssignedTo,UnAssignedTo,TaxKeyword';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getArticles(nextPageIndex: number) {
    if (nextPageIndex == undefined) {
      nextPageIndex = 1;
    }
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.Articles.GetArticles}/?localtime=${filterDate}&nextPageIndex=${nextPageIndex}`
    );
  }

  getMostViewedArticles(count: number) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.Articles.GetMostViewedArticles}?count=${count}&localtime=${filterDate}`
    );
  }

  getArticlesByCategory(categoryId) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.Articles.GetArticlesByCategory}/${categoryId}?localtime=${filterDate}`
    );
  }

  getArticlesByKeywords(keywords: string[]) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get(
      `${environment.ApiPath}api/${
        environment.API.Articles.GetArticlesByKeywords
      }?localtime=${filterDate}&keywords=${keywords.join(',')}`
    );
  }

  filterArticlesByCategory(categoryId) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.Articles.GetArticlesByCategory}/${categoryId}?localtime=${filterDate}`
    );
  }
  filterArticlesByCategories(ids) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.Articles.GetArticlesByCategories}?localtime=${filterDate}&ids=${ids}`
    );
  }

  getMoreArticles(requestString: any) {
    return this.http.get(requestString);
  }

  getArticleById(id: string) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.Articles.GetArticleById}/${id}?localtime=${filterDate}`
    );
  }

  getArticleCategories() {
    const fields =
      '$select=Title,TextColor,BackgroundColor,CountOfItems,AnnouncementsCount,VodVideosCount';
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        environment.SharePoint.SiteURL +
        ',' +
        environment.SharePoint.ContentSiteId
      }/lists/${
        environment.SharePoint.Categories
      }/items?$expand=fields(${fields})`
    );
  }
  getArticlesList(requests) {
    let body = {
      requests: requests,
    };
    return this.http.post(`${environment.GraphAPIURL}$batch`, body);
  }

  constructUrl(ids) {
    let requests: any = [];
    ids.forEach((element) => {
      let obj = {
        id: element,
        method: 'GET',
        url: `/sites/${
          environment.SharePoint.SiteURL +
          ',' +
          environment.SharePoint.ContentSiteId
        }/lists/${
          environment.SharePoint.Articles
        }/items/${element}?&$expand=fields($select=${this.fields})`,
      };

      requests.push(obj);
    });

    return requests;
  }
}
