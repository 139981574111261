import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IEvent } from 'src/app/Shared/Models/calendar.interface';
import { IMenuSidePopupBanner } from 'src/app/Shared/Models/side-menu-popup.interface';
import { ICibusData } from 'src/app/Shared/Models/sidebar-dashboard.model';
import { IBirthdayWish, IUserPermitions, IUserProfileData } from 'src/app/Shared/Models/user.model';
import { IWellbeingPopularEvent } from 'src/app/Shared/Models/wellbeing.interface';
import { MoreEventsModalService } from 'src/app/Shared/Services/more-events-modal.service';
import { FileReaderService } from 'src/app/Shared/Services/file-reader.service';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.scss']
})
export class DashboardContentComponent implements OnInit {

  @Input() popularActivities: IWellbeingPopularEvent[]
  @Input() userProfileData: IUserProfileData
  @Input() banner: IMenuSidePopupBanner
  @Input() birthdayWishes:IBirthdayWish[];
  @Input() dashboardEvents: IEvent[];
  @Input() monthlyActivitiesPDF:string;
  @Input() cibusData: ICibusData;
  @Input() userPermitions: IUserPermitions;

  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() onRefreshCalendarDataEmmiter = new EventEmitter<boolean>();
  
  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  modalRef?: BsModalRef

  constructor(
    private modalService: BsModalService,
    private moreEventsModalService: MoreEventsModalService,
    private fileReaderService: FileReaderService) { }

  ngOnInit(): void { 
    setTimeout(() => {
      this.swiper.swiperRef.on('sliderMove',  () => {
        this.onSlideChange();
      });
      this.swiper.swiperRef.on('slideChange',  () => {
        this.onSlideChange();
      });
      
    }, 100);
  }

  onSlideChange() {
    this.moreEventsModalService.updateModalPosition()
  }

  openWishesModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  onPdfOpen(link){
    this.close.emit();
    this.fileReaderService.openFile(link);
  }

  refreshCalendar() {
    this.onRefreshCalendarDataEmmiter.emit();
  }
}
