import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../../Services/DashboardService/DashboardService';
import { IUserApp } from 'src/app/Shared/Models/user.model';

@Component({
  selector: 'app-my-apps',
  templateUrl: './my-apps.component.html',
  styleUrls: ['./my-apps.component.scss']
})
export class MyAppsComponent implements OnInit {

  @Input() apps: IUserApp[];
  @Input() elementsToShow: number
  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
  }

  closeDashboardPlease(){
    this.dashboardService.close();
  }
}
