import { Component, Input, OnInit } from '@angular/core';
import { IDashboardHeader } from 'src/app/Shared/Models/sidebar-dashboard.model';
import { IUserProfileData } from 'src/app/Shared/Models/user.model';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {

  @Input() userProfileData: IUserProfileData
  @Input() headerData: IDashboardHeader
  
  constructor() { }

  ngOnInit(): void {
  }

  
}
