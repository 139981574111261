<div  class="mobile-header mobile-menu__container"
  [@header]="{ value: isHeaderExpanded ? 'expand' : 'contract'}"
  (@header.start)="onHeaderAnimation('start')"
  (@header.done)="onHeaderAnimation('end')">
    <div class="mobile-header__top" >
      <div  class="mobile-header__icons" >

          <!-- <img #menuElement (click)="openMenu()" style="width: 27px;" src="/assets/images/header-modal/burgermobile.png" > -->
      </div>
      <a class="mobile-header__mainlogo">
        <img src="/assets/images/global/playtispace-logo-new.png" alt="playtispace">
      </a>
      <div class="mobile-header__logo">
        <button type="button" class="">
            <img src="/assets/images/global/logo-icon.png" alt="logo">
        </button>
      </div>
    </div>

    <!-- <div [ngClass]="{'mobile-menu__closed': isMobileSearchOpen == false }" class="mobile-header__search">
        <app-search-header></app-search-header>
    </div> -->
    <div class="mobile-header__search-top" style="background-color: white;">
      <app-search-header></app-search-header>
    </div>
</div>

<div class="mobile-menu-content" [ngClass]="{'mobile-menu-content__closed': isMobileMenuModalOpen == false }">
  <div class="mobile-menu-content__container" [@mobileMenuModal]="{ value: isMobileMenuOpen ? 'open' : 'close'}">
    <div class="mobile-menu-content__search">
      <app-search-header></app-search-header>
    </div>
    <div #closeMenuElement  class="mobile-menu-content__header">
      <a class="profile profile-link" [routerLink]="['/personal-area/profile']" [routerLinkActive]="['menu-item--active']"
      [routerLinkActiveOptions]="{ exact: true }">
        <div *ngIf="userBasicInfo" class="profile">
            <div class="profile__photo">
              <img
              [src]="userBasicInfo.photo || '/assets/images/global/no-avatar.png'"
                alt="profile-photo"
              />
            </div>
            <div class="profile__text">
              <div class="profile__name">
                Hi, {{ userBasicInfo.givenName }}
              </div>
              <div class="profile__post">
                My profile<!-- {{ userBasicInfo.jobTitle }} -->
              </div>
            </div>
        </div>
        </a>
    </div>



    <div class="mobile-menu-content__body">
        <div class="mobile-menu-content__prifile">

        </div>

         <div class="mobile-menu-content__nav">
           <!-- <app-global-nav-menu [isMobile]="true" [mainMenu]="mainMenu"></app-global-nav-menu> -->
           <app-home-right-menu [sideItemsCategories]="sideItemsCategories"></app-home-right-menu>
         </div>
    </div>

    <app-my-apps [apps]="favoriteApps" [elementsToShow]="6"></app-my-apps>

    <!-- <button class="jump-on-load disable-click-close" (click)="dashboardOpen()"></button> -->
    <div class="mobile-menu-content__footer">
      <div class="made-withlove">
        Made with <svg viewBox="0 0 1792 1792" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
                       style="height: 0.8rem;">
        <path
          d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
          fill="#e25555"></path>
      </svg> by MIS
      </div>
      <!-- <div class="dashboard-open-button">
        <button class="jump-on-load" (click)="onDashboardOpenClicked()" [style.visibility]="isDashboardOpen ? 'hidden' : 'visible'"></button>
      </div> -->
    </div>
  </div>
  <div class="mobile-menu-content__close">
    <img src="/assets/images/global/icon-system-cross-medium.png">
  </div>
</div>
<!-- <div class="mobile-dashboard" [style.display]="isDashboardOpen ? 'block' : 'none'">
  <app-global-profile-dashboard
    [topBanner]="topBanner"
    [leftCard]="leftCard"
    [middleCard]="middleCard"
    [collaborators]="mostRecentPeople"
    [calendarEvents]="outlookCalendar"
    (close)="onDashboardCloseClicked()"
  ></app-global-profile-dashboard>
</div> -->
<ng-template #templateHeader>
  <app-header-modal
  (closeModalEmiter)="closeHeaderModal()"
  ></app-header-modal>
</ng-template>
