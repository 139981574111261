<div *ngIf="user" style="position: relative;" class="component-image-box" [ngStyle]="userPhotoSettings.userPhotoStyles" >
  <app-user-presence
  [user]="user"
  [size]="userPhotoSettings.size"
  *ngIf="showPresence">
</app-user-presence>
  <img title="{{user.displayName}}" *ngIf="userPhoto" [src]="userPhoto" class="animation">

  <div title="{{user.displayName}}" *ngIf="!userPhoto && user.displayName" class="user-initials" [ngStyle]="colors">
      <span class="user-initials__first-letter" [ngClass]="{
        'user-initials__first-letter-smaller':  userPhotoSettings.size == 'smaller',
        'user-initials__first-letter-small':  userPhotoSettings.size == 'small',
        'user-initials__first-letter-middle': userPhotoSettings.size == 'middle',
        'user-initials__first-letter-large':  userPhotoSettings.size == 'large'
      }">{{firstLetter}}</span>

          <span class="user-initials__first-letter" [ngClass]="{
            'user-initials__first-letter-smaller':  userPhotoSettings.size == 'smaller',
            'user-initials__first-letter-small':  userPhotoSettings.size == 'small',
          'user-initials__first-letter-middle': userPhotoSettings.size == 'middle',
          'user-initials__first-letter-large':  userPhotoSettings.size == 'large'
      }">{{secondLetter}}</span>
  </div>
</div>
