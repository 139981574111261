import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from './session-storage.service';

const siteUrl = environment.SharePoint.SiteURL;
export class SPListGraphApiService {
  constructor(
    protected http: HttpClient,
    private listName?: string,
    protected storage?: SessionStorageService
  ) {}

  getListByName(siteId: string, listName) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        siteUrl + ',' + siteId
      }/lists/${listName}`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }

  getListItems(
    siteId: string,
    fields: string,
    filter: string = '',
    orderBy: string = ''
  ) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${siteUrl + ',' + siteId}/lists/${
        this.listName
      }/items?${filter}&$expand=fields(${fields})&$orderby=${orderBy}`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }

  getListItemById(siteId: string, fields: string, itemId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${siteUrl + ',' + siteId}/lists/${
        this.listName
      }/items/${itemId}?$expand=fields(${fields})`
    );
  }

  createListItem(siteId: string, body: object) {
    return this.http.post(
      `${environment.GraphAPIURL}/sites/${siteUrl + ',' + siteId}/lists/${
        this.listName
      }/items`,
      body
    );
  }

  updateListItem(siteId: string, itemId: string, fieldsToUpdate: object) {
    let body = fieldsToUpdate;
    return this.http.patch(
      `${environment.GraphAPIURL}/sites/${siteUrl + ',' + siteId}/lists/${
        this.listName
      }/items/${itemId}/fields`,
      body
    );
  }

  deleteListItem(siteId: string, itemId: string) {
    return this.http.delete(
      `${environment.GraphAPIURL}/sites/${siteUrl + ',' + siteId}/lists/${
        this.listName
      }/items/${itemId}`
    );
  }

  isSiteAdmin(siteId: string, userEmail?: string) {
    let profile = this.storage?.getData('profile');
    let userPrincipalName;
    if (userEmail) {
      userPrincipalName = userEmail;
    } else {
      userPrincipalName = profile.userPrincipalName;
    }
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${siteId}/lists/User Information List/items?$filter=(fields/ContentType eq 'Person' and fields/UserName eq '${userPrincipalName}')&$expand=fields($select=IsSiteAdmin)`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }

  getSpSiteByName(siteName: string, subSiteName: string | null = null) {
    if (subSiteName) {
      siteName += `/${subSiteName}`;
    }
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${environment.SharePoint.SiteURL}:/sites/${siteName}?$select=id,displayName,description`
    );
  }

  getSpSiteById(siteId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${siteId}?$select=id,displayName,description`
    );
  }

  getSiteContentTypes(siteId: string, filter: string = '') {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        siteUrl + ',' + siteId
      }/contentTypes?${filter}`,
      { headers: { Prefer: 'allowthrottleablequeries' } }
    );
  }

  getDocumentLibery(siteId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${siteUrl + ',' + siteId}/lists/${
        this.listName
      }/drive/list/items?$expand=driveItem&$top=500`
    );
  }

  getLiberyFolders(siteId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${siteUrl + ',' + siteId}/lists/${
        this.listName
      }/drive/root/children`
    );
  }

  getFolderFiles(folderId: string, liberId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/drives/${liberId}/items/${folderId}/children`
    );
  }

  getFileById(fileId: string, liberId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/drives/${liberId}/items/${fileId}`
    );
  }

  getFileByPath(siteId: string, libraryName: string, path: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/sites/${
        siteUrl + ',' + siteId
      }/lists/${libraryName}/drive/root:${path}?$expand=thumbnails`
    );
  }

  getFileThumbnails(driveId: string, itemId: string) {
    return this.http.get(
      `${environment.GraphAPIURL}/drives/${driveId}/items/${itemId}/thumbnails`
    );
  }

  downloadFile(downloadUrl: string) {
    let headers = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(`${downloadUrl}`, headers);
  }
}
