import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, of, Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AiDataSource,
  IAiArticleComment,
  IAiLink,
  IAiLinks,
  IAiMedia,
  IAiMediaPagination,
  IAiTool,
  IAiToolComment,
  IAiTools,
  IAiToolsRequestParams,
  IAiVertical,
} from 'src/app/Shared/Models/ai-tools.interface';
import { FileService } from 'src/app/Shared/Services/file.service';
import { environment } from 'src/environments/environment';
import { ArticlesApiFacade } from './articles.facade';
import { VodsApiFacade } from './vods.facade';
import { PreloaderService } from 'src/app/Shared/Services/preloader.service';
import { AiToolsApiService } from 'src/app/Core/Services/ApiServices/api.ai-tools';
import { ApiService } from 'src/app/Core/Services/ApiServices/api.service';

@Injectable({
  providedIn: 'root',
})
export class AiToolsApiFacade {
  defaultVertical: IAiVertical = {
    id: 0,
    iconUrl:
      'https://stplaytika360.blob.core.windows.net/360images/TEST/Departments/all.svg',
    title: 'All',
  };

  PAGE_SIZE: number = 10;
  WORDS_PER_MINUTE: number = 150;

  _aiSource: AiDataSource = new AiDataSource();
  aiSource$: BehaviorSubject<AiDataSource> = new BehaviorSubject<AiDataSource>(
    this._aiSource
  );

  _currentAiToolComments: any[] = [];
  public currentAiToolCommentsSource$ = new BehaviorSubject<any[]>(this._currentAiToolComments);
  _currentAiArticleComments: any[] = [];
  public currentAiArticleCommentsSource$ = new BehaviorSubject<any[]>(this._currentAiArticleComments);

  _aiToolDetailsSource: any[] = [];
  public aiToolDetailsSource$ = new BehaviorSubject<any>(this._aiToolDetailsSource);
  _aiArticleDetailsSource: any[] = [];
  public aiArticleDetailsSource$ = new BehaviorSubject<any>(this._aiArticleDetailsSource);

  constructor(
    private aiToolsApiService: AiToolsApiService,
    private preloaderService: PreloaderService,
    private articlesApiFacade: ArticlesApiFacade,
    private vodsApiFacade: VodsApiFacade,
    private fileService: FileService,
    private apiService: ApiService
  ) {}

  getAiHomeData() {
    return this.aiToolsApiService.getAiHomeData().pipe(
      tap((data) => {
        this._aiSource.criteriaFilters = data.criteriaFilters;
        this._aiSource.verticals = [this.defaultVertical];
        if (data.verticals) {
          this._aiSource.verticals = [
            ...this._aiSource.verticals,
            ...data.verticals,
          ];
        }
        this._aiSource.indicators = data.indicators;
        this._aiSource.outputTypes = data.outputTypes;
        this._aiSource.pricing = data.pricing;

        this.aiSource$.next(this._aiSource);
      })
    );
  }

  resetSource() {
    this._aiSource = new AiDataSource();
    this.aiSource$.next(this._aiSource);
  }
  setSearchTerm(value) {
    this._aiSource.searchTerm = value;
    this.aiSource$.next(this._aiSource);
  }

  setFilterParams(filterParams: IAiToolsRequestParams) {
    this._aiSource.filterParams = filterParams;
    this.aiSource$.next(this._aiSource);
  }

  getAiTools(filterParams: IAiToolsRequestParams, loadMore: boolean = false) {
    if (!loadMore && this._aiSource.tools) {
      this._aiSource.tools = undefined;
    }
    var page = this.getNextPage(this._aiSource.tools);
    this.setFilterParams(filterParams);
    return this.aiToolsApiService
      .getAiTools(filterParams, page.index, page.size)
      .pipe(
        tap((data) => {
          if (loadMore && this._aiSource.tools) {
            this._aiSource.tools.pageIndex++;
            this._aiSource.tools.items.push(...data.tools.items);
          } else {
            this._aiSource.tools = data.tools;
          }

          this.getAiToolDetails(this._aiSource.tools.items.map(t => t.id));

          this.aiSource$.next(this._aiSource);
        })
      );
  }

  getFeaturedAiTools(filterParams: IAiToolsRequestParams, loadMore: boolean = false) {
    if (!loadMore && this._aiSource.featuredTools) {
      this._aiSource.featuredTools = undefined;
    }
    var page = this.getNextPage(this._aiSource.featuredTools);
    this.setFilterParams(filterParams);
    return this.aiToolsApiService
      .getFeaturedAiTools(filterParams, page.index, 2)
      .pipe(
        tap((data) => {
          if (loadMore && this._aiSource.featuredTools) {
            this._aiSource.featuredTools.pageIndex++;
            this._aiSource.featuredTools.items.push(...data.tools.items);
          } else {
            this._aiSource.featuredTools = data.tools;
          }

          this.getAiToolDetails(this._aiSource.featuredTools.items.map(t => t.id));

          this.aiSource$.next(this._aiSource);
        })
      );
  }

  sortByOrder(list: IAiTool[]) {
    return list
      .filter((tool) => tool.order != null)
      .sort((a, b) => {
        return (a.order ?? 0) - (b.order ?? 0);
      });
  }
  sortByABC(list: IAiTool[]) {
    return list
      .filter((tool) => tool.order == null)
      .sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
  }
  getAiBanner(toolId?, pageType: string = 'AI-Tools', isMainBanner: boolean = true) {
    return this.aiToolsApiService.getAiBanner(toolId, pageType, isMainBanner).pipe(
      tap((data) => {
        if (isMainBanner) {
          this._aiSource.banner = data.banner;
        }
        if (!isMainBanner && pageType !== 'AI-Tools') {
          this._aiSource.newsBanner = data.banner;
        }
        this.aiSource$.next(this._aiSource);
      })
    );
  }

  getAiFeaturedArticle(pageType: string) {
    return this.aiToolsApiService.getAiFeaturedArticle(pageType).pipe(
      tap((data) => {
          this._aiSource.featuredArticle = data;
        
        if (this._aiSource.featuredArticle)  {
          this.parseAiArticleVideoUrl(this._aiSource.featuredArticle);
          this.parseArticleReadDuration(this._aiSource.featuredArticle);
        }
        
        this.aiSource$.next(this._aiSource);
      })
    );
  }

  getAiArticles(filterParams: IAiToolsRequestParams, loadMore: boolean = false) {
    if (!loadMore && this._aiSource.articles) {
      this._aiSource.articles = undefined;
    }
    var page = this.getNextPage(this._aiSource.articles, 7);
    this.setFilterParams(filterParams);
    return this.aiToolsApiService
      .getAiNewsArticles(filterParams, page.index, page.size)
      .pipe(
        tap((data) => {
          if (loadMore && this._aiSource.articles) {
            this._aiSource.articles.pageIndex++;
            this._aiSource.articles.items.push(...data.media.items);
          } else {
            this._aiSource.articles = data.media;
          }

          this._aiSource.articles.items.forEach((element) => {
            this.parseAiArticleVideoUrl(element);
          });

          this.getAiArticlesDetails(this._aiSource.articles.items.map(t => t.id));
          this.parseArticlesReadDuration(this._aiSource.articles);

          console.log(data.media)
          this.aiSource$.next(this._aiSource);
        })
      );
  }
  getAiHomePageArticles() {
    return this.aiToolsApiService
      .getAiHomePageArticles()
      .pipe(
        tap((data) => {
          let sourceTools:IAiMediaPagination ={
            pageIndex:1,
            items:[],
            pageSize:1,
            totalItems:1
          };
          let sourceNews:IAiMediaPagination={
            pageIndex:1,
            items:[],
            pageSize:1,
            totalItems:1
          };
          data.media.items.forEach(element => {
            if(element.pageType=="AI-Tools"){
              sourceTools.items.push(element);
            }
            if(element.pageType=="AI-News"){
              sourceNews.items.push(element);
            }
          });
          this._aiSource.articlesTools =  sourceTools;
          this._aiSource.articlesNews =  sourceNews;
          this._aiSource.articles = data.media;
            
          this._aiSource.articles.items.forEach((element) => {
            this.parseAiArticleVideoUrl(element);
          });

          this.getAiArticlesDetails(this._aiSource.articles.items.map(t => t.id));
          this.parseArticlesReadDuration(this._aiSource.articles);

          this.aiSource$.next(this._aiSource);
        })
      );
  }
  getAiNewsArticlesCount(filterParams: IAiToolsRequestParams) {
    return this.aiToolsApiService.getAiNewsArticlesCount(filterParams).pipe(
      tap((count) => {
        this._aiSource.newsArticlesCount = count;
        this.aiSource$.next(this._aiSource);
      })
    );
  }

  resetAiArticles() {
    this._aiSource.articles = undefined;
    this.aiSource$.next(this._aiSource);
  }

  getAiSideMenu() {
    return this.aiToolsApiService.getSideMenu().pipe(
      tap((data) => {
        this._aiSource.sideMenu = data.sideMenu;
        this.aiSource$.next(this._aiSource);
      })
    );
  }

  resetAiTools() {
    this._aiSource.tools = undefined;
    this.aiSource$.next(this._aiSource);
  }

  getAiTool(toolId) {
    return this.aiToolsApiService.getAiTool(toolId).pipe(
      tap((data) => {
        this._aiSource.currentTool = data.tool;

        this.getAiToolDetails([data.tool.id]);
        this.aiSource$.next(this._aiSource);
      })
    );
  }

  getSimilarTools(toolId: any) {
    return this.aiToolsApiService.getSimilarTools(toolId).pipe(
      tap((data) => {
        this._aiSource.currentTool!.similarTools = data.tools;

        this.aiSource$.next(this._aiSource);
      })
    );
  }

  getSearchAnnouncement() {
    return this.aiToolsApiService.getSearchAnnouncement().pipe(
      tap((data) => {
        this._aiSource.searchAnnouncement = data.searchAnnouncement;

        this.aiSource$.next(this._aiSource);
      })
    );
  }

  getAiLinks(toolId) {
    var page = this.getNextPage(this._aiSource.links);
    return this.aiToolsApiService
      .getAiLinksData(toolId, page.size, page.index)
      .pipe(
        tap((data) => {
          this.parseAiFileLinks(data.links);
          if (this._aiSource.links) {
            this._aiSource.links.pageIndex++;
            this._aiSource.links.items.push(...data.links.items);
          } else {
            this._aiSource.links = data.links;
          }
          this.aiSource$.next(this._aiSource);
        })
      );
  }

  getAiMedia(toolId: number, pageSize?: number) {
    var page = this.getNextPage(this._aiSource.media, pageSize);

    return this.aiToolsApiService
      .getAiMediaData(toolId, page.size, page.index)
      .pipe(
        tap((data) => {
          if (this._aiSource.media) {
            this._aiSource.media.pageIndex++;
            this._aiSource.media.items.push(...data.media.items);
          } else {
            this._aiSource.media = data.media;
          }

          var articleIds = this.getMediaIdsByType(data.media.items, 'article');
          var vodIds = this.getMediaIdsByType(data.media.items, 'vod');

          this.articlesApiFacade.getArticleDetails(articleIds);
          this.vodsApiFacade.getVodDetails(vodIds);

          this._aiSource.media.items.forEach((element) => {
            this.parseAiArticleVideoUrl(element);
          });

          console.log(data.media)
          this.aiSource$.next(this._aiSource);
        })
      );
  }

  getMediaById(mediaId: number) {
    return this.aiToolsApiService.getAiMediaDataById(mediaId).pipe(
      tap((data) => {
        this.parseAiArticleVideoUrl(data);
        this._aiSource.currentMedia = data;

        this.aiSource$.next(this._aiSource);
      })
    );
  }

  saveUserRating(rating: number, toolId: number) {
    return this.aiToolsApiService.saveUserRating(rating, toolId).pipe(
      tap((data) => {
        const myPreviousRating: number | null =
          this._aiSource.currentTool!.myRating;
        this._aiSource.currentTool!.myRating = data.tool.myRating;
        this._aiSource.currentTool!.myPreviousRating = myPreviousRating;
        this._aiSource.currentTool!.rating = data.tool.rating;
        this._aiSource.currentTool!.ratingVotes = data.tool.ratingVotes;
        this.aiSource$.next(this._aiSource);
      })
    );
  }

  getNextPage(paginatable: any | undefined, defaultPageSize?: number) {
    var isLoaded = paginatable && paginatable.items.length > 0;
    var pageIndex = isLoaded ? paginatable.pageIndex! + 1 : 1;
    var pageSize = isLoaded
      ? paginatable.pageSize
      : defaultPageSize
      ? defaultPageSize
      : this.PAGE_SIZE;

    return {
      index: pageIndex,
      size: pageSize,
    };
  }

  private parseAiFileLinks(response: IAiLinks) {
    response.items.forEach((element) => {
      if (element.url.includes('.mp4')) {
        this.setVideoUrl(element);
      }
    });
  }

  private parseAiArticleVideoUrl(article: IAiMedia | undefined) {
    if (
      article &&
      article.video &&
      (article.video.includes('youtube') || article.video.includes('youtu.be'))
    ) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      var match = article.video.match(regExp);

      var videoId = match && match[2].length === 11 ? match[2] : null;
      article.video = `https://www.youtube.com/embed/${videoId}`; // any youtube link with videoID -> https://www.youtube.com/embed/{videoID}
    }
  }

  private setVideoUrl(file: IAiLink) {
    let libraryName = file.url.split('sites/')[1].split('/')[1];
    let pathArray = file.url.split('sites/')[1].split('/');
    let filePath = '';

    pathArray.forEach((element, index) => {
      if (index > 1) {
        filePath += '/' + element;

        if (index == pathArray.length - 1) {
          filePath = filePath.split('?')[0];
          this.fileService
            .getByPathFile(
              environment.SharePoint.ContentSiteId,
              libraryName,
              filePath
            )
            .subscribe((result) => {
              if (result != '') {
                file.url = result;
              }
            });
        }
      }
    });
  }

  private getMediaIdsByType(media: any[], mediaType: string): any[] {
    var mediaByType = media.filter((item) => item.id && item.type == mediaType);
    var ids = [
      ...new Map(mediaByType.map((item) => [item['id'], +item.id])).values(),
    ];
    return ids;
  }

  private parseArticlesReadDuration(articles: IAiMediaPagination) {
    var tempElement = document.createElement('span');

    for (var article of articles.items) {
      this.parseArticleReadDuration(article, tempElement);
    }
  }

  private parseArticleReadDuration(article: IAiMedia, auxillaryElement?: HTMLElement) {
    auxillaryElement = auxillaryElement ?? document.createElement('span');
    auxillaryElement.innerHTML = article.body ?? "";

    var durationInMinutes = Math.ceil(((auxillaryElement.textContent || auxillaryElement.innerText) ?? "").split(' ').length / this.WORDS_PER_MINUTE);
    var duration = { hours: Math.floor(durationInMinutes / 60), minutes: durationInMinutes % 60 }
    article.duration = `${ duration.hours > 0 ? `${duration.hours} H` : '' } ${duration.minutes} Min`;
  }

  getAiToolDetails(toolIds:number[]){
    let new_array_toolIds = this.splitArray(toolIds, (toolIds.length/10).toFixed(), true)
    let source:Array<Observable<any>> = [];

    new_array_toolIds.forEach(element => {
      source.push(this.apiService.getAiToolDetails(element));
    });

    forkJoin(source).pipe(
      tap(result => {
        var mergedResult = [].concat.apply([], result);

        if(result != null){
          this._aiToolDetailsSource.push(...mergedResult);
          this.aiToolDetailsSource$.next(this._aiToolDetailsSource);

          let filterResult = this._aiToolDetailsSource.map(a => {
              let obj = {
                  toolId:a.toolId,
                  comments:a.topComments
              }

              return obj
           });
           this._currentAiToolComments.push(...filterResult)
           this.currentAiToolCommentsSource$.next(this._currentAiToolComments);
        }
      })
    ).subscribe();
  }

  addAiToolLike(toolId:number, toolEditor:any){
      return this.apiService.addAiToolLike(toolId, toolEditor);
  }

  removeAiToolLike(toolId:number){
      return this.apiService.removeAiToolLike(toolId);
  }

  addAiToolCommentLike(commentId:number, toolId:number, commentEditor:any){
    return this.apiService.addAiToolComentLike(commentId, toolId, commentEditor);
  }

  removeAiToolCommentLike(commentId:number){
    return this.apiService.removeAiToolCommentLike(commentId);
  }

  getAiToolComments(toolId:number){
      this.preloaderService.showPreloader()
      let comments = this._currentAiToolComments.find(c => c.toolId == toolId)

      this.apiService.getAiToolComments(toolId).subscribe(result => {
          result.forEach(element => {
              let double = comments.comments.find(c => c.id == element.id)

              if(double == undefined){
                  comments.comments.push(element)
              }
          });

          comments.loadedAll = true;
          comments.limitSlice = comments.comments.length;
          this.preloaderService.hidePreloader()
          this.currentAiToolCommentsSource$.next(this._currentAiToolComments);
      })


  }

  getAiToolChildComments(commentId:number, toolId){

      let comments = this._currentAiToolComments.find(c => c.toolId == toolId)

      if(comments.comments.find(c => c.topParentId == commentId) == undefined){
          this.preloaderService.showPreloader()
          this.apiService.getAiToolChildComments(commentId).subscribe(result => {
              if(comments){
                  result.forEach(element => {
                      let double = comments.comments.find(c => c.id == element.id)

                      if(double == undefined){
                          comments.comments.push(element)
                      }
                  });
                  this.preloaderService.hidePreloader()
                  this.currentAiToolCommentsSource$.next(this._currentAiToolComments);
              }
          })
      }

  }

  addAiToolComment(commentData){
      this.apiService.addAiToolComment(commentData).subscribe(result => {

          let comments = this._currentAiToolComments.find(c => c.toolId == commentData.toolId)
          result.createdDate = result.createdDate.split('+')[0]
          result.likesCount = 0
          if(comments){
              comments.comments.unshift(result);
              let comme = comments.comments.find(c => c.id == commentData.topParentId)
              if(comme){
                  comme.childCommetsCount++
              }
          }else{
              let obj = {
                  toolId:commentData.toolId,
                  comments:[result]
              }
              this._currentAiToolComments.push(obj)
          }

          let details = this._aiToolDetailsSource.find(s => s.toolId == commentData.toolId)
          details.toolCommentsCount++
          this.currentAiToolCommentsSource$.next(this._currentAiToolComments);
          this.aiToolDetailsSource$.next(this._aiToolDetailsSource)
      })
  }

  editAiToolComment(commentData){
      this.apiService.editAiToolComment(commentData).subscribe(result => {
          if(result && result.id != 0){
              let comments = this._currentAiToolComments.find(c => c.toolId == commentData.toolId)
              var editedComment = comments.comments.find(c => c.id == commentData.commentId);

              if(editedComment != undefined){
                  editedComment.comment = result.comment;
                  this.currentAiToolCommentsSource$.next(this._currentAiToolComments);
              }
          }
      })
  }

  deleteComment(comment:IAiToolComment){
    let comments = this._currentAiToolComments.find(c => c.toolId == comment.toolId);
    let details = this._aiToolDetailsSource.find(d => d.toolId == comment.toolId);

    this.apiService.deleteAiToolComment(comment.id)
      .subscribe(result => {
        if (result) {
          console.log('removed')
          var deletedCommentsCount = this.removeChildComments(comment, comments.comments);
          details.toolCommentsCount -= deletedCommentsCount;

          this.preloaderService.hidePreloader()

          this.aiToolDetailsSource$.next(this._aiToolDetailsSource);
          this.currentAiToolCommentsSource$.next(this._currentAiToolComments);
        }
      });
  }

  private removeChildComments(comment: IAiToolComment, toolComments: IAiToolComment[]): number {
    var childComments = toolComments.filter(c => c.parentId == comment.id);
    var deletedCommentsCount = 1;

    if (childComments && childComments.length > 0) {
      for (var childComment of childComments) {
        deletedCommentsCount += this.removeChildComments(childComment, toolComments);
      }
    }

    toolComments.splice(toolComments.findIndex(c => c.id == comment.id), 1);
    return deletedCommentsCount;
  }



    splitArray(a, n, balanced) {

      if (n < 2)
          return [a];

      var len = a.length,
              out:any[] = [],
              i = 0,
              size;

      if (len % n === 0) {
          size = Math.floor(len / n);
          while (i < len) {
              out.push(a.slice(i, i += size));
          }
      }

      else if (balanced) {
          while (i < len) {
              size = Math.ceil((len - i) / n--);
              out.push(a.slice(i, i += size));
          }
      }

      else {

          n--;
          size = Math.floor(len / n);
          if (len % size === 0)
              size--;
          while (i < size * n) {
              out.push(a.slice(i, i += size));
          }
          out.push(a.slice(size * n));

      }

      return out;
    }
    getAiToolLikedUserList(eventId: number) {
      return this.apiService.getAiToolLikedUserList(eventId, null)
    }

    getAiArticlesDetails(articleIds:number[]){
      let new_array_articleIds = this.splitArray(articleIds, (articleIds.length/10).toFixed(), true)
      let source:Array<Observable<any>> = [];

      new_array_articleIds.forEach(element => {
        source.push(this.apiService.getAiArticlesDetails(element));
      });

      forkJoin(source).pipe(
        tap(result => {
          var mergedResult = [].concat.apply([], result);

          if(result != null){
            this._aiArticleDetailsSource.push(...mergedResult);
            this.aiArticleDetailsSource$.next(this._aiArticleDetailsSource);

            let filterResult = this._aiArticleDetailsSource.map(a => {
                let obj = {
                    articleId:a.articleId,
                    comments:a.topComments
                }

                return obj
             });
             this._currentAiArticleComments.push(...filterResult)
             this.currentAiArticleCommentsSource$.next(this._currentAiArticleComments);
          }
        })
      ).subscribe();
    }

    addAiArticleLike(articleId:number, articleEditor:any){
        return this.apiService.addAiArticleLike(articleId, articleEditor);
    }

    removeAiArticleLike(articleId:number){
        return this.apiService.removeAiArticleLike(articleId);
    }

    addAiArticleCommentLike(commentId:number, articleId:number, commentEditor:any){
      return this.apiService.addAiArticleComentLike(commentId, articleId, commentEditor);
    }

    removeAiArticleCommentLike(commentId:number){
      return this.apiService.removeAiArticleCommentLike(commentId);
    }

    getAiArticleComments(articleId:number){
        this.preloaderService.showPreloader()
        let comments = this._currentAiArticleComments.find(c => c.articleId == articleId)

        this.apiService.getAiArticleComments(articleId).subscribe(result => {
            result.forEach(element => {
                let double = comments.comments.find(c => c.id == element.id)

                if(double == undefined){
                    comments.comments.push(element)
                }
            });

            comments.loadedAll = true;
            comments.limitSlice = comments.comments.length;
            this.preloaderService.hidePreloader()
            this.currentAiArticleCommentsSource$.next(this._currentAiArticleComments);
        })


    }

    getAiArticleChildComments(commentId:number, articleId){

        let comments = this._currentAiArticleComments.find(c => c.articleId == articleId)

        if(comments.comments.find(c => c.topParentId == commentId) == undefined){
            this.preloaderService.showPreloader()
            this.apiService.getAiArticleChildComments(commentId).subscribe(result => {
                if(comments){
                    result.forEach(element => {
                        let double = comments.comments.find(c => c.id == element.id)

                        if(double == undefined){
                            comments.comments.push(element)
                        }
                    });
                    this.preloaderService.hidePreloader()
                    this.currentAiArticleCommentsSource$.next(this._currentAiArticleComments);
                }
            })
        }

    }

    addAiArticleComment(commentData){
        this.apiService.addAiArticleComment(commentData).subscribe(result => {
            let comments = this._currentAiArticleComments.find(c => c.articleId == commentData.articleId)
            result.createdDate = result.createdDate.split('+')[0]
            result.likesCount = 0
            if(comments){
                comments.comments.unshift(result);
                let comme = comments.comments.find(c => c.id == commentData.topParentId)
                if(comme){
                    comme.childCommetsCount++
                }
            }else{
                let obj = {
                    articleId:commentData.articleId,
                    comments:[result]
                }
                this._currentAiArticleComments.push(obj)
            }

            let details = this._aiArticleDetailsSource.find(s => s.articleId == commentData.articleId)
            details.articleCommentsCount++
            this.currentAiArticleCommentsSource$.next(this._currentAiArticleComments);
            this.aiArticleDetailsSource$.next(this._aiArticleDetailsSource)
        })
    }

    editAiArticleComment(commentData){
        this.apiService.editAiArticleComment(commentData).subscribe(result => {
            if(result && result.id != 0){
                let comments = this._currentAiArticleComments.find(c => c.articleId == commentData.articleId)
                var editedComment = comments.comments.find(c => c.id == commentData.commentId);

                if(editedComment != undefined){
                    editedComment.comment = result.comment;
                    this.currentAiArticleCommentsSource$.next(this._currentAiArticleComments);
                }
            }
        })
    }

    deleteArticleComment(comment:IAiArticleComment){
      let comments = this._currentAiArticleComments.find(c => c.articleId == comment.articleId);
      let details = this._aiArticleDetailsSource.find(d => d.articleId == comment.articleId);

      this.apiService.deleteAiArticleComment(comment.id)
        .subscribe(result => {
          if (result) {
            var deletedCommentsCount = this.removeArticleChildComments(comment, comments.comments);
            details.articleCommentsCount -= deletedCommentsCount;

            this.preloaderService.hidePreloader()

            this.aiArticleDetailsSource$.next(this._aiArticleDetailsSource);
            this.currentAiArticleCommentsSource$.next(this._currentAiArticleComments);
          }
        });
    }

    private removeArticleChildComments(comment: IAiArticleComment, articleComments: IAiArticleComment[]): number {
      var childComments = articleComments.filter(c => c.parentId == comment.id);
      var deletedCommentsCount = 1;

      if (childComments && childComments.length > 0) {
        for (var childComment of childComments) {
          deletedCommentsCount += this.removeArticleChildComments(childComment, articleComments);
        }
      }

      articleComments.splice(articleComments.findIndex(c => c.id == comment.id), 1);
      return deletedCommentsCount;
    }

    getAiArticleLikedUserList(eventId: number) {
      return this.apiService.getAiArticleLikedUserList(eventId, null)
    }

    addArticleFavorite(articleId:number){
      return this.apiService.addAiArticleFavorite(articleId);
    }

    removeArticleFavorite(articleId:number){
        return this.apiService.removeAiArticleFavorite(articleId);
    }
}
