import { Component, Input, OnInit } from '@angular/core';
import { BannerFacade } from 'src/app/Facade/SpFacade/banner.facade';
import { IBanner } from 'src/app/Shared/Models/banner.interface';


@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
  providers: [BannerFacade]
})
export class BannersComponent implements OnInit {

  @Input() banners:IBanner[];
  windowHref:string;
  constructor() { }

  ngOnInit(): void {
    this.windowHref = window.location.href;
  }
  
  ngOnDestroy(){
  }

}
