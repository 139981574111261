import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  IWellbeingCategory,
  IWellbeingEventDetails,
  IWellbeingMainBanner,
  IWellbeingEventItem,
  IWellbeingPopularItem,
  IWellbeingCategoryItem,
} from 'src/app/Shared/Models/wellbeing.interface';
import { FileService } from 'src/app/Shared/Services/file.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WellbeingApiService {
  constructor(private http: HttpClient, private fileService:FileService) {}
  getWellbeingSiteLocations() {
    return this.http.get<string[]>(
      `${environment.ApiPath}api/wellBeing/locations`
    );
  }
  getWellbeingMainBanner(location?: string) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get<IWellbeingMainBanner[]>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.GetWellbeingBanners}/${location}/${filterDate}`
    ).pipe(tap(resul => {
      if(resul != null){
        resul.forEach(element => {
          this.setImageUrl(element);
        });

      }
    }));;
  }
  
  getWellbeingPopularActivities(
    PageIndex: number,
    PageSize: number,
    location?: string
  ) {
    if (PageIndex == undefined) {
      PageIndex = 1;
    }
    if (PageSize == undefined) {
      PageSize = 3;
    }
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';

    return this.http.get<IWellbeingPopularItem>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.GetPopularActivities}/?Localtime=${filterDate}&PageIndex=${PageIndex}&PageSize=${PageSize}&SiteLocation=${location}`
    ).pipe(tap(resul => {
      if(resul.items != null){
        resul.items.forEach(element => {
          this.setImageUrl(element);
        });

      }
    }));
  }

  getWellbeingCategories(location) {
    return this.http.get<IWellbeingCategory[]>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.GetWellbeingCategories}/${location}/categories`
    );
  }

  getWellbeingEventsByCategory(
    PageIndex: number,
    PageSize: number,
    categoryId: number,
    location?: string
  ) {
    if (PageIndex == undefined) {
      PageIndex = 1;
    }
    if (PageSize == undefined) {
      PageSize = 3;
    }

    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get<IWellbeingCategoryItem>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.GetWellbeingEventsByCategory}/?CategoryId=${categoryId}&Localtime=${filterDate}&PageIndex=${PageIndex}&PageSize=${PageSize}&SiteLocation=${location}`
    ).pipe(tap(resul => {
      if(resul.items != null){
        resul.items.forEach(element => {
          this.setImageUrl(element);
        });

      }
    }));
  }

  getWellbeingEventsByKeywords(keywords: string[], location?: string) {
    console.log(location)
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get<IWellbeingEventItem[]>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.GetWellbeingEventsByKeywords}?Localtime=${filterDate}&Keywords=${keywords.join(",")}&SiteLocation=${location}`
    ).pipe(tap(results => {
      if(results != null){
        results.forEach(element => {
          this.setImageUrl(element);
        });
      }
    }));
  }

  getWellbeingEvent(eventId: number, location) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get<IWellbeingEventItem>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.GetWellbeingEvent}/?EventId=${eventId}&Localtime=${filterDate}&SiteLocation=${location}&Localtime=${filterDate}`
    ).pipe(tap(resul => {
      if(resul != null){
        this.setImageUrl(resul);
      }
    }));
  }
  getwellbeingEventDetails(
    eventIds: number[], siteLication
  ): Observable<IWellbeingEventDetails[]> {
    let body = {
      eventIds: eventIds,
      siteLication:siteLication
    };
    return this.http.post<IWellbeingEventDetails[]>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.GetWellbeingEventDetails}`,
      body
    );
  }

  addWellbeingEventLike(eventId: number, siteLocation:string): Observable<boolean> {
    let body = {
      itemId: eventId,
      discriminator:siteLocation
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.AddWellbeingEventLike}`,
      body
    );
  }

  removeWellbeingEventLike(eventId: number, siteLocation:string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.RemoveWellbeingEventLike}/${siteLocation}/${eventId}`,
      {}
    );
  }

  private setImageUrl(tempItem: any) {

    if (tempItem.image && tempItem.image.includes(environment.SharePoint.SiteURL)) {
      if(tempItem.image.includes("sites")){
        let libraryName = tempItem.image.split('sites/')[1].split('/')[1];
        let pathArray = tempItem.image.split('sites/')[1].split('/');
        let filePath = "";

        pathArray.forEach((element, index) => {
          if (index > 1) {
            filePath += '/' + element;

            if (index == pathArray.length - 1) {
              filePath = filePath.split('?')[0];
              this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName, filePath).subscribe(result => {
                if (result != "") {
                  tempItem.image = result;
                }else{
                  tempItem.image = "https://stplaytika360.blob.core.windows.net/360images/TEST/Well-BeingEventType/WellbeingEventTypeDefaultImage.jpg"
                }
              })
            }
          }
        });
      }else{
        tempItem.image = 'https://stplaytika360.blob.core.windows.net/360images/TEST/Well-BeingEventType/WellbeingEventTypeDefaultImage.jpg'
      }
    }
  }

  getWellbeingLikedUserList(eventId:number,location): Observable<string>{
    return this.http.get<string>(
      `${environment.ApiPath}api/${environment.API.Wellbeing.GetWellbeingLikedUserList}/?EventId=${eventId}&SiteLocation=${location}`
    );
  }
}
