import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AiToolsApiFacade } from 'src/app/Facade/ApiFacade/ai-tools.facade';
import { IAiArticleComment } from 'src/app/Shared/Models/ai-tools.interface';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { AiArticlesCommentaryService } from '../ai-articles-commentary.service';

@Component({
  selector: 'app-ai-articles-comment-item',
  templateUrl: './ai-articles-comment-item.component.html',
  styleUrls: ['./ai-articles-comment-item.component.scss']
})
export class AiArticlesCommentItemComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    if(event.key == "Escape"){
      this.onEditCancel()
    }
  }

  @Input() parentId;
  @Input() articleId;
  currentCommentIdReply;
  currentCommentIdEdit;

  commentAnswerControl = new UntypedFormControl("");
  commentEditControl = new UntypedFormControl("");
  commentToDelete:IAiArticleComment;
  commentToEdit:IAiArticleComment;
  aiArticleComments:IAiArticleComment[];
  allComments:IAiArticleComment[];
  userEmail:string;
  isParentDeleted:boolean = false;
  isMobile: boolean = false;

  modalRef?: BsModalRef;

  datepipe: DatePipe = new DatePipe('en-US')
  today = this.datepipe.transform(Date.now(),'yyyy-MM-dd')?.split('T')[0]


  userPhotoSettings = {
    userPhotoStyles: {
      'width':'38px',
      'height':'38px'
    },
    size: 'smaller'
  }

  constructor(private aiToolsApiFacade:AiToolsApiFacade,
    private aiArticleCommentaryService:AiArticlesCommentaryService,
    private modalService: BsModalService,
    private userProfileService:UserProfileService) { }

  ngOnInit(): void {
    this.aiToolsApiFacade.currentAiArticleCommentsSource$.subscribe(source => {
      var coment =  source.find(c => c.articleId == this.articleId)
      if(coment){
        this.aiArticleComments = coment.comments.filter(comment=> comment.parentId == this.parentId);
        this.allComments =  coment.comments;
        this.isParentDeleted = coment.comments.find(c => c.id == this.parentId && c.deleted == true) != undefined;
      }
    })

    this.userProfileService.userProfileSource$.subscribe(user => {
      this.userEmail = user.userPrincipalName;
    })

    this.aiArticleCommentaryService.commentarySource$.subscribe(state => {
      this.currentCommentIdReply = state.currentCommentIdReply;
      this.currentCommentIdEdit = state.currentCommentIdEdit;
    })

    this.onResize()
  }

  onDeleteComment(){
    this.aiToolsApiFacade.deleteArticleComment(this.commentToDelete);
    this.modalRef?.hide();
  }

  onReplyStart(comment){
    this.commentAnswerControl = new UntypedFormControl("")
    this.aiArticleCommentaryService.setCurrentCommentaryReplyId(comment.id);
  }

  onConfirmReply(comme:IAiArticleComment){
    let comment ={
      "articleId": this.articleId,
      "parentId": comme.id,
      "topParentId": comme.topParentId,
      "comment": this.commentAnswerControl.value,
      "relatedUserEmail": comme.userEmail
    }

    if(this.commentAnswerControl.value.trim() != '' && this.commentAnswerControl.value != ''){
      this.aiToolsApiFacade.addAiArticleComment(comment);
      this.aiArticleCommentaryService.setCurrentCommentaryReplyId(-0);
    }
  }

  onEditConfirm(){
    let comme ={
      "commentId": this.commentToEdit.id,
      "comment": this.commentEditControl.value,
      "articleId":this.articleId
    }

    if(this.commentEditControl.value.trim() != '' && this.commentEditControl.value != ''){
      this.aiToolsApiFacade.editAiArticleComment(comme);
      this.aiArticleCommentaryService.setCurrentCommentaryEditId(-1);
    }

  }

  onEditStart(comment){
    this.commentToEdit = comment;
      this.commentEditControl = new UntypedFormControl(comment.comment);
      this.aiArticleCommentaryService.setCurrentCommentaryEditId(comment.id);
  }

  onEditCancel(){
    this.commentEditControl = new UntypedFormControl("");
    this.aiArticleCommentaryService.setCurrentCommentaryEditId(-1);
  }

  AddCommentLike(comment: IAiArticleComment){
    this.aiToolsApiFacade.addAiArticleCommentLike(comment.id, comment.articleId, comment.userEmail).subscribe( result => {
      if(result == true){
        comment.isUserLike = true;
        comment.likesCount +=1;
      }
    })
  }

  RemoveCommentLike(comment: IAiArticleComment){
    this.aiToolsApiFacade.removeAiArticleCommentLike(comment.id).subscribe( result => {
      if(result == true){
        comment.isUserLike = false;
        comment.likesCount -=1;
      }
    })
  }

  hasChildrem(commentId){
    return this.allComments.find(c => c.parentId == commentId) != undefined;
  }


  openAlertModal(template: TemplateRef<any>, comment) {
    this.commentToDelete = comment;
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }
}
