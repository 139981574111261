import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isBs3 } from 'ngx-bootstrap/utils';
import { combineLatest, forkJoin, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SearchApiFacade } from 'src/app/Facade/ApiFacade/search.facade';
import { SearchGraphApiFacade } from 'src/app/Facade/GraphApiFacade/search-graph.facade';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';
import { imageFormats } from 'src/app/Pages/DocumentLibery/utilities/image-formats';
import { ISearchResultsType } from 'src/app/Shared/Models/search.interface';
import { FileReaderService } from 'src/app/Shared/Services/file-reader.service';
import { PreloaderService } from 'src/app/Shared/Services/preloader.service';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: [
    './styles/search-header-more.scss',
    './search-header.component.scss',
  ],
})
export class SearchHeaderComponent implements OnInit, OnDestroy {
  userPhotoSettings = {
    userPhotoStyles: {
      width: '35px',
      height: '35px',
    },
    size: 'small',
  };

  typeaheadHideResultsOnBlur = false;
  searchText = '';
  shortSearchResults: ISearchResultsType[] = [];
  allSearchResults: ISearchResultsType[] = [];
  allResultsCount: number;
  private readonly onDestroy$ = new Subject<void>();
  searchTimeout: any;
  searchDelay: number = 1000;
  areResultsLoading: boolean = false;

  windowHref: string;

  isBs3 = isBs3();

  constructor(
    private searchFacade: SearchApiFacade,
    private preloaderService: PreloaderService,
    private fileReaderService: FileReaderService,
    private graphUserFacade: UserGraphApiFacade,
    private searchGraphFacade: SearchGraphApiFacade,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.windowHref = window.location.href;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  enterAction() {
    this.router.navigateByUrl('/search?query=' + this.searchText);
  }
  openFile(link) {
    this.fileReaderService.openFile(link);
  }

  search() {
    this.areResultsLoading = true;
    combineLatest([
      this.searchFacade.searchAll(this.searchText).pipe(startWith([])),
      this.searchGraphFacade
        .searchInSharepoint(this.searchText + '*')
        .pipe(startWith(null)),
      this.searchGraphFacade
        .searchFileInSharepointDocumentLibrary(this.searchText + '*')
        .pipe(startWith(null)),
      this.searchGraphFacade
        .searchFolderInSharepointDocumentLibrary(this.searchText + '*')
        .pipe(startWith(null)),
      this.searchGraphFacade
        .searchInSharepointSideItems(this.searchText)
        .pipe(startWith([])),
      this.searchGraphFacade
        .searchVodVideosInSharepointDocumentLibrary(this.searchText + '*')
        .pipe(startWith(null)),
      this.searchGraphFacade
        .searchGalleryInSharepointDocumentLibrary(this.searchText + '*')
        .pipe(startWith(null)),
      this.graphUserFacade.searchPeople(this.searchText).pipe(startWith([])),
    ])
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        finalize(() => {
          this.areResultsLoading = false;
          if ((this.allSearchResults?.length ?? 0) == 0) {
            this.shortSearchResults = [
              { label: 'No records found', items: [] },
            ];
            this.allResultsCount = -1;
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        ([
          apiSearch,
          sharepointSearch,
          sharepointFileSearchDocLibrary,
          sharepointFolderSearchDocLibrary,
          sharepointSearchSideItems,
          sharepointVodVideosSearchDocLibrary,
          sharepointGallerySearchDocLibrary,
          searchPeopleResult,
        ]) => {
          var results = [
            ...apiSearch,
            sharepointSearch,
            ...sharepointSearchSideItems,
            sharepointFileSearchDocLibrary,
            sharepointFolderSearchDocLibrary,
            sharepointVodVideosSearchDocLibrary,
            sharepointGallerySearchDocLibrary,
            ...searchPeopleResult,
          ];

          this.filterData(this.searchText, results);
        }
      );
  }

  filterData(query: string, data: any[]): void {
    this.allSearchResults = this.searchFacade.searchFilter(query, data);

    if (
      this.allSearchResults.length > 0 &&
      JSON.stringify(this.allSearchResults) !==
        JSON.stringify(this.shortSearchResults)
    ) {
      let filtr = JSON.parse(JSON.stringify(this.allSearchResults));
      this.allResultsCount = this.searchFacade.allResultsCount;
      for (let option of filtr) {
        option.items = option.items.slice(0, 3);
      }
      this.shortSearchResults = filtr;
      this.mapMediaResults();
    }
  }

  isImage(item) {
    let format = imageFormats.find(
      (f) => f == item.label.split('.')[item.label.split('.').length - 1]
    );
    if (format != undefined) {
      this.setImage(item);
    }
  }

  checkImage(item) {
    let format = imageFormats.find(
      (f) => f == item.label.split('.')[item.label.split('.').length - 1]
    );
    return format != undefined ? true : false;
  }

  mapMediaResults() {
    for (let index = 0; index < this.shortSearchResults.length; index++) {
      const element = this.shortSearchResults[index];

      if (element.label == 'Files') {
        element.items.forEach((element) => {
          this.isImage(element);
        });
      }

      if (element.label == 'VOD') {
        element.total = 0;
        element.label = '';

        element.items.forEach((element) => {
          this.setVideoThumbnails(element);
        });

        setTimeout(() => {
          element.items = element.items.filter(
            (item) => item.canDisplay === true
          );
          element.total = element.items.filter(
            (x) => x.canDisplay == true
          ).length;
          if (element.total > 0) {
            element.label = 'VOD';
          }
        }, 1500);
      }

      if (element.label == 'Articles') {
        element.items.forEach((element) => {
          this.setArticleImage(element);
        });
      }
      if (element.label == 'Gallery') {
        element.total = 0;
        element.label = '';

        element.items.forEach((element) => {
          this.setGalleryImage(element);
        });
        setTimeout(() => {
          element.items = element.items.filter(
            (item) => item.canDisplay === true
          );
          element.total = element.items.filter(
            (x) => x.canDisplay == true
          ).length;
          if (element.total > 0) {
            element.label = 'Gallery';
          }
        }, 1500);
      }
      setTimeout(() => {
        this.shortSearchResults = this.shortSearchResults.filter(
          (item) => item.total && item.total > 0
        );
      }, 3100);
    }
  }

  setImage(item) {
    this.searchGraphFacade
      .getFileInB64(item.id, item.parentReference.driveId)
      .subscribe((result) => {
        if (result != '') {
          item.icon = result;
        }
      });
  }

  setArticleImage(item) {
    this.searchGraphFacade.setImageInB64(item);
  }

  setGalleryImage(item) {
    this.searchGraphFacade
      .getGalleryImage(item.listItemId)
      .subscribe((result) => {
        if (result != '') {
          item.icon = result.image;
          item.canDisplay = result.assignedTo;
        }
      });
  }

  setVideoThumbnails(item) {
    this.searchGraphFacade
      .getVodVodeoThumbnailsById(item.id)
      .subscribe((result) => {
        item.icon = result.image;
        item.canDisplay = result.assignedTo;
      });
  }

  downloadFile(file) {
    this.preloaderService.showPreloader();
    this.searchGraphFacade
      .getFileById(file.id, file.parentReference.driveId)
      .subscribe((result) => {
        this.preloaderService.hidePreloader();
        const link: any = document.createElement('a');
        link.href = result;
        link.click();
      });
  }

  seeAllResults() {
    this.searchFacade.topResultsFilter = '';
    this.searchFacade.setAllResults(this.searchText, this.allSearchResults);
  }

  displayFilteredResults(groupLabel) {
    this.searchFacade.topResultsFilter = groupLabel;
    this.searchFacade.setAllResults(this.searchText, this.allSearchResults);
  }

  clearSearch() {
    setTimeout(() => {
      this.searchText = '';
    }, 500);
  }
}
