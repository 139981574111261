import { Component, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';
import { AppsService } from 'src/app/Pages/Apps/apps.service';
import { IPerson, IUserApp, IUserInfo } from 'src/app/Shared/Models/user.model';
import { SessionStorageService } from 'src/app/Shared/Services/session-storage.service';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { MainMenu } from '../../../Shared/Models/mainMenu.model';
import { IMenuSidePopupBanner } from '../../../Shared/Models/side-menu-popup.interface';
import { IOutlookCalendar } from '../../../Shared/Models/outlookCalendar.inteface';
import { MenuSidePopupGraphFacade } from '../../../Facade/GraphApiFacade/menu-side-popup-graph.facade';
import { OutlookCalendarGraphFacade } from '../../../Facade/GraphApiFacade/outlookCalendar-graph.facade';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HeaderSearchScrollService } from 'src/app/Shared/Services/header-search-scroll.service';
import { ISideItemCategory } from 'src/app/Shared/Models/sideItem.interface';
import { Subscription } from 'rxjs';
import { SideItemsGraphFacade } from 'src/app/Facade/GraphApiFacade/sideItems-graph.facade';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [
    trigger('header', [
      state('contract', style({
        height: '70px',
      })),
      state('expand', style({
        height: '125px',
      })),
      transition('contract <=> expand', animate('300ms')),
    ]),
    trigger('mobileMenuModal', [
      state('open', style({
        width: '85%'
      })),
      state('close', style({
        width: '0%'
      })),
      transition('open <=> close', animate('300ms')),
    ])
  ]
})
export class MobileMenuComponent implements OnInit {

  @Input() mainMenu: MainMenu[];
  sideCategoriesItemsSubscription: Subscription;

  isMobileMenuOpen = false;
  isMobileMenuModalOpen = false;
  isMobileSearchOpen = false;
  isHeaderModalOpen = false;
  userBasicInfo: IUserInfo;
  modalHeaderRef?: BsModalRef;
  @ViewChild('closeMenuElement') closeMenuElement: ElementRef;
  @ViewChild('menuElement') menuElement: ElementRef;
  configHeader = {
    backdrop: true,
    ignoreBackdropClick: false,
    class:'modalHeader',
    animated: false
  };
  favoriteApps: IUserApp[] = [];
  allApps: IUserApp[];

  isDashboardOpen: boolean = false;
  mostRecentPeople:IPerson[];
  topBanner!: IMenuSidePopupBanner;
  leftCard!: IMenuSidePopupBanner;
  middleCard!: IMenuSidePopupBanner;
  outlookCalendar:IOutlookCalendar[];
  sideItemsCategories: ISideItemCategory[];
  isHeaderExpanded: boolean = false;

  constructor(private storage: SessionStorageService,
    private userGraphFacade: UserGraphApiFacade,
    private userProfileService:UserProfileService,
    private modalHeaderService: BsModalService,
    private userFacade: UserApiFacade,
    private sideItemsGraphFacade: SideItemsGraphFacade,
    private appsService: AppsService,
    private renderer: Renderer2,
    private menuSidePopupGraphFacade: MenuSidePopupGraphFacade,
    private outlookCalendarGraphFacade:OutlookCalendarGraphFacade,
    private headerSearchScrollService: HeaderSearchScrollService) {
      this.renderer.listen('window', 'click',(e:Event)=>{
       if(e.target !== this.closeMenuElement.nativeElement
         && e.target !== this.menuElement.nativeElement
         && e.target !== this.menuElement.nativeElement.children
         && !(e.target as Element).classList.contains('disable-click-close')
         && !(e.target as Element).classList.contains('p-autocomplete-input')
         ){
          this.closeMenu();
       }
   });
    }

  ngOnInit(): void {
    this.getUserInfo();

    this.userGraphFacade.userMostRecentPeople$.subscribe(result => {
      this.mostRecentPeople = result;
    })

    this.menuSidePopupGraphFacade.sideMenuPopupBanners$.subscribe(result => {
      if(result && result.length > 0){
        result.forEach(element => {
          if(element.location == 'top'){
            this.topBanner = element;
          }

          if(element.location == 'middle'){
            this.middleCard = element;
          }

          if(element.location == 'left'){
            this.leftCard = element;
          }
        });
      }
    })

    this.outlookCalendarGraphFacade.getCalendarEvents().subscribe(result => {
      this.outlookCalendar = result;
    });

    this.sideCategoriesItemsSubscription = this.sideItemsGraphFacade.sideCategoriesItems$.subscribe(items => {
      this.sideItemsCategories = items
    });

    this.headerSearchScrollService.getHeaderSearchState().subscribe(state => this.setHeaderSearchState(state))
  }


  getUserInfo(){
    this.userProfileService.userProfileSource$.subscribe(user => {
      if(user && user.userPrincipalName){
        this.userBasicInfo = user;
      }
    })
    this.getFavoriteApps();
  }
  getFavoriteApps() {

    this.userFacade.getUserApps().subscribe(
      (res) => {
        this.allApps = res.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );
        this.favoriteApps=[];
        for (let i = 0; i < this.allApps.length; i++) {
          if (this.allApps[i].isFavorite) {
            this.favoriteApps.push(this.allApps[i]);
          }
        }
        this.appsService.isEditComplete$.next(false);
        // this.preloaderService.hidePreloader();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  openMenu(){
    this.isMobileMenuOpen = true;
    this.isMobileMenuModalOpen = true;
    this.isDashboardOpen = false;
  }

  closeMenu(){
    this.isMobileMenuOpen = false;
    this.isDashboardOpen = false;
    if (this.isMobileMenuModalOpen) {
      setTimeout(() => { this.isMobileMenuModalOpen = false }, 300)
    }
  }


  // opneSearch(){

  //   if(this.isMobileSearchOpen == false){
  //     this.isMobileSearchOpen = true;
  //   }else{
  //     this.isMobileSearchOpen = false;
  //   }
  // }
  toggleHeaderModal(templateHeader: TemplateRef<any>) {
    if(!this.isHeaderModalOpen) {
      this.modalHeaderRef = this.modalHeaderService.show(templateHeader, this.configHeader);
    }else{
      this.modalHeaderService.hide();
    }
    this.isHeaderModalOpen=!this.isHeaderModalOpen;
    this.closeMenu();
  }
  closeHeaderModal(){
    this.isHeaderModalOpen=false;
    setTimeout(() => { this.modalHeaderService.hide() }, 100);
  }

  onDashboardCloseClicked() {
    this.isDashboardOpen = false;
  }

  dashboardOpen() {
    this.menuSidePopupGraphFacade.getSideMenuPopupBanners();
    this.isDashboardOpen = true;
  }

  setHeaderSearchState(isHeaderExpanded: boolean){
    this.isHeaderExpanded = isHeaderExpanded
  }

  onHeaderAnimation(animationState: string) {
    
    var search: any = document.getElementsByClassName('mobile-header__search-top')[0]
    if (this.isHeaderExpanded && animationState == 'end') {
      search.style.display = 'block'
    }

    if (!this.isHeaderExpanded && animationState == 'start') {
      search.style.display = 'none'
    }
  }
}
