import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  // add Google Analytics script to <head>
  const script = document.createElement('script');
  const script1 = document.createElement('script');

  script.src = `https://www.googletagmanager.com/gtag/js?id=G-N88L4SPQ5D`;
  document.head.appendChild(script);

  script1.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-N88L4SPQ5D');`;
  document.head.appendChild(script1);

  enableProdMode();
} else {
   // add Google Analytics script to <head>
   const script = document.createElement('script');
   const script1 = document.createElement('script');
 
   //script.src = `https://www.googletagmanager.com/gtag/js?id=G-ZT0HC0QHHF`;
   script.src = `https://www.googletagmanager.com/gtag/js?id=G-QEKWWB9B6F`;
   document.head.appendChild(script);
 
   // script1.innerHTML = `window.dataLayer = window.dataLayer || [];
   //     function gtag(){dataLayer.push(arguments);}
   //     gtag('js', new Date());
   //     gtag('config', 'G-ZT0HC0QHHF');`;
   script1.innerHTML = `window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       gtag('config', 'G-QEKWWB9B6F');`;
   document.head.appendChild(script1);
 
   enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
