<div class="modal-body">

  <div class="modal-top">
    <div>
      <h3>{{config.title}}</h3>
      <p>{{config.subTitle}}</p>
    </div>
  </div>
  <div class="modal-buttons">
    <button type="button" class="btn btn-lost" (click)='discardChanges()'> {{config.cancelText}}  </button>
    <button type="button" class="btn btn-save" (click)='saveChanges()' > {{config.confirmText}} </button>
  </div>
</div>
