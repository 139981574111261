<div class="profile-dashboard" [style.width]="sidebarDashboardWidth + 'px'" [ngClass]="isDarkMode ? 'gray' : 'red'">
  <div class="scrollable-container">
    <button
      class="dashboard-mode-switch"
      (click)="toggleDashboardMode()"
      [ngClass]="{'dark': isDarkMode}"
    ></button>
    <button class="sidebar-dashboard-close" (click)="onCloseClicked()"></button>
    <div class="dashboard-content">
      <app-dashboard-header *ngIf="header" [userProfileData]="userProfileData" [headerData]="header"></app-dashboard-header>
      <app-dashboard-shortcuts
        *ngIf="shortcuts"
        [shortcuts]="shortcuts"
        [isDarkMode]="isDarkMode"
      ></app-dashboard-shortcuts>
      <app-dashboard-content
      [cibusData]="cibusData"
      [userProfileData]="userProfileData"
      [popularActivities]="popularActivities"
      [monthlyActivitiesPDF]="monthlyActivitiesPDF"
      [dashboardEvents]="dashboardEvents"
      [userPermitions]="userPermitions"
      (onRefreshCalendarDataEmmiter)="refreshCalendar()"
      (close)="onCloseClicked()"
      ></app-dashboard-content>
      <div class="profile-dashboard-header">My day</div>
      <app-sidebar-dashboard-calendar *ngIf="calendarEvents && calendarEvents.length > 0" [calendarEvents]="calendarEvents"></app-sidebar-dashboard-calendar>
      <app-dashboard-calendar-placeholder *ngIf="!calendarEvents || calendarEvents && calendarEvents.length == 0" [areEventsLoading]="!calendarEvents"></app-dashboard-calendar-placeholder>
    </div>
</div>
