import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiInterceptor implements ApiInterceptor {
  constructor() {}
  private EXPIRES_IN_MINUTES: number = 10;
  private cache: Map<string, { response: HttpResponse<any>; ttl: Date }> =
    new Map();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.method !== 'GET' ||
      req.url.startsWith('https://graph.microsoft.com/') ||
      req.url.includes('outLookEvents/event') ||
      req.url.includes('GetLikeCommentNotifications') ||
      req.url.includes(environment.API.Tasks.GetCountTasksToSee) ||
      req.url.includes(environment.API.Departments.GetDepartmentsFiles) ||
      req.url.includes(environment.API.Tasks.GetUserTasks) ||
      req.url.includes(environment.API.Apps.Get) ||
      req.url.includes(environment.API.Announcements.GetAnnouncements) ||
      req.url.includes(environment.API.Dashboard.GetCurrentBudget) ||
      req.url.includes(environment.API.User.GetCountUserBirthdayWishes) 
    ) {
      return next.handle(req);
    }
    const loweredReq = req.urlWithParams.toLowerCase();
    // :( From some reason didn't work for date on iso format
    /* const parameters = new URLSearchParams(loweredReq);
    const localTime = parameters.get('localtime');*/
    //
    let key:any = loweredReq;
    if (loweredReq.indexOf('localtime=') > -1) {
      const startIndx = loweredReq.indexOf('localtime=');
      const partialQS = loweredReq.substring(startIndx, startIndx + 10 + 25);
      if (req.urlWithParams.toLowerCase().indexOf(`&${partialQS}&`) > -1) {
        key = loweredReq.replace(`&${partialQS}&`, '&');
      } else if (loweredReq.toLowerCase().indexOf(`&${partialQS}`) > -1) {
        key = loweredReq.replace(`&${partialQS}`, '');
      } else if (loweredReq.indexOf(`?${partialQS}&`) > -1) {
        key = loweredReq.replace(`?${partialQS}&`, '?');
      }
    }

    const responseValue = this.cache.get(key);
    const now = new Date();
    if (responseValue && now <= responseValue.ttl) {
      return of(responseValue.response);
    } else {
      return next.handle(req).pipe(
        tap(
          (event) => {
            if (event instanceof HttpResponse) {
              this.cache.set(key, {
                response: event.clone(),
                ttl: new Date(now.getTime() + this.EXPIRES_IN_MINUTES * 60000),
              });
            }
          },
          (err) => {
            if (err instanceof HttpErrorResponse) {
              // console.log(err);
            }
          }
        )
      );
    }
  }
}
