import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, tap } from "rxjs/operators";
import { IEvent } from "src/app/Shared/Models/calendar.interface";
import { IDashboardDataResponse, IDashboardShortcut } from "src/app/Shared/Models/sidebar-dashboard.model";
import { IUserPermitions } from "src/app/Shared/Models/user.model";
import { FileService } from "src/app/Shared/Services/file.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })


  export class DashboardApiService {

    constructor(private http:HttpClient, private fileService:FileService){}

    getDashboardInitData(){

      const datepipe: DatePipe = new DatePipe('en-US');

      var body = {
        filterDate: datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z',
      }

      return this.http.post<IDashboardDataResponse>(`${environment.ApiPath}api/${environment.API.Dashboard.GetDashboardData}`, body).pipe(tap(result => {
        if(result != null){
          result.dashboardShortcuts.forEach(element => {
            this.setImageUrl(element);
          });

          this.setSibusDefaultImageUrl(result.userPermitions);
          this.setWellbeingDefaultImageUrl(result.userPermitions);
          this.seMyDayDefaultImageUrl(result.userPermitions);
          result.dashboardEvents = this.mapDashboardEvents(result.dashboardEvents)
        }
      }));
      // var response = of(this.dashboardDataResponse)

      // return response;
    }

    getCurrentBudget(){
      var response = this.http.get<number | undefined>(`${environment.ApiPath}api/${environment.API.Dashboard.GetCurrentBudget}`);
      return response;
    }

    private setImageUrl(tempItem: IDashboardShortcut) {

      if (tempItem && tempItem.iconUrl && tempItem.iconUrl.includes(environment.SharePoint.SiteURL)) {
        if(tempItem.iconUrl.includes("sites")){
          let libraryName = tempItem.iconUrl.split('sites/')[1].split('/')[1];
          let pathArray = tempItem.iconUrl.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if (index > 1) {
              filePath += '/' + element;

              if (index == pathArray.length - 1) {
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName, filePath).subscribe(result => {
                  if (result != "") {
                    tempItem.iconUrl = result;
                  }else{
                    tempItem.iconUrl = "https://stplaytika360.blob.core.windows.net/360images/TEST/Well-BeingEventType/WellbeingEventTypeDefaultImage.jpg"
                  }
                })
              }
            }
          });
        }
      }
    }

    private setSibusDefaultImageUrl(tempItem: IUserPermitions) {

      if (tempItem && tempItem.sibusDefaultImage && tempItem.sibusDefaultImage.includes(environment.SharePoint.SiteURL)) {
        if(tempItem.sibusDefaultImage.includes("sites")){
          let libraryName = tempItem.sibusDefaultImage.split('sites/')[1].split('/')[1];
          let pathArray = tempItem.sibusDefaultImage.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if (index > 1) {
              filePath += '/' + element;

              if (index == pathArray.length - 1) {
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName, filePath).subscribe(result => {
                  if (result != "") {
                    tempItem.sibusDefaultImage = result;
                  }else{
                    tempItem.sibusDefaultImage = "https://stplaytika360.blob.core.windows.net/360images/TEST/Well-BeingEventType/WellbeingEventTypeDefaultImage.jpg"
                  }
                })
              }
            }
          });
        }
      }
    }

    private setWellbeingDefaultImageUrl(tempItem: IUserPermitions) {
      if (tempItem && tempItem.wellbeingDefaultImage && tempItem.wellbeingDefaultImage.includes(environment.SharePoint.SiteURL)) {
        if(tempItem.wellbeingDefaultImage.includes("sites")){
          let libraryName = tempItem.wellbeingDefaultImage.split('sites/')[1].split('/')[1];
          let pathArray = tempItem.wellbeingDefaultImage.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if (index > 1) {
              filePath += '/' + element;

              if (index == pathArray.length - 1) {
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName, filePath).subscribe(result => {
                  if (result != "") {
                    tempItem.wellbeingDefaultImage = result;
                  }else{
                    tempItem.wellbeingDefaultImage = "https://stplaytika360.blob.core.windows.net/360images/TEST/Well-BeingEventType/WellbeingEventTypeDefaultImage.jpg"
                  }
                })
              }
            }
          });
        }
      }
    }

    private seMyDayDefaultImageUrl(tempItem: IUserPermitions) {
      if (tempItem && tempItem.myDayDefaultImage && tempItem.myDayDefaultImage.includes(environment.SharePoint.SiteURL)) {
        if(tempItem.myDayDefaultImage.includes("sites")){
          let libraryName = tempItem.myDayDefaultImage.split('sites/')[1].split('/')[1];
          let pathArray = tempItem.myDayDefaultImage.split('sites/')[1].split('/');
          let filePath = "";

          pathArray.forEach((element, index) => {
            if (index > 1) {
              filePath += '/' + element;

              if (index == pathArray.length - 1) {
                filePath = filePath.split('?')[0];
                this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId,libraryName, filePath).subscribe(result => {
                  if (result != "") {
                    tempItem.myDayDefaultImage = result;
                  }else{
                    tempItem.myDayDefaultImage = "https://stplaytika360.blob.core.windows.net/360images/TEST/Well-BeingEventType/WellbeingEventTypeDefaultImage.jpg"
                  }
                })
              }
            }
          });
        }
      }
    }

    mapDashboardEvents(events: IEvent[]) {
      var result = events.map(e => {
          e.isCompany = true
          
          if (e.end) {
              e.daysCount = this.dateDiffInDays(e.start, e.end)
          } else {
              e.daysCount = 0
          }

          return e
      })

      return result
    }

    private dateDiffInDays(aDateRaw: Date, bDateRaw: Date) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const a = new Date(aDateRaw)
        const b = new Date(bDateRaw)
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
  }
