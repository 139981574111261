<div class="dashboard-activities">
    <div class="dashboard-activities__label">
        <p class="dashboard-activities__label__text">Well-Being</p>
        <h2 *ngIf="userPermitions && userPermitions.wellbeingDefaultTitle != null
        && monthlyActivitiesPDF != undefined
        && monthlyActivitiesPDF != null
        && monthlyActivitiesPDF.trim() != ''" class="dashboard-activities__label__sub-title">{{userPermitions.wellbeingDefaultTitle}}</h2>
    </div>
    <div class="dashboard-activities__ribbon-image">
        <!-- <img src="/assets/images/wellbeing/itemdashes-categories2.png" alt="Ribbon"> -->
    </div>
    <div class="dashboard-activities__carousel">

        <img
        *ngIf="monthlyActivitiesPDF != undefined
        && monthlyActivitiesPDF != null
        && monthlyActivitiesPDF.trim() != ''"
        style="cursor: pointer;"
        (click)="onPdfOpen()"
        [src]="(userPermitions && userPermitions.wellbeingDefaultImage != null) ? userPermitions.wellbeingDefaultImage : '/assets/images/dashboard/self-wellbeing-default.png'">

        <img *ngIf="
        monthlyActivitiesPDF != undefined
        && monthlyActivitiesPDF != null
        && monthlyActivitiesPDF.trim() == ''"
        [src]="(userPermitions && userPermitions.wellbeingDefaultImage != null) ? userPermitions.wellbeingDefaultImage :'/assets/images/dashboard/self-wellbeing-default.png'">

        <a routerLink="/wellbeing"
        *ngIf="(monthlyActivitiesPDF == undefined
        || monthlyActivitiesPDF == null)
        && popularActivities.length == 0" >
            <img [src]="(userPermitions && userPermitions.wellbeingDefaultImage != null) ? userPermitions.wellbeingDefaultImage :'/assets/images/dashboard/self-wellbeing-default.png'">
        </a>

        <swiper *ngIf="(monthlyActivitiesPDF == null || monthlyActivitiesPDF == undefined) && popularActivities.length > 0"
            class="dashboard-activities__carousel__body"
            [navigation]="true"
            [slidesPerView]="1"
            [pagination]="{ clickable: true, dynamicBullets: true, dynamicMainBullets: 5 }">
            <ng-template *ngFor="let activity of popularActivities" swiperSlide >
                <a
                    class="dashboard-activity"
                    [ngStyle]="{'background': 'url(' + activity.image + ') center no-repeat'}"
                    routerLink="/wellbeing/event/{{activity.id}}">
                    <p [ngClass]="{'longTitle': isLongTitle(activity.eventName)}" class="dashboard-activity__name" title="{{activity.eventName}}">{{activity.eventName}}</p>
                    <div *ngIf="activity.location" class="dashboard-activity__location">
                        <img src="/assets/images/global/location-icon.png" alt="Marker">{{activity.location}}
                    </div>
                </a>
            </ng-template>
        </swiper>
    </div>
</div>
