import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IBanner, IBannerSource } from 'src/app/Shared/Models/banner.interface';
import { FileService } from 'src/app/Shared/Services/file.service';
import { MemberOfService } from 'src/app/Shared/Services/member-of.service';
import { environment } from 'src/environments/environment';
import { BannerApi } from '../../Core/Services/GraphApiServices/banner.graph-api';

@Injectable({
  providedIn: 'root',
})
export class BannerFacade {

  fields = "$select= Image, ImageSecondary, URL, Title, Order, StartDate, _EndDate, PageLocation, AssignedTo,UnAssignedTo, IsPDF";
  bannersSource: IBannerSource = {
    profile: [],
    home: []
  };
  bannersSource$: BehaviorSubject<IBannerSource> = new BehaviorSubject<IBannerSource>(this.bannersSource)
  constructor(private api: BannerApi, private memberOfService: MemberOfService, private fileService: FileService) {
    this.getHomePageBanners().subscribe();
  }

  getProfilePageBanners() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate = datepipe.transform(Date.now(), 'yyyy-MM-dd');
    const pageLocation = 'Profile';


    return this.api.getData(filterDate, pageLocation).pipe(
      map((response: any) => {
        for (let index = 0; index < response.value.length; index++) {
          const element = response.value[index];

          let assigned =
            element.fields.AssignedTo != undefined || element.fields.UnAssignedTo != undefined
              ? this.memberOfService.isMember(element.fields.AssignedTo, element.fields.UnAssignedTo)
              : true;

          if (assigned) {
            let tempBanner = this.mapSideBanner(element);

            if (tempBanner.image) {
              this.bannersSource.profile.push(tempBanner);
              if (this.bannersSource.profile.length > 0) {
                break;
              }
            }
          }
        }
        this.bannersSource$.next(this.bannersSource)
      })
    )
  }

  getMainManuBanner() {
    return this.api.getMainMenuBanner().pipe(
      map((response: any) => {
        var banners;
        if (response != null) {
          banners = this.mapSideBanner(response)
        }
        return banners;
      })
    )
  }

  getHomePageBanners() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate = datepipe.transform(Date.now(), 'yyyy-MM-dd');
    const pageLocation = 'Home';
    return this.api.getData(filterDate, pageLocation).pipe(
      map((response: any) => {
        for (let index = 0; index < response.value.length; index++) {
          const element = response.value[index];

          let assigned =
            element.fields.AssignedTo != undefined || element.fields.UnAssignedTo != undefined
              ? this.memberOfService.isMember(element.fields.AssignedTo, element.fields.UnAssignedTo)
              : true;

          if (assigned) {
            let tempBanner = this.mapSideBanner(element);
            if (tempBanner.image) {
              this.bannersSource.home.push(tempBanner);
              if (this.bannersSource.home.length == 4) {
                break;
              }
            }
          }
        }
        this.bannersSource$.next(this.bannersSource)
      }),
      catchError(err => {
        this.bannersSource$.next(this.bannersSource)
        return of({})
      })
    )
  }

  private mapSideBanner(element: any) {
    let tempBanner: IBanner = {      
      order: element.fields.Order != undefined
        ? element.fields.Order : 0,
      image: element.fields.Image != undefined ? element.fields.Image.Url : undefined,
      title: element.fields.Title,
      isPdf: element.fields.IsPDF != undefined
        ? element.fields.IsPDF : false,
      IsExternalLink: element.fields.IsExternalLink != undefined ?
        element.fields.IsExternalLink : false,
      link:
        element.fields.URL != undefined
          ? element.fields.URL.Url
          : '',
      imageSecondary: element.fields.ImageSecondary != undefined ?  element.fields.ImageSecondary : undefined
    };

    if (tempBanner.image) {
      this.setBannerMediaUrl(tempBanner);
    }

    return tempBanner;
  }

  private setBannerMediaUrl(tempBanner: IBanner) {

    if (tempBanner.image && tempBanner.image.includes(environment.SharePoint.SiteURL)) {

      let libraryName = tempBanner.image.split('sites/')[1].split('/')[1];
      let pathArray = tempBanner.image.split('sites/')[1].split('/');
      let filePath = "";

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService.getFileByPathInB64(environment.SharePoint.ContentSiteId, libraryName, filePath).subscribe(result => {
              if (result != "") {
                tempBanner.image = result;
              }
            })
          }
        }
      });
    }

    // if(tempBanner.link && tempBanner.link.includes(environment.SharePoint.SiteURL) && tempBanner.isPdf == true){

    //   let libraryName =  tempBanner.link.split('sites/')[1].split('/')[1];
    //   let pathArray = tempBanner.link.split('sites/')[1].split('/');
    //   let filePath = "";

    //   pathArray.forEach((element, index) => {
    //     if(index > 1){
    //       filePath+='/'+element;

    //       if(index == pathArray.length-1){
    //         filePath = filePath.split('?')[0];
    //         this.fileService.getByPathFile(environment.SharePoint.ContentSiteId,libraryName,filePath).subscribe(result => {
    //           if(result != ""){
    //             tempBanner.link = result;
    //           }
    //         })
    //       }
    //     }
    //   });
    // }
  }

}
