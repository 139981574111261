import { Component, EventEmitter, Input, OnInit, Output, HostListener } from '@angular/core';
import { SideItemsGraphFacade } from 'src/app/Facade/GraphApiFacade/sideItems-graph.facade';
import { ISideItem, ISideItemCategory } from 'src/app/Shared/Models/sideItem.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FileReaderService } from 'src/app/Shared/Services/file-reader.service';

@Component({
  selector: 'app-header-modal',
  templateUrl: './header-modal.component.html',
  styleUrls: ['./header-modal.component.scss'],
  animations: [
    trigger('dropdown', [
      state('closed', style({
        height: '0px',
      })),
      state('open', style({
        height: '*',
      })),
      transition('open <=> closed', animate('300ms')),
    ]),
    trigger('dropdownArrow', [
      state('closed', style({
        transform: 'rotate(0deg)',
      })),
      state('open', style({
        transform: 'rotate(180deg)',
      })),
      transition('open <=> closed', animate('300ms')),
    ]),
    trigger('headerModal', [
      transition(':enter', [
        style({ width: '0%' }),
        animate('300ms', style({ width: '85%' })),
      ]),
      transition(':leave', [
        style({ width: '85%' }),
        animate('300ms', style({ width: '0%' })),
      ]),
    ])
  ]
})
export class HeaderModalComponent implements OnInit {

  sideItemsCategories:ISideItemCategory[];
  dropdownStates:string[] = [];
  @Output() closeModalEmiter = new EventEmitter();
  isModalOpen: boolean;
  areDropdownsEnabled: boolean;
  isMobile: boolean;
  sideItemsDropdownStates: {id: number, value: string}[] = []

  constructor( private fileReaderService:FileReaderService,
    private sideItemsGraphFacade:SideItemsGraphFacade) { }

  ngOnInit(): void {
    this.sideItemsGraphFacade.sideCategoriesItems$.subscribe(items => {
      this.sideItemsCategories = items;
      //setInterval(() => { console.log(this.isModalOpen) }, 100);
      this.isModalOpen = true;
      this.areDropdownsEnabled = true;

      for (var category of this.sideItemsCategories) {
        this.dropdownStates.push('closed');

        category.items.forEach(item => {
          if (this.hasChildren(item)) {
            this.sideItemsDropdownStates.push({id: item.id, value: 'closed'})
          }
        })
      }

      this.onResize();
    });
    document.querySelector<HTMLElement>(".modal-backdrop")?.classList.add("headerBackdrop");
    document.querySelector<HTMLElement>(".modal")?.classList.add("headerModal");
  }
  closeHeaderModalEmitter(){
    this.areDropdownsEnabled = false;
    setTimeout(() => {
      this.isModalOpen = false;
    }, 10);
    setTimeout(() => {
      this.closeModalEmiter.emit();
    }, 300);
  }

  onPdfOpen(link){
    this.fileReaderService.openFile(link)
  }
  onDestroy(){
    document.querySelector<HTMLElement>(".modal-backdrop")?.classList.remove("headerBackdrop");
    document.querySelector<HTMLElement>(".modal")?.classList.remove("headerModal");
  }
  onDropdownClick(i){ //I'm using state names here instead of bools to prevent a close animation from firing on init
    this.dropdownStates[i] = this.dropdownStates[i] != 'open' ? 'open' : 'closed';
  }

  onStateDropdownClick(id) {
    var i = this.sideItemsDropdownStates.findIndex(state => state.id == id)
    this.sideItemsDropdownStates[i].value = this.sideItemsDropdownStates[i].value != 'open' ? 'open' : 'closed'
  }

  getSideItemDropdownState(id) {
    return this.sideItemsDropdownStates.find(state => state.id == id)?.value
  }

  hasChildren(sideItem: ISideItem) {
    return sideItem.children.length > 0
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;

    if (!this.isMobile) {
      for (var i = 0; i < this.dropdownStates.length; i++) {
        this.dropdownStates[i] = 'open';
      }
    }
  }
}
