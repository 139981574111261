import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SPListGraphApiService } from "src/app/Shared/Services/spList-graphApi.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export class MenuSidePopupGraphApiService extends SPListGraphApiService{
    constructor(protected http: HttpClient){
      super(http, environment.SharePoint.SidePopupBanners)
    }

    getMenuSidePopupBanners(){
        return this.http.get(
            `${environment.ApiPath}api/${environment.API.SidePopup.GetSidePopup}`,
            {headers:{Prefer:'allowthrottleablequeries'}}
        );
    }
}
