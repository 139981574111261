<div class="dashboard-shortcuts">
    <div class="dashboard-shortcuts__carousel" #shortcutsCarousel>
        <div *ngIf="!areControlsDisabled" class="dashboard-shortcuts__carousel__controls">
            <div class="navigation-button prev-shortcut" (click)="swipePrev()" [ngClass]="{'disabled': isPrevDisabled}">
            </div>
            <div class="navigation-button next-shortcut" (click)="swipeNext()" [ngClass]="{'disabled': isNextDisabled}">
            </div>
        </div>
        <swiper class="dashboard-shortcuts__carousel__body" [navigation]="false" [slidesPerView]="'auto'"
            [spaceBetween]="9" [pagination]="false">
            <ng-template *ngFor="let shortcut of shortcuts; let i = index" swiperSlide>
                <a href="{{shortcut.url}}" target="{{shortcut.isExternalLink == true ? '_blank'  : '_self'}}" (click)="swiperLinkClicked(shortcut)"
                    [ngClass]="isDarkMode ? 'gray' : 'red'" class="shortcut" title="{{shortcut.title}}">
                    <img class="shortcut__icon" src="{{shortcut.iconUrl}}">
                    <p class="shortcut__title" [ngClass]="{'one-word': isOneWord[i], 'multiple-words': !isOneWord[i]}">
                        {{shortcut.title}}</p>
                </a>
            </ng-template>
        </swiper>
    </div>
</div>