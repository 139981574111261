
<div style="margin-left: 56px;" *ngIf="vodComments && vodComments.length > 0" >

    <div style="position: relative;" *ngFor="let comment of vodComments; let i=index">
      <div *ngIf="hasChildrem(comment.id) && comment.displayName" class="has-childrem-vector"></div>
      <div style="position: relative;">
        <div *ngIf="comment.displayName" class="childrem-to-parent-vector"  [ngClass]="{'childrem-to-parent-vector-t60':isParentDeleted && i==0}"></div>
      </div>
        <div class="comment">
            <div  class="comment__content">
              <div *ngIf="vodComments.length-1 == i" class="childrem-vector-limit"></div>
                <div class="comment__user-photo">
                    <app-user-photo
                    [userEmail]="comment.userEmail"
                    [userPhotoSettings]="userPhotoSettings"
                    (userDisplayName)="comment.displayName = $event"
                    ></app-user-photo>
                </div>
                <div style="width: 100%; position: relative;">
                    <img *ngIf="comment.isUserLike && this.currentCommentIdEdit != comment.id && !comment.deleted" class="comment__like-icon" src="/assets/images/article/article-like-circle-active.png">
                    <div *ngIf="currentCommentIdEdit != comment.id" class="comment__description">
                        <div *ngIf="!comment.deleted" class="comment__user-name">
                            {{comment.displayName}}
                        </div>
                        <div  class="comment__text" [innerHTML]="comment.comment | safeHtml" [ngClass]="{'comment__deleted':comment.deleted}">
                        </div>
                    </div>

                    <app-edit-comment-box
                        *ngIf="currentCommentIdEdit == comment.id"
                        [commentEditControl]="commentEditControl"
                        (onSaveEmitter)="onEditConfirm()"
                        [userName]="comment.displayName"
                    ></app-edit-comment-box>

                    <div *ngIf="currentCommentIdEdit == comment.id && !comment.deleted" class="comment__footer">
                        <div class="comment__footer-buttons">
                            <span (click)="onEditCancel()" class="esc-text">Press Esc to <span style="color:#ff874f">cancel</span></span>
                        </div>
                    </div>

                    <div  *ngIf="currentCommentIdEdit != comment.id" class="comment__footer">
                        <div *ngIf="!comment.deleted" class="comment__footer-buttons">
                            <div *ngIf="userEmail == comment.userEmail" class="comment__footer-modify">
                                <span class="comment__footer-divider-circle" (click)="onEditStart(comment)">Edit</span>
                                <span class="comment__footer-divider-circle" (click)="openAlertModal(templateConfirm, comment)">Delete</span>
                            </div>
                            <div class="comment__footer-reations">
                                <span class="comment__footer-divider-circle" (click)="comment.isUserLike == false? AddCommentLike(comment) : RemoveCommentLike(comment)"
                                [ngClass]="{'active-like':comment.isUserLike}">Like</span>
                                <span class="comment__footer-divider-circle" (click)="onReplyStart(comment)">Reply</span>
                                <span class="datetime" *ngIf="comment.createdDate!.split('T')[0]==today">at {{ comment.createdDate+'Z' | date: 'shortTime'}}</span>
                                <span class="datetime" *ngIf="comment.createdDate!.split('T')[0]!=today">{{ comment.createdDate+'Z' | date: 'longDate'}}</span>
                            </div>
                        </div>

                        <app-add-comment-box
                            *ngIf="currentCommentIdReply == comment.id"
                            [userEmail]="userEmail"
                            [commentControl]="commentAnswerControl"
                            [isReply]="true"
                            (onSaveEmitter)="onConfirmReply(comment)"
                        ></app-add-comment-box>
                    </div>
                </div>
            </div>


        </div>

        <app-comment-item [parentId]="comment.id" [vodId]="vodId"></app-comment-item>
    </div>
</div>

<ng-template #templateConfirm>
    <app-delete-alert-modal
    [message]="'Are you sure you want to remove this comment?'"
    (onCloseModalEmmiter)="modalRef?.hide()"
    (onDeleteButtonEmmiter)="onDeleteComment()"
    ></app-delete-alert-modal>
  </ng-template>
