import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-coming-soon',
  templateUrl: './dashboard-coming-soon.component.html',
  styleUrls: ['./dashboard-coming-soon.component.scss']
})
export class DashboardComingSoonComponent implements OnInit {

  @Input() backgroundImage: string;
  @Input() additionalInfo: string;
  @Input() sibusDefaultTitle: string;

  constructor() { }

  ngOnInit(): void {
  }

}
