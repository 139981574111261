<!-- <button (click)="loginRedirect()" *ngIf="!loginDisplay">login</button>
<button mat-raised-button (click)="logout()" *ngIf="loginDisplay">Logout</button>


<a [routerLink]="['profile']" *ngIf="loginDisplay">Profile</a> -->
<div>
  <app-start-preloader></app-start-preloader>

  <div class="app-wrapper">
    <div class="app-container">
      <div class="global-sidebar-wrapper">
        <global-sidebar-with-dashboard (closeModalEmiter)="closeHeaderModalEmitter()"></global-sidebar-with-dashboard>
        <div class="profile-filler"></div>
      </div>
      <div class="header-wrapper">
        <app-header [modalHeaderService]="modalHeaderService"></app-header>
      </div>
      <div class="main-content-wrapper">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
        <app-scroll-to-top></app-scroll-to-top>
      </div>
    </div>
  </div>
</div>

