import { IPagination } from './commoon.interface';

export interface IAiSideMenu {
  id: number;
  label: string;
  icon: string;
  url: string;
  isExternalLink: boolean | null;
  isDisabled: boolean | null;
}

export interface IAiVertical {
  id: number;
  title: string;
  iconUrl: string;
}

export interface IAiCriteriaFilter {
  id: number;
  title: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface IAiBanner {
  id: number;
  title: string;
  imageUrl: string;
  link?: string;
  isExternalLink?: boolean;
}

export interface IAiLink {
  id: number;
  title: string;
  description?: string;
  url: string;
  isExternalLink: boolean;
}

export interface IAiMedia {
  //the same as IDepartmentMedia
  id: number;
  title: string;
  image?: string;
  video?: string;
  startDate?: string;
  endDate?: string;
  body?: string;
  description?: string;
  isHebrewTitle: boolean;
  isHebrewDescription: boolean;
  isHebrewBody: boolean;
  outputs?: IAiOutputType[];
  duration?: string;
  articleType?: IAiArticleType;
  experienceLevel?: IAiArticleExperienceLevel;
  pageType?:string;
}

export interface IAiArticleType {
  id: number;
  title: string;
}

export interface IAiArticleExperienceLevel {
  id: number;
  title: string;
}

export interface IAiOutputType {
  id: number;
  title: string;
  iconUrl: string;
}

export interface IAiPricing {
  id: number;
  title: string;
}

export interface IAiIndicator {
  id: number;
  title: string;
  color: string;
}

export interface IAiLinks extends IPagination {
  items: IAiLink[];
}

export interface IAiMediaPagination extends IPagination {
  items: IAiMedia[];
}

export interface IAiTool {
  id: number;
  title: string;
  description: string;
  warningText: string;
  categories?: IAiVertical[];
  filters?: IAiCriteriaFilter[];
  link: string;
  logo: string;
  indicator: IAiIndicator;
  outputs: IAiOutputType[];
  order: number | null;
  image: string | null;
  pricing?: string;
  featured: boolean;
  ratingVotes: number;
  rating: number;
  playtikanExpert: string | null;
  myRating: number | null;
  myPreviousRating: number | null;
  similarTools?: IAiTools;
}

export interface IAiTools extends IPagination {
  items: IAiTool[];
}

export class AiDataSource {
  banner?: IAiBanner;
  newsBanner?: IAiBanner;
  sideMenu?: IAiSideMenu[];
  verticals?: IAiVertical[];
  criteriaFilters?: IAiCriteriaFilter[];
  indicators?: IAiIndicator[];
  outputTypes?: IAiOutputType[];
  searchTerm?: string;
  tools?: IAiTools;
  currentTool?: IAiTool;
  currentMedia?: IAiMedia;
  media?: IAiMediaPagination;
  links?: IAiLinks;
  filterParams?: IAiToolsRequestParams;
  pricing?: IAiPricing[];
  searchAnnouncement?: IAiSearchAnnouncement;
  featuredArticle?: IAiMedia;
  featuredTools?: IAiTools;
  articles?: IAiMediaPagination;
  newsArticlesCount: number;
  articlesNews?: IAiMediaPagination;
  articlesTools?: IAiMediaPagination;
}

export interface IAiHomeDataResponse {
  verticals?: IAiVertical[];
  criteriaFilters?: IAiCriteriaFilter[];
  indicators?: IAiIndicator[];
  outputTypes?: IAiOutputType[];
  pricing?: IAiPricing[];
}

export interface IAiToolsRequestParams {
  orderBy?: AiOrderBy;
  pricingIds?: number[];
  verticalId?: number;
  criteriaFilterIds?: number[];
  indicatorIds?: number[];
  outputTypeIds?: number[];
  filterdate?: string;
  searchQuery?: string;
}

export interface IAiToolResponse {
  tool: IAiTool;
}

export interface IAiToolsResponse {
  tools: IAiTools;
}

export interface IAiLinksResponse {
  links: IAiLinks;
}

export interface IAiMediaResponse {
  media: IAiMediaPagination;
}

export interface IAiRating {
  id: number;
  toolId: number;
  userEmail: string;
  rating: number;
}

export interface IAiBannerResponse {
  banner: IAiBanner;
}

export interface IAiSideMenuResponse {
  sideMenu: IAiSideMenu[];
}

export enum AiOrderBy {
  Recent = 1,
  TopReviewed = 2,
  Oldest = 3
}

export interface IAiOrderBy {
  id: AiOrderBy;
  name: string;
}

export interface IAiSearchAnnouncement {
  id: number;
  title: string;
  link: string;
  linkTitle: string;
  externalLink: boolean;
}

export interface IAiSearchAnnouncementResponse {
  searchAnnouncement: IAiSearchAnnouncement;
}

export interface IAiToolDetails{
  toolId?:number;
  topUsersLiked: string[],
  topUsersCommented: string[],
  toolLikesCount: number,
  toolCommentsCount:number,
  isUserLiked: boolean,
}

export interface IAiToolComment{
  id:number;
  toolId: number,
  parentId: number,
  topParentId:number,
  deleted:boolean,
  comment: string,
  userEmail: string,
  displayName?: string,
  createdDate: string,
  childCommetsCount:number,
  likesCount:number,
  isUserLike:boolean
}

export interface IAiArticleDetails{
  articleId?:number;
  topUsersLiked: string[],
  topUsersCommented: string[],
  articleLikesCount: number,
  articleCommentsCount:number,
  isUserLiked: boolean,
  articleFavoritesCount: number,
  isUserFavorite: boolean,
}

export interface IAiArticleComment{
  id:number;
  articleId: number,
  parentId: number,
  topParentId:number,
  deleted:boolean,
  comment: string,
  userEmail: string,
  displayName?: string,
  createdDate: string,
  childCommetsCount:number,
  likesCount:number,
  isUserLike:boolean
}

// export interface IAiArticleFavorite{
//   userEmail: string,
//   articleId: number;
// }
