import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SPListGraphApiService } from 'src/app/Shared/Services/spList-graphApi.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BannerApi extends SPListGraphApiService{

  constructor(protected http: HttpClient) {
    super(http)
  }

  getData(filterDate: string | null, pageLocation: string) {
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.SideBanners.GetSideBanners}?localtime=${filterDate}&pageLocation=${pageLocation}`,
      {headers:{Prefer:'allowthrottleablequeries'}}
    );
  } 

  getMainMenuBanner(){
    return this.http.get(`${environment.ApiPath}api/${environment.API.SpecialBanners.GetMainMenuBanner}`,{headers:{Prefer:'allowthrottleablequeries'}})
  }
}
