<div class="header__search" (keydown.enter)="enterAction()">
  <p-autoComplete
    styleClass="header__search-input"
    class="header__search-autocomplete"
    [(ngModel)]="searchText"
    [group]="true"
    [delay]="500"
    [minLength]="3"
    scrollHeight="auto"
    [suggestions]="shortSearchResults"
    placeholder="Search PlaytiSpace…"
    (completeMethod)="search()"
    (onSelect)="clearSearch()"
    (onHide)="clearSearch()"
    field="label"
  >
    <ng-template let-header pTemplate="header">
      <div class="top-search-results-header">
        <div class="p-d-flex p-ai-center">
          <h6 class="top-search-results">Top search results</h6>
        </div>

        <div>
          <a
            (click)="seeAllResults()"
            [routerLink]="['/search']"
            [queryParams]="{ query: searchText }"
            queryParamsHandling="merge"
            class="p-d-flex p-ai-center search-more-autocomplete"
            *ngIf="allResultsCount > 0"
          >
            <span class="top-search-results label"
              >Show All Results ({{ allResultsCount }})</span
            >
          </a>
        </div>
      </div>
    </ng-template>

    <ng-template let-group pTemplate="group">
      <div class="p-d-flex p-ai-center top-search-align"
        [ngClass]="{'disabled': group.items.length == 0}"
      >
        <a
          (click)="displayFilteredResults(group.label)"
          class="group__text"
          [routerLink]="['/search']"
          [queryParams]="{ query: searchText }"
          queryParamsHandling="merge"
          >{{ group.label }}
          <span class="group__text" *ngIf="group.total > 0"
            >({{ group.total }})</span
          >
        </a>
      </div>
    </ng-template>

    <ng-template let-item pTemplate="item" let-index="index">
      <div class="search-item">
        <a
          href="{{ item.url }}"
          class="search-item-apps"
          *ngIf="item.type.indexOf('Navigation Menu') > -1"
        >
          <b [innerHTML]="item.label | highlightSearch : searchText"></b>
        </a>

        <a
          (click)="openFile(item.url)"
          class="search-item-apps"
          *ngIf="item.type === 'Documents & Policies' && !item.isExternal"
        >
          <div class="search-item-articles-image search-item-videos">
            <img [src]="item.documentTypeIcom" />
          </div>
          <b [innerHTML]="item.title | highlightSearch : searchText"></b>
        </a>

        <a
          href="{{ item.url }}"
          target="_blank"
          class="search-item-apps"
          *ngIf="item.type === 'Documents & Policies' && item.isExternal"
        >
          <div class="search-item-articles-image search-item-videos">
            <img [src]="item.documentTypeIcom" />
          </div>
          <b [innerHTML]="item.title | highlightSearch : searchText"></b>
        </a>

        <div
          (click)="downloadFile(item)"
          class="search-item-apps"
          *ngIf="item.type === 'Files' && checkImage(item)"
        >
          <div class="search-item-articles-image">
            <img [src]="item.icon" />
          </div>
          <b [innerHTML]="item.label | highlightSearch : searchText"></b>
          <div class="document-download">
            <div class="document-download-icon"></div>
          </div>
        </div>

        <div
          (click)="downloadFile(item)"
          class="search-item-apps"
          *ngIf="item.type === 'Files' && !checkImage(item)"
        >
          <div class="search-item-apps-icon"><img [src]="item.icon" /></div>
          <b [innerHTML]="item.label | highlightSearch : searchText"></b>
          <div class="document-download">
            <div class="document-download-icon"></div>
          </div>
        </div>

        <a
          class="search-item-apps"
          *ngIf="item.type === 'Folders'"
          routerLink="/document-libery/search/{{ item.label }}/{{ item.id }}/{{
            item.parentReference.driveId
          }}"
        >
          <div class="search-item-apps-icon"><img src="{{ item.icon }}" /></div>
          <b [innerHTML]="item.label | highlightSearch : searchText"></b>
        </a>

        <a
          class="search-item-apps"
          *ngIf="item.type === 'Apps'"
          href="{{ item.url }}"
          target="{{
            item.url.includes(windowHref) == true ? '_self' : '_blank'
          }}"
        >
          <div class="search-item-apps-icon">
            <img
              src="{{ item.icon }}"
              onerror="this.onerror=null;this.style.display='none'"
            />
          </div>
          <b [innerHTML]="item.name | highlightSearch : searchText"></b>
        </a>

        <a
          href="/articles/article/{{ item.id }}"
          target="_self"
          class="search-item-articles"
          *ngIf="item.type === 'Articles'"
        >
          <div class="search-item-articles-content">
            <div class="search-item-articles-image">
              <img
                *ngIf="item.image"
                src="{{ item.image }}"
                onerror="this.onerror=null;this.style.display='none'"
              />
            </div>
            <div>
              <div class="label">
                <b [innerHTML]="item.label | highlightSearch : searchText"></b>
              </div>
              <span
                class="text"
                [innerHTML]="item.text | highlightSearch : searchText"
              ></span>
            </div>
          </div>
          <span class="search-item-articles-date">{{
            item.createdDate | date : "dd/MM/YY"
          }}</span>
        </a>

        <a
          href="/wellbeing/event/{{ item.id }}"
          target="_self"
          class="search-item-articles"
          *ngIf="item.type === 'Well-Being Events'"
        >
          <div class="search-item-articles-content">
            <div class="search-item-articles-image">
              <img
                *ngIf="item.image"
                src="{{ item.image }}"
                onerror="this.onerror=null;this.style.display='none'"
              />
            </div>
            <div>
              <div class="label">
                <b
                  [innerHTML]="item.eventName | highlightSearch : searchText"
                ></b>
              </div>
              <span
                class="text"
                [innerHTML]="item.description | highlightSearch : searchText"
              ></span>
            </div>
          </div>
          <span class="search-item-articles-date">{{
            item.eventDateTime | date : "dd/MM/YY, h:mm:ss a"
          }}</span>
        </a>

        <a
          routerLink="/vods/vod/{{ item.id }}"
          class="search-item-apps"
          *ngIf="item.type === 'VOD' && item.canDisplay == true"
        >
          <div class="search-item-articles-image search-item-videos">
            <img
              class="play-button"
              src="/assets/images/article/play-video-icon.png"
            />
            <img [src]="item.icon" />
          </div>
          <b [innerHTML]="item.label | highlightSearch : searchText"></b>
        </a>

        <a
          href="/galleries/{{ item.id + '_' + item.listItemId }}"
          target="_self"
          class="search-item-articles"
          *ngIf="item.type === 'Gallery' && item.canDisplay == true"
        >
          <div class="search-item-articles-content">
            <div class="search-item-articles-image">
              <img
                *ngIf="item.icon"
                src="{{ item.icon }}"
                onerror="this.onerror=null;this.style.display='none'"
              />
            </div>
            <div>
              <div class="label">
                <b [innerHTML]="item.label | highlightSearch : searchText"></b>
              </div>
            </div>
          </div>
          <span class="search-item-articles-date">{{
            item.createdDate | date : "dd/MM/YY"
          }}</span>
        </a>

        <a
          class="search-item-person"
          *ngIf="item.type === 'People'"
          routerLink="/people/home/{{ item.userPrincipalName }}"
        >
          <div class="search-item-person-photo">
            <app-user-photo
              [userEmail]="item.userPrincipalName"
              [userPhotoSettings]="userPhotoSettings"
            ></app-user-photo>
          </div>
          <div class="search-item-person-info">
            <b [innerHTML]="item.displayName | highlightSearch : searchText"></b
            ><br />
            <div
              class="search-item-person-jobTitle"
              [innerHTML]="item.jobTitle"
            ></div>
          </div>
        </a>
      </div>
    </ng-template>
  </p-autoComplete>

  <button class="header__search-icon" *ngIf="!areResultsLoading">
    <img src="/assets/images/global/icon-system-search.png" alt="search" />
  </button>
  <div class="pi-spinner-container" *ngIf="areResultsLoading">
    <i class="pi pi-spin pi-spinner"></i>
  </div>
</div>
