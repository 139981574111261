import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SideItemsGraphFacade } from 'src/app/Facade/GraphApiFacade/sideItems-graph.facade';

@Injectable({
  providedIn: 'root'
})
export class SideItemsGuard implements CanActivate {
  constructor(private sideItemsGraphFacade:SideItemsGraphFacade, private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currentRoute = state.url;
      return this.sideItemsGraphFacade.getAllSideItems().pipe(
        map(items => {
          const articlesMainRoute = '/articles'
          const vodsMainRoute = '/vods'
          const galeriesMainRoute = '/galleries'
          const wellBeingMainRoute = '/wellbeing'
          const departmentsMainRoute = '/departments'

          const articles =  items.find(i => i.link?.toLocaleLowerCase() == articlesMainRoute && i.isAllwed == false)
          const vods =  items.find(i => i.link?.toLocaleLowerCase() == vodsMainRoute && i.isAllwed == false)
          const galeries =  items.find(i => i.link?.toLocaleLowerCase() == galeriesMainRoute && i.isAllwed == false)
          const wellBeing =  items.find(i => i.link?.toLocaleLowerCase() == wellBeingMainRoute && i.isAllwed == false)
          const departments =  items.find(i => i.link?.toLocaleLowerCase() == departmentsMainRoute && i.isAllwed == false)

          let isAllwed = items.find(i => i.link?.toLocaleLowerCase() == currentRoute && i.isAllwed == true && 
          (
            !currentRoute.includes(String(wellBeing?.link)) || 
            !currentRoute.includes(String(vods?.link)) || 
            !currentRoute.includes(String(galeries?.link)) || 
            !currentRoute.includes(String(departments?.link)) || 
            !currentRoute.includes(String(articles?.link))
          )) != undefined

          

          let isNotInTheList = items.find(i => i.link?.toLocaleLowerCase() == currentRoute 
          || currentRoute.includes(String(wellBeing?.link))
          || currentRoute.includes(String(vods?.link))
          || currentRoute.includes(String(galeries?.link))
          || currentRoute.includes(String(departments?.link))
          || currentRoute.includes(String(articles?.link))
          ) == undefined

          // console.log("sideItems: Items ",items)
          // console.log("sideItems: isNotInTheList ",isNotInTheList)
          // console.log("sideItems: isAllwed ",isAllwed)
          // console.log("sideItems: currentRoute ",currentRoute)
          // console.log("sideItems: galeriesMainRoute ",galeriesMainRoute)
          // console.log(`sideItems: galeries in the list by /${galeriesMainRoute} `,galeries)

          if(isAllwed){
            return true;
          }

          if(isNotInTheList){
            return true;
          }

          this.router.navigateByUrl('/not-found')
          return false;
        })
      )
  }
  
}
