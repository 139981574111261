import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-emojs',
  templateUrl: './emojs.component.html',
  styleUrls: ['./emojs.component.scss']
})
export class EmojsComponent implements OnInit {

  @Output() onChooseEmojiEmitter = new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      let a  = document.getElementsByClassName("ngx-emoji-category-btn")
      if(a){
        for (let index = 0; index < a.length; index++) {
          if(index>0){
            break;
          }
          const element:any = a[index];
          element.style.display = "none";

        }
      }
    }, 0);
  }

  handleEmoji(event){
    this.onChooseEmojiEmitter.emit(event)
  }

}
