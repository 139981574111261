import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { CkEditorService } from 'src/app/Shared/Services/ck-editor.service';
import * as ckCusomEditor from '../../../../../ckCustomEditor/build/ckeditor'
import { ArticleCommentaryService } from '../article-commentary.service';

@Component({
  selector: 'app-edit-article-comment-box',
  templateUrl: './edit-article-comment-box.component.html',
  styleUrls: ['./edit-article-comment-box.component.scss']
})
export class EditArticleCommentBoxComponent implements OnInit {
  shiftKeyPressed = false;

  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {

  //   if(event.key == "Escape"){
  //     this.onCancelEdit()
  //   }

  //   if(event.key == "Enter" && this.shiftKeyPressed == false){
  //     this.removeEmpty(this.commentEditControl.value)
  //   }

  //   if(event.key == "Shift"){
  //     this.shiftKeyPressed = true;
  //   }

  // }

  // @HostListener('window:keyup', ['$event'])
  // handleKeyboardEvent1(event: KeyboardEvent) {
  //   if(event.key == "Shift"){
  //     this.shiftKeyPressed = false;
  //   }
  // }

  @Input() commentEditControl:UntypedFormControl;
  @Input() userName;

  @Output() onSaveEmitter = new EventEmitter(false)

  @ViewChild('EditArticleEditor', { static: false }) editor: CKEditorComponent|any;

  toggled: boolean = false;

  public Editor;
  public ckConfig;

  constructor(private articleCommentaryService:ArticleCommentaryService, private ckEditorService: CkEditorService) { }

  ngOnInit(): void {
    this.Editor =  this.ckEditorService.getSkEditor();
    this.ckConfig =  this.ckEditorService.getSkEditorConfig();

  }

  handleEmoji(event) {
    this.toggled =false;
    const selection = this.editor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
      this.editor.editorInstance.model.change(writer => {
        writer.insert(event.char, range.start);
      });
  }

  onCancelEdit(){
    this.commentEditControl.reset();
    this.articleCommentaryService.setCurrentCommentaryEditId(-1);
  }

  onConfirm(){
    this.onSaveEmitter.emit(true)
  }

  removeEmpty(str){
    if(str && str.includes('<p>&nbsp;</p>')){
      str = str.replace('<p>&nbsp;</p>', "")
      this.removeEmpty(str)
    }else{
      this.commentEditControl.setValue(str)
      this.onConfirm()
    }
  }
}
