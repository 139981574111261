import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ArticlesFacade } from 'src/app/Facade/GraphApiFacade/articles-graph.facade';

@Component({
  selector: 'app-article-comments-mobile',
  templateUrl: './article-comments-mobile.component.html',
  styleUrls: ['./article-comments-mobile.component.scss']
})
export class ArticleCommentsMobileComponent implements OnInit {

  @Input() articleId
  @Output() closeModalEmitter = new EventEmitter();
  postAuthor: string

  constructor(private articlesFacade: ArticlesFacade) { }

  ngOnInit(): void {
    this.articlesFacade.getArticleById(String(this.articleId)).subscribe(article => { 
      this.postAuthor = article.editor == undefined ? 
      (article.department?.title == undefined ? '' : article?.department.title) 
      : article.editor
    })
  }

  closeModal() {
    this.closeModalEmitter.emit()
  }
}
