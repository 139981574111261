import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileService } from './file.service';
import { PreloaderService } from './preloader.service';

@Injectable({
  providedIn: 'root',
})
export class FileReaderService {
  private currentFile = new BehaviorSubject<any>('');
  public currentFile$ = this.currentFile.asObservable();

  constructor(
    private router: Router,
    private preloaderService: PreloaderService,
    private fileService: FileService
  ) {}

  openFile(link) {
    if (
      link &&
      link.includes(environment.SharePoint.SiteURL) &&
      link.includes('sites')
    ) {
      let siteName = link.split('sites/')[1].split('/')[0];
      let libraryName = link.split('sites/')[1].split('/')[1];
      let pathArray = link.split('sites/')[1].split('/');
      let filePath = '';
      let newFilePath = '';
      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;
        }
      });

      newFilePath = filePath.replace(/\//g, '&#47');

      var i = newFilePath.indexOf('?');
      if (i > -1) {
        newFilePath = newFilePath.slice(0, i);
      }

      console.log(newFilePath);
      this.router.navigate([
        `/file-reader/${siteName}/${libraryName}/${newFilePath}`,
      ]);
    } else {
      window.open(link, '_blank');
    }
  }

  getFileData(params) {
    let newPath = params.path.replace(/\&#47/g, '/');

    let newParams = {
      siteName: params.siteName,
      subSiteName: params.subSiteName,
      libraryName: params.libraryName,
      path: newPath,
    };
    this.preloaderService.showPreloader();
    this.fileService
      .getBySiteNamePathFile(newParams)
      .pipe()
      .subscribe((result) => {
        let obj = {
          pdfFile: result != '' ? result : null,
          error: result != '' ? false : true,
        };
        this.preloaderService.hidePreloader();
        this.currentFile.next(obj);
      });
  }

  resetCurrentFile() {
    this.currentFile.next('');
  }
}
