<div class="container" >
    <div class="comment">
        <div class="comment__user-photo">
            <app-user-photo
            [userEmail]="userEmail"
            [userPhotoSettings]="userPhotoSettings"
            ></app-user-photo>
        </div>
        <div class="comment__area">
          <ng-container *ngIf="ckConfig && Editor">

              <!-- <form (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" > -->
                <form (mouseenter)="mouseEnter()">
                  <div *ngIf="!showEditor" class="ckEditor-placeholder">Write your comment</div>
                  <ckeditor
                    *ngIf="showEditor"
                    #AddAiToolsEditor
                    class="content-display"
                    [formControl]="commentControl"
                    [config]="ckConfig"
                    [editor]="Editor"
                    (ready)="onEditorReady($event)"

                    >
              </ckeditor>
              </form>
          </ng-container>

          <div class="comment__submit" (click)="onConfirm()">
            <img src="/assets/images/global/send-icon.png">
          </div>
        </div>
    </div>
    <!-- <div class="comment__buttons">
        <button  class="comment__buttons-save" (click)="onSaveEmitter.emit(true)">Save</button>
        <button *ngIf="isReply == true" class="comment__buttons-cancel"(click)="onCancelReply()">Cancel</button>
    </div> -->

</div>
