<div class="header-wrapper" [ngClass]="{'hide-search-part header-wrapper-pt': wellbeingHeader}">

  <!-- <header [ngClass]="{'header-transparent': headerTransparent, 'header-gap':wellbeingHeader}" class="header" #header>
    <app-search-header style="flex-basis: 100%;"></app-search-header> -->
  <!-- <div [ngClass]="{'hide-header-part': wellbeingHeader}" class="header__date"> -->
  <!-- <div class="header__date">
      {{currentDate | date:'EEEE, MMMM d'}}
    </div>
    <div class="header__logo-box">
        <div class="header__logo-image">
          <button *ngIf="!showTooltip" class="header__logo-image__button" type="button"
          (click)="toggleHeaderModal(templateHeader)"
          >
            <img class="bounce" src="../../../../assets/images/global/logo-icon.png" alt="logo">
          </button>
          <button  class="header__logo-image__button" type="button"
          (click)="toggleHeaderModal(templateHeader)"
          [tooltip]="headerTooltipTemplate"
          containerClass="headerTooltip"
          #pop="bs-tooltip"
          container="body"
          [adaptivePosition]="false"
          triggers=""
          placement="bottom"
          >
            <img *ngIf="showTooltip" class="bounce" src="../../../../assets/images/global/logo-icon.png" alt="logo">

          </button>
          <button  #showHeaderTooltipTemplate (click)="pop.show()">  </button>
          <ng-template  class="headerTooltipTemplate" #headerTooltipTemplate>
            <div>Click for the menu</div>
            <div ><button (click)="hideTooltip()"><img src="../../../../assets/images/global/icon-system-cross-medium-gray.png" ></button></div>
          </ng-template>
        </div>

    </div> 

  </header>-->

  <header class="header" [ngClass]="{'header-transparent': headerTransparent, 'header-gap':wellbeingHeader}" #header>

    <div class="search-box">
      <img src="assets/makeover/header/playtispace-logo.svg" alt="logo">
      <app-search-header></app-search-header>
    </div>

    <div class="nav-buttons">
      <div class="nav-item" [routerLink]="['/home']" routerLinkActive="nav-item--selected">
        <img [src]="currentRoute == 'home' ? '/assets/makeover/header/home.svg': '/assets/makeover/header/home2.svg'"
          alt="playtika">
        <div class="header-tooltip">
          <span>Home</span>
        </div>
      </div>
      <div class="nav-item" [routerLink]="['/apps']" routerLinkActive="nav-item--selected">
        <img [src]="currentRoute == 'apps' ? '/assets/makeover/header/apps.svg': '/assets/makeover/header/apps2.svg'"
          alt="playtika">
        <div class="header-tooltip">
          <span>apps</span>
        </div>
      </div>
      <div class="nav-item" [routerLink]="['/articles']" routerLinkActive="nav-item--selected">
        <img
          [src]="currentRoute == 'articles' ? '/assets/makeover/header/articles.svg': '/assets/makeover/header/articles2.svg'"
          alt="playtika">
        <div class="header-tooltip">
          <span>articles</span>
        </div>
      </div>
      <div class="nav-item" [routerLink]="['/calendar']" routerLinkActive="nav-item--selected">
        <img
          [src]="currentRoute == 'calendar' ? '/assets/makeover/header/calendar.svg': '/assets/makeover/header/calendar2.svg'"
          alt="playtika">
        <div class="header-tooltip">
          <span>calendar</span>
        </div>
      </div>
      <div class="nav-item" [routerLink]="['/notifications']" routerLinkActive="nav-item--selected">
        <img
          [src]="currentRoute == 'notifications' ? '/assets/makeover/header/notifi.svg': '/assets/makeover/header/notifi2.svg'"
          alt="playtika">
          <div class="red-circle" *ngIf="currentRoute=='/notifications' && notificationsCountToSee > 0">
            {{notificationsCountToSee}}</div>
        <div class="header-tooltip">
          <span>notifications</span>

        </div>
      </div>
    </div>

    <div class="time-and-logo">
      <div class="header__date">
        {{currentDate | date:'EEEE, MMMM d'}}
      </div>
      <img class="" src="/assets/images/global/logo-icon.png" alt="playtika-logo">
    </div>

  </header>



</div>

<!-- <ng-template #templateHeader>
  <app-header-modal (closeModalEmiter)="closeHeaderModal()"></app-header-modal>
</ng-template> -->
<app-mobile-menu [mainMenu]="mainMenu"></app-mobile-menu>