import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CalendarApiFacade } from 'src/app/Facade/ApiFacade/calendar.facade';
import { WellbeingFacade } from 'src/app/Facade/ApiFacade/wellbeing.facade';
import { IWellbeingEventItem } from 'src/app/Shared/Models/wellbeing.interface';
import { PreloaderService } from 'src/app/Shared/Services/preloader.service';
import { UtitlityService } from 'src/app/Shared/Services/utilitiy.service';

@Component({
  selector: 'app-calendar-event-item',
  templateUrl: './calendar-event-item.component.html',
  styleUrls: ['./calendar-event-item.component.scss'],
})
export class CalendarEventItemComponent implements OnInit {
  @Input() event: any;
  @Input() isEditMode: boolean = false;
  @Input() isPersonalCalendarEvent: boolean = false;
  @ViewChild('modalConfirmToDuplicateEvent')
  modalConfirmToDuplicateEvent: TemplateRef<any>;
  @ViewChild('modalSuccessAddedEvent') modalSuccessAddedEvent: TemplateRef<any>;
  @ViewChild('modalError') modalError: TemplateRef<any>;
  @Output() onRefreshCalendarData = new EventEmitter();
  @Output() onEventEditPress = new EventEmitter<any>();
  addToEventCalendarDisabled = false;
  modalRef?: BsModalRef;
  isSuccess: boolean;
  textTitle: string;
  constructor(
    private utitlityService: UtitlityService,
    private preloaderService: PreloaderService,
    private wellbeingFacade: WellbeingFacade,
    private modalService: BsModalService,
    private calendarFacade: CalendarApiFacade
  ) {}

  ngOnInit(): void {}

  isHebrew(text) {
    return this.utitlityService.isHebrew(text);
  }

  hasImage(title) {
    return title != undefined ? title.includes('img src=') : false;
  }
  addToEventCalendar(event: any) {
    this.preloaderService.showPreloader();
    this.textTitle = event.title;
    if (this.hasImage(event.title)) {
      this.textTitle = event.title.split('img')[1].split('">')[1];
    }
    const datepipe: DatePipe = new DatePipe('en-US');
    var startDateTime = datepipe.transform(
      event.eventDateTime,
      'yyyy-MM-ddTHH:mm:ss'
    );
    var endDateTime = datepipe.transform(
      event.eventEndDate,
      'yyyy-MM-ddTHH:mm:ss'
    );

    if (startDateTime == null) {
      startDateTime = new Date(event.start).toISOString();
    }
    if (endDateTime == null) {
      endDateTime = new Date(event.end).toISOString();
    }

    const newEvent: IWellbeingEventItem = {
      id: event.id,
      eventName: this.textTitle,
      subTitle: '',
      cost: 0,
      costRange: '',
      discountPercentage: 0,
      currency: '',
      description: event.description,
      location: event.location,
      eventDay: event.start,
      eventDateTime: startDateTime,
      eventEndDate: endDateTime,
      image: '',
      imageMedium: '',
      pdfFileURL1: {
        url: '',
        description: '',
      },
      startDate: event.start,
      endDate: event.end,
      isFree: true,
      allDay: event.allDay,
      daysCount: event.daysCount,
      eventDetails: '',
      isImageOnly: false,
    };
    this.calendarFacade
      .createUserCalendarEvent(newEvent)
      .subscribe((result) => {
        this.preloaderService.hidePreloader();

        if (result && result.created == true) {
          this.openModalTemplate(this.modalSuccessAddedEvent);
          this.addToEventCalendarDisabled = true;
        }

        if (result && result.exists == true) {
          this.openModalTemplate(this.modalConfirmToDuplicateEvent);
        }

        if (result && result.error == true) {
          this.openModalTemplate(this.modalError);
          this.addToEventCalendarDisabled = false;
        }
      });
  }
  openModalTemplate(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
    });
    this.isSuccess = true;
  }
  closeModal() {
    this.isSuccess = false;
    this.onRefreshCalendarData.emit();
    this.modalRef?.hide();
  }
  isConfirmedToDuplicate(eventagree, event) {
    if (eventagree == false) {
      this.addToEventCalendarDisabled = true;
      this.modalRef?.hide();
    } else {
      this.modalRef?.hide();
      this.preloaderService.showPreloader();
      const datepipe: DatePipe = new DatePipe('en-US');
      var newDateTime = datepipe.transform(event.start, 'yyyy-MM-ddTHH:mm:ss');
      if (newDateTime == null) {
        newDateTime = new Date(event.start).toISOString();
      }
      this.textTitle = event.title;
      if (this.hasImage(event.title)) {
        this.textTitle = event.title.split('img')[1].split('">')[1];
      }
      const newEvent: IWellbeingEventItem = {
        id: event.id,
        eventName: this.textTitle,
        subTitle: '',
        cost: 0,
        costRange: '',
        currency: '',
        discountPercentage: 0,
        description: event.description,
        location: event.location,
        eventDay: event.start,
        eventDateTime: newDateTime,
        eventEndDate: '',
        image: '',
        imageMedium: '',
        pdfFileURL1: {
          url: '',
          description: '',
        },
        startDate: event.start,
        endDate: event.end,
        isFree: true,
        allDay: event.allDay,
        daysCount: event.daysCount,
        eventDetails: '',
        isImageOnly: false,
      };

      this.wellbeingFacade
        .createCalendarEvent(newEvent, true)
        .subscribe((result) => {
          this.preloaderService.hidePreloader();
          if (result && result.created == true) {
            this.addToEventCalendarDisabled = true;
            this.openModalTemplate(this.modalSuccessAddedEvent);
          }

          if (result && result.error == true) {
            this.addToEventCalendarDisabled = false;
            this.openModalTemplate(this.modalError);
          }
        });
    }
  }

  editEvent(eventId: any, isDelete: boolean, eventName?: string) {
    this.onEventEditPress?.emit({ id: Number(eventId), isDelete: isDelete, name: eventName ? eventName.replace(/<.*>/g, '').trim() : undefined });
  }

  areDateTimesSame(a: any, b: any) {
    return (new Date(a).getTime() - new Date(b).getTime()) == 0;
  }
}
