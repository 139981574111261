import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartPreloaderService {

  isLoading$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showPreloader(){
      this.isLoading$.next(true)
  }

  hidePreloader(){
      this.isLoading$.next(false)
  }
  
}
