<div *ngIf="userInfo" class="dashboard-user-info">
    <div class="dashboard-user-info__left">
        <a
            class="dashboard-user-info__left__photo"
            routerLink="/people/home/{{userInfo.userPrincipalName ? userInfo.userPrincipalName : ''}}"
        >
            <app-user-photo
                [userEmail]="userInfo.userPrincipalName"
                [showPresence]="true"
                [userPhotoSettings]="userPhotoSettings"
            ></app-user-photo>
        </a>
        <div class="dashboard-user-info__left__buttons">
            <a *ngIf="userInfo.userPrincipalName" href="{{'mailto:'+userInfo.userPrincipalName}}" target="_blank"><i class="icon--mail icon"></i></a>
            <a *ngIf="userInfo.userPrincipalName" href="https://teams.microsoft.com/l/chat/0/0?users={{userInfo.userPrincipalName}}" target="_blank"><i class="icon--teams icon"></i></a>
        </div>
    </div>
    <div class="dashboard-user-info__right">
        <div class="dashboard-user-info__right__title"><p>{{title}}</p></div>
        <a
            class="dashboard-user-info__right__name"
            title="{{userInfo.displayName}}"
            routerLink="/people/home/{{userInfo.userPrincipalName ? userInfo.userPrincipalName : ''}}"
        >{{userInfo.displayName}}</a>
        <div class="dashboard-user-info__right__profession" title="{{userInfo.jobTitle}}, {{userInfo.department}}">{{userInfo.jobTitle}}, {{userInfo.department}}</div>
        <div class="dashboard-user-info__right__contacts">
            <div *ngIf="userInfo.officeLocation" class="dashboard-user-info__contact">
                <i class="icon--marker icon icon--right"></i>
                <p>{{userInfo.officeLocation}}</p>
            </div>

        </div>
    </div>
</div>

<div *ngIf="!userInfo" class="dashboard-user-info">
  <div class="dashboard-user-info__left">

  </div>
  <div class="dashboard-user-info__right">
      <div class="dashboard-user-info__right__title"><p>{{title}}</p></div>

  </div>
</div>
