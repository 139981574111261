import { Injectable } from '@angular/core';
import { UserGraphApiService } from '../../Core/Services/GraphApiServices/user.graph-api.service';
import { catchError, delay, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  AsyncSubject,
  BehaviorSubject,
  forkJoin,
  from,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  IDirectReports,
  IEmployeeBirthdaysData,
  IMainPerson,
  IPerson,
  IUserInfo,
} from 'src/app/Shared/Models/user.model';
import { ISearchResultsType } from 'src/app/Shared/Models/search.interface';
import { SessionStorageService } from 'src/app/Shared/Services/session-storage.service';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { environment } from 'src/environments/environment';
import { PlaytikaSiteAdminService } from 'src/app/Shared/Services/playtika-site-admin.service';
import { GraphToolkitCachingService } from 'src/app/Shared/Services/toolkit-caching.service';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/Core/Services/ApiServices/api.service';
import { IMenuSidePopupBanner } from 'src/app/Shared/Models/side-menu-popup.interface';
import { FileService } from 'src/app/Shared/Services/file.service';
import { IWellbeingEventItem } from 'src/app/Shared/Models/wellbeing.interface';

@Injectable({
  providedIn: 'root',
})
export class UserGraphApiFacade {
  private listOfmanagers: any = [];
  user;

  userLocation?: string; 

  _userMostRecentPeopleBirthDay: IEmployeeBirthdaysData[] = [];
  userMostRecentPeopleBirthDay$: BehaviorSubject<IEmployeeBirthdaysData[]> =
    new BehaviorSubject<IEmployeeBirthdaysData[]>(
      this._userMostRecentPeopleBirthDay
    );

  _userMostRecentPeople: IPerson[] = [];
  userMostRecentPeople$: BehaviorSubject<IPerson[]> = new BehaviorSubject<
    IPerson[]
  >(this._userMostRecentPeople);

  _directReports: IDirectReports[] = [];
  directReports$: Subject<IDirectReports[]> = new Subject<IDirectReports[]>();

  constructor(
    private userApiSrv: UserGraphApiService,
    private userApiDBSrv: ApiService,
    private graphToolkitCachingService: GraphToolkitCachingService,
    private storage: SessionStorageService,
    private fileService: FileService,
    private playtikaSiteAdminService: PlaytikaSiteAdminService,
    private userProfileService: UserProfileService
  ) {
    this.user = this.storage.getData('profile');
    if (this.user == null) {
      this.getUserProfile().subscribe((userInfo: any) => {
        this.userProfileService.setUserProfile(userInfo);
        this.user = userInfo;
        setTimeout(() => {
          this.storage.saveData(userInfo, 'profile', true);
        }, 2500);
      });
    } else {
      this.userProfileService.setUserProfile(this.user);
    }

    userProfileService.userProfileSource$.subscribe((user) => {
      if (user && user.userPrincipalName) {
        this.getUserMostRecentPeople();
        this.getUserMostConnectedPeopleBirthdays(user.userPrincipalName);
        this.isMainSiteAdmin(user.userPrincipalName).subscribe(
          (isMainSiteAdmin) => {
            this.playtikaSiteAdminService.setMainSiteAdmin(isMainSiteAdmin);
          }
        );
        this.userLocation = this.user?.officeLocation;
      }
    });
  }

  public getUserProfile(): Observable<IUserInfo> {
    return this.userApiSrv.getUserProfile().pipe(
      map((userInfo) => {
        let datepipe = new DatePipe('en-US');
        if (userInfo.userPrincipalName != '' || undefined) {
          if (userInfo.extension_218efcb9f4dc475cacd2e04d3ca582ba_dateOfBirth) {
            userInfo.birthday = datepipe.transform(
              userInfo.extension_218efcb9f4dc475cacd2e04d3ca582ba_dateOfBirth,
              'MM-dd'
            );
          }
          this.getUserPhoto(String(userInfo.userPrincipalName)).subscribe(
            (userPhoto) => {
              userInfo.photo = userPhoto;
            },
            (err) => err
          );
        }
        return userInfo;
      }),
      catchError((err) => {
        return of({});
      }),
      delay(500)
    );
  }

  public getUserLocation(){
    return of(this.userLocation).pipe(
      switchMap(location => {
        if(location != undefined && location != ''){
          return of(location)
        }
        return this.getUserProfile().pipe(
          map(user => {
            let location:string = ""
            if (user && user.userPrincipalName) {
              location = String(user?.officeLocation);
            }
            return location;
          }),
          catchError(() => {return of('null')})
          )
      }))
  }

  createUserCalendarEvent(event: IWellbeingEventItem, isOneDay?:boolean) {
    return this.userApiSrv.createUserCalendarEvent(event,isOneDay).pipe(
      catchError((err) => {
        return of(false);
      })
    );
  }

  updateUserCalendarEvent(enventId, event: IWellbeingEventItem, isOneDay?:boolean) {
    return this.userApiSrv.updateUserCalendarEvent(enventId, event, isOneDay)
  }

  getUserBirthDayBanner() {
    var response: Observable<any> = of(false);
    let datepipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(), 'MM-dd');
    let storedBirthDayBanner = JSON.parse(
      String(localStorage.getItem('BirthDayBanner'))
    );
    let birthDayBanner;

    if (storedBirthDayBanner) {
      birthDayBanner = storedBirthDayBanner.birthDayBanner;
    }

    if (
      storedBirthDayBanner == null ||
      (storedBirthDayBanner && storedBirthDayBanner.cachedAt != todayDate)
    ) {
      response = this.userApiDBSrv.getUserBirthday().pipe(
        switchMap((user: any) => {
          var responseBanner: Observable<any> = of(false);
          let birthday = user?.dateOfBirth;
          if (birthday && datepipe.transform(birthday, 'MM-dd') == todayDate) {
            responseBanner = this.getBirthDayBanner().pipe(
              tap((b) => {
                let obj: any = {
                  birthDayBanner: b,
                  cachedAt: todayDate,
                };
                localStorage.setItem('BirthDayBanner', JSON.stringify(obj));
              })
            );
          }
          return responseBanner;
        })
      );
    } else if (storedBirthDayBanner) {
      response = of(birthDayBanner);
    }

    return response;
  }

  public getUserMemberOf(): Observable<any> {
    return this.userApiSrv.getUserMemberOf().pipe(
      catchError((error) => {
        return of([]);
      })
    );
  }

  public getUserMemberOfCount(): Observable<number> {
    return this.userApiSrv.getUserMemberOfCount().pipe(
      map((result) => {
        return result['@odata.count'];
      }),
      catchError((error) => {
        return of(0);
      })
    );
  }

  public getUserManagers(userEmail: string): Observable<IUserInfo[]> {
    this.listOfmanagers = [];
    return this.userApiDBSrv.getManagers(userEmail).pipe(
      map((managers) => {
        return managers.reverse();
      }),
      catchError((e) => {
        let managers: IUserInfo[] = [];
        return of(managers);
      })
    );
  }

  public getUserManager(userEmail: string): Observable<IUserInfo> {
    return this.userApiDBSrv.getManager(userEmail).pipe(
      map((userInfo) => {
        return userInfo;
      }),
      catchError((e) => {
        let manager: IUserInfo = {};
        return of(manager);
      })
    );
  }

  public getPersonByEmail(userEmail: string): Observable<IUserInfo> {
    return this.graphToolkitCachingService.getPersonByEmail(userEmail).pipe(
      catchError((e) => {
        let person: IUserInfo = {};
        return of(person);
      })
    );
  }

  public getAllDirectReports(emails: string[], timeout) {
    let directReports: IDirectReports[] = [];
    let newEmails: any[] = [];

    emails.forEach((element) => {
      let directReport = this.getDirectReportsData(element);

      if (directReport) {
        let directRepor: IDirectReports = {
          userEmail: element,
          directReports: directReport,
        };
        directReports.push(directRepor);
      } else {
        newEmails.push(element);
      }
    });

    if (newEmails.length > 0) {
      this.userApiDBSrv.getAllDirectReports(newEmails).subscribe((result) => {
        if (result != null) {
          directReports.push(...result);
          setTimeout(() => {
            this.directReports$.next(directReports);
          }, timeout);

          result.forEach((element) => {
            this.saveDirectReportsData(
              element.directReports,
              element.userEmail
            );
          });
        } else {
          setTimeout(() => {
            this.directReports$.next(directReports);
          }, timeout);
        }
      });
    } else {
      setTimeout(() => {
        this.directReports$.next(directReports);
      }, timeout);
    }
  }

  public getUserDirectReports(userEmail: string): Observable<IUserInfo[]> {
    let directReport = this.getDirectReportsData(userEmail);

    if (directReport) {
      return of(directReport);
    } else {
      return this.userApiDBSrv.getDirectReports(userEmail).pipe(
        map((data) => {
          let ret_result = data.filter((r) => r.accountEnabled == true);
          setTimeout(() => {
            let checkDirectReport = this.getDirectReportsData(userEmail);
            if (!checkDirectReport) {
              this.saveDirectReportsData(ret_result, userEmail);
            }
          }, 2500);
          return ret_result;
        }),
        catchError((e) => {
          let people: IUserInfo[] = [];
          return of(people);
        })
      );
    }
  }

  public getCachedMostRecentPeople(userEmail: string): Observable<IPerson[]> {
    return this.userApiDBSrv.getMostRecentPeople(userEmail).pipe(
      switchMap((resul) => {
        if (resul != null) {
          return of(resul);
        }

        return this.getMostRecentPeople(userEmail).pipe(
          tap((result) => {
            if (userEmail != this.user.userPrincipalName) {
              this.userApiDBSrv.addMostConnectedPeople(result, userEmail);
            }
          })
        );
      })
    );
  }

  private getMostRecentPeople(userEmail: string): Observable<IPerson[]> {
    return this.userApiSrv.getMostRecentPeople(userEmail, 100).pipe(
      switchMap((data) => this.filterActivePeople(data.value)),
      catchError((e) => {
        let people: IPerson[] = [];
        return of(people);
      })
    );
  }

  filterActivePeople(people) {
    let emails: any[] = [
      ...new Map(
        people.map((item) => [
          item['userPrincipalName'],
          "'" + item.userPrincipalName + "'",
        ])
      ).values(),
    ];
    let new_array_emails = this.splitArray(
      emails,
      (emails.length / 8).toFixed(),
      true
    );
    return this.userApiSrv
      .getBatchRequest(this.userApiSrv.constructBatchRequest(new_array_emails))
      .pipe(
        map((result: any) => {
          result.responses.sort((a, b) => a.id - b.id);

          let ret_result: any = { value: [] };
          result.responses.forEach((element) => {
            if (element.status == 200) {
              ret_result.value.push(...element.body.value);
            }
          });

          let userMostRecentPeople = [
            ...people.filter((person) => {
              let notActive = ret_result.value.find(
                (p) => person.userPrincipalName == p.userPrincipalName
              );
              return (
                notActive != undefined &&
                person.userPrincipalName != this.user.userPrincipalName
              );
            }),
          ];

          return userMostRecentPeople;
        })
      );
  }

  savePeopleHomeData(mainPerson: IMainPerson) {
    let datepipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(), 'MM-dd');
    let persons: any = JSON.parse(
      String(localStorage.getItem('peopleHomeData'))
    );

    if (persons && persons.cachedAt == todayDate) {
      let person = persons.persons.find(
        (p) =>
          p.person.userPrincipalName == mainPerson.person?.userPrincipalName
      );
      if (person == undefined) {
        persons.persons.push(mainPerson);
        localStorage.setItem('peopleHomeData', JSON.stringify(persons));
      }
    } else {
      let obj: any = {
        persons: [],
        cachedAt: todayDate,
      };
      obj.persons.push(mainPerson);
      localStorage.setItem('peopleHomeData', JSON.stringify(obj));
    }
  }

  getPeopleHomeData(userEmail: string) {
    let datepipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(), 'MM-dd');

    let persons: any = JSON.parse(
      String(localStorage.getItem('peopleHomeData'))
    );

    if (persons && persons.cachedAt == todayDate) {
      let person = persons.persons.find(
        (p) => p.person.userPrincipalName == userEmail
      );
      if (person != undefined) {
        return person;
      }
    }

    return null;
  }

  savePeopleOrganizationData(organizationChartData: any) {
    let datepipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(), 'MM-dd');
    let organizationCharts: any = JSON.parse(
      String(localStorage.getItem('peopleOrganizationData'))
    );

    if (organizationCharts && organizationCharts.cachedAt == todayDate) {
      let organizationChart = organizationCharts.organizationCharts.find(
        (p) =>
          p.top.userPrincipalName == organizationChartData.top.userPrincipalName
      );
      if (organizationChart == undefined) {
        organizationCharts.organizationCharts.push(organizationChartData);
        localStorage.setItem(
          'peopleOrganizationData',
          JSON.stringify(organizationCharts)
        );
      }
    } else {
      let obj: any = {
        organizationCharts: [],
        cachedAt: todayDate,
      };
      obj.organizationCharts.push(organizationChartData);
      localStorage.setItem('peopleOrganizationData', JSON.stringify(obj));
    }
  }

  getPeopleOrganizationData(userEmail: string) {
    let datepipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(), 'MM-dd');

    let organizationCharts: any = JSON.parse(
      String(localStorage.getItem('peopleOrganizationData'))
    );

    if (organizationCharts && organizationCharts.cachedAt == todayDate) {
      let organizationChart = organizationCharts.organizationCharts.find(
        (p) => p.top.userPrincipalName == userEmail
      );
      if (organizationChart != undefined) {
        return organizationChart;
      }
    }

    return null;
  }

  private saveDirectReportsData(directReportsData: any, userEmail) {
    let datepipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(), 'MM-dd');
    let directReports: any = JSON.parse(
      String(localStorage.getItem('directReportsData'))
    );

    if (directReports && directReports.cachedAt == todayDate) {
      let directReport = directReports.directReports.find(
        (p) => p.userEmail == userEmail
      );
      if (directReport == undefined) {
        let obj: any = {
          userEmail: userEmail,
          directReport: directReportsData,
        };
        directReports.directReports.push(obj);
        localStorage.setItem(
          'directReportsData',
          JSON.stringify(directReports)
        );
      }
    } else {
      let obj: any = {
        directReports: [],
        cachedAt: todayDate,
      };

      let obj1: any = {
        userEmail: userEmail,
        directReport: directReportsData,
      };
      obj.directReports.push(obj1);
      localStorage.setItem('directReportsData', JSON.stringify(obj));
    }
  }

  private getDirectReportsData(userEmail: string) {
    let datepipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(), 'MM-dd');

    let directReports: any = JSON.parse(
      String(localStorage.getItem('directReportsData'))
    );

    if (directReports && directReports.cachedAt == todayDate) {
      let directReport = directReports.directReports.find(
        (p) => p.userEmail == userEmail
      );
      if (directReport != undefined) {
        return directReport.directReport;
      }
    }

    return null;
  }

  public getUserMostConnectedPeopleBirthdays(userEmail: string) {
    const datepipe: DatePipe = new DatePipe('en-US');
    let todayDate = datepipe.transform(Date.now(), 'MM-dd');

    let userMostRecentPeopleBirthdays: any = JSON.parse(
      String(localStorage.getItem('userMostRecentPeopleBirthdays'))
    );

    if (
      userMostRecentPeopleBirthdays &&
      userMostRecentPeopleBirthdays.cachedAt == todayDate
    ) {
      this.userMostRecentPeopleBirthDay$.next(
        userMostRecentPeopleBirthdays.userMostRecentPeopleBirthdays
      );
    } else {
      this.getMostRecentPeopleBirthDay(userEmail)
        .pipe(
          map((b) => {
            let obj: any = {
              userMostRecentPeopleBirthdays: b,
              cachedAt: todayDate,
            };
            this.userMostRecentPeopleBirthDay$.next(b);
            localStorage.setItem(
              'userMostRecentPeopleBirthdays',
              JSON.stringify(obj)
            );
          })
        )
        .subscribe();
    }
  }

  private getMostRecentPeopleBirthDay(userEmail: string) {
    return this.userApiSrv.getMostRecentPeople(userEmail, 100).pipe(
      switchMap((data) => this.filterActivePeople(data.value)),
      switchMap((result) => {
        let employeeEmails: any[] = [
          ...new Map(
            result.map((item) => [
              item['userPrincipalName'],
              item.userPrincipalName.toLowerCase(),
            ])
          ).values(),
        ];

        if (employeeEmails == null) {
          employeeEmails = [];
        }

        localStorage.setItem('userMostRecentPeople', JSON.stringify(result));

        setTimeout(() => {
          this.userMostRecentPeople$.next(result.slice(0, 12));
        }, 0);

        return this.userApiDBSrv.GetMostConnectedBirthdays(employeeEmails);
      }),
      map((result) => {
        return result;
      })
    );
  }

  public getUserMostRecentPeople() {
    let data: any = localStorage.getItem('userMostRecentPeople');
    let userMostRecentPeople = JSON.parse(data);

    if (userMostRecentPeople) {
      this.userMostRecentPeople$.next(userMostRecentPeople.splice(0, 12));
    }
  }

  public loadMoreMostRecentPeople(
    requestString: string
  ): Observable<IPerson[]> {
    return this.userApiSrv.loadMoreMostRecentPeople(requestString).pipe(
      map((data) => {
        let mostRecentPeople: IPerson[] = this.mapPeople(data.value);
        return mostRecentPeople;
      }),
      catchError((e) => {
        let people: IPerson[] = [];
        return of(people);
      })
    );
  }

  public searchPeople(
    query: string,
    top?: number
  ): Observable<ISearchResultsType[]> {
    return this.userApiSrv.searchPeople(query, top).pipe(
      map((data: any) => {
        let searchResults: ISearchResultsType[] = [];
        let searchedPeople: IPerson[] = data.value;

        if (searchedPeople.length > 0) {
          let searchObj: ISearchResultsType = {
            items: searchedPeople.sort((a: any, b: any) =>
              a.displayName.localeCompare(b.displayName)
            ),
            total: searchedPeople.length,
            label: 'People',
          };

          searchResults.push(searchObj);
        } else {
          searchResults = [{ label: 'There are no people found', items: [] }];
        }

        return searchResults;
      })
    );
  }

  public theSearchPeopleComments(query, top) {
    return this.userApiSrv.searchPeople(query, top);
  }
  public getUserPhoto(userEmail: string) {
    return this.graphToolkitCachingService.getUserPhoto(userEmail);
  }

  public getUsersPresence(ids: string[], currentPerson: string) {
    return this.userApiSrv.getUsersPresence(ids).pipe(
      tap((data: any) => {
        let storedPresences = this.storage.getData('presences');
        if (storedPresences && storedPresences.currentPerson == currentPerson) {
          let currPresences = [...storedPresences.presences, ...data.value];
          storedPresences.presences = currPresences;
          this.storage.saveData(storedPresences, 'presences');
        } else {
          if (data.value) {
            let presences = {
              currentPerson: currentPerson,
              presences: data.value,
            };
            this.storage.saveData(presences, 'presences');
          }
        }
      })
    );
  }

  isMainSiteAdmin(userEmail: string): Observable<boolean> {
    return this.userApiSrv
      .isSiteAdmin(environment.SharePoint.ContentSiteId, userEmail)
      .pipe(
        map((result: any) => {
          let isSiteAdmin =
            result.value && result.value.length > 0
              ? result.value[0].fields.IsSiteAdmin
              : false;
          return isSiteAdmin;
        })
      );
  }

  public getUserPresence(userId: string) {
    return this.graphToolkitCachingService.getUserPresence(userId);
  }

  getBirthDayBanner(): Observable<IMenuSidePopupBanner> {
    return this.userApiSrv.getUserBirthDayBanner().pipe(
      map((result: any) => {
        let banner: IMenuSidePopupBanner = {
          image:
            result.value[0].fields.Image != undefined
              ? result.value[0].fields.Image.Url
              : undefined,
          location: 'top',
          link:
            result.value[0].fields.URL != undefined
              ? result.value[0].fields.URL.Url
              : undefined,
          isExternal:
            result.value[0].fields.IsExternalLink != undefined
              ? result.value[0].fields.IsExternalLink
              : true,
          isPdf: false,
        };

        this.setImageUrl(banner);
        return banner;
      })
    );
  }

  private createAListOfManagers(person: any) {
    if (person.manager) {
      this.listOfmanagers.push(person.manager);
      this.createAListOfManagers(person.manager);
    }

    return this.listOfmanagers;
  }

  private mapPeople(people: any, userEmail?: string) {
    let ret_people: IUserInfo[] = [];

    people.forEach((element: any) => {
      //let phoneNumber = element.phones.find((f:any) => f.type == 'mobile' || 'business');

      let recentUser: IUserInfo = {
        id: element.id,
        displayName: element.displayName,
        jobTitle: element.jobTitle,
        userPrincipalName: element.userPrincipalName,
        mobilePhone: element.mobilePhone,
        officeLocation: element.officeLocation,
        department: element.department,
        birthday: element.birthday,
        nextLink: people['@odata.nextLink'],
      };

      if (userEmail && userEmail == recentUser.userPrincipalName) {
      } else {
        ret_people.push(recentUser);
      }
    });
    return ret_people;
  }

  private setImageUrl(banner: IMenuSidePopupBanner) {
    if (banner.image && banner.image.includes(environment.SharePoint.SiteURL)) {
      let libraryName = banner.image.split('sites/')[1].split('/')[1];
      let pathArray = banner.image.split('sites/')[1].split('/');
      let filePath = '';

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService
              .getFileByPathInB64(
                environment.SharePoint.ContentSiteId,
                libraryName,
                filePath
              )
              .subscribe((result) => {
                if (result != '') {
                  banner.image = result;
                  this.storage.saveData(banner, 'BirthDayBanner', true);
                }
              });
          }
        }
      });
    }
  }

  private createPhotoFromBlob(image: Blob) {
    return this.blobToArrayBuffer(image).pipe(
      map((arrayBuffer) => {
        let photo =
          'data:image/jpeg;base64,' + this.arraybufferToBase64(arrayBuffer);
        return photo;
      })
    );
  }

  private arraybufferToBase64(arrayBuffer: any) {
    return btoa(
      new Uint8Array(arrayBuffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    );
  }

  private blobToArrayBuffer(blob: any) {
    var arrayBuffer = from(new Response(blob).arrayBuffer());
    return arrayBuffer;
  }

  private splitArray(a, n, balanced) {
    if (n < 2) return [a];

    var len = a.length,
      out: any[] = [],
      i = 0,
      size;

    if (len % n === 0) {
      size = Math.floor(len / n);
      while (i < len) {
        out.push(a.slice(i, (i += size)));
      }
    } else if (balanced) {
      while (i < len) {
        size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, (i += size)));
      }
    } else {
      n--;
      size = Math.floor(len / n);
      if (len % size === 0) size--;
      while (i < size * n) {
        out.push(a.slice(i, (i += size)));
      }
      out.push(a.slice(size * n));
    }

    return out;
  }
}
