import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PreloaderService } from '../../Services/preloader.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

  animation = '/assets/images/global/cubeAni.json'
  options: AnimationOptions;

  isLoading:boolean = false;
  subscription:Subscription

  constructor(private readonly service:PreloaderService) {
     this.subscription = this.service.isLoading$.subscribe(isLoading => {
      setTimeout(() => {
        this.isLoading = isLoading
      }, 0);
    });
   }

  ngOnInit(): void {
    let animationResources = [
      '/assets/images/global/ship.json' ,
      '/assets/images/global/cubeAni.json',
      '/assets/images/global/playground.json',
      '/assets/images/global/card.json'
    ]

    let path = animationResources[Math.floor(Math.random() * 4)]

    this.options = {
      path: path
    };
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  animationCreated(animationItem: AnimationItem): void {
  }
}
