import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardsApiFacade } from 'src/app/Facade/ApiFacade/dashboard.facade';
import { IOutlookCalendar } from 'src/app/Shared/Models/outlookCalendar.inteface';
import { TextWidthService } from 'src/app/Shared/Services/text-width.service';
import { SwiperComponent } from 'swiper/angular';
import { ICalendarEvent } from '../../../Shared/Models/sidebar-dashboard.model';
import { DashboardService } from '../../Services/DashboardService/DashboardService';

@Component({
  selector: 'app-sidebar-dashboard-calendar',
  templateUrl: './sidebar-dashboard-calendar.component.html',
  styleUrls: ['./sidebar-dashboard-calendar.component.scss']
})
export class SidebarDashboardCalendarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() calendarEvents: IOutlookCalendar[];

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'35px',
      'height':'35px',
    },
    size: 'smaller'
  }


  @ViewChild(SwiperComponent) swiper: SwiperComponent;
  @ViewChild('myDayCarousel', {read: ElementRef}) carouselElement: ElementRef<HTMLElement>;

  isNextDisabled: boolean = false;
  isPrevDisabled: boolean = true;
  areControlsDisabled: boolean = true;

  calendarEventWidth: number = 267;
  gap: number = 14;

  isDashboardOpening: boolean;
  dashboardOpeningSubscription: Subscription;

  constructor(private dashboardService: DashboardService) { }


  ngOnInit(): void {

    setTimeout(() => {
      this.swiper.swiperRef.on('sliderMove',  () => {
        this.onSlideChange();
      });
      this.swiper.swiperRef.on('slideChange',  () => {
        this.onSlideChange();
      });
    }, 100);

    this.dashboardOpeningSubscription = this.dashboardService.isOpening$.subscribe(isOpening => {
      if (!isOpening) this.checkControls();
    })
  }

  ngOnChanges() {
    this.checkControls()
  }

  ngOnDestroy(): void {
    this.dashboardOpeningSubscription.unsubscribe();
  }

  @HostListener('window:resize', [])
  checkControls() {
    var length = this.calendarEvents !== undefined ? this.calendarEvents.length : 0;

    if (this.carouselElement) {
      this.areControlsDisabled = this.isDashboardOpening || this.carouselElement.nativeElement.clientWidth > length * this.calendarEventWidth + (length - 1) * this.gap
    }
  }

  onSlideChange() {
    this.isPrevDisabled = this.swiper.swiperRef.isBeginning;
    this.isNextDisabled = this.swiper.swiperRef.isEnd;
  }

  swipePrev() {
    if (!this.isPrevDisabled) {
      this.swiper.swiperRef.slidePrev();
    }

  }
  swipeNext() {
    if (!this.isNextDisabled) {
      this.swiper.swiperRef.slideNext();
    }
  }
}
