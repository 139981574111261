import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PlaytikaSiteAdmin } from "../Models/wiki.models";

@Injectable({
    providedIn: 'root'
  })

export class PlaytikaSiteAdminService{

    siteAdmin:PlaytikaSiteAdmin = new PlaytikaSiteAdmin();
    private playtikaSiteAdmin = new BehaviorSubject<PlaytikaSiteAdmin>(this.siteAdmin);
    public playtikaSiteAdminSource$ = this.playtikaSiteAdmin.asObservable();

    constructor(){}

    setMainSiteAdmin(value:boolean){
        this.siteAdmin.isMainSiteAdmin = value;
        this.playtikaSiteAdmin.next(this.siteAdmin);
    }

    setWikiSiteAdmin(value:boolean){
        this.siteAdmin.isWikiSiteAdmin = value;
        this.playtikaSiteAdmin.next(this.siteAdmin);
    }

}