<ngx-emoj   
                (onemojipick)="handleEmoji($event)"
                [width]="'35vh'"
                [height]="'30vh'"
                [theme]="{
                martShowHeader: true,
                martShowFooter: false,
                martHeaderPadding: {x: '0', y: '0'},
                martFooterPadding: {x: '0', y: '0'},
                martHeaderFontSize: '14px',
                martHeaderBG: '#ffffff',
                martBG: '#f4f6f8',
                martCategoryColor: '#94a0a6',
                martCategoryColorActive: '#455a64',
                martActiveCategoryIndicatorColor: '#ff874f',
                martEmojiFontSize: '150%',
                martCategoryFontSize: '20px',
                martBorderRadius: '5px',
                martActiveCategoryIndicatorHeight: '4px',
                martEmojiPadding: {x: '40px', y: '40px'}
            }"
>
</ngx-emoj>