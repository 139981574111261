import { DatePipe } from "@angular/common"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "src/environments/environment"

@Injectable({
    providedIn:"root"
})
export class OutlookCalendarGraphApiService {


    constructor(protected http: HttpClient){}

    getOutLookCalendarWithColors(viewDate){
        const fields = 'categories,subject,attendees,organizer,start,end,onlineMeeting,webLink,recurrence,type,changeKey, createdDateTime,isAllDay'

        const datepipe: DatePipe = new DatePipe('en-US')
        const currentViewMonth = datepipe.transform(viewDate,'yyyy-MM')+'-01'

        const filterStartDate = datepipe.transform(this.subDays(currentViewMonth,6),'yyyy-MM-dd')+'T00:00:00Z'
        const filterEndDate = datepipe.transform(this.addDays(new Date(currentViewMonth),37),'yyyy-MM-dd')+'T23:59:59Z'

        const body = {
            "requests": [
                {
                    "id": "1",
                    "method": "GET",
                    "url": "/me/outlook/masterCategories"
                },
                {
                    "id": "2",
                    "method": "GET",
                    "url": `/me/calendar/calendarView/delta?startDateTime=${filterStartDate}&endDateTime=${filterEndDate}&$select=${fields}`
                }

            ]
        }
        return this.http.post(`${environment.GraphAPIURL}$batch`, body);
    }

    getCalendarEvents(){
        const datepipe: DatePipe = new DatePipe('en-US')
        var timezone = datepipe.transform(Date.now(), 'ZZZ')
        const filterStartDate = this.toUTC(datepipe.transform(Date.now(),'yyyy-MM-dd')+`T00:00:00${timezone}`)
        const filterEndDate = this.toUTC(datepipe.transform(Date.now(),'yyyy-MM-dd')+`T23:59:59${timezone}`)
        const fields = 'categories,subject,attendees,organizer,start,end,onlineMeeting,webLink,recurrence,type,changeKey, createdDateTime,isAllDay'
        return this.http.get(`${environment.GraphAPIURL}${environment.API.User.Profile}/calendarView/delta?startDateTime=${filterStartDate}&endDateTime=${filterEndDate}&$select=${fields}`)
    }

    getMasterCategories(){
        return this.http.get(`${environment.GraphAPIURL}${environment.API.User.Profile}/outlook/masterCategories`)
    }

    private addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    private subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    //yyyy-MM-ddTHH:mm:ssZZZ -> yyyy-MM-ddTHH:mm:ssZ
    private toUTC(isoStringWithTimezone: string) {
        const datepipe: DatePipe = new DatePipe('en-US')
        return datepipe.transform(isoStringWithTimezone, 'yyyy-MM-ddTHH:mm:ss', 'UTC')+'Z'
    }
}
