import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';
import { IUserProfileData } from '../Models/user.model';
import { UserProfileService } from './user-profile.service';
import { EnGaEventType, GaEvent } from '../Models/google-analytics.interface';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagService {
  userData: any = {};
  userProfile:any
  eventType = EnGaEventType;
  subscription$: Subscription;
  constructor(private userProfileService: UserProfileService) {
    this.userProfileService.userProfileSource$.subscribe((user) => {
      if (user && user.userPrincipalName) {
        this.userData = user;
        this.userProfile = this.buildUserProfileJson(user);
      }
    })
  }
  buildUserProfileJson(user: IUserProfileData) {
    return {
      fullName : user.firstName + user.lastName!
    }
  }

  ngOnInit(): void {
    
  }

  sendGAEvent(eventName: string, eventDetails: any): void {
    var _window: any = window;
    _window.gtag('event', eventName, eventDetails);
  }

  sendGaEventWithUserProfile(gaEvent: GaEvent) {
    var _window: any = window;
    let eventDetails = gaEvent.eventDetails;
    _window.gtag('event', gaEvent.eventName, {
      ...eventDetails,
      userFullName: this.userData.displayName,
      userOfficeLocation: this.userData.officeLocation
    });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
