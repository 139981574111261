import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-vods-comments-mobile',
  templateUrl: './vods-comments-mobile.component.html',
  styleUrls: ['./vods-comments-mobile.component.scss']
})
export class VodsCommentsMobileComponent implements OnInit {

  @Input() vodId
  @Output() closeModalEmitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  closeModal() {
    this.closeModalEmitter.emit()
  }
}
