import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { IDashboardShortcut } from 'src/app/Shared/Models/sidebar-dashboard.model';
import { EventsParams, SwiperComponent } from 'swiper/angular';
import { DashboardService } from '../../Services/DashboardService/DashboardService';
import { GoogleTagService } from 'src/app/Shared/Services/google-tag.service';
import { EnGaEventType } from 'src/app/Shared/Models/google-analytics.interface';

@Component({
  selector: 'app-dashboard-shortcuts',
  templateUrl: './dashboard-shortcuts.component.html',
  styleUrls: ['./dashboard-shortcuts.component.scss']
})
export class DashboardShortcutsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() shortcuts: IDashboardShortcut[]
  isOneWord: boolean[] = [];
  @Input() isDarkMode: boolean;

  @ViewChild(SwiperComponent) swiper: SwiperComponent;
  @ViewChild('shortcutsCarousel', { read: ElementRef }) carouselElement: ElementRef<HTMLElement>;

  isNextDisabled: boolean = false;
  isPrevDisabled: boolean = true;
  areControlsDisabled: boolean = true;

  shortcutWidth: number = 115;
  gap: number = 9;

  isDashboardOpening: boolean;
  dashboardOpeningSubscription: Subscription;


  constructor(private dashboardService: DashboardService, private gaService: GoogleTagService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.swiper.swiperRef.on('sliderMove', () => {
        this.onSlideChange();
      });
      this.swiper.swiperRef.on('slideChange', () => {
        this.onSlideChange();
      });

      this.checkControls()
      // console.log(this.swiper.activeSlides)
    }, 100);

    for (var i = 0; i < this.shortcuts.length; i++) {
      this.isOneWord.push(this.shortcuts[i].title.split(' ').length == 1);
    }

    this.dashboardOpeningSubscription = this.dashboardService.isOpening$.subscribe(isOpening => {
      if (!isOpening) this.checkControls();
    })
  }

  ngOnChanges() {
    this.checkControls()
  }

  ngOnDestroy(): void {
    this.dashboardOpeningSubscription.unsubscribe();
  }

  @HostListener('window:resize', [])
  checkControls() {
    var length = this.shortcuts !== undefined ? this.shortcuts.length : 0;
    if (this.carouselElement) {
      this.areControlsDisabled = this.carouselElement.nativeElement.clientWidth > length * this.shortcutWidth + (length - 1) * this.gap
    }
  }

  onSlideChange() {
    this.isPrevDisabled = this.swiper.swiperRef.isBeginning;
    this.isNextDisabled = this.swiper.swiperRef.isEnd;
  }

  swipePrev() {
    if (!this.isPrevDisabled) {
      this.swiper.swiperRef.slidePrev();
    }

  }
  swipeNext() {
    if (!this.isNextDisabled) {
      this.swiper.swiperRef.slideNext();
    }
  }

  swiperLinkClicked(shortcut: IDashboardShortcut) {
    if (this.shortcuts.findIndex(s => s == shortcut) != -1) {
      this.gaService.sendGaEventWithUserProfile({ eventName: `${shortcut.title.toUpperCase()}_Click`, eventType: EnGaEventType.Click, eventDetails: {} })
    }
  }
}
