import { Injectable } from '@angular/core';
import { debounce } from 'lodash';
import { BehaviorSubject, Subject,Observable } from 'rxjs';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';
import * as ckCusomEditor from '../../ckCustomEditor/build/ckeditor'


const items = [
  { id: '@swarley', userId: '1', name: 'Barney Stinson', link: 'https://www.imdb.com/title/tt0460649/characters/nm0000439' },
  { id: '@lilypad', userId: '2', name: 'Lily Aldrin', link: 'https://www.imdb.com/title/tt0460649/characters/nm0004989' },
  { id: '@marry', userId: '3', name: 'Marry Ann Lewis', link: 'https://www.imdb.com/title/tt0460649/characters/nm1130627' },
  { id: '@marshmallow', userId: '4', name: 'Marshall Eriksen', link: 'https://www.imdb.com/title/tt0460649/characters/nm0781981' },
  { id: '@rsparkles', userId: '5', name: 'Robin Scherbatsky', link: 'https://www.imdb.com/title/tt0460649/characters/nm1130627' },
  { id: '@tdog', userId: '6', name: 'Ted Mosby', link: 'https://www.imdb.com/title/tt0460649/characters/nm1102140' }
];

@Injectable({
  providedIn: 'root'
})
export class CkEditorService {
  public mentionItems;

  dataSource = new Subject<any>()
  public mentionUsers:any[]=[];
  public Editor = ckCusomEditor;
  public ckConfig = {
    toolbar: {
      items: [],
      shouldNotGroupWhenFull: true
    },
    link: {
      addTargetToExternalLinks: true
    },
    mention: {
      feeds: [
          {
              marker: '@',
              commitKeys: [  32 ],
              feed:  this.getFeedItems1.bind(this),
              itemRenderer: this.customItemRenderer,
              minimumCharacters: 3
          }
      ]
    },
    language: 'en',
    placeholder: 'Write your comment',
    fillEmptyBlocks: false,
  };

  constructor(private graphFacade: UserGraphApiFacade ) {}



  getFeedItems1( queryText ) {
    // As an example of an asynchronous action, return a promise
    // that resolves after a 100ms timeout.
    // This can be a server request or any sort of delayed action.

    return new Promise( resolve => {

         this.graphFacade.theSearchPeopleComments(queryText, 5).subscribe(data =>{
        //this.dataSource.next(data)
        //this.mentionItems=data;
        let filterMentions = data.value.map((user,index) =>
        {
          return { id: '@'+user.displayName, userId: index+1, mention:'@'+user.userPrincipalName.split("@")[0] ,name: user.displayName, link: '/people/home/'+user.userPrincipalName }
        });

        const itemsToDisplay = filterMentions
        // Filter out the full list of all items to only those matching the query text.
        .filter( isItemMatching )
        // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
        .slice( 0, 10 );

        resolve( itemsToDisplay );


      });


    } );

    // Filtering function - it uses the `name` and `username` properties of an item to find a match.
    function isItemMatching( item ) {
        // Make the search case-insensitive.
        const searchString = queryText.toLowerCase();

        // Include an item in the search results if the name or username includes the current user input.
        return (
            item.name.toLowerCase().includes( searchString ) ||
            item.id.toLowerCase().includes( searchString )
        );
    }
  }


  customItemRenderer( item ) {
      const itemElement = document.createElement( 'span' );

      itemElement.classList.add( 'custom-item' );
      itemElement.id = `mention-list-item-id-${ item.userId }`;
      itemElement.textContent = `${ item.name } `;

      const usernameElement = document.createElement( 'span' );

      usernameElement.classList.add( 'custom-item-username' );
      usernameElement.textContent = item.id;

      itemElement.appendChild( usernameElement );

      return itemElement;
  }
  onClick(event){
    console.log(event)
  }

  getSkEditor(){
    return this.Editor
  }

  getSkEditorConfig(){
    return this.ckConfig
  }

  MentionCustomization( editor ) {
    // The upcast converter will convert <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' attribute.
    editor.conversion.for( 'upcast' ).elementToAttribute( {
        view: {
            name: 'a',
            key: 'data-mention',
            classes: 'mention',
            attributes: {
                href: true,
                'data-user-id': true
            }
        },
        model: {
            key: 'mention',
            value: viewItem => {
                // The mention feature expects that the mention attribute value
                // in the model is a plain object with a set of additional attributes.
                // In order to create a proper object, use the toMentionAttribute helper method:
                const mentionAttribute = editor.plugins.get( 'Mention' ).toMentionAttribute( viewItem, {
                    // Add any other properties that you need.
                    link: viewItem.getAttribute( 'href' ),
                    userId: viewItem.getAttribute( 'data-user-id' )
                } );

                return mentionAttribute;
            }
        },
        converterPriority: 'highest'
    } );

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for( 'downcast' ).attributeToElement( {
        model: 'mention',
        view: ( modelAttributeValue, { writer } ) => {
            // Do not convert empty attributes (lack of value means no mention).
            if ( !modelAttributeValue ) {
                return;
            }
            return writer.createAttributeElement( 'a', {
                class: 'mention mentionColour',
                'data-mention': modelAttributeValue.mention,
                'data-user-id': modelAttributeValue.userId,
                'href': modelAttributeValue.link,

            }, {
                // Make mention attribute to be wrapped by other attribute elements.
                priority: 20,
                // Prevent merging mentions together.
                id: modelAttributeValue.uid
            });

        },
        converterPriority: 'highest'
    } );
}
}
