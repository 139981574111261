import { Injectable } from '@angular/core';
import { FilterService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/Core/Services/ApiServices/api.service';
import { ISearchResultsType } from 'src/app/Shared/Models/search.interface';
import { environment } from 'src/environments/environment';
import { SearchGraphApiFacade } from '../GraphApiFacade/search-graph.facade';
import { WellbeingFacade } from './wellbeing.facade';

@Injectable({
  providedIn: 'root',
})
export class SearchApiFacade {
  allResultsCount: number;
  searchFromHeaderBar = false;
  topResultsFilter = '';
  allResults$: BehaviorSubject<any> = new BehaviorSubject<any>({
    query: '',
    results: [],
  });

  constructor(
    private apiSrv: ApiService,
    private searchGraphFacade: SearchGraphApiFacade,
    private wellbeingFacade: WellbeingFacade,
    private filterService: FilterService
  ) {}

  searchAll(query: string) {
    return this.wellbeingFacade
      .getUserLocation()
      .pipe(switchMap((result) => this.apiSrv.searchAll(query, result)));
  }

  setAllResults(searchQuery: string, results: ISearchResultsType[]) {
    this.allResults$.next({ query: searchQuery, results: results });
  }

  searchFilter(query: string, data: any[]) {
    this.allResultsCount = 0;
    let filteredGroups: any = [];
    data = data.filter((o) => o != null);

    //element.items = element.items.filter
    for (let optgroup of data) {
      //For results from ARTICLES SHAREPOINT
      if (optgroup.hasOwnProperty('@odata.context')) {
        if (
          optgroup.value[0].hitsContainers[0].hits &&
          optgroup.value[0].hitsContainers[0].hits[0].resource[
            '@odata.type'
          ].includes('listItem')
        ) {
          if (
            environment.SharePoint.ArticlesListId ==
            optgroup.value[0].hitsContainers[0].hits[0].resource.sharepointIds
              .listId
          ) {
            optgroup = this.searchGraphFacade.createDataFromSharepointSearch(
              optgroup.value[0].hitsContainers[0].hits
            );
          }
        } else if (
          optgroup.value[0].hitsContainers[0].hits &&
          optgroup.value[0].hitsContainers[0].hits[0].resource[
            '@odata.type'
          ].includes('driveItem')
        ) {
          if (
            environment.SharePoint.DocumentLibraryId ==
            optgroup.value[0].hitsContainers[0].hits[0].resource.parentReference
              .sharepointIds.listId
          ) {
            if (
              optgroup.value[0].hitsContainers[0].hits[0].resource.size == 0
            ) {
              optgroup =
                this.searchGraphFacade.createDataFromSharepointLibraryFolderSearch(
                  optgroup.value[0].hitsContainers[0].hits
                );
            } else {
              optgroup =
                this.searchGraphFacade.createDataFromSharepointLibraryFileSearch(
                  optgroup.value[0].hitsContainers[0].hits
                );
            }
          } else if (
            environment.SharePoint.PlaytikaVODLibraryId ==
            optgroup.value[0].hitsContainers[0].hits[0].resource.parentReference
              .sharepointIds.listId
          ) {
            optgroup =
              this.searchGraphFacade.createDataFromSharepointLibraryVideoSearch(
                optgroup.value[0].hitsContainers[0].hits
              );
          } else if (
            environment.SharePoint.PlaytikaGalleryId ==
            optgroup.value[0].hitsContainers[0].hits[0].resource.parentReference
              .sharepointIds.listId
          ) {
            optgroup =
              this.searchGraphFacade.createDataFromSharepointGallerySearch(
                optgroup.value[0].hitsContainers[0].hits
              );
          }
        }
      }

      let filteredSubOptions = this.filterService.filter(
        optgroup.items,
        [
          'label',
          'title',
          'eventName',
          'playtikaTitleOWSTEXT',
          'description',
          'name',
          'text',
          'taskApp.name',
          'displayName',
        ],
        query,
        'contains'
      );

      if (optgroup.label === 'Documents & Policies') {
        filteredSubOptions = optgroup.items;
      }
      if (filteredSubOptions && filteredSubOptions.length) {
        filteredSubOptions = filteredSubOptions.filter(
          (i) =>
            !(i.startDate && this.toDate(i.startDate).getTime() > Date.now()) &&
            !(
              i.createdDate && this.toDate(i.createdDate).getTime() > Date.now()
            )
        );

        this.allResultsCount = this.allResultsCount + filteredSubOptions.length;
        for (let option of filteredSubOptions) {
          option.type = optgroup.label;
        }

        filteredGroups.push({
          label: optgroup.label,
          items: filteredSubOptions,
          total: filteredSubOptions.length,
        });
      }
    }

    for (var option of filteredGroups) {
      this.updateGroupLabel(option);
      option.order = this.getGroupOrder(option.label);
    }

    filteredGroups = filteredGroups.sort((a, b) => a.order - b.order);

    return filteredGroups;
  }

  updateGroupLabel(group: any) {
    var label = group.label;
    switch (group.label) {
      case 'Department files':
        label = 'Documents & Policies';
        break;
      case 'Wellbeing':
        label = 'Well-Being Events';
        break;
      default:
        break;
    }

    group.label = label;
  }

  getGroupOrder(label: string): number {
    var result = 0;
    switch (label) {
      case 'Documents & Policies':
        result = 1;
        break;
      case 'Folders':
        result = 1;
        break;
      case 'Files':
        result = 1;
        break;
      case 'Articles':
        result = 3;
        break;
      case 'Apps':
        result = 4;
        break;
      case 'Well-Being Events':
        result = 5;
        break;
      case 'VOD':
        result = 6;
        break;
      case 'Gallery':
        result = 7;
        break;
      case 'People':
        result = 8;
        break;
      default:
        if (label.indexOf('Navigation Menu') > -1) result = 2;
        else result = 10000;
        break;
    }

    return result;
  }

  toDate(isoString: string): Date {
    return new Date(isoString);
  }
}
