import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AiToolsApiFacade } from 'src/app/Facade/ApiFacade/ai-tools.facade';
import { IAiToolComment, IAiToolDetails } from 'src/app/Shared/Models/ai-tools.interface';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { AiToolsCommentaryService } from './ai-tools-commentary.service';

@Component({
  selector: 'app-ai-tools-comments',
  templateUrl: './ai-tools-comments.component.html',
  styleUrls: ['./ai-tools-comments.component.scss']
})
export class AiToolsCommentsComponent implements OnInit {

  @Input() toolId;
  @Input() padding;
  @Input() isInsideOfModal = false;

  isEditing = false;
  isAnswer = false;
  isMobile = false;
  loadedAllComments = false;
  showAllcomments = false;
  showLessComments = false;
  loadMoreButtonClicked = false;
  addedComments = false;
  currentCommentId;
  currentCommentIdReply;
  currentCommentIdEdit;
  toolComments:IAiToolComment[];

  commentToDelete:IAiToolComment;
  commentToEdit:IAiToolComment;
  commentControl = new UntypedFormControl("");
  commentEditControl = new UntypedFormControl("");
  commentReplyControl = new UntypedFormControl("");
  userEmail:string;
  aiToolDetails:IAiToolDetails;
  modalRef?: BsModalRef;
  mobileModalRef?: BsModalRef;
  limitSlice = 2;
  datepipe: DatePipe = new DatePipe('en-US')
  today = this.datepipe.transform(Date.now(),'yyyy-MM-dd')?.split('T')[0]

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'64px',
      'height':'64px'
    },
    size: 'middle'
  }

  constructor(private aiToolsApiFacade:AiToolsApiFacade,
    private aiToolsCommentaryService:AiToolsCommentaryService,
    private modalService: BsModalService,
    private userProfileService:UserProfileService) { }

  ngOnInit(): void {
    this.aiToolsApiFacade.currentAiToolCommentsSource$.subscribe(source => {
      let comments = source.find(c => c.toolId == this.toolId)

      if(comments != undefined){
        this.toolComments = comments.comments.filter(comment=> comment.parentId == null);
        this.checkLoadMore(this.toolComments)
      }

      if(comments && comments.comments.length > 0 && this.loadMoreButtonClicked){
        this.limitSlice = this.toolComments.length;
        this.loadedAllComments = true;
        this.showLessComments =  true;
      }
    })

    this.aiToolsApiFacade.aiToolDetailsSource$.subscribe(source => {
      let details = source.find(s => s.toolId == Number(this.toolId))
      if(details != undefined){
        this.aiToolDetails = details;
        this.checkLoadMore(this.toolComments)
      }
    })

    this.userProfileService.userProfileSource$.subscribe(user => {
      this.userEmail = user.userPrincipalName;
    })

    this.aiToolsCommentaryService.commentarySource$.subscribe(state => {
      this.currentCommentIdReply = state.currentCommentIdReply;
      this.currentCommentIdEdit = state.currentCommentIdEdit;

      if(this.toolId != state.currentCommentLoadMore){
        this.onViewLessComments();
      }
    })

    if (this.isInsideOfModal) {
      this.getAiToolsComments();
    }

    this.onResize()
  }

  checkLoadMore(comments){
    let count=0;
    if(this.toolComments && this.aiToolDetails){

      for (let index = 0; index < comments.length; index++) {
        const element = comments[index];

        count+=element.childCommetsCount
        if(index == 1 || index == 0 && comments.length == 1){
          count+=comments.length;
          if(count == this.aiToolDetails.toolCommentsCount && this.toolComments.length <= 2){
            this.loadedAllComments = true;
          }else if(count < this.aiToolDetails.toolCommentsCount && !this.showLessComments){
            this.loadedAllComments = false;
          }
          break;
        }
      }

      if(this.toolComments.length <= 2 && this.showLessComments == true){
        this.showLessComments = false;
      }

      if(this.toolComments.length > 2 && !this.loadMoreButtonClicked){
        this.loadedAllComments = false;
      }

      if(this.toolComments.length == 0 && this.aiToolDetails.toolCommentsCount == 0){
        this.loadedAllComments = true;
      }
    }
  }

  getAiToolsComments(){
    this.aiToolsCommentaryService.setCurrentCommentaryLoadMore(this.toolId)
    if(this.toolComments.length <=2 || this.addedComments == true){
      this.aiToolsApiFacade.getAiToolComments(this.toolId);
    }else{
      this.limitSlice = this.toolComments.length;
      this.loadedAllComments = true;
      this.showLessComments =  true;
    }
    this.loadMoreButtonClicked = true;
  }

  onViewLess(){
    this.showAllcomments = false;
    this.currentCommentId = -1;
  }

  onViewLessComments(){
    this.limitSlice = 2;
    this.loadedAllComments = false;
    this.showLessComments =  false;
    this.loadMoreButtonClicked = false;
    this.addedComments = false;
    this.checkLoadMore(this.toolComments)
  }

  onViewAll(commentaryId){
    this.currentCommentId = commentaryId;
    this.showAllcomments = true;
    this.aiToolsApiFacade.getAiToolChildComments(commentaryId, this.toolId)
  }

  onSaveComment(){
    let commentText= this.commentControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comment ={
      "toolId": this.toolId,
      "parentId": null,
      "comment": commentText,
      "relatedUserEmail": this.userEmail
    }
    if(this.commentControl.value && this.commentControl.value.trim() != '' && this.commentControl.value != ''){
      this.aiToolsApiFacade.addAiToolComment(comment);
      this.commentControl = new UntypedFormControl("")
      this.addedComments = true;
    }
  }

  onConfirmReply(comme:IAiToolComment){
    let replyText= this.commentReplyControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comment ={
      "toolId": this.toolId,
      "parentId": comme.id,
      "topParentId": comme.id,
      "comment": replyText,
      "relatedUserEmail": comme.userEmail
    }

    if(this.commentReplyControl.value && this.commentReplyControl.value.trim() != '' && this.commentReplyControl.value != ''){
      this.aiToolsCommentaryService.setCurrentCommentaryReplyId(-0);
      this.aiToolsApiFacade.addAiToolComment(comment);
    }
  }

  onEditConfirm(){
    let editText= this.commentEditControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comme ={
      "commentId": this.commentToEdit.id,
      "comment": editText,
      "toolId": this.toolId
    }

    if(this.commentEditControl.value && this.commentEditControl.value.trim() != '' && this.commentEditControl.value != ''){
      this.aiToolsApiFacade.editAiToolComment(comme);
      this.aiToolsCommentaryService.setCurrentCommentaryEditId(-1);
    }

  }

  onEditStart(comment){
      this.commentToEdit = comment;
      this.commentEditControl = new UntypedFormControl(comment.comment);
      this.aiToolsCommentaryService.setCurrentCommentaryEditId(comment.id);
  }

  onReplyStart(comment){
    this.commentReplyControl = new UntypedFormControl("")
    this.aiToolsCommentaryService.setCurrentCommentaryReplyId(comment.id);
  }

  onEditCancel(){
    this.commentEditControl = new UntypedFormControl("");
    this.aiToolsCommentaryService.setCurrentCommentaryEditId(-1);
  }

  onDeleteComment(){
    this.aiToolsApiFacade.deleteComment(this.commentToDelete);
    this.modalRef?.hide();
  }

  AddCommentLike(comment: IAiToolComment){
    this.aiToolsApiFacade.addAiToolCommentLike(comment.id, comment.toolId, comment.userEmail).subscribe( result => {
      if(result == true){
        comment.isUserLike = true;
        comment.likesCount +=1;
      }
    })
  }

  RemoveCommentLike(comment: IAiToolComment){
    this.aiToolsApiFacade.removeAiToolCommentLike(comment.id).subscribe( result => {
      if(result == true){
        comment.isUserLike = false;
        comment.likesCount -=1;
      }
    })
  }

  openAlertModal(template: TemplateRef<any>, comment) {
    this.commentToDelete = comment;
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  openAllCommentsModal(template: TemplateRef<any>) {
    if (!this.isInsideOfModal) {
      this.mobileModalRef = this.modalService.show(template,{ignoreBackdropClick:true, animated:false});
    }
  }

  closeModal() {
    if (!this.isInsideOfModal) {
      this.mobileModalRef?.hide();
    }
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }
}
