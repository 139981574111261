<div class="sidebar-container"
  [ngClass]="{'dashboard-opened': isDashboardOpen || isDashboardChangingState, 'red': !isDarkMode, 'gray': isDarkMode}">
  <div class="sidebar-content-container">

    <div class="sidebar-menu">

      <app-global-profile [banner]="mainMenuFooterBanner" [sideItemsCategories]="sideItemsCategories"
        [displaySelfyModule]="displaySelfyModule" (closeModalEmiter)="closeHeaderModalEmitter()"
        (openModalEmiter)="onDashboardOpenClicked()"></app-global-profile>
      <div class="sidebar-footer">
        <div class="made-withlove">
          Made with <svg viewBox="0 0 1792 1792" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
            style="height: 0.8rem;">
            <path
              d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
              fill="#000"></path>
          </svg> by MIS
        </div>
      </div>
    </div>
    <div class="sidebar-dashboard" [@parentSlide]="{ value: isDashboardOpen, params: { sidebarDashboardWidth } }"
      (@parentSlide.start)="onDashboardStateChanging($event)" (@parentSlide.done)="onDashboardStateChanging($event)">
      <app-global-profile-dashboard [calendarEvents]="outlookCalendar" [sidebarDashboardWidth]="sidebarDashboardWidth"
        (close)="onDashboardCloseClicked()" (onDashboardModeSwitched)="onDashboardModeSwitched($event)"
        (onRefreshCalendarDataEmmiter)="refreshCalendar()"></app-global-profile-dashboard>
    </div>
  </div>
</div>