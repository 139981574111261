import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionStateService {
  private taskItemsCountStatusSource = new BehaviorSubject<number>(0);
  currentTaskItemsCountStatus$ = this.taskItemsCountStatusSource.asObservable();

  constructor() { }

  changeTaskItemsCountStatus(status: number) {
    this.taskItemsCountStatusSource.next(status)
  }
}
