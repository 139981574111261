import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/Core/Services/ApiServices/api.service';
import { SearchGraphApiService } from 'src/app/Core/Services/GraphApiServices/search.graph-api.service';
import {
  ISearchInOnedriveItem,
  ISearchInSharepointItems,
  ISharepointApiSearchFilter,
} from 'src/app/Shared/Models/search.interface';
import { ISideItemCategory } from 'src/app/Shared/Models/sideItem.interface';
import { IWikiSiteList } from 'src/app/Shared/Models/wiki.models';
import { FileService } from 'src/app/Shared/Services/file.service';
import { MemberOfService } from 'src/app/Shared/Services/member-of.service';
import { SessionStorageService } from 'src/app/Shared/Services/session-storage.service';
import { environment } from 'src/environments/environment';
import { UserApiFacade } from '../ApiFacade/user.facade';
import { SideItemsGraphFacade } from './sideItems-graph.facade';
import { IUserProfileData } from 'src/app/Shared/Models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SearchGraphApiFacade {
  user: IUserProfileData;
  constructor(
    private searchGraphApiSrv: SearchGraphApiService,
    private fileService: FileService,
    private sideItemsGraphFacade: SideItemsGraphFacade,
    private memberOfService: MemberOfService,
    private storage: SessionStorageService,
    private apiService: ApiService,
    private userFacade: UserApiFacade
  ) {
    this.getWikiSiteLists();
    userFacade.getUserProfile().subscribe((user) => (this.user = user));
  }

  searchInSharepoint(searchText: string) {
    return this.searchGraphApiSrv.searchInSharepoint(searchText);
  }

  searchInSharepointSideItems(searchText: string) {
    return forkJoin(
      this.sideItemsGraphFacade.getAllSideItems(),
      this.sideItemsGraphFacade.getAllSideItemCategories()
    ).pipe(
      map((data) => {
        var sideItems = data[0];
        var sideItemCategories = data[1].value;

        var response: any[] = [];
        for (var category of sideItemCategories) {
          var items: any[] = sideItems
            .filter(
              (i) =>
                i.categoryId == category.id &&
                i.title &&
                i.title
                  ?.trim()
                  .toLowerCase()
                  .indexOf(searchText.trim().toLowerCase()) > -1
            )
            .filter(
              (i, index, list) =>
                list.findIndex((i2) => i2.id === i.id) === index
            );

          if (items && items.length > 0) {
            items = items.map((i) => {
              return {
                id: i.id,
                label: i.title,
                type: 'SideItemCategory',
                url: i.link,
              };
            });

            response.push({
              label: `Navigation Menu - ${category.fields.Title}`,
              items: items,
            });
          }
        }

        return response;
      })
    );
  }

  getWikiSiteLists() {
    this.apiService.getWikiSiteLists().subscribe((data) => {
      this.storage.saveData(data, 'wikiSiteLists', true);
    });
  }

  searchFileInSharepointDocumentLibrary(searchText: string) {
    return this.searchGraphApiSrv.searchFileInSharepointDocumentLibrary(
      searchText
    );
  }

  searchFolderInSharepointDocumentLibrary(searchText: string) {
    return this.searchGraphApiSrv.searchFolderInSharepointDocumentLibrary(
      searchText
    );
  }

  searchVodVideosInSharepointDocumentLibrary(searchText: string) {
    return this.searchGraphApiSrv.searchVodVideosInSharepointDocumentLibrary(
      searchText
    );
  }

  searchGalleryInSharepointDocumentLibrary(searchText: string) {
    return this.searchGraphApiSrv.searchGalleryInSharepointDocumentLibrary(
      searchText
    );
  }

  createDataFromSharepointSearch(data: ISharepointApiSearchFilter[]) {
    let items: ISearchInSharepointItems[] = [];
    let obj = {
      label: 'Articles',
      items: items,
    };
    if (data) {
      data = data.filter(
        (i) => !this.isItemUnassigned(i) && this.VerifyStartDate(i)
      );
      obj.items = data
        .filter((a) => {
          return a.resource.fields.assignedTo != undefined || null
            ? this.memberOfService.isMember(
                a.resource.fields.assignedTo.split(';'),
                undefined,
                'search'
              ) == true
            : a;
        })
        .map((v: ISharepointApiSearchFilter) => {
          return {
            id: v.resource.sharepointIds.listItemId,
            label: v.resource.fields.title,
            text: v.summary.replace(/(<([^>]+)>)/gi, ''),
            createdDate: v.resource.createdDateTime,
            type: 'Articles',
            image:
              v.resource.fields.imageOWSURLH != undefined
                ? v.resource.fields.imageOWSURLH.split(',')[0]
                : undefined,
          };
        });
    }

    obj.items = obj.items.sort((a: any, b: any) =>
      b.createdDate.localeCompare(a.createdDate)
    );
    return obj;
  }

  createDataFromSharepointWikiListsSearch(
    data: ISharepointApiSearchFilter[],
    wikiList: IWikiSiteList
  ) {
    let items: ISearchInSharepointItems[] = [];
    let obj = {
      label: `Navigation Menu - ${wikiList.siteName}`,
      items: items,
    };
    if (data) {
      obj.items = data.map((v: ISharepointApiSearchFilter) => {
        return {
          id: v.resource.sharepointIds.listItemId,
          label: v.resource.fields.title,
          type: wikiList.siteName,
          wikiList: wikiList,
        };
      });
    }

    return obj;
  }

  createDataFromSharepointLibraryFileSearch(
    data: ISharepointApiSearchFilter[]
  ) {
    let items: ISearchInSharepointItems[] = [];
    let obj = {
      label: 'Files',
      items: items,
    };

    if (data) {
      obj.items = data.map((v: ISharepointApiSearchFilter) => {
        return {
          id: v.resource.id,
          label: v.resource.name,
          parentReference: v.resource.parentReference,
          type: 'Files',
          size: v.resource.size,
          icon: '/assets/images/document-libery/document-template-icon.png',
        };
      });
    }
    return obj;
  }

  createDataFromSharepointLibraryVideoSearch(
    data: ISharepointApiSearchFilter[]
  ) {
    let items: ISearchInSharepointItems[] = [];
    let obj = {
      label: 'VOD',
      items: items,
    };

    if (data) {
      data = data.filter((i) => !this.isItemUnassigned(i));
      obj.items = data.map((v: ISharepointApiSearchFilter) => {
        return {
          id: v.resource.parentReference.sharepointIds.listItemId,
          label: v.resource.listItem.fields.playtikaTitleOWSTEXT,
          parentReference: v.resource.parentReference,
          type: 'Videos',
          size: v.resource.size,
          icon: '/assets/images/global/video-default-bg.png',
          canDisplay: false,
        };
      });
    }
    return obj;
  }

  createDataFromSharepointLibraryFolderSearch(
    data: ISharepointApiSearchFilter[]
  ) {
    let items: ISearchInSharepointItems[] = [];
    let obj = {
      label: 'Folders',
      items: items,
    };
    if (data) {
      obj.items = data.map((v: ISharepointApiSearchFilter) => {
        return {
          id: v.resource.id,
          label: v.resource.name,
          parentReference: v.resource.parentReference,
          type: 'Folders',
          size: v.resource.size,
          icon: 'https://modernb.akamai.odsp.cdn.office.net/files/fabric-cdn-prod_20211025.001/assets/item-types/20/folder.svg',
        };
      });
    }
    return obj;
  }

  createDataFromSharepointGallerySearch(data: ISharepointApiSearchFilter[]) {
    let items: ISearchInSharepointItems[] = [];
    let obj = {
      label: 'Gallery',
      items: items,
    };

    if (data) {
      data = data.filter((i) => !this.isItemUnassigned(i));
      obj.items = data.map((v: ISharepointApiSearchFilter) => {
        return {
          id: v.resource.id,
          listItemId: v.resource.parentReference.sharepointIds.listItemId,
          label: v.resource.listItem.fields.playtikaTitleOWSTEXT,
          parentReference: v.resource.parentReference,
          type: 'Gallery',
          size: v.resource.size,
          icon: '/assets/images/global/gallery-icon.png',
          canDisplay: false,
        };
      });
    }
    return obj;
  }

  searchInOnedrive(query: string) {
    return this.searchGraphApiSrv.searchInOneDrive(query).pipe(
      map((response: any) => {
        let oneDriveItems: ISearchInOnedriveItem[] = [];
        for (let [index, item] of response.value.entries()) {
          let oneDriveItem: ISearchInOnedriveItem = {
            id: item.id,
            name: item.name,
            url: item.webUrl,
            icon: '',
          };
          if (item.folder) {
            oneDriveItem.icon =
              'https://modernb.akamai.odsp.cdn.office.net/files/fabric-cdn-prod_20211025.001/assets/item-types/20/folder.svg';
          } else if (
            item.file?.mimeType ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ) {
            oneDriveItem.icon =
              'https://modernb.akamai.odsp.cdn.office.net/files/fabric-cdn-prod_20211025.001/assets/item-types/20/docx.svg';
          } else if (
            item.file?.mimeType ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ) {
            oneDriveItem.icon =
              'https://modernb.akamai.odsp.cdn.office.net/files/fabric-cdn-prod_20211025.001/assets/item-types/20/xlsx.svg';
          } else if (
            item.file?.mimeType ===
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
          ) {
            oneDriveItem.icon =
              'https://modernb.akamai.odsp.cdn.office.net/files/fabric-cdn-prod_20211025.001/assets/item-types/20/pptx.svg';
          } else if (item.package?.type === 'oneNote') {
            oneDriveItem.icon =
              'https://modernb.akamai.odsp.cdn.office.net/files/fabric-cdn-prod_20211025.001/assets/item-types/20/onetoc.svg';
          } else if (item.file?.mimeType === 'application/octet-stream') {
            oneDriveItem.icon =
              'https://modernb.akamai.odsp.cdn.office.net/files/fabric-cdn-prod_20211025.001/assets/item-types/20/link.svg';
          }
          // and another one 'if' declaration https://www.youtube.com/watch?v=poz6W0znOfk&list=RDpoz6W0znOfk&start_radio=1&ab_channel=placeboing
          oneDriveItems.push(oneDriveItem);
        }
        return { label: 'OneDrive', items: oneDriveItems };
      })
    );
  }

  public getFileById(fileId, liberId): Observable<any> {
    return this.fileService.getByIdFile(fileId, liberId);
  }

  public getFileInB64(fileId: string, liberId: string): Observable<any> {
    return this.fileService.getFileInB64(fileId, liberId);
  }

  public setImageInB64(item: any) {
    if (item.image && item.image.includes(environment.SharePoint.SiteURL)) {
      let libraryName = item.image.split('sites/')[1].split('/')[1];
      let pathArray = item.image.split('sites/')[1].split('/');
      let filePath = '';

      pathArray.forEach((element, index) => {
        if (index > 1) {
          filePath += '/' + element;

          if (index == pathArray.length - 1) {
            filePath = filePath.split('?')[0];
            this.fileService
              .getFileByPathInB64(
                environment.SharePoint.ContentSiteId,
                libraryName,
                filePath
              )
              .subscribe((result) => {
                if (result != '') {
                  item.image = result;
                }
              });
          }
        }
      });
    } else {
      if (item.image && item.image.includes('jpg')) {
        item.image = item.image.split('jpg')[0] + 'jpg';
      }
    }
  }

  public getVodVodeoThumbnailsById(vodItenId: string) {
    return this.searchGraphApiSrv.getVodImage(vodItenId).pipe(
      map((result: any) => {
        let obj: any = {};

        if (result) {
          obj = {
            image:
              result.driveItem.thumbnails &&
              result.driveItem.thumbnails[0].large.url != undefined
                ? result.driveItem.thumbnails[0].large.url
                : '/assets/images/global/video-default-bg.png',
            assignedTo:
              result.fields.AssignedTo != undefined ||
              result.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(
                    result.fields.AssignedTo,
                    result.fields.UnAssignedTo
                  )
                : true,
          };
        }
        return obj;
      })
    );
  }

  getGalleryImage(galleryId: string) {
    return this.searchGraphApiSrv.getGalleryImage(galleryId).pipe(
      map((result: any) => {
        let obj: any = {};

        if (result) {
          obj = {
            image:
              result.fields.GalleryMainImage != undefined
                ? result.fields.GalleryMainImage.Url
                : '/assets/images/global/gallery-icon.png',
            assignedTo:
              result.fields.AssignedTo != undefined ||
              result.fields.UnAssignedTo != undefined
                ? this.memberOfService.isMember(
                    result.fields.AssignedTo,
                    result.fields.UnAssignedTo
                  )
                : true,
          };
        }
        return obj;
      })
    );
  }

  isItemUnassigned(item) {
    var result = false;
    if (this.user && this.user.workEmail) {
      result =
        item.resource &&
        item.resource.fields &&
        item.resource.fields.unAssignedToOWSUSER &&
        item.resource.fields.unAssignedToOWSUSER
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(this.user.workEmail?.trim().toLowerCase()) > -1;
    }
    // console.log(item.resource.fields.unAssignedToOWSUSER.toString())
    return result;
  }

  VerifyStartDate(item) {
    const result =
      item?.resource?.fields?.startDateOWSDATE &&
      Date.parse(item.resource.fields.startDateOWSDATE) <= Date.now();
    return result;
  }
}
