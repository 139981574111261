import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAddBirthdayWish,
  IBirthdayWish,
  IDirectReports,
  IEmployeeBirthdaysData,
  IOrder,
  IPerson,
  IUserApp,
  IUserInfo,
  IUserProfileData,
} from '../../../Shared/Models/user.model';
import { environment } from '../../../../environments/environment';
import { IAddApp } from 'src/app/Shared/Models/add-app-item.interface';
import {
  ITask,
  ITaskApp,
  ITaskSummary,
} from 'src/app/Shared/Models/task.model';
import {
  IArticle,
  IArticleComment,
  IArticleDetails,
} from 'src/app/Shared/Models/article.model';
import { Observable, of, throwError } from 'rxjs';
import {
  IVodComment,
  IVodDetails,
  IVodItem,
} from 'src/app/Shared/Models/vod-item.interface';
import { DatePipe } from '@angular/common';
import {
  IGalleryDetails,
  IGalleryItem,
} from 'src/app/Shared/Models/gallery.interface';
import { ICalendarView } from 'src/app/Shared/Models/calendar.interface';
import { catchError, map, tap } from 'rxjs/operators';
import {
  ICommentsMention,
  INotification,
} from 'src/app/Shared/Models/announcements.interface';
import { IWikiSiteList } from 'src/app/Shared/Models/wiki.models';
import { FileService } from 'src/app/Shared/Services/file.service';
import { IAiArticleComment, IAiArticleDetails, IAiToolComment, IAiToolDetails } from 'src/app/Shared/Models/ai-tools.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private cacheArticleDetails: any[] = [];
  private cachedArticleDetailsRequests: any = [];

  constructor(public http: HttpClient, private fileService: FileService) {}

  getUser(userEmail: string) {
    return this.http.get<IUserInfo>(
      `${environment.ApiPath}api/${environment.API.User.GetUser}/${userEmail}`
    );
  }

  getWikiSiteLists() {
    return this.http
      .get<IWikiSiteList[]>(
        `${environment.ApiPath}api/${environment.API.Wiki.WikiSiteList}`
      )
      .pipe(
        catchError((err) => {
          let list: IWikiSiteList[] = [];
          return of(list);
        })
      );
  }

  addBirthdayWish(wish: IAddBirthdayWish) {
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.User.AddBirthdayWish}`,
      wish
    );
  }

  getUserBirthday() {
    return this.http.get<IEmployeeBirthdaysData>(
      `${environment.ApiPath}api/${environment.API.User.GetUserBirthday}`
    );
  }

  getCountUserBirthdayWishes(birthday: string) {
    return this.http.get<number>(
      `${environment.ApiPath}api/${environment.API.User.GetCountUserBirthdayWishes}/${birthday}`
    );
  }

  setReadBirthdayWishes(wishIds: number[]) {
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.User.SetReadBirthdayWishes}`,
      wishIds
    );
  }

  getUserBirthdayWishes(birthday: string) {
    return this.http.get<IBirthdayWish[]>(
      `${environment.ApiPath}api/${environment.API.User.GetUserBirthdayWishes}/${birthday}`
    );
  }

  getUserApps() {
    return this.http
      .get<IUserApp[]>(`${environment.ApiPath}api/${environment.API.Apps.Get}`)
      .pipe(
        tap((resul) => {
          if (resul != null) {
            resul.forEach((element) => {
              this.setImageUrl(element);
            });
          }
        })
      );
  }

  getUserProfile(userEmail?: string) {
    return userEmail == undefined
      ? this.http.get<IUserProfileData>(
          `${environment.ApiPath}api/${environment.API.User.UserProfile}`
        )
      : this.http.get<IUserProfileData>(
          `${environment.ApiPath}api/${
            environment.API.User.UserProfile
          }/?workEmail=${userEmail == undefined ? null : userEmail}`
        );
  }

  addMostConnectedPeople(users: any[], email) {
    return this.http
      .post(
        `${environment.ApiPath}api/${environment.API.User.AddMostConnectedPeople}/${email}`,
        users
      )
      .subscribe();
  }

  getMostRecentPeople(userEmail: string) {
    return this.http.get<IPerson[]>(
      `${environment.ApiPath}api/${environment.API.User.GetMostConnectedPeople}/${userEmail}`
    );
  }

  getDirectReports(userEmail: string) {
    return this.http.get<IUserInfo[]>(
      `${environment.ApiPath}api/${environment.API.User.GetDirectReports}/${userEmail}`
    );
  }

  getAllDirectReports(emails: string[]) {
    return this.http.post<IDirectReports[]>(
      `${environment.ApiPath}api/${environment.API.User.GetDirectReports}`,
      emails
    );
  }

  getManagers(userEmail: string) {
    return this.http.get<IUserInfo[]>(
      `${environment.ApiPath}api/${environment.API.User.GetManagers}/${userEmail}`
    );
  }

  getManager(userEmail: string) {
    return this.http.get<IUserInfo>(
      `${environment.ApiPath}api/${environment.API.User.GetManager}/${userEmail}`
    );
  }

  GetMostConnectedBirthdays(employeeEmails: string[]) {
    let body = {
      employeeEmails: employeeEmails,
    };
    return this.http.post<IEmployeeBirthdaysData[]>(
      `${environment.ApiPath}api/${environment.API.User.GetMostConnectedBirthdays}`,
      body
    );
  }

  toggleFavoriteApps(names: string[]) {
    return this.http.put<boolean>(
      `${environment.ApiPath}api/AddFavoriteApps`,
      names
    );
  }

  removeApps(names: string[]) {
    return this.http.delete(`${environment.ApiPath}api/RemoveApps`, {
      body: names,
    });
  }

  setOrder(apps: IOrder[]) {
    return this.http.post(
      `${environment.ApiPath}api/${environment.API.Apps.OrderApps}`,
      apps
    );
  }

  addApps(apps: IAddApp[]) {
    return this.http.post(`${environment.ApiPath}api/CreateApps`, apps);
  }

  updateApp(app: IAddApp) {
    return this.http.post(`${environment.ApiPath}api/UpdateApp`, app);
  }

  searchAll(query: string, siteLocation?: string) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http
      .get<any>(
        `${environment.ApiPath}api/${environment.API.SearchAll}/${query}?Localtime=${filterDate}&SiteLocation=${siteLocation}`
      )
      .pipe(catchError((err) => throwError(err)));
  }

  getUserTasks() {
    return this.http.get<ITask[]>(
      `${environment.ApiPath}api/${environment.API.Tasks.GetUserTasks}`
    );
  }

  getTasksApps() {
    return this.http.get<ITaskApp[]>(
      `${environment.ApiPath}api/${environment.API.Tasks.GetTasksApps}`
    );
  }

  setUserTasksToSee(tasksToSee: any) {
    return this.http.post(
      `${environment.ApiPath}api/${environment.API.Tasks.SetTasksUserSeen}`,
      tasksToSee
    );
  }

  getUserCountTasksToSee() {
    return this.http.get<number>(
      `${environment.ApiPath}api/${environment.API.Tasks.GetCountTasksToSee}`
    );
  }

  GetUserTasksSummary() {
    return this.http.get<ITaskSummary>(
      `${environment.ApiPath}api/${environment.API.Tasks.GetUserTasksSummary}`
    );
  }

  SetNotificationsReaded() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.Notifications.SetNotificationsReaded}/${filterDate}`
    );
  }
  GetNewUserNotifications() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get<any>(
      `${environment.ApiPath}api/${environment.API.Notifications.GetNewUserNotifications}/${filterDate}`
    );
  }
  getMentionedArticlesNotifications() {
    return this.http
      .get<ICommentsMention[]>(
        `${environment.ApiPath}api/${environment.API.Notifications.GetMentionedArticlesNotifications}`
      )
      .pipe(
        map((data) => {
          data.map((m) => {
            if (!m.createdDate.includes('Z')) {
              m.createdDate = m.createdDate + 'Z';
            }
          });
          return data;
        })
      );
  }

  getMentionedVodsNotifications() {
    return this.http
      .get<ICommentsMention[]>(
        `${environment.ApiPath}api/${environment.API.Notifications.GetMentionedVodsNotifications}`
      )
      .pipe(
        map((data) => {
          data.map((m) => {
            if (!m.createdDate.includes('Z')) {
              m.createdDate = m.createdDate + 'Z';
            }
          });
          return data;
        })
      );
  }

  getNotifications() {
    return this.http.get<INotification[]>(
      `${environment.ApiPath}api/${environment.API.Notifications.GetMentionNotifications}`
    );
  }

  // setMentionNotificationsRead() {
  //   return this.http.post<boolean>(`${environment.ApiPath}api/${environment.API.Notifications.SetMentionNotificationsRead}`, {})
  // }

  setMentionArticlesNotificationsRead() {
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Notifications.ReadMentionedArticlesNotifications}`,
      {}
    );
  }

  setMentionVodsNotificationsRead() {
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Notifications.ReadMentionedVodsNotifications}`,
      {}
    );
  }

  GetNewUserNotificationsCount() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.get<number>(
      `${environment.ApiPath}api/${environment.API.Notifications.GetNewUserNotificationsCount}/${filterDate}`
    );
  }

  getVodDetails(vodIds: number[]): Observable<IVodDetails[]> {
    let body = {
      vodIds: vodIds,
    };
    return this.http.post<IVodDetails[]>(
      `${environment.ApiPath}api/${environment.API.Vods.GetVodDetails}`,
      body
    );
  }

  addVodLike(vodId: number, vodEditor: string): Observable<boolean> {
    let body = {
      itemId: vodId,
      relatedUserEmail: vodEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Vods.AddVodLike}`,
      body
    );
  }

  removeVodLike(vodId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Vods.RemoveVodLike}/${vodId}`,
      {}
    );
  }

  getVodComments(vodId: number): Observable<IVodComment[]> {
    return this.http.get<IVodComment[]>(
      `${environment.ApiPath}api/${environment.API.Vods.GetVodComments}/${vodId}`
    );
  }

  getVodChildComments(commentId: number): Observable<IVodComment[]> {
    return this.http.get<IVodComment[]>(
      `${environment.ApiPath}api/${environment.API.Vods.GetChildComments}/${commentId}`
    );
  }

  addVodComment(commentData): Observable<IVodComment> {
    return this.http.post<IVodComment>(
      `${environment.ApiPath}api/${environment.API.Vods.AddVodComment}`,
      commentData
    );
  }

  editVodComment(commentData): Observable<IVodComment> {
    return this.http.post<IVodComment>(
      `${environment.ApiPath}api/${environment.API.Vods.UpdateVodComment}`,
      commentData
    );
  }

  deleteVodCommentWithChildren(commentData): Observable<IVodComment> {
    return this.http.post<IVodComment>(
      `${environment.ApiPath}api/${environment.API.Vods.DeleteVodCommentWithChildren}`,
      commentData
    );
  }

  deleteVodComment(commentId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Vods.DeleteVodComment}/${commentId}`,
      {}
    );
  }

  getArticleDetails(articleIds: number[]): Observable<IArticleDetails[]> {
    let body = {
      articleIds: articleIds,
    };
    return this.http.post<IArticleDetails[]>(
      `${environment.ApiPath}api/${environment.API.Articles.GetArticleDetails}`,
      body
    );
  }

  addArticleLike(articleId: number, articleEditor: any): Observable<boolean> {
    let body = {
      itemId: articleId,
      relatedUserEmail: articleEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Articles.AddArticleLike}`,
      body
    );
  }

  removeArticleLike(articleId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Articles.RemoveArticleLike}/${articleId}`,
      {}
    );
  }

  getArticleComments(articleId: number): Observable<IArticleComment[]> {
    return this.http.get<IArticleComment[]>(
      `${environment.ApiPath}api/${environment.API.Articles.GetArticleComments}/${articleId}`
    );
  }

  getArticleChildComments(commentId: number): Observable<IArticleComment[]> {
    return this.http.get<IArticleComment[]>(
      `${environment.ApiPath}api/${environment.API.Articles.GetChildComments}/${commentId}`
    );
  }

  addArticleComment(commentData): Observable<IArticleComment> {
    return this.http.post<IArticleComment>(
      `${environment.ApiPath}api/${environment.API.Articles.AddArticleComment}`,
      commentData
    );
  }

  editArticleComment(commentData): Observable<IArticleComment> {
    return this.http.post<IArticleComment>(
      `${environment.ApiPath}api/${environment.API.Articles.UpdateArticleComment}`,
      commentData
    );
  }

  deleteArticleComment(commentId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Articles.DeleteArticleComment}/${commentId}`,
      {}
    );
  }

  deleteArticleCommentWithChildren(commentData): Observable<IArticleComment> {
    return this.http.post<IArticleComment>(
      `${environment.ApiPath}api/${environment.API.Articles.DeleteArticleCommentWithChildren}`,
      commentData
    );
  }

  getAiToolDetails(toolIds: number[]): Observable<IAiToolDetails[]> {
    let body = {
      toolIds: toolIds,
    };
    return this.http.post<IAiToolDetails[]>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiToolDetails}`,
      body
    );
  }

  addAiToolLike(toolId: number, toolEditor: any): Observable<boolean> {
    let body = {
      itemId: toolId,
      relatedUserEmail: toolEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.AddAiToolLike}`,
      body
    );
  }

  removeAiToolLike(toolId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.RemoveAiToolLike}/${toolId}`,
      {}
    );
  }

  getAiToolComments(toolId: number): Observable<IAiToolComment[]> {
    return this.http.get<IAiToolComment[]>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiToolComments}/${toolId}`
    );
  }

  getAiToolChildComments(commentId: number): Observable<IAiToolComment[]> {
    return this.http.get<IAiToolComment[]>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetChildComments}/${commentId}`
    );
  }

  addAiToolComment(commentData): Observable<IAiToolComment> {
    return this.http.post<IAiToolComment>(
      `${environment.ApiPath}api/${environment.API.AiTools.AddAiToolComment}`,
      commentData
    );
  }

  editAiToolComment(commentData): Observable<IAiToolComment> {
    return this.http.post<IAiToolComment>(
      `${environment.ApiPath}api/${environment.API.AiTools.UpdateAiToolComment}`,
      commentData
    );
  }

  deleteAiToolComment(commentId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.DeleteAiToolComment}/${commentId}`,
      {}
    );
  }

  getAiArticlesDetails(articleIds: number[]): Observable<IAiArticleDetails[]> {
    let body = {
      articleIds: articleIds,
    };
    return this.http.post<IAiArticleDetails[]>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiArticleDetails}`,
      body
    );
  }

  addAiArticleLike(articleId: number, articleEditor: any): Observable<boolean> {
    let body = {
      itemId: articleId,
      relatedUserEmail: articleEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.AddAiArticleLike}`,
      body
    );
  }

  removeAiArticleLike(articleId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.RemoveAiArticleLike}/${articleId}`,
      {}
    );
  }

  getAiArticleComments(articleId: number): Observable<IAiArticleComment[]> {
    return this.http.get<IAiArticleComment[]>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiArticleComments}/${articleId}`
    );
  }

  getAiArticleChildComments(commentId: number): Observable<IAiArticleComment[]> {
    return this.http.get<IAiArticleComment[]>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiArticleChildComments}/${commentId}`
    );
  }

  addAiArticleComment(commentData): Observable<IAiArticleComment> {
    return this.http.post<IAiArticleComment>(
      `${environment.ApiPath}api/${environment.API.AiTools.AddAiArticleComment}`,
      commentData
    );
  }

  editAiArticleComment(commentData): Observable<IAiArticleComment> {
    return this.http.post<IAiArticleComment>(
      `${environment.ApiPath}api/${environment.API.AiTools.UpdateAiArticleComment}`,
      commentData
    );
  }

  deleteAiArticleComment(commentId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.DeleteAiArticleComment}/${commentId}`,
      {}
    );
  }



  addAiArticleFavorite(articleId: number): Observable<boolean> {
    let body = {
      articleId: articleId,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.AddAiArticleFavorite}`,
      body
    );
  }

  removeAiArticleFavorite(articleId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.RemoveAiArticleFavorite}/${articleId}`,
      {}
    );
  }

  getGalleryDetails(galleryIds: number[]): Observable<IGalleryDetails[]> {
    let body = {
      galleryIds: galleryIds,
    };
    return this.http.post<IGalleryDetails[]>(
      `${environment.ApiPath}api/${environment.API.Galleries.GetGalleryDetails}`,
      body
    );
  }

  addGalleryLike(
    galleryId: number,
    galleryEditor: string
  ): Observable<boolean> {
    let body = {
      itemId: galleryId,
      relatedUserEmail: galleryEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Galleries.AddGalleryLike}`,
      body
    );
  }

  removeGalleryLike(galleryId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Galleries.RemoveGalleryLike}/${galleryId}`,
      {}
    );
  }
  addVodFavorite(vodId: number): Observable<boolean> {
    let body = {
      vodId: vodId,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Vods.AddVodFavorite}`,
      body
    );
  }

  removeVodFavorite(vodId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Vods.RemoveVodFavorite}/${vodId}`,
      {}
    );
  }
  addGalleryFavorite(galleryId: number): Observable<boolean> {
    let body = {
      galleryId: galleryId,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Galleries.AddGalleryFavorite}`,
      body
    );
  }

  removeGalleryFavorite(galleryId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Galleries.RemoveGalleryFavorite}/${galleryId}`,
      {}
    );
  }
  addArticleFavorite(articleId: number): Observable<boolean> {
    let body = {
      articleId: articleId,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Articles.AddArticleFavorite}`,
      body
    );
  }

  removeArticleFavorite(articleId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Articles.RemoveArticleFavorite}/${articleId}`,
      {}
    );
  }

  addArticleComentLike(
    commentId: number,
    articleId: number,
    commentEditor: string
  ): Observable<boolean> {
    let body = {
      commentId: commentId,
      itemId: articleId,
      relatedUserEmail: commentEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Articles.AddArticleCommentLike}`,
      body
    );
  }

  removeArticleCommentLike(commentId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Articles.RemoveArticleCommentLike}/${commentId}`,
      {}
    );
  }

  addAiToolComentLike(
    commentId: number,
    toolId: number,
    commentEditor: string
  ): Observable<boolean> {
    let body = {
      commentId: commentId,
      itemId: toolId,
      relatedUserEmail: commentEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.AddAiToolCommentLike}`,
      body
    );
  }

  removeAiToolCommentLike(commentId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.RemoveAiToolCommentLike}/${commentId}`,
      {}
    );
  }

  addAiArticleComentLike(
    commentId: number,
    articleId: number,
    commentEditor: string
  ): Observable<boolean> {
    let body = {
      commentId: commentId,
      itemId: articleId,
      relatedUserEmail: commentEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.AddAiArticleCommentLike}`,
      body
    );
  }

  removeAiArticleCommentLike(commentId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.AiTools.RemoveAiArticleCommentLike}/${commentId}`,
      {}
    );
  }

  addVodCommentLike(
    commentId: number,
    vodId: number,
    commentEditor: string
  ): Observable<boolean> {
    let body = {
      commentId: commentId,
      itemId: vodId,
      relatedUserEmail: commentEditor,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.Vods.AddVodCommentLike}`,
      body
    );
  }

  removeVodCommentLike(commentId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.ApiPath}api/${environment.API.Vods.RemoveVodCommentLike}/${commentId}`,
      {}
    );
  }
  getGalleriesFavorites(): Observable<IGalleryItem[]> {
    return this.http.post<IGalleryItem[]>(
      `${environment.ApiPath}api/${environment.API.Galleries.GetFavoriteGalleries}`,
      {}
    );
  }

  getArticleFavorites(): Observable<IArticle[]> {
    const datepipe: DatePipe = new DatePipe('en-US');
    const filterDate =
      datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.ms') + 'Z';
    return this.http.post<IArticle[]>(
      `${environment.ApiPath}api/${environment.API.Articles.GetFavoriteArticles}?Localtime=${filterDate}`,
      {}
    );
  }
  getVodsFavorites(): Observable<IVodItem[]> {
    return this.http.post<IVodItem[]>(
      `${environment.ApiPath}api/${environment.API.Vods.GetFavoriteVods}`,
      {}
    );
  }

  getCompanyCalendar(viewDate: string): Observable<ICalendarView> {
    const datepipe: DatePipe = new DatePipe('en-US');
    const currentViewMonth = datepipe.transform(viewDate, 'yyyy-MM') + '-01';
    const offSet = new Date().getTimezoneOffset();

    const filterStartDate =
      datepipe.transform(this.subDays(currentViewMonth, 6), 'yyyy-MM-dd') +
      'T00:00:00Z';
    const filterEndDate =
      datepipe.transform(
        this.addDays(new Date(currentViewMonth), 37),
        'yyyy-MM-dd'
      ) + 'T23:59:59Z';

    let body = {
      todayDateTime:
        datepipe.transform(Date.now(), 'yyyy-MM-dd') + 'T00:00:00Z',
      startDateTime: filterStartDate,
      endDateTime: filterEndDate,
      endDateTimeForCache:
        datepipe.transform(this.addDays(Date.now(), 360), 'yyyy-MM-dd') +
        'T00:00:00Z',
      clientOffset: Math.abs(offSet),
    };

    return this.http
      .post<ICalendarView>(
        `${environment.ApiPath}api/${environment.API.Calendar.GetCompanyCalendar}`,
        body
      )
      .pipe(
        map((data) => {
          data.events.map((e) => {
            e.isCompany = true;
            if (e.officeClosed == true) {
              e.title = `${e.title} (Offices Closed)`;
            }

            // if (
            //   e.end &&
            //   String(e.start).split('T')[0] != String(e.end).split('T')[0]
            // ) {
            //   console.log(String(e.start).split('T')[1] == String(e.end).split('T')[1])
            //   if (
            //     String(e.start).split('T')[1] == String(e.end).split('T')[1] ||
            //     String(e.end).split('T')[1].includes('00:00:00')
            //   ) {
            //     e.end = this.subDays(e.end, 1);
            //   }
            // }

            if (e.icon) {
              var icon = `<img src="${e.icon}"> `;
              e.title = icon + e.title;
            }

            e.start = new Date(String(e.start));
            if (e.end) {
              e.end = new Date(String(e.end));
              e.daysCount =
                this.dateDiffInDays(e.start, e.end) > 0
                  ? this.dateDiffInDays(e.start, e.end) + 1
                  : 0;
            } else {
              e.daysCount = 0;
            }
          });
          return data;
        }),
        catchError((err) => {
          let calendar: ICalendarView = {
            events: [],
            eventLocations: [],
            eventTypes: [],
          };
          return of(calendar);
        })
      );
  }

  private addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private subDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  private dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  getArticleLikedUserList(eventId: number, location): Observable<string> {
    return this.http.get<string>(
      `${environment.ApiPath}api/${environment.API.Articles.GetArticleLikedUserList}/?EventId=${eventId}&SiteLocation=${location}`
    );
  }
  getAiToolLikedUserList(eventId: number, location): Observable<string> {
    return this.http.get<string>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiToolLikedUserList}/?EventId=${eventId}&SiteLocation=${location}`
    );
  }
  getAiArticleLikedUserList(eventId: number, location): Observable<string> {
    return this.http.get<string>(
      `${environment.ApiPath}api/${environment.API.AiTools.GetAiArticleLikedUserList}/?EventId=${eventId}&SiteLocation=${location}`
    );
  }
  getGalleryLikedUserList(eventId: number, location): Observable<string> {
    return this.http.get<string>(
      `${environment.ApiPath}api/${environment.API.Galleries.GetGalleryLikedUserList}/?EventId=${eventId}&SiteLocation=${location}`
    );
  }
  getVodLikedUserList(eventId: number, location): Observable<string> {
    return this.http.get<string>(
      `${environment.ApiPath}api/${environment.API.Vods.GetVodLikedUserList}/?EventId=${eventId}&SiteLocation=${location}`
    );
  }
  private setImageUrl(tempItem: any) {
    if (
      tempItem.icon &&
      tempItem.icon.includes(environment.SharePoint.SiteURL)
    ) {
      if (tempItem.icon.includes('sites')) {
        let libraryName = tempItem.icon.split('sites/')[1].split('/')[1];
        let pathArray = tempItem.icon.split('sites/')[1].split('/');
        let filePath = '';

        pathArray.forEach((element, index) => {
          if (index > 1) {
            filePath += '/' + element;

            if (index == pathArray.length - 1) {
              filePath = filePath.split('?')[0];
              this.fileService
                .getFileByPathInB64(
                  environment.SharePoint.ContentSiteId,
                  libraryName,
                  filePath
                )
                .subscribe((result) => {
                  if (result != '') {
                    tempItem.image = result;
                  } else {
                    tempItem.image =
                      'https://stplaytika360.blob.core.windows.net/360images/TEST/Well-BeingEventType/WellbeingEventTypeDefaultImage.jpg';
                  }
                });
            }
          }
        });
      } else {
        tempItem.image =
          'https://stplaytika360.blob.core.windows.net/360images/TEST/Well-BeingEventType/WellbeingEventTypeDefaultImage.jpg';
      }
    }
  }
  // getArticleDetails(articleId): Observable<IArticleDetails> {
  //   setTimeout(() => {
  //     this.clearCacheData()
  //   }, 1000);
  //   let articleDetails: Observable<IArticleDetails>;
  //   var cachedDetails = this.cacheArticleDetails.find(c => c.id == articleId);
  //   var cachedObservable = this.cachedArticleDetailsRequests.find(c => c.id == articleId);
  //   if (cachedDetails) {
  //     articleDetails = of(cachedDetails.articleDetails);
  //   }  else if (cachedObservable && cachedObservable.cachedObserv) {
  //     articleDetails = cachedObservable.cachedObserv;
  //   } else {
  //     //handlw duplicate request
  //     cachedObservable = this.http.get<IArticleDetails>(`${environment.ApiPath}api/${environment.API.Articles.GetArticleDetails}/${articleId}`)
  //       .pipe(
  //         tap(res => {
  //           let obj = {articleDetails: res, id:articleId};
  //           this.cacheArticleDetails.push(obj)
  //         }),
  //         share(),
  //         finalize(() => cachedObservable = null)
  //       );

  //       articleDetails = cachedObservable;

  //       this.cachedArticleDetailsRequests.push({
  //         cachedObserv: cachedObservable,
  //         id:articleId
  //       })
  //   }

  //   return articleDetails;
  // }

  // clearCacheData(){
  //   this.cacheArticleDetails = [];
  //   this.cachedArticleDetailsRequests = [];
  // }
}
