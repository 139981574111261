import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SessionStorageService } from "./session-storage.service";

@Injectable({
    providedIn: 'root'
  })

export class EditModeService{

    private editModeState = new BehaviorSubject<boolean>(false);
    public editModeStateSource$ = this.editModeState.asObservable();

    constructor(private storage:SessionStorageService){
        let initialState = this.storage.getData('isEditModeActive')

        if(initialState != null){
            this.editModeState.next(initialState)
        }
    }



    isEditModeActiveState(value:boolean){
        this.editModeState.next(value);
        this.storage.saveData(value,'isEditModeActive')
    }


}