import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, of } from "rxjs";
import { ApiService } from "src/app/Core/Services/ApiServices/api.service";
import { OutlookCalendarGraphFacade } from "../GraphApiFacade/outlookCalendar-graph.facade";
import { ICalendarFilter, ICalendarSource} from "src/app/Shared/Models/calendar.interface";
import { PreloaderService } from "src/app/Shared/Services/preloader.service";
import { DatePipe } from "@angular/common";
import { UserGraphApiFacade } from "../GraphApiFacade/user-graph.facade";
import { IWellbeingEventItem } from "src/app/Shared/Models/wellbeing.interface";
import { OutLookEventApiService } from "src/app/Core/Services/ApiServices/outLookEvents.api";
import { catchError, map, switchMap, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class CalendarApiFacade {
  currentEvent:any;
  calendarTyoes: any = [
    {
      title:"All Calendars",
      id:0,
      selected:false,
      icon:'/assets/images/global/calendar.png',
      iconActive:'/assets/images/global/orange-calendar.png'
    },
    {
      title:"Company",
      id:1,
      selected:true,
      icon:"/assets/images/global/logo-icon.png",
      iconActive:"/assets/images/global/logo-icon.png",
    },
    {
      title:"Personal",
      id:2,
      selected:false,
      icon:"/assets/images/calendar/gray-person.png",
      iconActive:"/assets/images/calendar/orange-person.png"
    }
  ];

  currentSource:ICalendarSource={
    events:[],
    eventTypes:[],
    eventLocations:[],
    allPersonalEvents:[],
    allCompanyEvents:[],
    calendarTypes:this.calendarTyoes,
  };

  currentMonth = "";
  userLocation = "";
  count = 0;
  addEventToCalendarStatus:any;
  currentdbEventId:any;
  isMainSiteAdmin: boolean = false;
  adminSelectedLocation?: string = 'Select';

  private calendarSource = new BehaviorSubject<ICalendarSource>(this.currentSource);
  public calendarSource$ = this.calendarSource.asObservable();

  constructor(
    private calendarApi: ApiService,
    private userGraphApiFacade:UserGraphApiFacade,
    private preloaderService: PreloaderService,
    private outLookEventApiService:OutLookEventApiService,
    private outlookCalendarGraphFacade:OutlookCalendarGraphFacade
    ) {
      userGraphApiFacade.getUserLocation().subscribe(location => {
        this.userLocation = location;
      })
  }

  getCalendars(viewDate){
    const datepipe: DatePipe = new DatePipe('en-US')
    const today =  datepipe.transform(Date.now(),'yyyy-MM')
    const currentMonth =  datepipe.transform(viewDate,'yyyy-MM')
    const currentViewMonth = datepipe.transform(viewDate,'yyyy-MM')+'-01'

    if(currentViewMonth != this.currentMonth || today == currentMonth){
      this.currentMonth=currentViewMonth;
      this.currentSource.events = [];
      this.calendarSource.next(this.currentSource);
      this.getCompanyCalendar(viewDate);
      this.getPersonalCalendar(viewDate);
    }
  }
  refreshCalendars(viewDate){
    const datepipe: DatePipe = new DatePipe('en-US')
    const today =  datepipe.transform(Date.now(),'yyyy-MM')
    const currentMonth =  datepipe.transform(viewDate,'yyyy-MM')
    const currentViewMonth = datepipe.transform(viewDate,'yyyy-MM')+'-01'

      this.currentMonth=currentViewMonth;
      this.currentSource.events = [];
      this.calendarSource.next(this.currentSource);
      this.getCompanyCalendar(viewDate);
      this.getPersonalCalendar(viewDate);
  }
  getCompanyCalendar(viewDate){

    this.preloaderService.showPreloader();


    this.calendarApi.getCompanyCalendar(viewDate).subscribe(company => {

      this.currentSource.allCompanyEvents = [...new Set(company.events)];
      this.currentSource.eventTypes = company.eventTypes;
      this.currentSource.eventLocations = company.eventLocations;

      let currentFilter:ICalendarFilter = JSON.parse(String(localStorage.getItem("calendarFilterSettings")));

      if(currentFilter){

        for (let index = 0; index < currentFilter.currentLocations.length; index++) {
          const element = currentFilter.currentLocations[index];

          let currFilter = this.currentSource.eventLocations.find(e => e.id == element.id);

          if(!currFilter){
            element.notList = true
            this.currentSource.eventLocations.push(element);
          }
        }

        for (let index = 0; index < currentFilter.currentEventTypes.length; index++) {
          const element = currentFilter.currentEventTypes[index];

          let currFilter = this.currentSource.eventTypes.find(e => e.id == element.id);

          if(!currFilter){
            element.notList = true
            this.currentSource.eventTypes.push(element);
          }
        }

      }



      if(company.events.length > 0 || this.currentSource.eventTypes.length == 0 || this.currentSource.eventLocations.length == 0){
        this.handleCalendarFilter(currentFilter, viewDate);
      }

      var userLocation = company.eventLocations.find(l => l.title.toLowerCase() == this.userLocation.toLocaleLowerCase())

      if(this.count == 0 && userLocation != undefined){
            userLocation.selected = true;
            var event = {
              direction:userLocation,
              id:"global"
            }

            var alreadySetted = currentFilter != null
            ? currentFilter.currentLocations.find(l => l.title.toLowerCase() == userLocation?.title.toLocaleLowerCase())
            : undefined

            if(alreadySetted == undefined){
              this.filterChange(event)
              this.count++
            }
      }

      this.preloaderService.hidePreloader();
  })
  }

  getPersonalCalendar(viewDate){

    this.outlookCalendarGraphFacade.getOutLookCalendar(viewDate).subscribe(personal => {

      this.currentSource.allPersonalEvents = [...new Set(personal)];

      let currentFilter:ICalendarFilter = JSON.parse(String(localStorage.getItem("calendarFilterSettings")));

      if(personal.length > 0){
        this.handleCalendarFilter(currentFilter, viewDate);
      }
      this.preloaderService.hidePreloader();
    })
  }

  filterChange(event){
    var currentFilter:ICalendarFilter = JSON.parse(String(localStorage.getItem("calendarFilterSettings")));
    if(event.id == "calendar"){
      currentFilter.currentCalendar = event.direction
    }

    if(event.id == "global"){
      if(event.direction.id==0){
        currentFilter.currentLocations=[];
        currentFilter.currentLocations.push(event.direction);
      }else{
        let defaultIndex = currentFilter.currentLocations.findIndex((obj => obj.id == this.currentSource.eventLocations[0].id));
        let locationIndex = currentFilter.currentLocations.findIndex((obj => obj.id == event.direction.id));
        let locationIndexNotList = this.currentSource.eventLocations.findIndex((obj => obj.id ==  event.direction.id && obj.notList == true));

        if(defaultIndex==0){
          currentFilter.currentLocations.splice(defaultIndex,1)
        }

        if(locationIndex==-1){
          currentFilter.currentLocations.push(event.direction);
        }else{
          currentFilter.currentLocations.splice(locationIndex,1);

          if(locationIndexNotList >= 0){
            this.currentSource.eventLocations.splice(locationIndexNotList,1);
          }
        }
        if(currentFilter.currentLocations.length == 0){
          currentFilter.currentLocations.push(this.currentSource.eventLocations[0]);
        }
      }
    }

    if(event.id == "flag"){
      if(event.direction.id==0){
        currentFilter.currentEventTypes=[];
        currentFilter.currentEventTypes.push(event.direction);
      }else{
        let defaultEvent = currentFilter.currentEventTypes.findIndex((obj => obj.id == this.currentSource.eventTypes[0].id));
        let eventTypeIndex = currentFilter.currentEventTypes.findIndex((obj => obj.id == event.direction.id));
        let eventTypeIndexNotList = this.currentSource.eventTypes.findIndex((obj => obj.id ==  event.direction.id && obj.notList == true));
        if(defaultEvent==0){
          currentFilter.currentEventTypes.splice(defaultEvent,1)
        }

        if(eventTypeIndex==-1){
          currentFilter.currentEventTypes.push(event.direction);
        }else{
          currentFilter.currentEventTypes.splice(eventTypeIndex,1);

          if(eventTypeIndexNotList >= 0){
            this.currentSource.eventTypes.splice(eventTypeIndexNotList,1);
          }
        }
        if(currentFilter.currentEventTypes.length == 0){
          currentFilter.currentEventTypes.push(this.currentSource.eventTypes[0]);
        }
      }
    }

    localStorage.setItem("calendarFilterSettings", JSON.stringify(currentFilter))
    this.handleCalendarFilter(currentFilter)
  }

  private handleCalendarFilter(currentFilter:ICalendarFilter, v?:any){

    if(currentFilter){

      let calendarTypeIndex = this.currentSource.calendarTypes.findIndex((obj => obj.id == currentFilter.currentCalendar.id));

      for (let index = 0; index < this.currentSource.calendarTypes.length; index++) {
        const element = this.currentSource.calendarTypes[index];
        if(index == calendarTypeIndex){
          element.selected = true;
        }else{
          element.selected =false;
        }
      }


      this.currentSource.eventLocations.forEach(element => {
        let locationIndex = currentFilter.currentLocations.findIndex((obj => obj.id == element.id));
        if(locationIndex==-1){
          element.selected=false;
        }else{
          element.selected=true;
        }
      });

      this.currentSource.eventTypes.forEach(element => {
        let locationIndex = currentFilter.currentEventTypes.findIndex((obj => obj.id == element.id));
        if(locationIndex==-1){
          element.selected=false;
        }else{
          element.selected=true;
        }
      });

      switch (currentFilter.currentCalendar.id) {
        case 0:
          this.currentSource.events=[...new Set(this.currentSource.allCompanyEvents)];
          if(currentFilter.currentLocations[0].id != 0){
            this.currentSource.events =  [...new Set(this.currentSource.events.filter(e => {
              let location = e.meta?.some(r =>  currentFilter.currentLocations.findIndex((obj => obj.id == r.id)) >= 0);
              return location;
            }))];
          }

          if(currentFilter.currentEventTypes[0].id != 0){
            this.currentSource.events = [...new Set(this.currentSource.events.filter(e => {
              return currentFilter.currentEventTypes.some(r=> r.id == e.eventTypeId);
            }))]
          }

          this.currentSource.events.push(...new Set(this.currentSource.allPersonalEvents));
          this.currentSource.events.sort((a:any, b:any) => a.start-b.start);
          this.calendarSource.next(this.currentSource);
          break;

        case 1:
          this.currentSource.events=[...new Set(this.currentSource.allCompanyEvents)];

          if(currentFilter.currentLocations[0].id != 0){
            this.currentSource.events =  [...new Set(this.currentSource.events.filter(e => {
              let location = e.meta?.some(r =>  currentFilter.currentLocations.findIndex((obj => obj.id == r.id)) >= 0);
              return location;
            }))];
          }

          if(currentFilter.currentEventTypes[0].id != 0){
            this.currentSource.events = [...new Set(this.currentSource.events.filter(e => {
                return currentFilter.currentEventTypes.some(r=> r.id == e.eventTypeId);
            }))]
          }

          this.currentSource.events.sort((a:any, b:any) => a.start-b.start);
          this.calendarSource.next(this.currentSource);
          break;

        case 2:
          this.currentSource.events = [...new Set(this.currentSource.allPersonalEvents)]
          this.currentSource.events.sort((a:any, b:any) => a.start-b.start);
          this.calendarSource.next(this.currentSource);
          break;
      }

      localStorage.setItem("calendarFilterSettings", JSON.stringify(currentFilter))
    }else{

      let calendarFilter:ICalendarFilter = {
        currentCalendar:this.currentSource.calendarTypes[1],
        currentLocations:[this.currentSource.eventLocations[0]],
        currentEventTypes:[this.currentSource.eventTypes[0]]
      }
      this.currentSource.calendarTypes[1].selected = true;
      this.currentSource.eventLocations[0].selected = true;
      this.currentSource.eventTypes[0].selected = true;

      this.currentSource.events = [...new Set(this.currentSource.allCompanyEvents)]

      localStorage.setItem("calendarFilterSettings", JSON.stringify(calendarFilter))

    }

    this.calendarSource.next(this.currentSource);
    this.preloaderService.hidePreloader()
  }
  getLocation() {
    if (this.isMainSiteAdmin && this.adminSelectedLocation !== 'Select') {
      return this.adminSelectedLocation;
    }
    return this.userLocation;
  }
  createUserCalendarEvent(event:IWellbeingEventItem){
    this.addEventToCalendarStatus = undefined;
    this.currentdbEventId = undefined;
    return this.outLookEventApiService.getOutLookEvent(event.id, String(this.getLocation())).pipe(
      switchMap(result => {
        if(result == null){
          return this.createCalendarEvent(event)
        }else if(result != null &&
          result.eventDateTime != event.eventDateTime ||
          result.subject != event.eventName ||
          result.eventLocation != event.location ||
          result.eventBody != event.description){
          return  this.updateCalendarEvent(event, result).pipe(
            catchError(err => {
                 return this.createCalendarEvent(event)
              })
            )
        }else if(result == false){
          this.addEventToCalendarStatus = {
            error:true
          };
          return of(this.addEventToCalendarStatus)
        }

        this.addEventToCalendarStatus = {
          exists:true
        };

        this.currentdbEventId = result.id;
        return of(this.addEventToCalendarStatus)
      }),
      map(() => {return this.addEventToCalendarStatus})
    )
  }
  createCalendarEvent(event,isDuplicated?){
    return this.userGraphApiFacade.createUserCalendarEvent(event).pipe(
      switchMap(value =>  {
        if(value == false){
          this.addEventToCalendarStatus = {
            error: true
          };
          return of(false)
        }

        this.addEventToCalendarStatus = {
          created: true
        };

        this.currentEvent = value;
        if(isDuplicated == true){
          return this.outLookEventApiService.updateOutLookEvent(this.currentdbEventId,value.id,event)
        }

        return this.outLookEventApiService.createOutLookEvent(event,value.id,String(this.getLocation()))
      })).pipe(
        map(() => {return this.addEventToCalendarStatus})
      )
  }
  updateCalendarEvent(spEvent, dbEvent){
    return this.userGraphApiFacade.updateUserCalendarEvent(dbEvent.outlookEventId,spEvent).pipe(
      switchMap(value => {
        if(value == false){
          this.addEventToCalendarStatus = {
            error: true
          };
          return of(false)
        }

        this.addEventToCalendarStatus = {
          created:true
        };

        return this.outLookEventApiService.updateOutLookEvent(dbEvent.id,dbEvent.outlookEventId,spEvent)
      })
    )
  }
}
