import { MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/', ['user.read']);
    protectedResourceMap.set(environment.ApiPath, [environment.ApiScope]);
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }