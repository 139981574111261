import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, shareReplay, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/Core/Services/ApiServices/api.service';
import { IArticleComment, IArticleDetails} from 'src/app/Shared/Models/article.model';
import { PreloaderService } from 'src/app/Shared/Services/preloader.service';

@Injectable({
    providedIn: 'root'
})
export class ArticlesApiFacade {

_currentArticleComments: any[] = [];
public currentArticleCommentsSource$ = new BehaviorSubject<any[]>(this._currentArticleComments);

_articleDetailsSource: any[] = [];
public articleDetailsSource$ = new BehaviorSubject<any>(this._articleDetailsSource);


constructor(private ArticlesApi: ApiService, private preloaderService: PreloaderService) {

}


getArticleDetails(articleIds:number[]){
  let new_array_articleIds = this.splitArray(articleIds, (articleIds.length/10).toFixed(), true)
  let source:Array<Observable<any>> = [];

  new_array_articleIds.forEach(element => {
    source.push(this.ArticlesApi.getArticleDetails(element));
  });

  forkJoin(source).pipe(
    tap(result => {
      var mergedResult = [].concat.apply([], result);

      if(result != null){
        this._articleDetailsSource.push(...mergedResult);
        this.articleDetailsSource$.next(this._articleDetailsSource);

        let filterResult = this._articleDetailsSource.map(a => {
            let obj = {
                articleId:a.articleId,
                comments:a.topComments
            }

            return obj
         });
         this._currentArticleComments.push(...filterResult)
         this.currentArticleCommentsSource$.next(this._currentArticleComments);
      }
    })
  ).subscribe();
}

addArticleLike(ArticleId:number, articleEditor:any){
    return this.ArticlesApi.addArticleLike(ArticleId, articleEditor);
}

removeArticleLike(ArticleId:number){
    return this.ArticlesApi.removeArticleLike(ArticleId);
}

addArticleCommentLike(commentId:number, articleId:number, commentEditor:any){
  return this.ArticlesApi.addArticleComentLike(commentId, articleId, commentEditor);
}

removeArticleCommentLike(commentId:number){
  return this.ArticlesApi.removeArticleCommentLike(commentId);
}

getArticleComments(articleId:number){
    this.preloaderService.showPreloader()
    let comments = this._currentArticleComments.find(c => c.articleId == articleId)

    this.ArticlesApi.getArticleComments(articleId).subscribe(result => {
        result.forEach(element => {
            let double = comments.comments.find(c => c.id == element.id)

            if(double == undefined){
                comments.comments.push(element)
            }
        });

        comments.loadedAll = true;
        comments.limitSlice = comments.comments.length;
        this.preloaderService.hidePreloader()
        this.currentArticleCommentsSource$.next(this._currentArticleComments);
    })


}

getArticleChildComments(commentId:number, articleId){

    let comments = this._currentArticleComments.find(c => c.articleId == articleId)

    if(comments.comments.find(c => c.topParentId == commentId) == undefined){
        this.preloaderService.showPreloader()
        this.ArticlesApi.getArticleChildComments(commentId).subscribe(result => {
            if(comments){
                result.forEach(element => {
                    let double = comments.comments.find(c => c.id == element.id)

                    if(double == undefined){
                        comments.comments.push(element)
                    }
                });
                this.preloaderService.hidePreloader()
                this.currentArticleCommentsSource$.next(this._currentArticleComments);
            }
        })
    }

}

addArticleComment(commentData){
    this.ArticlesApi.addArticleComment(commentData).subscribe(result => {

        let comments = this._currentArticleComments.find(c => c.articleId == commentData.articleId)
        result.createdDate = result.createdDate.split('+')[0]
        result.likesCount = 0
        if(comments){
            comments.comments.unshift(result);
            let comme = comments.comments.find(c => c.id == commentData.topParentId)
            if(comme){
                comme.childCommetsCount++
            }
        }else{
            let obj = {
                articleId:commentData.articleId,
                comments:[result]
            }
            this._currentArticleComments.push(obj)
        }

        let details = this._articleDetailsSource.find(s => s.articleId == commentData.articleId)
        details.articleCommentsCount++
        this.currentArticleCommentsSource$.next(this._currentArticleComments);
        this.articleDetailsSource$.next(this._articleDetailsSource)
    })
}

editArticleComment(commentData){
    this.ArticlesApi.editArticleComment(commentData).subscribe(result => {
        if(result && result.id != 0){
            let comments = this._currentArticleComments.find(c => c.articleId == commentData.articleId)
            var editedComment = comments.comments.find(c => c.id == commentData.commentId);

            if(editedComment != undefined){
                editedComment.comment = result.comment;
                this.currentArticleCommentsSource$.next(this._currentArticleComments);
            }
        }
    })
}

deleteComment(commentData){
    if(this.isChildComments(commentData.id, commentData.articleId)){
        let comment ={
            "commentId": commentData.id,
            "comment": "This comment has been removed",
            "articleId":commentData.articleId
          }
        this.deleteArticleCommentWithChildren(comment)
    }else{
        this.deleteArticleComment(commentData)
    }
}

private deleteArticleCommentWithChildren(commentData){
    this.ArticlesApi.deleteArticleCommentWithChildren(commentData).subscribe(result => {
        if(result && result.id != 0){
            let comments = this._currentArticleComments.find(c => c.articleId == commentData.articleId)
            var editedComment = comments.comments.find(c => c.id == commentData.commentId);

            if(editedComment != undefined){
                editedComment.comment = result.comment;
                editedComment.deleted = result.deleted;
                this.currentArticleCommentsSource$.next(this._currentArticleComments);
            }

        }
    })
}

private deleteArticleComment(comment:IArticleComment){
    this.ArticlesApi.deleteArticleComment(comment.id).subscribe(result => {
        if(result == true){
            let comments = this._currentArticleComments.find(c => c.articleId == comment.articleId)
            var deletedComment = comments.comments.find(c => c.id == comment.id);
            if(deletedComment != undefined){
                comments.comments.splice(comments.comments.indexOf(deletedComment), 1)
                this.currentArticleCommentsSource$.next(this._currentArticleComments);

                let comme = comments.comments.find(c => c.id == comment.topParentId)
                if(comme){
                    comme.childCommetsCount--
                }
            }

            let details = this._articleDetailsSource.find(s => s.articleId == comment.articleId)
            details.articleCommentsCount--
            this.articleDetailsSource$.next(this._articleDetailsSource)
        }
    })
}

private isChildComments(id, articleId){
    let comments = this._currentArticleComments.find(c => c.articleId == articleId)
    return comments.comments.find(c => c.parentId == id) != undefined;
}

private getStructuredComments(commentsArray) {

    let commentsIterator = 0;
    let commentsLength = commentsArray.length;

    while (commentsIterator < commentsLength) {
        if (commentsArray[commentsIterator].parentId != 0 && commentsArray[commentsIterator].parentId != null) {
            for (var j = 0; j < commentsArray.length; j++) {
                if (commentsArray[j].id == commentsArray[commentsIterator].parentId) {
                    if (typeof commentsArray[j].childComments === 'undefined') {
                        commentsArray[j].childComments = [];
                    }
                    commentsArray[j].childComments.push(commentsArray[commentsIterator]);
                    break;
                }
            }
            commentsArray.splice(commentsIterator, 1);
            commentsIterator--;
            commentsLength--;
        }
        commentsIterator++;
    }

    return commentsArray;
}

  splitArray(a, n, balanced) {

    if (n < 2)
        return [a];

    var len = a.length,
            out:any[] = [],
            i = 0,
            size;

    if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
            out.push(a.slice(i, i += size));
        }
    }

    else if (balanced) {
        while (i < len) {
            size = Math.ceil((len - i) / n--);
            out.push(a.slice(i, i += size));
        }
    }

    else {

        n--;
        size = Math.floor(len / n);
        if (len % size === 0)
            size--;
        while (i < size * n) {
            out.push(a.slice(i, i += size));
        }
        out.push(a.slice(size * n));

    }

    return out;
  }
  addArticleFavorite(ArticleId:number){
    return this.ArticlesApi.addArticleFavorite(ArticleId);
  }

  removeArticleFavorite(ArticleId:number){
      return this.ArticlesApi.removeArticleFavorite(ArticleId);
  }

  getArticlesFavorites(){
    return this.ArticlesApi.getArticleFavorites();
  }
  getArticleLikedUserList(eventId: number) {
    return this.ArticlesApi.getArticleLikedUserList(eventId, null)
  }
}
