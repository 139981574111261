import { Injectable } from "@angular/core";
import { result } from "lodash";
import { BehaviorSubject, fromEvent } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HeaderSearchScrollService {

    isHeaderExpanded = new BehaviorSubject<boolean>(false);
    previousScrollY: number = 0;
    deltaScroll: number = 0;
    MIN_SCROLL: number = -150;
  
    constructor(){
      fromEvent(window, 'scroll').subscribe(event => this.setHeaderSearchState(event))
    }

    setHeaderSearchState(event: Event): void {
      let lightbox:any = document.getElementsByClassName("lightbox-shown");
      if(!lightbox[0]){
        var search: any = document.getElementsByClassName('mobile-header__search-top')[0]
        var searchInputField = search.querySelector('input')

        var deltaScrollOnFrame = window.scrollY - this.previousScrollY
        this.deltaScroll = deltaScrollOnFrame >= 0 ? deltaScrollOnFrame : this.deltaScroll + deltaScrollOnFrame
        
        var isHeaderExpandedValue: boolean = this.isHeaderExpanded.value
        isHeaderExpandedValue = (this.deltaScroll > this.MIN_SCROLL && isHeaderExpandedValue || this.deltaScroll >= 0) && window.scrollY != 0

        if(searchInputField != undefined){
          var searchInput = searchInputField.value
          isHeaderExpandedValue = searchInput.length > 0 ? true : isHeaderExpandedValue
        }

        this.previousScrollY = window.scrollY;

        this.isHeaderExpanded.next(isHeaderExpandedValue)
      }
    }

    getHeaderSearchState() {
      return this.isHeaderExpanded
    }
}
