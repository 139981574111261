import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { IWellbeingEventItem } from "src/app/Shared/Models/wellbeing.interface";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class OutLookEventApiService {
  constructor(private http: HttpClient) {}

  getOutLookEvent(eventId:number, location:string): Observable<any> {
    return this.http.get(
      `${environment.ApiPath}api/${environment.API.OutLookEvent.GetOutLookEvent}/${location}/${eventId}`).pipe(
        catchError(err => {return of(false)})
      );
  }

  createOutLookEvent(event:IWellbeingEventItem, outlookEventId:string, location:string): Observable<boolean> {
    let body = {
      eventId: event.id,
      outlookEventId: outlookEventId,
      subject: event.eventName,
      eventBody:event.description,
      eventLocation:event.location,
      discriminator: location,
      eventDateTime: event.eventDateTime,
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.OutLookEvent.CreateOutLookEvent}`,body);
  }

  updateOutLookEvent(id:number,outlookEventId:string, event:IWellbeingEventItem,): Observable<boolean> {
    let body = {
      id:id,
      outlookEventId:outlookEventId,
      subject: event.eventName,
      eventDateTime: event.eventDateTime,
      eventBody:event.description,
      eventLocation:event.location
    };
    return this.http.post<boolean>(
      `${environment.ApiPath}api/${environment.API.OutLookEvent.UpdateOutLookEvent}`,body);
  }
}
