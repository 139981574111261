import { IEvent } from "./calendar.interface";
import { IUserPermitions } from "./user.model";
import { IWellbeingPopularEvent } from "./wellbeing.interface";

export interface ICalendarEvent {
  color: string,
  title: string,
  time: string,
  users: string[]
}

export class DashboardSource {
  shortcuts: IDashboardShortcut[];
  header: IDashboardHeader;
  popularActivities: IWellbeingPopularEvent[];
  currentBudget:number;
  dashboardEvents: IEvent[];
  monthlyActivitiesPDF:string;
  userPermitions:IUserPermitions
}

export interface IDashboardDataResponse {
  dashboardShortcuts: IDashboardShortcut[],
  dashboardHeader: IDashboardHeader,
  popularActivities: IWellbeingPopularEvent[],
  dashboardEvents: IEvent[],
  monthlyActivitiesPDF:string,
  userPermitions:IUserPermitions
}

export interface IDashboardShortcut {
  id: number,
  title: string,
  url: string,
  isExternalLink?: boolean,
  iconUrl: string
}

export interface IDashboardHeader {
  description: string,
  url?: string,
  isExternalLink?: boolean
}

export interface IDashboardColor {
  backgroundColor: string,
  shortcutColor: string,
}

export interface ICibusData {
  leftQuantity: number;
  leftPercentage: number;
}
