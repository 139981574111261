import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Subscription, of } from 'rxjs';
import { MainMenuGraphFacade } from 'src/app/Facade/GraphApiFacade/main-menu-graph.facade';
import { MainMenu } from '../../../Shared/Models/mainMenu.model';
import { DashboardService } from '../../Services/DashboardService/DashboardService';
import { AnnouncementsGraphFacade } from 'src/app/Facade/GraphApiFacade/announcements-graph.facade';

const HEADER_HEIGHT = 73;
const SCROLL_START_ANIMATION = 120;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  mainMenu: MainMenu[];
  currentDate = new Date();
  modalHeaderRef?: BsModalRef;
  modalHeaderIsOpen: boolean = false;
  showTooltip = false;
  wellbeingHeader = false;
  headerTransparent = false;
  configHeader = {
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'modalHeader',
    animated: false
  };
  currentRoute: string = '';
  newannouncementsCountSourceSubscription:Subscription;
  notificationsCountToSee: number =0;

  @ViewChild('header') header;
  @ViewChild('showHeaderTooltipTemplate') showHeaderTooltipTemplate: ElementRef;
  @ViewChild('pop') tooltip;
  @Input() modalHeaderService: BsModalService;
  constructor(private announcementsGraphFacade:AnnouncementsGraphFacade,private mainMenuGraphFacade: MainMenuGraphFacade, private router: Router, private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.router.events.subscribe(e => {
      
      if (e instanceof NavigationEnd || e instanceof NavigationStart) {
        this.currentRoute = e.url.split('/')[1];
        if (e.url.includes('wellbeing')) {
          this.wellbeingHeader = true;
        } else {
          this.wellbeingHeader = false;
        }
      }
    })
    this.showTooltip = this.getStorageValue();

    if (this.showTooltip) {
      setTimeout(() => {
        if(this.showHeaderTooltipTemplate && this.showHeaderTooltipTemplate.nativeElement)
        this.showHeaderTooltipTemplate.nativeElement.click();
      }, 2500);
    }

    this.mainMenuGraphFacade.mainMenu$.subscribe(result => {
      this.mainMenu = result;
    })

    this.getCountNotifications()
     this.newannouncementsCountSourceSubscription = this.announcementsGraphFacade.newannouncementsCountSource$.subscribe(source => {
      this.notificationsCountToSee = source;
    });
  }

  getCountNotifications(){
    this.announcementsGraphFacade.getUserCountNotificationsToSee();
  }

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event) {
  //   if (window.scrollY > SCROLL_START_ANIMATION) {
  //     this.header.nativeElement.classList.add('sticky');
  //     this.header.nativeElement.style.top = Math.min(- HEADER_HEIGHT - SCROLL_START_ANIMATION + window.scrollY, 0) + 'px';
  //   } else {
  //     this.header.nativeElement.classList.remove('sticky');
  //     this.header.nativeElement.style.top = 0;
  //   }
  // }

  toggleHeaderModal(templateHeader: TemplateRef<any>) {
    if (this.modalHeaderIsOpen == true) {
      this.modalHeaderIsOpen = false;
    }
    if (!this.modalHeaderIsOpen) {
      this.modalHeaderRef = this.modalHeaderService.show(templateHeader, this.configHeader);
    } else {
      this.modalHeaderService.hide();
    }

    this.modalHeaderIsOpen = !this.modalHeaderIsOpen;

    if (this.showTooltip) {
      this.hideTooltip();
    }

  }

  closeHeaderModal() {
    this.modalHeaderService.hide();
    this.modalHeaderIsOpen = false;
  }

  hideTooltip() {
    this.tooltip.hide();
    localStorage.setItem('showtooltip', 'false');
  }

  private getStorageValue() {
    const value = localStorage.getItem('showtooltip');
    return (value == null || value != 'false');
  }
}
