<div style="border:1px solid {{event.color!.primary}};
  background-color: {{event.color!.secondary}};" class="event-item">
      <div class="event-item__left">
          <img [src]="event.icon">
      </div>
      <div class="event-item__middle">
          <div class="event-item__middle-title" [ngClass]="{'hebrew-font': isHebrew(event.title) == true }">
              <span *ngIf="hasImage(event.title)">{{event.title.split("img")[1].split('">')[1]}}</span>
              <span *ngIf="!hasImage(event.title)">{{event.title}}</span>
          </div>
          <div *ngIf="event.organizer" class="event-item__middle-description">{{event.organizer}}</div>
          <div *ngIf="event.isCompany" class="event-item__middle__addToCalendar">
            <button  [ngClass]="{'wellbeing-details__footer__addbutton-disabled':addToEventCalendarDisabled}"  (click)="addToEventCalendar(event)">
              <img src="/assets/images/calendar/AddToCalendar.png" alt="Add to calendar"/>
              Add to calendar
            </button>
            <button *ngIf="isEditMode && event.id && !isPersonalCalendarEvent" [ngClass]="{'wellbeing-details__footer__addbutton-disabled':addToEventCalendarDisabled}"  (click)="editEvent(event.id, false)">
              <img src="/assets/images/global/edit-app-pencil.png" alt="Edit event">
              Edit
            </button>

            <button *ngIf="isEditMode && event.id" [ngClass]="{'wellbeing-details__footer__addbutton-disabled':addToEventCalendarDisabled}"  (click)="editEvent(event.id, true, event.title)">
              <img src="/assets/images/global/edit-app-remove.png" alt="Delete event">
              Delete
            </button>
          </div>

      </div>

      <div *ngIf="!event.allDay" class="event-item__right">{{event.start | date:'HH:mm'}}</div>
      <div *ngIf="event.allDay == true && (event.daysCount == 0 || event.daysCount == 1)" class="event-item__right">All day</div>
      <div *ngIf="event.daysCount && event.allDay == true && event.daysCount > 1" class="event-item__right">{{event.daysCount}} days</div>
  </div>
  <ng-template #modalSuccessAddedEvent>
    <div class="success-modal" *ngIf="isSuccess">
      <div class="success-modal__header">
        <button
          class="success-modal__header__close-icon"
          (click)="closeModal()"
          aria-label="Close"
          type="button"
        >
          <img
            src="../../../../assets/images/global/close-modal-apps-page.png"
            alt="close-modal"
          />
        </button>
      </div>
      <div class="success-modal__content">
        <div *ngIf="!event.end || event.end && areDateTimesSame(event.start, event.end)" class="success-modal__content__header">
          The event {{ textTitle }} added to your calendar on {{ event.start | date: "d/M/yy" }} at {{event.start | date: "h:mm a" }}
        </div>
        <div *ngIf="event.end && event.daysCount < 1 && !areDateTimesSame(event.start, event.end)" class="success-modal__content__header">
          The event {{ textTitle }} added to your calendar on {{ event.start | date: "d/M/yy" }} from {{event.start | date: "h:mm a" }} to {{event.end | date: "h:mm a"}}
        </div>
        <div *ngIf="event.end && event.daysCount > 1" class="success-modal__content__header">
          The event {{ textTitle }} added to your calendar from {{ event.start | date: "d/M/yy" }} {{event.start | date: "h:mm a" }} to {{ event.end | date: "d/M/yy" }} {{event.end | date: "h:mm a"}}
        </div>
        <img
          src="../../../../assets/images/global/add-apps-success.png"
          alt="Success"
        />
      </div>
    </div>
  </ng-template>

  <ng-template #modalError>
    <div class="success-modal" *ngIf="isSuccess">
      <div class="success-modal__header">
        <button
          class="success-modal__header__close-icon"
          (click)="closeModal()"
          aria-label="Close"
          type="button"
        >
          <img
            src="../../../../assets/images/global/close-modal-apps-page.png"
            alt="close-modal"
          />
        </button>
      </div>
      <div class="success-modal__content">
        <div class="success-modal__content__header">
          Something went wrong. Please try again later!!!
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalConfirmToDuplicateEvent>
    <app-confirm-alert-modal class="confirm-modal"
    (isConfirmedEmitter)="isConfirmedToDuplicate($event,event)"
    ></app-confirm-alert-modal>
  </ng-template>
