import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VodsApiFacade } from 'src/app/Facade/ApiFacade/vods.facade';
import { IVodComment, IVodDetails } from '../../../../Shared/Models/vod-item.interface';
import { UserProfileService } from '../../../../Shared/Services/user-profile.service';
import { CommentaryService } from './commentary.service';


@Component({
  selector: 'vod-comments',
  templateUrl: './vod-comments.component.html',
  styleUrls: ['./vod-comments.component.scss']
})
export class VodCommentsComponent implements OnInit {

  @Input() vodId;
  @Input() padding;
  @Input() isInsideOfModal = false;
  @Input() vodEditor?:string;

  vodTitle;
  isEditing = false;
  isAnswer = false;
  isMobile = false;
  showAllcomments = false;
  loadedAllComments = false;
  showLessComments = false;
  loadMoreButtonClicked = false
  addedComments = false;
  currentCommentId;
  currentCommentIdReply;
  currentCommentIdEdit;
  vodComments:IVodComment[];

  commentToDelete:IVodComment;
  commentToEdit:IVodComment;
  commentControl = new UntypedFormControl("");
  commentEditControl = new UntypedFormControl("");
  commentReplyControl = new UntypedFormControl("");
  userEmail:string;
  vodDatails:IVodDetails;
  modalRef?: BsModalRef;
  mobileModalRef?: BsModalRef;

  limitSlice = 2;
  datepipe: DatePipe = new DatePipe('en-US')
  today = this.datepipe.transform(Date.now(),'yyyy-MM-dd')?.split('T')[0]

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'38px',
      'height':'38px'
    },
    size: 'smaller'
  }

  constructor(private vodsApiFacade:VodsApiFacade,
    private commentaryService:CommentaryService,
    private modalService: BsModalService,
    private userProfileService:UserProfileService) { }

  ngOnInit(): void {
    this.vodsApiFacade.currentVodCommentsSource$.subscribe(source => {
      let comments = source.find(c => c.vodId == this.vodId)

      if(comments != undefined){
        this.vodComments = comments.comments.filter(comment=> comment.parentId == null);
        this.checkLoadMore(this.vodComments)
      }

      if(comments && comments.comments.length > 0 && this.loadMoreButtonClicked){
        this.limitSlice = this.vodComments.length;
        this.loadedAllComments = true;
        this.showLessComments =  true;
      }
    })

    this.vodsApiFacade.vodDetailsSource$.subscribe(source => {
      let details = source.find(s => s.vodId == Number(this.vodId))
      if(details != undefined){
        this.vodDatails = details;
        this.checkLoadMore(this.vodComments)
      }
    })

    this.userProfileService.userProfileSource$.subscribe(user => {
      this.userEmail = user.userPrincipalName;
    })

    this.commentaryService.commentarySource$.subscribe(state => {
      this.currentCommentIdReply = state.currentCommentIdReply;
      this.currentCommentIdEdit = state.currentCommentIdEdit;

      if(this.vodId != state.currentCommentLoadMore){
        this.onViewLessComments();
      }
    })

    if (this.isInsideOfModal) {
      this.getVodComments();
    }

    this.onResize()
  }

  checkLoadMore(comments){
    let count=0;
    if(this.vodComments && this.vodDatails){

      for (let index = 0; index < comments.length; index++) {
        const element = comments[index];

        count+=element.childCommetsCount
        if(index == 1 || index == 0 && comments.length == 1){
          count+=comments.length;
          if(count == this.vodDatails.vodCommentsCount && this.vodComments.length <= 2){
            this.loadedAllComments = true;
          }else if(count < this.vodDatails.vodCommentsCount && !this.showLessComments){
            this.loadedAllComments = false;
          }
          break;
        }
      }

      if(this.vodComments.length <= 2 && this.showLessComments == true){
        this.showLessComments = false;
      }

      if(this.vodComments.length > 2 && !this.loadMoreButtonClicked){
        this.loadedAllComments = false;
      }

      if(this.vodComments.length == 0 && this.vodDatails.vodCommentsCount == 0){
        this.loadedAllComments = true;
      }
    }
  }

  getVodComments(){
    this.commentaryService.setCurrentCommentaryLoadMore(this.vodId)
    if(this.vodComments.length <=2 || this.addedComments == true){
      this.vodsApiFacade.getVodComments(this.vodId);
    }else{
      this.limitSlice = this.vodComments.length;
      this.loadedAllComments = true;
      this.showLessComments =  true;
    }
    this.loadMoreButtonClicked = true;
  }

  onViewLess(){
    this.showAllcomments = false;
    this.currentCommentId = -1;
  }

  onViewLessComments(){
    this.limitSlice = 2;
    this.loadedAllComments = false;
    this.showLessComments =  false;
    this.loadMoreButtonClicked = false;
    this.addedComments = false;
    this.checkLoadMore(this.vodComments)
  }

  onViewAll(commentaryId){
    this.currentCommentId = commentaryId;
    this.showAllcomments = true;
    this.vodsApiFacade.getVodChildComments(commentaryId, this.vodId)
  }

  onSaveComment(){
    let commentText= this.commentControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comment ={
      "vodId": this.vodId,
      "parentId": null,
      "comment": commentText,
      "relatedUserEmail": this.vodEditor
    }

    if(this.commentControl.value && this.commentControl.value.trim() != '' && this.commentControl.value != ''){
      this.vodsApiFacade.addVodComment(comment);
      this.commentControl = new UntypedFormControl("")
      this.addedComments = true;
    }
  }

  onConfirmReply(comme:IVodComment){
    let replyText= this.commentReplyControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comment ={
      "vodId": this.vodId,
      "parentId": comme.id,
      "topParentId": comme.id,
      "comment": replyText,
      "relatedUserEmail": comme.userEmail
    }

    if(this.commentReplyControl.value && this.commentReplyControl.value.trim() != '' && this.commentReplyControl.value != ''){
      this.commentaryService.setCurrentCommentaryReplyId(-0);
      this.vodsApiFacade.addVodComment(comment);
    }
  }

  onEditConfirm(){
    let editText= this.commentEditControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comme ={
      "commentId": this.commentToEdit.id,
      "comment": editText,
      "vodId": this.vodId
    }

    if(this.commentEditControl.value && this.commentEditControl.value.trim() != '' && this.commentEditControl.value != ''){
      this.vodsApiFacade.editVodComment(comme);
      this.commentaryService.setCurrentCommentaryEditId(-1);
    }

  }

  onEditStart(comment){
    this.commentToEdit = comment;
      this.commentEditControl = new UntypedFormControl(comment.comment);
      this.commentaryService.setCurrentCommentaryEditId(comment.id);
  }

  onReplyStart(comment){
    this.commentReplyControl = new UntypedFormControl("")
    this.commentaryService.setCurrentCommentaryReplyId(comment.id);
  }

  onEditCancel(){
    this.commentEditControl = new UntypedFormControl("");
    this.commentaryService.setCurrentCommentaryEditId(-1);
  }

  onDeleteComment(){
    this.vodsApiFacade.deleteComment(this.commentToDelete);
    this.modalRef?.hide();
  }

  AddCommentLike(comment: IVodComment){
    this.vodsApiFacade.addVodCommentLike(comment.id, comment.vodId, comment.userEmail).subscribe( result => {
      if(result == true){
        comment.isUserLike = true;
        comment.likesCount +=1;
      }
    })
  }

  RemoveCommentLike(comment: IVodComment){
    this.vodsApiFacade.removeVodCommentLike(comment.id).subscribe( result => {
      if(result == true){
        comment.isUserLike = false;
        comment.likesCount -=1;
      }
    })
  }

  openAlertModal(template: TemplateRef<any>, comment) {
    this.commentToDelete = comment;
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  openAllCommentsModal(template: TemplateRef<any>) {
    if (!this.isInsideOfModal) {
      this.mobileModalRef = this.modalService.show(template,{ignoreBackdropClick:true, animated:false});
    }
  }

  closeModal() {
    if (!this.isInsideOfModal) {
      this.mobileModalRef?.hide();
    }
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }
}
