<div class="comment-edit">
  <div class="comment-edit__top">
    <div class="comment-edit__top-left"></div>
    <div class="comment-edit__user-name">
      {{userName}}
  </div>
  </div>
    <div  class="comment-edit__content">
      <div class="comment-edit__icons">
        <img (click)="toggled = !toggled" src="/assets/images/global/smille-icon-orange.png">
        <app-emojs class="box-emojs" *ngIf="toggled" (onChooseEmojiEmitter)="handleEmoji($event)"></app-emojs>
      </div>

      <div  class="comment-edit__text">
          <form>
            <ckeditor #EditToolEditor class="edit-content-display" [formControl]="commentEditControl" [config]="ckConfig" [editor]="Editor"></ckeditor>
          </form>
      </div>

      <div class="comment-edit__submit" (click)="onConfirm()">
        <img src="/assets/images/global/send-icon.png">
      </div>
    </div>
</div>
