<div class="right-bar__main sticky" #stickyMenu
  >
  <div>
    <app-banners [banners]="sideBanners"></app-banners>
  </div>
  <div class="right-bar__main__sections">
    <!-- <div *ngFor="let category of sideItemsCategories" class="right-bar__main__section">
      <button (click)="onToggleWikiNavMenu(category)">
        <div class="right-bar__main__section__image">
          <div class="right-bar__main__section__image__shadow">
            <img class="right-bar__main__section__image__icon" [src]="category.image" onerror="this.onerror=null;this.src='/assets/images/global/spinner.gif';">
          </div>
          <div class="right-bar__main__section__title" title="{{category.title}}">{{category.title}}</div>
          <div class="right-bar__main__section__arrow">
            <img *ngIf="!isActiveNav || currentNavItem.id != category.id" src="/assets/images/global/icon-system-chevron.png" alt="dropdown" >
            <img *ngIf="isActiveNav && currentNavItem.id == category.id" src="/assets/images/global/icon-system-chevron-acive.png" alt="dropdown" >
          </div>
        </div>
      </button>
      <div [@openClose]="isActiveNav && currentNavItem.id == category.id" class="right-bar__main__section__links">
        <div class="link" *ngFor="let item of category.items">
          <ng-container *ngIf="!hasChildren(item)">
            <a *ngIf="item.isExternal && !item.isPdf" href="{{item.link}}" target="_blank">{{item.title}}</a>
            <a *ngIf="!item.isExternal && !item.isPdf" routerLink="/{{item.link}}">{{item.title}}</a>
            <a (click)="onPdfOpen(item.link)" *ngIf="item.isPdf">{{item.title}}</a>
          </ng-container>
          <ng-container *ngIf="hasChildren(item)">
            <div *ngIf="item" class="link__parent" (click)="onStateDropdownClick(item.id)">
              <a *ngIf="item.isExternal && !item.isPdf" href="{{item.link}}" target="_blank">{{item.title}}</a>
              <a *ngIf="!item.isExternal && !item.isPdf" routerLink="/{{item.link}}">{{item.title}}</a>
              <a (click)="onPdfOpen(item.link)" *ngIf="item.isPdf">{{item.title}}</a>
              <div
                class="link__dropdown-button"
                [@dropdownArrow]="{ value: getSideItemDropdownState(item.id)}">
              </div>
            </div>
          </ng-container>

          <div class="link__children" *ngIf="hasChildren(item)" [@dropdown]="{ value: getSideItemDropdownState(item.id)}">
            <ng-container *ngFor="let childItem of item.children">
              <a *ngIf="childItem.isExternal && !childItem.isPdf" href="{{childItem.link}}" target="_blank" class="link__item">{{childItem.title}}</a>
              <a *ngIf="!childItem.isExternal && !childItem.isPdf" routerLink="/{{childItem.link}}" class="link__item">{{childItem.title}}</a>
              <a (click)="onPdfOpen(childItem.link)" *ngIf="childItem.isPdf" class="link__item" >{{childItem.title}}</a>
            </ng-container>
          </div>
        </div>
      </div>
  </div> -->


  </div>
</div>