import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {


    private userProfile = new BehaviorSubject<any>({});
    public userProfileSource$ = this.userProfile.asObservable();

    constructor(
      private userApiFacade: UserApiFacade
    ){}

    setUserProfile(value:any){
        this.userProfile.next(value);

        if (value && value.userPrincipalName) {
          this.userApiFacade.getUserProfile(value.userPrincipalName).subscribe();
        }
    }
}
