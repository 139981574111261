import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IEvent } from 'src/app/Shared/Models/calendar.interface';
import { MoreEventsModalService } from 'src/app/Shared/Services/more-events-modal.service';

@Component({
  selector: 'app-dashboard-events',
  templateUrl: './dashboard-events.component.html',
  styleUrls: ['./dashboard-events.component.scss']
})
export class DashboardEventsComponent implements OnInit {

  @Input() dashboardEvents: IEvent[];

  @Output() onRefreshCalendarDataEmmiter = new EventEmitter<boolean>();

  selectedEvent: IEvent;
  modalRef?: BsModalRef;
  isModalOpened: boolean = false;

  constructor(private moreEventsModalService: MoreEventsModalService) { }

  ngOnInit(): void { }

  openModal(event: any, template: TemplateRef<any>, selectedEvent: IEvent){
    this.selectedEvent = selectedEvent;
    if(!this.isModalOpened){
      this.isModalOpened = true;

      event.preventDefault()
      var element = <HTMLElement> event.currentTarget
      
      this.moreEventsModalService.openModal(template, element);
    }
  }

  closeModal(){
    this.isModalOpened = false;
    this.moreEventsModalService.closeModal();
  }

  getSelectedEvent() {
    var result: IEvent[] = [];

    if (this.selectedEvent) {
      result.push(this.selectedEvent)
    }

    return result;
  }

  refreshCalendar() {
    this.onRefreshCalendarDataEmmiter.emit();
  }
}
