<div class="container" *ngIf="articleId && aiArticleDetails" [ngClass]="{'container-margin-top': aiArticleDetails.articleCommentsCount > 0,'container-padding-l container-padding-r':padding, 'container-no-borders': !padding}">
    <div *ngIf="isInsideOfModal" class="container-mobile-padding"></div>
    <div  class="comment__view-all" style="margin-top: 11px; cursor:default;">
        <p *ngIf="articleComments.length == 0" style="cursor:default;">
            0 comments
        </p>
        <p *ngIf="(!isMobile && articleComments.length > 0) || isInsideOfModal == true" style="cursor:default;">
            All comments ({{aiArticleDetails.articleCommentsCount}})
        </p>
        <p *ngIf="isMobile && articleComments.length > 0 && isInsideOfModal == false" style="cursor:pointer;" (click)="openAllCommentsModal(allCommentsModal)">
            View all comments ({{aiArticleDetails.articleCommentsCount}})
        </p>
    </div>
    <div style="position: relative;" *ngFor="let comment of (articleComments && articleComments.slice(0,limitSlice)); let i=index">
      <div
      *ngIf="comment.displayName
      && currentCommentId != comment.id
      && currentCommentIdEdit != comment.id
      && comment.childCommetsCount > 0" class="count-comment-vector-indicator"></div>
      <div
      *ngIf="comment.displayName
      && currentCommentId == comment.id
      && comment.childCommetsCount > 0" class="top-parent-vector"></div>
      <div  class="comment">
            <div class="comment__content">

                <div class="comment__user-photo">
                    <app-user-photo
                    [userEmail]="comment.userEmail"
                    [userPhotoSettings]="userPhotoSettings"
                    (userDisplayName)="comment.displayName = $event"
                    ></app-user-photo>
                </div>
                <div style="width: 100%; position: relative;">
                    <img *ngIf="comment.isUserLike && this.currentCommentIdEdit != comment.id && !comment.deleted" class="comment__like-icon" src="/assets/images/article/article-like-circle-active.png">
                    <div *ngIf="currentCommentIdEdit != comment.id" class="comment__description">
                        <div *ngIf="!comment.deleted" class="comment__user-name">
                            {{comment.displayName}}
                        </div>
                        <div class="comment__text"  [innerHTML]="comment.comment | safeHtml" [ngClass]="{'comment__deleted':comment.deleted}">

                        </div>
                    </div>

                    <app-edit-ai-articles-comment-box
                        *ngIf="currentCommentIdEdit == comment.id"
                        [commentEditControl]="commentEditControl"
                        (onSaveEmitter)="onEditConfirm()"
                        [userName]="comment.displayName"
                    ></app-edit-ai-articles-comment-box>

                    <div *ngIf="currentCommentIdEdit == comment.id && !comment.deleted" class="comment__footer">
                        <div class="comment__footer-buttons">
                            <span (click)="onEditCancel()" class="esc-text">Press Esc to <span style="color:#ff874f">cancel</span></span>
                        </div>
                    </div>

                    <div *ngIf="currentCommentIdEdit != comment.id" class="comment__footer">
                        <div *ngIf="!comment.deleted" class="comment__footer-buttons">
                            <div *ngIf="userEmail == comment.userEmail" class="comment__footer-modify">
                                <span class="comment__footer-divider-circle" (click)="onEditStart(comment)">Edit</span>
                                <span class="comment__footer-divider-circle"(click)="openAlertModal(templateConfirm, comment)">Delete</span>
                            </div>
                            <div class="comment__footer-reations">
                                <span class="comment__footer-divider-circle" (click)="comment.isUserLike == false? AddCommentLike(comment) : RemoveCommentLike(comment)"
                                [ngClass]="{'active-like':comment.isUserLike}">Like</span>
                                <span class="comment__footer-divider-circle" (click)="onReplyStart(comment)">Reply</span>
                                <span class="datetime" *ngIf="comment.createdDate!.split('T')[0]==today">at {{ comment.createdDate+'Z' | date: 'shortTime'}}</span>
                                <span class="datetime" *ngIf="comment.createdDate!.split('T')[0]!=today">{{ comment.createdDate+'Z' | date: 'longDate'}}</span>
                            </div>
                        </div>

                        <app-add-ai-articles-comment-box
                            *ngIf="currentCommentIdReply == comment.id"
                            [userEmail]="userEmail"
                            [isReply]="true"
                            [commentControl]="commentReplyControl"
                            (onSaveEmitter)="onConfirmReply(comment)"
                        ></app-add-ai-articles-comment-box>

                        <div *ngIf="this.currentCommentId != comment.id && comment.childCommetsCount > 0" class="comment__view-all">
                            <p (click)="onViewAll(comment.id)">View {{comment.childCommetsCount}} repl{{comment.childCommetsCount ==1 ? 'y' : 'ies'}}<img class="arrow" src="/assets/images/global/comment-arrow-grey.png"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-ai-articles-comment-item
            *ngIf="showAllcomments == true && this.currentCommentId  == comment.id"
            [parentId]="comment.id"
            [articleId]="articleId"
        ></app-ai-articles-comment-item>

        <div class="view-less__childrem">
            <div style="width: 38px;"></div>
            <div (click)="onViewLess()" *ngIf="showAllcomments == true && this.currentCommentId == comment.id && comment.childCommetsCount>0" class="view-less">
                <p class="view-less__text">View less <img class="arrow" src="/assets/images/global/comment-arrow-orange-up.png"></p>
            </div>
        </div>

    </div>
    <div *ngIf="!loadedAllComments && !isMobile" (click)="getAiArticlesComments()" class="view-less">
        <p class="view-less__text">Load more<img class="arrow" src="/assets/images/global/comment-arrow-orange-down.png"></p>
    </div>

    <div *ngIf="showLessComments && !isMobile" (click)="onViewLessComments()" class="view-less">
        <p class="view-less__text">View less <img class="arrow" src="/assets/images/global/comment-arrow-orange-up.png"></p>
    </div>
</div>

<div class="add-comment" [ngClass]="{'container-padding-l container-padding-r container-padding-b':padding}">
    <app-add-ai-articles-comment-box
    [userEmail]="userEmail"
    [commentControl]="commentControl"
    (onSaveEmitter)="onSaveComment()"
    [articleId]="articleId"
    ></app-add-ai-articles-comment-box>

    <ng-template #templateConfirm>
        <app-delete-alert-modal
        [message]="'Are you sure you want to delete this comment?'"
        (onCloseModalEmmiter)="modalRef?.hide()"
        (onDeleteButtonEmmiter)="onDeleteComment()"
        ></app-delete-alert-modal>
      </ng-template>
</div>

<ng-template #allCommentsModal>
    <app-ai-articles-comments-mobile [articleId]="articleId" (closeModalEmitter)="closeModal()"></app-ai-articles-comments-mobile>
</ng-template>
