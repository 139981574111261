<app-preloader></app-preloader>
<div class="birthday-wish-modal">
    <div class="header">
        Birthdays
      <button
        (click)="onCloseModalEmmiter.emit(true)"
        class="close-icon"
        aria-label="Close"
        type="button"
      >
        <img
          src="/assets/images/global/close-modal-apps-page.png"
          alt="close-modal"
        />
      </button>
    </div>
    <div class="content">
        <div class="birthday" *ngFor="let control of withFormControls; let i=index">
            <div class="birthday__top">
                <div class="birthday__user">
                    <div class="birthday__user-photo">
                        <app-user-photo
                        [userEmail]="recentPeopleBirthdays[i].userEmail"
                        [userPhotoSettings]="userPhotoSettings"
                        ></app-user-photo>
                    </div>
                    <div class="birthday__user-name">{{recentPeopleBirthdays[i].displayName}}</div>
                </div>
                <div class="birthday__icon">
                    <img title="click to send a birthday cake" (click)="onSendCake(true, recentPeopleBirthdays[i], i)" [src]="birthdayCake" alt="">
                </div>
            </div>
            <div class="birthday__bottom">
                <ng-container *ngIf="ckConfig && Editor">
                    <form>
                        <ckeditor [ngClass]="{'content-hebrew': isHebrewText == true && currentUser.userEmail == recentPeopleBirthdays[i].userEmail }"
                        id="{{recentPeopleBirthdays[i].userEmail}}" 
                        (focus)="focusEvent(recentPeopleBirthdays[i])" 
                        (click)="clickEvent(i,recentPeopleBirthdays[i], BirthdayEditor)"   
                        #BirthdayEditor class="content-display" 
                        [formControl]="control" 
                        [config]="ckConfig" 
                        (change)="isHebrew()"
                        [editor]="Editor"></ckeditor>
                    </form>
                </ng-container>
    
                <div class="birthday__icons">
                    <img (click)="onOpenEmojiBox(recentPeopleBirthdays[i])" src="/assets/images/global/smille-icon.png"
                    >
                    <app-emojs [ngClass]="{'box-emojs-top': i==0}" class="box-emojs" *ngIf="toggled && currentUser && currentUser.userEmail==recentPeopleBirthdays[i].userEmail" (onChooseEmojiEmitter)="handleEmoji($event)"></app-emojs>
                </div>
            </div>
            <div *ngIf="recentPeopleBirthdays[i].successInformation" [innerHTML]="recentPeopleBirthdays[i].successInformation | safeHtml" class="successfully-sent">
            </div>
        </div>
    </div>
  </div>
