import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { DashboardApiService } from "src/app/Core/Services/ApiServices/api.dashboard";
import { DashboardSource } from "src/app/Shared/Models/sidebar-dashboard.model";
import { UserApiFacade } from "./user.facade";
import { IUserPermitions } from "src/app/Shared/Models/user.model";

@Injectable({
    providedIn: 'root'
})
export class DashboardsApiFacade {

    _dashboardSource:DashboardSource = new DashboardSource();
    userPermitions:IUserPermitions;
    dashboardSource$:Subject<DashboardSource>
    = new Subject<DashboardSource> ();

    currentBudgetSource$:Subject<number | undefined>
    = new Subject<number | undefined>();

    constructor(
        private dashboardApiService: DashboardApiService,
        private userFacade: UserApiFacade
    ) {}

    getDashboardInitData(){
    //   this._dashboardSource = new DashboardSource()
    //   this.dashboardSource$.next(this._dashboardSource)
       return this.dashboardApiService.getDashboardInitData().pipe(
           tap(data => {
               this._dashboardSource.shortcuts = data.dashboardShortcuts;
               this._dashboardSource.header = data.dashboardHeader;
               this._dashboardSource.popularActivities = data.popularActivities;
               this._dashboardSource.dashboardEvents = data.dashboardEvents;
               this._dashboardSource.monthlyActivitiesPDF = data.monthlyActivitiesPDF;
               this._dashboardSource.userPermitions = data.userPermitions;
               this.userPermitions = data.userPermitions;
               this.dashboardSource$.next(this._dashboardSource);
           })
       )
    }


    getCurrentBudget(){
        return this.dashboardApiService.getCurrentBudget().pipe(
            tap(result => (
                this.currentBudgetSource$.next(result)
            ))
        )
    }

    getUserPermisions(){
        return this.userPermitions;
    }


}
