import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ai-articles-comments-mobile',
  templateUrl: './ai-articles-comments-mobile.component.html',
  styleUrls: ['./ai-articles-comments-mobile.component.scss']
})
export class AiArticlesCommentsMobileComponent implements OnInit {

  @Input() articleId
  @Output() closeModalEmitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closeModal() {
    this.closeModalEmitter.emit()
  }
}
