<div class="more-events-modal" [ngClass]="{'on-dashboard': onDashboard}" #container>
    <div class="header">
        <p class="header-title" *ngIf="dayEvents">{{day | date:' d MMMM y'}}</p>
        <button
            (click)="onCloseModalEmmiter.emit(true)"
            class="close-icon"
            aria-label="Close"
            type="button">
            <img src="/assets/images/global/close-modal-apps-page.png"
                alt="close-modal"/>
        </button>
    </div>

  <div class="content" *ngIf="dayEvents">
      <ng-container *ngFor="let event of dayEvents">
       <app-calendar-event-item
       [event]="event"
       [isEditMode]="isEditMode"
       [isPersonalCalendarEvent]="isPersonalCalendarEvent"
       (onRefreshCalendarData)="refreshCalendarDataEmmiter()"
       (onEventEditPress)="editEvent($event)"
       ></app-calendar-event-item>
      </ng-container>
  </div>
</div>
