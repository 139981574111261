import { Component, ElementRef, HostListener, Input,  OnDestroy, OnInit,  ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardsApiFacade } from 'src/app/Facade/ApiFacade/dashboard.facade';
import { TextWidthService } from 'src/app/Shared/Services/text-width.service';
import { DashboardService } from '../../Services/DashboardService/DashboardService';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'app-dashboard-calendar-placeholder',
  templateUrl: './dashboard-calendar-placeholder.component.html',
  styleUrls: ['./dashboard-calendar-placeholder.component.scss']
})
export class DashboardCalendarPlaceholderComponent implements OnInit,  OnDestroy {

  @Input() areEventsLoading: boolean;
  placeholderImage: string;
  placeholderText: string = ""//"Your day is free, use it for something good";
  firstLine: string = "";
  hideClass: string = "";
  secondLine: string = "";
  maxSecondLineElementWidth: number = 520;
  maxFirstLineElementWidth: number = 400;
  separators: string[] = ['.', ','];
  @ViewChild('placeholderFirstLine', {read: ElementRef}) firstLineElement: ElementRef<HTMLElement>;
  @ViewChild('placeholderSecondLine', {read: ElementRef}) secondLineElement: ElementRef<HTMLElement>;
  @ViewChild('preloader', {read: ElementRef}) preloaderElement: ElementRef<HTMLElement>;
  dashboardSourceSubscription: Subscription;
  isDashboardOpening: boolean;
  dashboardOpeningSubscription: Subscription;
  animation = '/assets/images/global/ship.json'
  options: AnimationOptions;
  constructor(
    private textWidthService: TextWidthService,
    private dashboardApiFacade: DashboardsApiFacade,
    private dashboardService: DashboardService,) { }

  ngOnInit(): void {
    let animationResources = [
      '/assets/images/global/ship.json' ,
      '/assets/images/global/ship.json',
      '/assets/images/global/ship.json',
      '/assets/images/global/ship.json'
    ]

    let path = animationResources[Math.floor(Math.random() * 4)]

    this.options = {
      path: path
    };


    this.dashboardSourceSubscription = this.dashboardApiFacade.dashboardSource$.subscribe(source => {
      this.placeholderImage = source.userPermitions.myDayDefaultImage;
      this.placeholderText = source.userPermitions.myDayDefaultText;
      setTimeout(() => {
        this.hideClass = "hideSHip";
      }, 5000);
      this.updateDescriptionPartition();

    })

    var userPermitions = this.dashboardApiFacade.getUserPermisions();

    this.placeholderText = (userPermitions != undefined && userPermitions.myDayDefaultText != undefined && userPermitions.myDayDefaultText != null) ? userPermitions.myDayDefaultText : this.placeholderText;

    setTimeout(() => {
      this.updateDescriptionPartition()
    }, 100);

    this.dashboardOpeningSubscription = this.dashboardService.isOpening$.subscribe(isOpening => {
      if (!isOpening) this.updateDescriptionPartition();
    });

  }


  animationCreated(animationItem: AnimationItem): void {
  }
  ngOnDestroy(): void {
    this.dashboardSourceSubscription.unsubscribe();
    this.dashboardOpeningSubscription.unsubscribe();
  }

  @HostListener('window:resize', [])
  updateDescriptionPartition() {
    if (!this.placeholderText || !this.firstLineElement) return;

    var secondLineElementWidth: number;
    var firstLineElementWidth: number;

    firstLineElementWidth = this.firstLineElement.nativeElement.clientWidth;
    firstLineElementWidth = firstLineElementWidth < this.maxFirstLineElementWidth ? firstLineElementWidth : this.maxFirstLineElementWidth;

    if (this.secondLineElement) {
      secondLineElementWidth = this.secondLineElement.nativeElement.clientWidth;
      secondLineElementWidth = secondLineElementWidth < this.maxSecondLineElementWidth ? secondLineElementWidth : this.maxSecondLineElementWidth;
    } else {
      secondLineElementWidth = this.maxSecondLineElementWidth;
    }

    var descriptionToFit = this.getDescriptionToFit(firstLineElementWidth, secondLineElementWidth);
    var length = descriptionToFit.length;
    var half = length % 2 == 0 ? length / 2 : (length - 1) / 2;
    var words = descriptionToFit.split(' ');
    this.firstLine = "";
    var firstLineWidth = 0;

    for (var i = 0; i < words.length; i++) {
      this.firstLine += `${words[i]} `;

      firstLineWidth = this.getTextWidth(this.firstLine + words[i]);
      if (firstLineWidth > firstLineElementWidth || (this.firstLine + words[i]).length > half)
        break;
    }

    this.secondLine = descriptionToFit.substring(this.firstLine.length - 1).trim()
    this.firstLine = this.firstLine.trim()

    var lastChar = this.firstLine.charAt(this.firstLine.length - 1)
    if (this.separators.indexOf(lastChar) > -1) {
      this.firstLine = this.firstLine.substring(0, this.firstLine.length - 1)
    }
  }

  getDescriptionToFit(firstLineWidth: number, secondLineWidth: number): string {
    var allWords = this.placeholderText.split(' ');
    var descriptionToFit = "";
    var i = 0;
    for (var i = 0; i < allWords.length; i++) {
      descriptionToFit += `${allWords[i]} `;
      if (this.getTextWidth(descriptionToFit) >= secondLineWidth + firstLineWidth) break;
    }

    return descriptionToFit.trim();
  }

  getMatch(substr: string, regex: string): string {
    var matches = substr.match(regex);

    return matches ? matches[0] : '';
  }

  getTextWidth(text: string): number {
    return this.textWidthService.getTextWidth(text, this.firstLineElement.nativeElement);
  }
}
