<swiper class="dashboard-content"
    [navigation]="false"
    [slidesPerView]="'auto'"
    [spaceBetween]="14"
    [pagination]="false"
>
    <ng-template *ngIf="userProfileData && cibusData" swiperSlide>
        <div class="dashboard-content__people dashboard-content__tab">
            <app-dashboard-cibus
            [cibusData]="cibusData"
            [userPermitions]="userPermitions"
            ></app-dashboard-cibus>
        </div>
    </ng-template>

    <ng-template *ngIf="!cibusData" swiperSlide>
        <div class="dashboard-content__tab">
            <app-dashboard-coming-soon
                [backgroundImage]="(userPermitions && userPermitions.sibusDefaultImage != null) ? userPermitions.sibusDefaultImage : '/assets/images/dashboard/cibus-coming-soon.png'"
                [additionalInfo]="'Vacation & Sick Days'"
                [sibusDefaultTitle]="(userPermitions && userPermitions.sibusDefaultTitle != null) ? userPermitions.sibusDefaultTitle : 'COMING SOON'"
            ></app-dashboard-coming-soon>
        </div>
    </ng-template>

    <ng-template *ngIf="dashboardEvents" swiperSlide>
        <div class="dashboard-content__tab">
            <app-dashboard-events
                [dashboardEvents]="dashboardEvents"
                (onRefreshCalendarDataEmmiter)="refreshCalendar()"
            ></app-dashboard-events>
        </div>
    </ng-template>

    <ng-template *ngIf="(userPermitions && userPermitions.wellbeingDefaultImage && userPermitions.wellbeingDefaultImage!=null)
    || (monthlyActivitiesPDF != undefined
    && monthlyActivitiesPDF != null
    && monthlyActivitiesPDF.trim() != '')" swiperSlide>
        <div class="dashboard-content__tab">
            <app-dashboard-activities
                [monthlyActivitiesPDF]="monthlyActivitiesPDF"
                [popularActivities]="popularActivities"
                [userPermitions]="userPermitions"
                >
            </app-dashboard-activities>
        </div>
    </ng-template>

    <ng-template *ngIf="userProfileData" swiperSlide>
        <div class="dashboard-content__people dashboard-content__tab">
            <app-dashboard-user-info class="dashboard-content__people__user" *ngIf="userProfileData.hrBpInfo" [title]="'My HR'" [userInfo]="userProfileData.hrBpInfo"></app-dashboard-user-info>
            <app-dashboard-user-info class="dashboard-content__people__user" *ngIf="!userProfileData.hrBpInfo" [title]="'My HR'" ></app-dashboard-user-info>
            <app-dashboard-user-info class="dashboard-content__people__user" *ngIf="userProfileData.hrAdminInfo" [title]="'Admin'" [userInfo]="userProfileData.hrAdminInfo"></app-dashboard-user-info>
            <app-dashboard-user-info class="dashboard-content__people__user" *ngIf="!userProfileData.hrAdminInfo"[title]="'Admin'" ></app-dashboard-user-info>
        </div>
    </ng-template>

    <ng-template *ngIf="!userProfileData" swiperSlide>
      <div class="dashboard-content__people dashboard-content__tab">
          <app-dashboard-user-info class="dashboard-content__people__user" [title]="'My HR'" ></app-dashboard-user-info>
          <app-dashboard-user-info class="dashboard-content__people__user" [title]="'Admin'" ></app-dashboard-user-info>
      </div>
  </ng-template>
</swiper>

<ng-template #templateBirthdayWishes>
    <app-birthday-wishes-modal
    (onCloseModalEmmiter)="this.modalRef?.hide()"
    [birthdayWishes]="birthdayWishes"
    ></app-birthday-wishes-modal>
</ng-template>
